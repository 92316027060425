import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Form } from 'react-bulma-components';
import info_icon from '../../images/info_icon.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class EditableField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: this.props.value,
    };

    this.editableFieldRef = React.createRef();
  }

  render() {
    const { editableField } = this.props.ui;
    let editablePart = null;
    if (editableField && editableField.content === this.props.id && this.editableFieldRef.current) {
      const boundingRect = this.editableFieldRef.current.getBoundingClientRect();
      const top = boundingRect.y - 20;
      const left = boundingRect.x - 20;
      editablePart = (
        <Box className="editable-field-editor" style={{ top, left }}>
          <Form.Field>
            <Form.Control>
              <Form.Input
                autoFocus
                value={this.state.input}
                onChange={this.onInputChange}
                className="editable-input-input"
              />
            </Form.Control>
          </Form.Field>
          {this.props.children}
          <Form.Field kind="group" align="right">
            <Form.Control>
              <button className="button is-secondary" onClick={this.handleClose}>
                Cancel
              </button>
            </Form.Control>
            <Form.Control>
              <button className="button is-primary" onClick={this.onSave}>
                Save
              </button>
            </Form.Control>
          </Form.Field>
        </Box>
      );
    }

    let className = 'editable-input';
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    let editableTextClassName = 'text-overflow-ellipsis ';
    if (this.props.canEdit) {
      editableTextClassName += 'editable-text';
    }
    if (this.props.editableTextClassName) {
      editableTextClassName += ` ${this.props.editableTextClassName}`;
    }

    let inputVal = this.props.value;
    if (!this.props.value && this.props.placeholder) {
      inputVal = this.props.placeholder;
    }

    const toolTip = this.props.toolTip && (
      <span
        className="is-flex mls is-flex-shrink-0"
        data-tooltip-id="matik-tooltip"
        data-tooltip-html={this.props.toolTip}
        data-tooltip-place="bottom"
      >
        <img src={info_icon} alt="Description" height="15px" />
      </span>
    );

    return (
      <div className="text-overflow-ellipsis" ref={this.editableFieldRef}>
        {this.props.canEdit && editablePart}
        <div className={className} onClick={this.props.canEdit ? this.handleOpen : undefined}>
          <span className={editableTextClassName}>{inputVal}</span>
          {toolTip}
        </div>
      </div>
    );
  }

  onInputChange = (e) => {
    e.preventDefault();
    this.setState({ input: e.target.value });
  };

  onSave = (e) => {
    e.preventDefault();

    this.props.onSave(this.state.input);
    this.handleClose(e);
  };

  handleClose = (e) => {
    e.preventDefault();
    this.props.closeEditableField();
  };

  handleOpen = (e) => {
    e.preventDefault();
    this.props.openEditableField(this.props.id);
  };
}

EditableField.propTypes = {
  className: PropTypes.string,
  editableTextClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  value: PropTypes.string,
  toolTip: PropTypes.string,
  children: PropTypes.any,
  canEdit: PropTypes.bool,
  ui: PropTypes.object,
  id: PropTypes.string,
  closeEditableField: PropTypes.func,
  openEditableField: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(EditableField);
