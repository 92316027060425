import React from 'react';
import { useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import { PickColor } from 'components/shared/PickColor';

function EmailBody({ children, style }) {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div ref={(dom) => connect(drag(dom))} style={style}>
      {children}
    </div>
  );
}

EmailBody.craft = {
  props: {
    style: {
      backgroundColor: '#FFFFFF',
      width: '100%',
    },
  },
  rules: {
    canDrag: () => false,
    canDrop: () => false,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: EmailBodySettings,
  },
};

EmailBody.propTypes = {
  style: PropTypes.object,
  children: PropTypes.object,
};

function EmailBodySettings() {
  const {
    actions: { setProp },
    backgroundColor,
  } = useNode((node) => ({
    backgroundColor: node.data.props.style.backgroundColor,
  }));

  const onColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      props.style[propName] = hex;
    });
  };

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Background Color</label>
        <PickColor
          onChange={onColorChange('backgroundColor')}
          hexColor={backgroundColor}
          ancestorEl={document.querySelector('.template-components')}
        />
      </div>
    </div>
  );
}

EmailBodySettings.propTypes = {
  style: PropTypes.object,
};

export default EmailBody;
