import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Progress, Container, Level } from 'react-bulma-components';
import { UserContext } from '../../UserContext';
import WithGooglePicker from '../../shared/WithGooglePicker';
import { ReactComponent as Close } from '../../../svg/close.svg';
import presentation_icon from '../../../images/presentation_icon.svg';
import google_slides_icon from '../../../images/google_slides_icon.svg';
import ppt_icon from '../../../images/ppt_icon.svg';

class TemplateUpload extends Component {
  render() {
    return (
      <div>
        <Form.Field kind="addons">
          <Container className="choose-template-file">
            <img className="pbm choose-template-icon" src={presentation_icon} alt="Presentations" />
            <Form.Control className="has-text-centered">
              <input
                className="file-input ppt-upload"
                accept=".ppt,.pptx"
                type="file"
                name="file"
                onChange={this.props.handleSelectFile}
              />
              <a className="template-file-picker" href="#upload">
                Upload PowerPoint file
              </a>
            </Form.Control>
            {!this.props.hideGoogle && (
              <React.Fragment>
                <span>or</span>
                <Form.Control className="has-text-centered">
                  <a className="template-file-picker" onClick={this.props.onGoogleSlidesClick} href="#pickGoogle">
                    Choose Google Presentation
                  </a>
                </Form.Control>
              </React.Fragment>
            )}
          </Container>
        </Form.Field>
        {this.renderUploadSection()}
        {this.props.isLoading && (
          <div>
            <Progress max={100} value={this.props.percentCompleted} color="success" size="small" />
            <Form.Help>{this.props.processingStatus}</Form.Help>
          </div>
        )}
      </div>
    );
  }

  renderUploadSection() {
    let body = '';
    let icon = '';
    let fileName = '';

    if (this.props.selectedGooglePresentation) {
      icon = <img src={google_slides_icon} alt="Google Slides" />;
      fileName = this.props.selectedGooglePresentation.name;
    } else if (this.props.selectedFile) {
      icon = <img src={ppt_icon} alt="PowerPoint" />;
      fileName = this.props.selectedFile.name;
    }

    if (this.props.selectedGooglePresentation || this.props.selectedFile) {
      body = (
        <div>
          <Level className="template-file-upload">
            <Level.Side align="left">
              <Level.Item>{icon}</Level.Item>
              <Level.Item>
                <span className="file-name">{fileName}</span>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <Close className="template-file-close" onClick={this.props.handleClearSelection} />
              </Level.Item>
            </Level.Side>
          </Level>
        </div>
      );
    }

    return body;
  }
}

TemplateUpload.propTypes = {
  handleSelectFile: PropTypes.func,
  isLoading: PropTypes.bool,
  onGoogleSlidesClick: PropTypes.func,
  percentCompleted: PropTypes.number,
  picker: PropTypes.object,
  processingStatus: PropTypes.string,
  selectedFile: PropTypes.object,
  selectedFileError: PropTypes.string,
  selectedGooglePresentation: PropTypes.object,
  handleClearSelection: PropTypes.func,
  hideGoogle: PropTypes.bool,
};

TemplateUpload.contextType = UserContext;

export default WithGooglePicker(TemplateUpload);
