import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import { Link, useRouteMatch } from 'react-router-dom';
import { filter } from 'lodash';
import CustomSearchList from '../search/CustomSearchList';
import EmailStatusCell from 'components/shared/presentations/EmailStatusCell';
import utils from 'lib/utils';
import API from 'lib/api';
import Constants from 'components/Constants';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';

function RecipientsPanel({ run, fetchCsvData }) {
  const [filteredPresentations, setFilteredPresentations] = useState([]);
  const [fullPresentationList, setFullPresentationList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const match = useRouteMatch();

  useEffect(() => {
    API.fetchContent(
      `/bulk_presentations/${run.id}/presentations/`,
      () => setIsLoading(true),
      onContentFetch,
      0,
      Constants.PANEL_PAGE_SIZE,
    );
  }, []);

  const fetchItems = (page, sort = null) => {
    const offset = page * Constants.PANEL_PAGE_SIZE;
    paginateContent(offset, Constants.PANEL_PAGE_SIZE, sort);
  };

  const paginateContent = (offset, limit = Constants.PANEL_PAGE_SIZE, sort = null) => {
    API.fetchContent(
      `/bulk_presentations/${run.id}/presentations/`,
      () => setIsLoading(true),
      onContentFetch,
      offset,
      limit,
      sort,
    );
  };

  const onContentFetch = (presentations, pagination) => {
    const emailPresentations = presentations.filter(
      (presentation) => presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL,
    );
    setFullPresentationList(emailPresentations);
    // setting state is async so just in case it's slow, pass the email presentations manually to searchRecpiients
    searchRecipients(searchValue, emailPresentations);
    setPagination(pagination);
    setIsLoading(false);
  };

  const emptyInfo = {
    emptyImageUrl: null,
    emptyTitle: 'No emails to display',
  };

  const recipientCell = (row) => <TextOverflowWithTooltip className="text-overflow-ellipsis" text={row.value} />;

  const deliveryStatusCell = (row) => {
    let warnings = [];
    let errors = [];
    if (
      row.original.status === 'done' ||
      row.original.status === 'error' ||
      row.original.status === 'failed condition'
    ) {
      warnings = filter(row.original.logs, { level: Constants.LOG_LEVEL.warning });
      errors = filter(row.original.logs, { level: Constants.LOG_LEVEL.error });
    }

    return <EmailStatusCell presentation={row.original} warnings={warnings} errors={errors} leftMargin="" />;
  };

  const openStatusCell = (row) => {
    return row.value > 0 ? (
      <span className="presentation-status-cell">
        <div className="indicator done mrs"></div>
        Opened
      </span>
    ) : (
      '-'
    );
  };

  const clickedStatusCell = (row) => {
    return row.value > 0 ? (
      <span className="presentation-status-cell">
        <div className="indicator done mrs"></div>
        Clicked
      </span>
    ) : (
      '-'
    );
  };

  const unsubscribeStatusCell = (row) => {
    return row.value === true ? (
      <span className="presentation-status-cell">
        <div className="indicator error mrs"></div>
        Unsubscribed
      </span>
    ) : (
      '-'
    );
  };

  const spamStatusCell = (row) => {
    return row.value === true ? (
      <span className="presentation-status-cell">
        <div className="indicator error mrs"></div>
        Reported Spam
      </span>
    ) : (
      '-'
    );
  };

  const openPresentationCell = (row) => {
    if (row.original.deleted) {
      return null;
    }
    const linkUrl = match.path.includes('create')
      ? `/create/presentations/${row.value}`
      : `/presentations/${row.value}`;
    return (
      <Link target="_blank" to={linkUrl} className="mll button small square pan is-secondary">
        <Icon name="open" size={16} theme="regular" />
      </Link>
    );
  };

  const onSearchChange = (e) => {
    const searchString = e.target.value;
    searchRecipients(searchString);
  };

  const searchRecipients = (searchString, emailPresentations = []) => {
    const presentationsToSearch = fullPresentationList.length ? fullPresentationList : emailPresentations;
    setSearchValue(searchString);
    if (searchString.length) {
      setFilteredPresentations(
        presentationsToSearch.filter((presentation) =>
          presentation.sendgrid_data?.recipient?.toLowerCase().includes(searchString.toLowerCase()),
        ),
      );
    } else {
      setFilteredPresentations(presentationsToSearch);
    }
  };
  const columns = [
    {
      id: 'recipient',
      Header: utils.tableHeader('Recipient'),
      accessor: (presentation) => presentation.sendgrid_data?.recipient || '-',
      Cell: recipientCell,
    },
    {
      id: 'delivery_status',
      Header: utils.tableHeader('Delivery Status'),
      className: 'overflow-visible',
      accessor: (presentation) => presentation.sendgrid_data?.delivery_status || '-',
      Cell: deliveryStatusCell,
    },
    {
      id: 'open_status',
      Header: utils.tableHeader('Open Status'),
      accessor: (presentation) => presentation.sendgrid_data?.opens || '-',
      Cell: openStatusCell,
    },
    {
      id: 'click_status',
      Header: utils.tableHeader('Clicked Status'),
      accessor: (presentation) => presentation.sendgrid_data?.clicks || '-',
      Cell: clickedStatusCell,
    },
    {
      id: 'unsubscribe_status',
      Header: utils.tableHeader('Unsubscribe Status'),
      accessor: (presentation) => presentation.sendgrid_data?.unsubscribe,
      Cell: unsubscribeStatusCell,
    },
    {
      id: 'spam_status',
      Header: utils.tableHeader('Spam Report Status'),
      accessor: (presentation) => presentation.sendgrid_data?.spam_report,
      Cell: spamStatusCell,
    },
    {
      id: 'open_presentation',
      accessor: (presentation) => presentation.id,
      Cell: openPresentationCell,
    },
  ];

  return (
    <div className="analytics-panel">
      <div className="analytics-panel-title">
        Recipients
        <button className="button is-secondary" type="button" onClick={() => fetchCsvData(run.id)}>
          <Icon name="arrow_download" size={20} theme="regular" />
          <span className="ml-2">Download CSV</span>
        </button>
      </div>
      <div className="mlm">
        <CustomSearchList
          isLoading={isLoading}
          presentations={filteredPresentations}
          elements={filteredPresentations}
          emptyInfo={emptyInfo}
          pagination={pagination}
          searchValue={searchValue}
          columns={columns}
          onSearchChange={onSearchChange}
          fetchItems={fetchItems}
        />
      </div>
    </div>
  );
}

RecipientsPanel.propTypes = {
  run: PropTypes.object,
  fetchCsvData: PropTypes.func,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  entityType: PropTypes.string,
};

export default RecipientsPanel;
