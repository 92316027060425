import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useEditor, useNode } from '@craftjs/core';
import 'react-autocomplete-input/dist/bundle.css';
import Indicator from './Indicator';
import Constants from '../../../Constants';
import WYSIWYGEditor from 'components/shared/WYSIWYGEditor';
import RichTextColorPicker from './RichTextColorPicker';
import { PickColor } from 'components/shared/PickColor';
import PaddingInput from 'components/shared/PaddingInput';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';
import { MAlert } from '../../../shared/Alerts';

import align_center_icon from '../../../../images/wysiwyg/align-center.svg';
import align_left_icon from '../../../../images/wysiwyg/align-left.svg';
import align_right_icon from '../../../../images/wysiwyg/align-right.svg';
import bold_icon from '../../../../images/wysiwyg/bold.svg';
import color_icon from '../../../../images/wysiwyg/color.svg';
import emoji_icon from '../../../../images/wysiwyg/emoji.svg';
import font_size from '../../../../images/wysiwyg/font-size.svg';
import italic_icon from '../../../../images/wysiwyg/italic.svg';
import link_icon from '../../../../images/wysiwyg/link.svg';
import list_ordered_icon from '../../../../images/wysiwyg/list-ordered.svg';
import list_unordered_icon from '../../../../images/wysiwyg/list-unordered.svg';
import underline_icon from '../../../../images/wysiwyg/underline.svg';
import unlink_icon from '../../../../images/wysiwyg/unlink.svg';
import FontDropdown from './FontDropdown';
import { useAllDynamicContentById } from '../../../../lib/hooks/useDynamicContent';

function DraggableText({ style, text, previewedText, isNewNode }) {
  const [editable, setEditable] = useState(false);
  const { dynamicContentById } = useAllDynamicContentById();
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(previewedText.length > 0 ? true : false);
  const { testResult, setIsFetchingDc, setTestResult, setAllDynamicContent, allDynamicContent, onPreviewDcModal } =
    useContext(HtmlBuilderContext);

  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    actions: { setProp },
    connectors: { connect, drag },
    hasSelectedNode,
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
    hasSelectedNode: state.events.selected,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [isActive]);

  let validDynamicContent = [];

  if (dynamicContentById) {
    validDynamicContent = Object.values(dynamicContentById).filter(
      (dc) => dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT,
    );
  }
  const allTextDc = validDynamicContent.reduce((dynamicContentObj, item) => {
    dynamicContentObj[item.name] = { ...item };
    return dynamicContentObj;
  }, {});

  const autoCompleteOptions = validDynamicContent.map((content) => `{{${content.name}}}`);

  // on preview dynamic content
  function onPreviewDc() {
    if (isReceivingData) {
      setIsReceivingData(false);
      setIsFetchingDc(false);
    } else {
      setIsReceivingData(true);

      // extract all dynamic content from text and create object
      const textDcKey = [...text.matchAll(/\{\{([^}]+)\}\}/g)].map((match) => match[1]);
      const allDynamicContent = textDcKey.reduce((dynamicContentObj, key) => {
        const baseKey = key.split(/[|]/)[0];
        if (allTextDc[baseKey]) {
          dynamicContentObj[baseKey] = allTextDc[baseKey];
        }
        return dynamicContentObj;
      }, {});
      if (Object.keys(allDynamicContent).length > 0) {
        setAllDynamicContent(allDynamicContent);
      } else {
        setIsReceivingData(false);
        MAlert('No Dynamic Content to preview', 'Error', 'error');
      }
    }
  }

  // when allDynamicContent is set open inputs modal
  useEffect(() => {
    if (isReceivingData && Object.keys(allDynamicContent).length > 0) {
      onPreviewDcModal();
    }
  }, [allDynamicContent]);

  // on test result return
  useEffect(() => {
    if (isReceivingData && Object.keys(testResult).length > 0) {
      if (testResult?.status === 'error') {
        MAlert(`${testResult.message}`, 'Error', 'error');
        setIsFetchingDc(false);
      } else {
        let updatePreviewedText = text;
        const placeholders = [...updatePreviewedText.matchAll(/\{\{([^}]+)\}\}/g)];
        placeholders.forEach((match) => {
          const fullPlaceholder = match[0];
          const baseKey = match[1].split(/[|]/)[0];

          if (baseKey in testResult) {
            updatePreviewedText = updatePreviewedText.replace(fullPlaceholder, testResult[baseKey]);
          }
        });

        setProp((props) => {
          props.previewedText = updatePreviewedText;
        });
        setIsPreviewed(true);
        setIsFetchingDc(false);
        // deselect node
        selectNode(null);
      }
      setIsReceivingData(false);
      setTestResult({});
    }
  }, [testResult]);

  // on node selection, set text to non-previewed value
  useEffect(() => {
    if (isActive && isPreviewed) {
      setIsPreviewed(false);
      setProp((props) => {
        props.previewedText = '';
      });
    }
  }, [isActive]);

  return (
    <div ref={(dom) => connect(dom)} className="is-relative" style={style}>
      {(isActive || isHover) && (
        <Indicator
          borderWidth="2px"
          borderRadius="4px"
          tabText="Text"
          isActive={isActive}
          nodeId={id}
          drag={drag}
          onPreviewDc={onPreviewDc}
          isLoading={isReceivingData}
          isPreviewed={isPreviewed}
        />
      )}
      <WYSIWYGEditor
        autoResize={true}
        className="long-input"
        closingPattern="}}"
        floatingToolbar={true}
        id="template-desc"
        name="html-builder-text-input"
        paddingOffset={parseInt(style.paddingTop) + parseInt(style.paddingBottom)}
        placeholder="Type your text here"
        showAutoComplete={hasSelectedNode}
        suggestions={autoCompleteOptions}
        toolbarHidden={!editable}
        toolOptions={draggableTextToolbarOptions}
        triggerPattern="{{"
        updateEditor={(value) => setProp((props) => (props.text = value))}
        updateTextHTML={(value) => setProp((props) => (props.textHTML = value))}
        value={isPreviewed ? previewedText : text}
        isPreviewed={isPreviewed}
      />
    </div>
  );
}

DraggableText.craft = {
  props: {
    text: '',
    textHTML: '',
    previewedText: '',
    isNewNode: true,
    style: {
      backgroundColor: '#FFFFFF',
      paddingBottom: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
    },
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
  related: {
    settings: TextSettings,
  },
};

DraggableText.propTypes = {
  dynamicContent: PropTypes.array,
  style: PropTypes.object,
  text: PropTypes.string,
  textHTML: PropTypes.string,
  previewedText: PropTypes.string,
  isNewNode: PropTypes.bool,
};

const draggableTextToolbarOptions = {
  blockType: {
    className: 'rich-text-component',
    dropdownClassName: 'rich-text-component dropdown',
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
  },
  colorPicker: {
    icon: color_icon,
    component: RichTextColorPicker,
  },
  emoji: {
    icon: emoji_icon,
    className: 'wysiwyg-button',
  },
  fontFamily: {
    component: FontDropdown,
  },
  fontSize: {
    className: 'rich-text-component',
    dropdownClassName: 'rich-text-component dropdown',
    fontSizeOptions: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    icon: font_size,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: bold_icon, className: 'wysiwyg-button' },
    italic: { icon: italic_icon, className: 'wysiwyg-button' },
    underline: { icon: underline_icon, className: 'wysiwyg-button' },
  },
  link: {
    link: { icon: link_icon, className: 'wysiwyg-button' },
    unlink: { icon: unlink_icon, className: 'wysiwyg-button' },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: { icon: list_unordered_icon, className: 'wysiwyg-button' },
    ordered: { icon: list_ordered_icon, className: 'wysiwyg-button' },
  },
  options: ['blockType', 'fontFamily', 'fontSize', 'colorPicker', 'inline', 'list', 'textAlign', 'link', 'emoji'],
  textAlign: {
    options: ['left', 'center', 'right'],
    left: { icon: align_left_icon, className: 'wysiwyg-button' },
    center: { icon: align_center_icon, className: 'wysiwyg-button' },
    right: { icon: align_right_icon, className: 'wysiwyg-button' },
  },
};

function TextSettings() {
  const {
    actions: { setProp },
    backgroundColor,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = useNode((node) => ({
    backgroundColor: node.data.props.style.backgroundColor,
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingLeft: parseInt(node.data.props.style.paddingLeft),
    paddingRight: parseInt(node.data.props.style.paddingRight),
    paddingTop: parseInt(node.data.props.style.paddingTop),
  }));

  const onColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[propName] = hex;
    });
  };

  const onNumberChange = (num, incomingProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[incomingProp] = `${num}px`;
    });
  };

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Background color</label>
        <PickColor
          onChange={onColorChange('backgroundColor')}
          hexColor={backgroundColor}
          ancestorEl={document.querySelector('.template-components')}
        />
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onNumberChange}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
          />
        </div>
      </div>
    </div>
  );
}

export default DraggableText;
