import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Constants from '../Constants';
import ReactTablePagination from './ReactTablePagination';
import LoadingOverlay from './LoadingOverlay';
import localeDateFormatterUtils from '../../lib/localeDate';
import { withRouter } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../images/icons/copy-20x20-regular.svg';
import Icon from '../lib/Icon';
import utils from 'lib/utils';

class FullPageList extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.componentDidUpdate) {
      this.props.componentDidUpdate(prevProps, this.props);
    }
  }

  render() {
    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(`{{${text}}}`);
        utils.notify('Copied to clipboard');
      } catch (err) {
        utils.notify('Failed to copy to clipboard');
      }
    };

    // render a null TheadComponent when we need to hide the header
    const headerSuppressor = this.props.hideHeader ? { TheadComponent: () => null } : {};
    let table;
    const columnsWithFormattedDates = localeDateFormatterUtils.formatDatesInArrayOfObjects(this.props.columns);

    const emailEditorType = this.props.emailEditorType;

    const shouldDisplayCopyButton = (row) => {
      const dynamicContentType = row.value.dynamic_content_type;

      const isTextOrImage =
        dynamicContentType === Constants.DynamicContentTypes.TEXT ||
        dynamicContentType === Constants.DynamicContentTypes.IMAGE;

      return (
        !emailEditorType ||
        (emailEditorType === 'code' && isTextOrImage) ||
        (emailEditorType === 'visual' && dynamicContentType === Constants.DynamicContentTypes.TEXT)
      );
    };

    const renderCopyButtonCell = (row) => {
      if (shouldDisplayCopyButton(row)) {
        return (
          <button
            className="icon-button"
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(row.value.name, row.value.dynamic_content_type);
            }}
          >
            <Icon>
              <CopyIcon />
            </Icon>
          </button>
        );
      }
      return null;
    };

    const columnsWithCopyButton = [
      ...columnsWithFormattedDates,
      {
        Header: '',
        id: 'copy',
        accessor: (d) => ({ name: d.name, dynamic_content_type: d.dynamic_content_type }),
        Cell: renderCopyButtonCell,
        width: 50,
        sortable: false,
        filterable: false,
      },
    ];

    if (!this.props.pagination) {
      table = (
        <ReactTable
          data={this.props.entitiesToRender}
          columns={columnsWithFormattedDates}
          resizable={false}
          getNoDataProps={this.getNoDataProps}
          pageSize={200}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          showPagination={false}
          loading={this.props.loading}
          LoadingComponent={LoadingOverlay}
          {...headerSuppressor}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
          minRows={0}
        />
      );
    } else {
      let sorted = [];
      if (this.props.pagination.sort && this.props.pagination.sort.length > 0) {
        sorted = [{ id: this.props.pagination.sort[0], desc: this.props.pagination.sort[1] === 'desc' }];
      }
      let showPagination = false;
      if (this.props.pagination.numberOfPages > 1) {
        showPagination = true;
      }
      table = (
        <ReactTable
          data={this.props.entitiesToRender}
          columns={this.props.emailEditorType ? columnsWithCopyButton : columnsWithFormattedDates}
          resizable={false}
          showPaginationTop={!this.props.hideTopPagination}
          PaginationComponent={ReactTablePagination}
          getNoDataProps={this.getNoDataProps}
          pageSize={Constants.PAGE_SIZE}
          manual
          page={this.props.pagination.currentPage}
          loading={this.props.loading}
          LoadingComponent={LoadingOverlay}
          pages={this.props.pagination.numberOfPages}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          sorted={sorted}
          showPagination={showPagination}
          {...headerSuppressor}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
          minRows={0}
          onSortedChange={this.onSortedChange}
          onPageChange={this.onPageChange}
        />
      );
    }
    return table;
  }

  getTdProps = () => {
    if (this.props.match.params.tab === Constants.Tabs.FLOWS) {
      return { className: 'full-page-list-cell pvm phn' };
    }
    return { className: 'full-page-list-cell is-size-5' };
  };

  getTrProps = (_state, rowInfo) => {
    let classNameString = 'full-page-list-row has-text-grey-dark has-cursor-pointer';
    if (this.props.match.params.tab === Constants.Tabs.FLOWS) {
      classNameString = 'full-page-list-row has-cursor-pointer';
    }

    if (this.props.selectedRow && this.props.selectedRow.id === parseInt(rowInfo.original.id)) {
      classNameString += ' card-selected';
    }

    if (this.props.hideHeader) {
      classNameString += ' is-borderless';
    }

    return {
      className: classNameString,
      onClick: (e) => this.onRowClick(e, rowInfo),
      id: `row-item-${rowInfo.original.id}`,
    };
  };

  getProps = () => {
    return { className: 'full-page-list-table -highlight' };
  };

  getThProps = () => {
    return { className: 'full-page-list-header' };
  };

  getTbodyProps = () => {
    if (this.props.scrollInElement) {
      if (this.props.pagination && this.props.pagination.numberOfPages > 1) {
        return { className: 'tbody-scrollable' };
      } else {
        return { className: 'tbody-scrollable-no-pagination' };
      }
    }

    return {};
  };

  getNoDataProps = () => {
    return { style: { bottom: 0, background: 'transparent' }, className: 'mtl' };
  };

  onRowClick = (e, rowInfo) => {
    if (this.props.isSearchable) {
      e.preventDefault();
    } else {
      e.persist();
    }
    if (e.target.tagName.toLowerCase() === 'label' || e.target.tagName.toLowerCase() === 'input') {
      return;
    }
    if (e.target.classList && e.target.classList.contains('presentation-list-menu-icon')) {
      return;
    }
    if (
      e.target.className &&
      typeof e.target.className === 'string' &&
      e.target.className.split(' ').includes('checkbox-cell')
    ) {
      return;
    }
    if (this.props.onRowClick) {
      if (rowInfo.original?.flow_type) {
        this.props.onRowClick(rowInfo.original.flow_type, rowInfo.original.id);
      } else {
        this.props.onRowClick(rowInfo.original.id);
      }
    }
    if (this.props.onHeaderClick) {
      this.props.onHeaderClick(e, rowInfo.original.id);
    }
  };

  onSortedChange = (newSorted) => {
    let sorted = null;
    if (newSorted && newSorted.length > 0) {
      sorted = [newSorted[0].id, newSorted[0].desc ? 'desc' : 'asc'];
    }
    this.props.fetchItems(this.props.pagination.currentPage, sorted);
  };

  onPageChange = (pageIndex) => {
    this.props.fetchItems(pageIndex, this.props.pagination.sort);
  };
}

FullPageList.propTypes = {
  match: PropTypes.any,
  entitiesToRender: PropTypes.array,
  columns: PropTypes.array,
  fetchItems: PropTypes.func,
  hideHeader: PropTypes.bool,
  hideTopPagination: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  pagination: PropTypes.object,
  scrollInElement: PropTypes.bool,
  selectedRow: PropTypes.object,
  children: PropTypes.any,
  location: PropTypes.object,
  onHeaderClick: PropTypes.func,
  isSearchable: PropTypes.bool,
  emailEditorType: PropTypes.string,
  componentDidUpdate: PropTypes.func,
};

export default withRouter(FullPageList);
