import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from 'react-bulma-components';
import { mapAnalyticsUsersStateToProps } from '../../../redux/users/stateMappers';
import { mapUsersDispatchToProps } from '../../../redux/users/dispatchers';
import utils from '../../../lib/utils';
import FullPageList from '../../shared/FullPageList';
import { NameCell, RoleCell } from '../../shared/FullPageListCells';
import Constants from '../../Constants';

class AnalyticsUserList extends Component {
  render() {
    const columns = [
      { id: 'name', Header: utils.tableHeader('Name'), accessor: (d) => (d.name ? d.name : d.email), Cell: NameCell },
      { id: 'role', Header: utils.tableHeader('Role'), Cell: RoleCell },
      {
        id: 'presentation_count',
        Header: utils.tableHeader('Presentations'),
        accessor: (d) => (d.total_presentations ? d.total_presentations : 0),
      },
      {
        id: 'avg_presentations',
        Header: utils.tableHeader('Avg Presentations / Month'),
        accessor: (d) => (d.monthly_presentations_avg ? d.monthly_presentations_avg : 0),
      },
    ];

    return (
      <Box className="is-shadowless is-paddingless">
        <FullPageList
          entitiesToRender={this.props.users}
          columns={columns}
          onRowClick={this.onRowClick}
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
        />
      </Box>
    );
  }

  componentDidMount() {
    this.props.fetchAnalyticsUsersIfNeeded();
  }

  fetchItems = (pageIndex, sort) => {
    const offset = pageIndex * Constants.PAGE_SIZE;
    this.props.fetchAnalyticsUsersIfNeeded(offset, Constants.PAGE_SIZE, sort, this.props.pagination.count);
  };
}

AnalyticsUserList.propTypes = {
  isFetching: PropTypes.bool,
  pagination: PropTypes.object,
  users: PropTypes.array,
  fetchAnalyticsUsersIfNeeded: PropTypes.func,
};

export default connect(mapAnalyticsUsersStateToProps, mapUsersDispatchToProps)(AnalyticsUserList);
