import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import API from 'lib/api';
import { AvatarSelect } from 'components/shared/FormSelect';
import Constants from 'components/Constants';
import team_icon from 'images/team.svg';

function AccessSelector(props) {
  const { data, isLoading } = useQuery({
    queryKey: ['enterprises', 'users groups'],
    queryFn: () => {
      return API.getMultiple(
        ['/enterprises/users/', '/enterprises/groups/'],
        (usersResponse, groupsResponse) => {
          return {
            users: usersResponse.data,
            groups: groupsResponse.data,
          };
        },
        API.defaultError,
      );
    },
    staleTime: 0,
  });

  let userAccesses;
  if (props.producersOnly) {
    userAccesses = data?.users?.filter(
      (user) => user.roles && (user.roles.indexOf('producer') >= 0 || user.roles.indexOf('producer_admin') >= 0),
    );
  } else {
    userAccesses = data?.users;
  }
  const userAccessOptions = userAccesses?.map((user) => ({
    value: `user_${user.id}`,
    label: user.name ? user.name : user.email,
    photo_url: user.photo_url,
  }));

  const groupAccessOptions = data?.groups?.map((group) => ({
    value: `group_${group.id}`,
    label: group.name,
  }));

  const artificialGroupAccessOptions = [];
  if (!props.producersOnly) {
    artificialGroupAccessOptions.push({
      value: `group_${Constants.ROLE_ID_MAPPING.consumer}`,
      label: Constants.ROLE_GROUP_ACCESS_MAPPING[Constants.ROLE_ID_MAPPING.consumer],
      photo_url: team_icon,
    });
  }
  artificialGroupAccessOptions.push({
    value: `group_${Constants.ROLE_ID_MAPPING.producer}`,
    label: Constants.ROLE_GROUP_ACCESS_MAPPING[Constants.ROLE_ID_MAPPING.producer],
    photo_url: team_icon,
  });

  const accessOptions = [
    { options: artificialGroupAccessOptions },
    { options: userAccessOptions },
    { options: groupAccessOptions },
  ];

  const selectedAccesses = [];
  for (let accessValue in props.accesses) {
    const accessLabel = props.accesses[accessValue];
    selectedAccesses.push({ label: accessLabel, value: accessValue });
  }
  return (
    <AvatarSelect
      className={props.className}
      options={accessOptions}
      onChange={props.onChange}
      menuPlacement={props.menuPlacement}
      styles={{
        group: (base) => ({
          ...base,
          borderBottom: '1px solid #E0E5EE', // grey-300
          marginBottom: '0',
          paddingTop: '1px',
          paddingBottom: '4px',
        }),
      }}
      isLoading={isLoading}
      isDisabled={isLoading}
      isMulti={true}
      placeholder={isLoading ? 'Fetching User List...' : 'Enter Names or Groups to Give Access'}
      classNamePrefix="access-select"
      value={selectedAccesses}
    />
  );
}
AccessSelector.propTypes = {
  accesses: PropTypes.object,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  producersOnly: PropTypes.bool,
  styles: PropTypes.object,
  className: PropTypes.string,
};

export default AccessSelector;
