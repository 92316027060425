const roleNames = [
  { name: 'producer', label: 'Admin' },
  { name: 'consumer', label: 'End User' },
  { name: 'superadmin', label: 'Super Admin' },
  { name: 'producer_admin', label: 'Producer Admin' },
];

// react-admin has a native ability to let admins add custom roles.
// any additional role MUST BE ADDED TO THIS ARRAY HERE or the custom roles will NOT work

export default roleNames;
