import React, { useState, useEffect, useContext } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { Level } from 'react-bulma-components';
import PropTypes from 'prop-types';
import FileUpload from 'components/shared/fileUpload/FileUpload';
import { toast } from 'react-toastify';
import PaddingInput from 'components/shared/PaddingInput';
import FormInput from 'components/shared/FormInput';
import UploadedFileBanner from 'components/shared/fileUpload/UploadedFileBanner';
import Indicator from './Indicator';
import HtmlBuilderImageEmptyOrDcState from './HtmlBuilderImageEmptyOrDcState';
import { ReactComponent as TextAlignLeftIcon } from 'svg/text_align_left.svg';
import { ReactComponent as TextAlignCenterIcon } from 'svg/text_align_center.svg';
import { ReactComponent as TextAlignRightIcon } from 'svg/text_align_right.svg';
import utils from 'lib/utils';
import { useDispatch } from 'react-redux';
import { openSidepane } from 'redux/ui/action';
import Constants from 'components/Constants';
import Button from '../../../lib/Button';
import Tabs from '../../../lib/Tabs';
import TabItem from '../../../lib/TabItem';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';
import { MAlert } from '../../../shared/Alerts';
import InputDynamicContent from '../../../producer/dynamicContent/InputDynamicContent';
import { useAllDynamicContentById } from '../../../../lib/hooks/useDynamicContent';

function Image({ imageAlignment, imageDc, imageURL, selectImageType, style, isNewNode }) {
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(imageURL.length > 0 ? true : false);
  const { testResult, setAllDynamicContent, allDynamicContent, onPreviewDcModal, setTestResult, setIsFetchingDc } =
    useContext(HtmlBuilderContext);

  const { id } = useNode((node) => ({
    id: node.id,
  }));

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    actions: { setProp },
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  useEffect(() => {
    if (imageURL.length === 0) {
      setIsPreviewed(false);
    } else {
      setIsPreviewed(true);
    }
  }, [imageURL]);

  // on preview dynamic content
  function onPreviewDc() {
    if (isReceivingData) {
      setIsReceivingData(false);
      setIsFetchingDc(false);
    } else {
      setIsReceivingData(true);
      const previewImageDc = {
        [imageDc.name]: imageDc,
      };
      setAllDynamicContent(previewImageDc);
    }
  }

  // when allDynamicContent is set open inputs modal
  useEffect(() => {
    if (isReceivingData && Object.keys(allDynamicContent).length > 0) {
      onPreviewDcModal();
    }
  }, [allDynamicContent]);

  // on test result return
  useEffect(() => {
    const fetchData = async () => {
      if (isReceivingData) {
        if (testResult?.status === 'error') {
          MAlert(`${testResult.message}`, 'Error', 'error');
          setIsFetchingDc(false);
        } else {
          let imageUrl = testResult[imageDc.name];
          if (imageUrl.startsWith('data:image/')) {
            setProp((props) => (props.imageURL = imageUrl));
            setIsPreviewed(true);
          } else {
            try {
              const base64url = await utils.convertImageUrlToBase64(imageUrl);
              setProp((props) => (props.imageURL = base64url));
              setIsPreviewed(true);
            } catch (error) {
              MAlert('Failed to convert image.', 'Error', 'error');
            }
          }
          setIsFetchingDc(false);
        }
        setIsReceivingData(false);
        setTestResult({});
      }
    };

    fetchData();
  }, [testResult]);

  return (
    <div
      className="is-relative is-transparent"
      ref={(dom) => connect(dom)}
      style={{ display: 'flex', justifyContent: imageAlignment }}
    >
      {(isActive || isHover) && (
        <Indicator
          borderWidth="0px"
          borderRadius="4px"
          tabText="Image"
          isActive={isActive}
          nodeId={id}
          drag={drag}
          onPreviewDc={
            Object.keys(imageDc).length > 0 && imageDc.dynamic_content_method !== 'static' ? onPreviewDc : null
          }
          isLoading={isReceivingData}
          isPreviewed={isPreviewed}
        />
      )}
      {!imageURL && <HtmlBuilderImageEmptyOrDcState dcName={imageDc.name} selectImageType={selectImageType} />}
      <img style={style} src={imageURL} hidden={!imageURL} align={imageAlignment} />
    </div>
  );
}

Image.craft = {
  props: {
    imageAlignment: 'left',
    imageDc: {},
    imageResponsiveness: 'on',
    imageURL: '',
    selectImageType: 'static',
    isNewNode: true,
    style: {
      paddingBottom: '0px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      width: '35%',
    },
  },
  related: {
    settings: ImageSettings,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
};

Image.propTypes = {
  imageAlignment: PropTypes.string,
  imageDc: PropTypes.object,
  imageResponsiveness: PropTypes.string,
  imageURL: PropTypes.string,
  justifyContent: PropTypes.string,
  selectImageType: PropTypes.string,
  style: PropTypes.object,
  isNewNode: PropTypes.bool,
};

function ImageSettings() {
  const {
    actions: { setProp },
    imageAlignment,
    imageDc,
    imageResponsiveness,
    imageURL,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    selectImageType,
    width,
  } = useNode((node) => ({
    imageAlignment: node.data.props.imageAlignment,
    imageDc: node.data.props.imageDc,
    imageResponsiveness: node.data.props.imageResponsiveness,
    imageURL: node.data.props.imageURL,
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingLeft: parseInt(node.data.props.style.paddingLeft),
    paddingRight: parseInt(node.data.props.style.paddingRight),
    paddingTop: parseInt(node.data.props.style.paddingTop),
    selectImageType: node.data.props.selectImageType,
    width: parseInt(node.data.props.style.width),
  }));

  const [imageFile, setImageFile] = useState('');
  const [imageName, setImageName] = useState('');
  const [showDropZone, setShowDropZone] = useState(imageURL === '');
  const dispatch = useDispatch();

  const onUpdatePercentInputValue = (e, propName) => {
    const value = e.target.value;
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[propName] = `${value}%`;
    });
  };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      props.style[paddingProp] = `${num}px`;
    });
  };

  const onSelectImageTypeChange = (e) => {
    setProp((props) => {
      props.imageDc = {};
      props.imageURL = '';
      props.selectImageType = e;
    });
    setImageFile('');
    setImageName('');
    setShowDropZone(true);
  };

  const onImageAlignmentChange = (e) => {
    setProp((props) => {
      props.imageAlignment = e;
    });
  };

  const onImageResponsivenessChange = (e) => {
    setProp((props) => {
      props.imageResponsiveness = e;
    });
  };

  const onSelectFile = (acceptedFiles) => {
    let file = acceptedFiles?.[0];
    if (!file) {
      return toast.error('Invalid File Type');
    }
    // read the file contents to render a preview image
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (readerEvent) => {
      const content = readerEvent.target.result;
      setImageFile(content);
    };
    setImageName(file.name);
  };

  const onDynamicContentChange = async (dynamicContent) => {
    if (dynamicContent.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
      setProp((props) => {
        props.imageDc = dynamicContent;
        props.imageURL = dynamicContent.query_obj.query_string;
      });
    } else {
      setProp((props) => {
        props.imageDc = dynamicContent;
        props.imageURL = '';
      });
    }
    return dynamicContent;
  };

  const editOnClick = () => {
    dispatch(openSidepane(utils.getTagFromSingleDynamicContent(imageDc), 'template-sidepane'));
  };

  const onUploadImage = () => {
    setProp((props) => {
      props.imageURL = imageFile;
    });
    setShowDropZone(false);
  };

  const onChooseAnotherImage = () => {
    setShowDropZone(true);
  };

  const { dynamicContentById } = useAllDynamicContentById();

  return (
    <div className="module-settings-container">
      <div className="module-setting">
        <label>Select Image</label>
        <Level.Item className="has-text-centered">
          <Tabs>
            <TabItem label="Static" isActive={selectImageType === 'static'} onClick={onSelectImageTypeChange} />
            <TabItem label="Dynamic" isActive={selectImageType === 'dynamic'} onClick={onSelectImageTypeChange} />
          </Tabs>
        </Level.Item>
      </div>
      {selectImageType === 'static' ? (
        <div className="pts">
          {showDropZone ? (
            <>
              <FileUpload onSelectFile={onSelectFile} buttonText="Upload an image to Matik" inputAccept="image/*" />
              {imageFile && (
                <div className="module-setting">
                  <UploadedFileBanner
                    onCancel={() => {
                      setImageFile('');
                      setImageName('');
                    }}
                    uploadedFileName={imageName}
                    uploadedIconSrc={imageFile}
                  />
                  <button type="button" className="button is-primary" onClick={() => onUploadImage()}>
                    Upload
                  </button>
                </div>
              )}
              <div className="module-setting">
                <p className="sidebar-description">
                  Select image straight from your desktop to upload. To replace this image with an image from your
                  datasource switch to “Dynamic”.
                </p>
              </div>
            </>
          ) : (
            <div className="center-children">
              <img src={imageURL} alt="Static" style={{ width: '180px' }} />
              <p>
                <Button
                  type="button"
                  category="secondary"
                  onClick={() => onChooseAnotherImage()}
                  className="button mtm"
                >
                  Choose another image
                </Button>
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="pts">
            <InputDynamicContent
              onEditClick={editOnClick}
              onChange={onDynamicContentChange}
              content={imageDc}
              acceptedContentTypes={[Constants.DynamicContentTypes.IMAGE]}
              showSubtitle={true}
              allDynamicContentNamesById={dynamicContentById}
            />
          </div>
          <div className="module-setting">
            <p className="sidebar-description">
              To replace this image with an image from your datasource select a piece of dynamic content.
            </p>
          </div>
        </div>
      )}
      <div className="module-settings-divider" />
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onPaddingChange}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
          />
        </div>
      </div>
      <div className="module-setting ptxs pbxs">
        <label>Image Alignment</label>
        <div className="html-builder-settings-icons">
          <TextAlignLeftIcon
            className={`html-builder-settings-button ${imageAlignment === 'left' ? 'selected' : ''}`}
            onClick={() => onImageAlignmentChange('left')}
          />
          <TextAlignCenterIcon
            className={`html-builder-settings-button ${imageAlignment === 'center' ? 'selected' : ''}`}
            onClick={() => onImageAlignmentChange('center')}
          />
          <TextAlignRightIcon
            className={`html-builder-settings-button ${imageAlignment === 'right' ? 'selected' : ''}`}
            onClick={() => onImageAlignmentChange('right')}
          />
        </div>
      </div>
      <div className="module-setting pts pbxs">
        <label>Image Responsiveness</label>
        <Level.Item className="has-text-centered">
          <Tabs>
            <TabItem label="On" isActive={imageResponsiveness === 'on'} onClick={onImageResponsivenessChange} />
            <TabItem label="Off" isActive={imageResponsiveness === 'off'} onClick={onImageResponsivenessChange} />
          </Tabs>
        </Level.Item>
      </div>
      <div className="module-setting">
        <label>Width</label>
        <FormInput
          inputClass="module-settings-input pixel-input"
          onChange={(e) => onUpdatePercentInputValue(e, 'width')}
          suffix={<span>%</span>}
          type="number"
          value={width}
        />
      </div>
    </div>
  );
}

export default Image;
