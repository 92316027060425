import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import { isEqual } from 'lodash';
import Constants from '../../../Constants';
import InputMapping from '../InputMapping';
import utils from '../../../../lib/utils';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import SalesforceFilterButtons from './SalesforceFilterButtons';
import RenamableModal from '../RenamableModal';
import info_icon from '../../../../images/info_icon.svg';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import dataSources from '../../../../lib/dataSources';
import SenderApiReturnFields from './SenderApiReturnFields';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { components } from 'react-select';

class GainsightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnFields: [],
      showFormFields: false,
      currentlyRenamingField: null,
      displayNameUpdate: false,
    };
  }

  componentDidMount() {
    if (
      this.props.queryObj &&
      this.props.apiInfo.objects &&
      this.props.queryObj.selectedObject &&
      this.props.apiInfo.objects[this.props.queryObj.selectedObject]
    ) {
      if (this.props.apiInfo.objects[this.props.queryObj.selectedObject].fields) {
        this.setState({ showFormFields: true });
      } else {
        this.getObjectFields(this.props.queryObj.selectedObject);
      }
      const returnFields = dataSources.buildCRMReturnFields(
        this.props.apiInfo,
        'objects',
        this.props.queryObj.selectedObject,
        'fields',
      );
      this.setState({ returnFields });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.queryObj?.selectedObject, this.props.queryObj?.selectedObject) ||
      !isEqual(prevProps.apiInfo, this.props.apiInfo)
    ) {
      if (
        this.props.queryObj &&
        this.props.apiInfo.objects &&
        this.props.queryObj.selectedObject &&
        this.props.apiInfo.objects[this.props.queryObj.selectedObject]
      ) {
        if (this.props.apiInfo.objects[this.props.queryObj.selectedObject].fields) {
          this.setState({ showFormFields: true });
          const returnFields = dataSources.buildCRMReturnFields(
            this.props.apiInfo,
            'objects',
            this.props.queryObj.selectedObject,
            'fields',
          );
          this.setState({ returnFields });
        } else {
          this.getObjectFields(this.props.queryObj.selectedObject);
        }
      }
    }
  }

  render() {
    const objectOptions = this.props.apiInfo.objects
      ? Object.keys(this.props.apiInfo.objects).map((objectName) => ({
          value: objectName,
          label: this.props.apiInfo.objects[objectName].label,
        }))
      : [];

    let objectValue = null;
    if (
      this.props.queryObj &&
      this.props.queryObj.selectedObject &&
      this.props.apiInfo.objects[this.props.queryObj.selectedObject]
    ) {
      objectValue = {
        label: this.props.apiInfo.objects[this.props.queryObj.selectedObject].label,
        value: this.props.queryObj.selectedObject,
      };
    }

    return (
      <>
        <Form.Field className="mbl">
          <Form.Label>Object</Form.Label>
          <Form.Help>Select Gainsight object to return</Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Gainsight Object"
              classNamePrefix="matik-select"
              className="matik-select-container"
              isDisabled={this.context.isReadOnly}
              value={objectValue}
              name="selectedObject"
              onChange={(obj, action) => this.selectGainsightObject(obj, action)}
              placeholder="Select Gainsight Object"
              options={objectOptions}
              menuPortalTarget={this.props.formRef}
            ></Select>
          </Form.Control>
          {this.props.queryObj &&
            this.props.queryObj.selectedObject &&
            this.props.apiInfo.objects &&
            !this.props.apiInfo.objects[this.props.queryObj.selectedObject] && (
              <Form.Help color="danger">
                Selected Object &quot;{this.props.queryObj.selectedObject}&quot; missing in Gainsight
              </Form.Help>
            )}
        </Form.Field>
        {this.state.showFormFields && (
          <React.Fragment>
            {this.renderReturnFields()}
            {this.renderFilters()}
            {this.renderOrderLimit()}
          </React.Fragment>
        )}
        <RenamableModal
          show={this.props.ui.modal?.name === 'renamableModal'}
          key={this.state.currentlyRenamingField}
          onClose={this.closeRenamableModal}
          fieldName={this.state.currentlyRenamingField?.name}
          fieldLabel={this.state.currentlyRenamingField?.label}
          initialInput={this.state.currentlyRenamingField?.displayName}
          onSave={this.updateReturnFieldMapping}
        />
      </>
    );
  }

  selectGainsightObject = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.selectedObject = value;
      updatedQueryObj.returnFieldsByName = {};
      updatedQueryObj.filters = [];
      if (
        this.props.apiInfo &&
        this.props.apiInfo.objects &&
        this.props.apiInfo.objects[value] &&
        this.props.apiInfo.objects[value]['fields']
      ) {
        let orderByField = this.props.apiInfo.objects[value]['fields'][0];
        updatedQueryObj.orderByField = orderByField.fieldName;
        updatedQueryObj.orderByFieldLabel = orderByField.label;
        updatedQueryObj.orderByDirection = 'asc';
        updatedQueryObj.orderByDirectionLabel = 'ASC';
      }

      this.context.onQueryObjectUpdate(updatedQueryObj);
      if (!this.props.apiInfo.objects[updatedQueryObj.selectedObject]['fields']) {
        this.getObjectFields(updatedQueryObj.selectedObject);
      }
    }
  };

  getObjectFields = (selectedObject) => {
    this.props.fetchApiInfoIfNeeded(this.props.dataSourceId, 'objects', selectedObject, 'fields');
  };

  defaultSortField() {
    let orderByField;
    this.props.apiInfo.objects[this.props.queryObj.selectedObject]['fields'].forEach((field) => {
      return (orderByField = field);
    });
    return { label: orderByField.label, value: orderByField.fieldName };
  }

  renderReturnFields() {
    const returnFieldsByName = this.props.queryObj.returnFieldsByName || {};

    const returnFieldsArray =
      Object.keys(returnFieldsByName).length > 0
        ? Object.keys(returnFieldsByName)
            .filter((fieldName) => returnFieldsByName[fieldName])
            .map((fieldName) =>
              dataSources.createReturnFieldLabel(
                this.props.queryObj,
                fieldName,
                this.state.returnFields,
                this.toggleRenameModal,
              ),
            )
        : [];
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      return (
        <SenderApiReturnFields
          onReturnFieldChange={this.onReturnFieldChange}
          formRef={this.props.formRef}
          options={this.returnFieldGroups(false)}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Form.Field className="mbl">
            <Form.Label>Fields To Return</Form.Label>
            <Form.Help>Select field(s) to return</Form.Help>
            <Form.Control>
              <Select
                aria-label="Select Return Fields"
                placeholder="Select Gainsight fields to return..."
                classNamePrefix="matik-select"
                value={returnFieldsArray}
                isMulti={true}
                isRenamable={true}
                options={this.returnFieldGroups(false)}
                onChange={(obj, action) => this.onReturnFieldChange(obj, action)}
                isDisabled={this.context.isReadOnly}
                menuPortalTarget={this.props.formRef}
                componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
              />
            </Form.Control>
          </Form.Field>
          {this.props.entityType && this.props.entityType === 'input' && (
            <InputMapping
              queryObj={this.props.queryObj}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              input={this.props.input}
              returnFieldsArray={returnFieldsArray}
              inputMapping={this.state.displayNameUpdate ? {} : this.props.inputMapping}
            />
          )}
        </React.Fragment>
      );
    }
  }

  onReturnFieldChange = async (obj, action) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj) || {};
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      await this.context.onSenderFieldUpdate(action.name, obj.value);
      const senderReturnFields = new Set(Object.values(this.context.multiFieldMapping));
      updatedQueryObj.returnFieldsByName = {};
      senderReturnFields.forEach((field) => (updatedQueryObj.returnFieldsByName[field] = true));
    } else {
      if (action.action === 'select-option') {
        updatedQueryObj.returnFieldsByName[action.option.value] = true;
      } else if (action.action === 'select-all-options') {
        const entries = action.option.map((option) => [option.value, true]);
        updatedQueryObj.returnFieldsByName = Object.fromEntries(entries);
      } else if (action.action === 'remove-value') {
        // Javascript is weird and while objects properties don't have order they kinda do
        // By deleting the key and re-adding it with the value false we reorder the property in the object
        delete updatedQueryObj.returnFieldsByName[action.removedValue.value];
        updatedQueryObj.returnFieldsByName[action.removedValue.value] = false;
        if (updatedQueryObj.returnFieldMapping && action.removedValue.value in updatedQueryObj.returnFieldMapping) {
          delete updatedQueryObj.returnFieldMapping[action.removedValue.value].displayName;
        }
        if (updatedQueryObj.orderByField === action.removedValue.value) {
          updatedQueryObj.unselectedOrderField = true;
        }
      } else if (action.action === 'clear') {
        updatedQueryObj.returnFieldsByName = {};
      }
    }

    // check if current orderByField is a return field option
    const returnFields = Object.keys(updatedQueryObj.returnFieldsByName).filter(
      (fieldName) => updatedQueryObj.returnFieldsByName[fieldName],
    );
    if (!returnFields.includes(updatedQueryObj.orderByField) && returnFields.length > 0) {
      const firstField = dataSources.createReturnFieldLabel(
        this.props.queryObj,
        returnFields[0],
        this.state.returnFields,
        this.toggleRenameModal,
      );
      updatedQueryObj.orderByField = firstField.value;
      updatedQueryObj.orderByFieldLabel = firstField.label;
    }

    // check if the newly added return field is the sort by field or not
    updatedQueryObj.unselectedOrderField = !updatedQueryObj.returnFieldsByName[updatedQueryObj.orderByField];

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  renderOrderLimit = () => {
    const objectFields = this.props.apiInfo?.objects?.[this.props.queryObj.selectedObject]?.['fields'] || [];
    const orderFieldsArray = objectFields.map((field) => {
      return { value: field.fieldName, label: field.label };
    });
    orderFieldsArray.forEach((field) => {
      if (field.dataType === 'PICKLIST') {
        field.isDisabled = true;
      }
    });
    return (
      <React.Fragment>
        <Form.Label>Result Order</Form.Label>
        <Form.Help>
          Select Gainsight field to order by.
          <span
            className="mls order-hint-tooltip"
            data-tooltip-id="matik-tooltip"
            data-tooltip-html="Ordering by Picklist fields is not supported by the Gainsight API<br/>Ordering on these fields will result in default ordering."
            data-tooltip-place="right"
          >
            <img src={info_icon} alt="Order By Info" width="14px" className="icon-pull-down" />
          </span>
        </Form.Help>
        <Form.Control>
          <Form.Field className="mbl" kind="group">
            <Form.Control style={{ flex: '3 1' }}>
              <Select
                value={
                  this.props.queryObj.orderByField
                    ? { label: this.props.queryObj.orderByFieldLabel, value: this.props.queryObj.orderByField }
                    : orderFieldsArray.length > 0
                    ? orderFieldsArray[0]
                    : this.defaultSortField()
                }
                classNamePrefix="matik-select"
                onChange={(obj, action) => this.onSortChange(obj, action)}
                isDisabled={this.context.isReadOnly}
                options={orderFieldsArray}
                menuPortalTarget={this.props.formRef}
                aria-label="Select Gainsight field to order by"
              />
            </Form.Control>
            <Form.Control style={{ flex: '1 1' }}>
              <Select
                aria-label="Select Direction to Order By"
                classNamePrefix="matik-select"
                isDisabled={this.context.isReadOnly}
                value={
                  this.props.queryObj.orderByDirection
                    ? { label: this.props.queryObj.orderByDirectionLabel, value: this.props.queryObj.orderByDirection }
                    : { label: 'ASC', value: 'asc' }
                }
                onChange={(obj, action) => this.onSortDirectionChange(obj, action)}
                options={[
                  { label: 'ASC', value: 'asc' },
                  { label: 'DESC', value: 'desc' },
                ]}
                menuPortalTarget={this.props.formRef}
              />
            </Form.Control>
          </Form.Field>
        </Form.Control>

        <Form.Field className="mbl">
          <Form.Label>Result Limit</Form.Label>
          <Form.Help>Maximum number of results to return (leave blank for no limit)</Form.Help>
          <Form.Control>
            <Form.Input
              type="text"
              value={this.props.queryObj.limit}
              onChange={this.onLimitChange}
              name="limit"
              style={{ maxWidth: '50px' }}
              onKeyPress={utils.preventSubmit}
              aria-label="Gainsight limit"
              disabled={this.context.isReadOnly}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  renderFilters = () => {
    const queryFilters = this.props.queryObj.filters || [];
    let body = (
      <a href="#dummy" onClick={(e) => this.addFilter(e, 'EQ')}>
        Add Filter
      </a>
    );
    if (queryFilters.length > 0) {
      const supportedOperators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.gainsight;
      body = queryFilters.map((filter, idx) => {
        const options = this.returnFieldGroups(true);

        const objectFilter = (
          <Form.Control style={{ flex: '3 1' }}>
            <Select
              aria-label="Select Filter Gainsight Field"
              placeholder="Select Gainsight fields to filter..."
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={filter['field'] && filter['label'] ? { value: filter['field'], label: filter['label'] } : null}
              name="filterObject"
              onChange={(obj, action) => this.onFilterFieldChange(obj, action, idx)}
              options={options}
              menuPortalTarget={this.props.formRef}
            ></Select>
          </Form.Control>
        );

        const operators = [];
        for (let key in supportedOperators) {
          operators.push({ label: key, value: supportedOperators[key] });
        }

        return (
          <Form.Field kind="group" key={idx}>
            {objectFilter}
            <Form.Control style={{ flex: '1 1' }}>
              <Select
                value={{ label: filter['operatorLabel'] || 'equals', value: filter['operator'] || 'EQ' }}
                onChange={(obj, action) => this.onOperatorChange(obj, action, idx)}
                classNamePrefix="matik-select"
                isDisabled={this.context.isReadOnly}
                options={operators}
                menuPortalTarget={this.props.formRef}
              />
            </Form.Control>
            <Form.Control style={{ flex: '2 1' }}>
              <InputWithOptionalInputs
                onChange={(newValue) => this.onFilterInputChange(idx, newValue)}
                value={filter['value']}
                inputs={this.context.existingInputs}
                isReadOnly={this.context.isReadOnly}
                isInputPopoverDisabled={this.props.isInputPopoverDisabled}
              />
            </Form.Control>
            <SalesforceFilterButtons
              queryObj={this.props.queryObj}
              addFilter={this.addFilter}
              removeFilter={this.removeFilter}
              isReadOnly={this.context.isReadOnly}
              idx={idx}
            />
          </Form.Field>
        );
      });
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Filters</Form.Label>
          <Form.Help>
            Select fields and inputs to filter by.
            <span
              className="mls filter-hint-tooltip"
              data-tooltip-id="matik-tooltip"
              data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
              data-tooltip-place="right"
            >
              <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
            </span>
          </Form.Help>
          <Form.Control>{body}</Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  onSortChange = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const orderByFieldLabel = obj.value.includes('.') ? obj.value : obj.label;
      updatedQueryObj.orderByField = obj.isDisabled ? null : obj.value;
      updatedQueryObj.orderByFieldLabel = obj.isDisabled ? null : orderByFieldLabel;
      updatedQueryObj.unselectedOrderField = obj.isDisabled ? null : !updatedQueryObj.returnFieldsByName[obj.value];
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  onSortDirectionChange = (obj) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.orderByDirection = obj.value;
    updatedQueryObj.orderByDirectionLabel = obj.label;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onLimitChange = (e) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const name = e.target.name;
    updatedQueryObj[name] = e.target.value;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  addFilter = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = updatedQueryObj.filters || [];
    filters.push({ field: '', value: '', type: 'string', operator: 'EQ' });
    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  removeFilter = (e, idx) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = [];
    const queryFilters = updatedQueryObj.filters || [];
    for (let i = 0; i < queryFilters.length; i++) {
      if (i !== idx) {
        filters.push(updatedQueryObj.filters[i]);
      }
    }
    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onFilterFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const filterField = obj.value;
      // Need special case for field names with '_' character in them
      const filterFieldLabel = obj.value.includes('.') ? obj.value : obj.label;
      const filterType = obj.type || filterField[filterField.length - 1];
      const filters = updatedQueryObj.filters || [];
      if (idx === filters.length) {
        filters.push({ field: filterField, value: '', type: filterType, label: filterFieldLabel });
      } else {
        filters[idx].field = filterField;
        filters[idx].type = filterType;
        filters[idx].label = filterFieldLabel;
      }
      updatedQueryObj.filters = filters;
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  onFilterInputChange = (idx, value) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filters = updatedQueryObj.filters || [];
    if (idx === filters.length) {
      filters.push({ field: '', value: value });
    } else {
      filters[idx].value = value;
    }

    updatedQueryObj.filters = filters;

    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  onOperatorChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const filters = updatedQueryObj.filters;
      filters[idx].operator = obj.value;
      filters[idx].operatorLabel = obj.label;

      updatedQueryObj.filters = filters;
      this.context.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  updateReturnFieldMapping = (fieldName, displayName, fieldLabel) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (displayName) {
      if (!updatedQueryObj.returnFieldMapping) {
        updatedQueryObj.returnFieldMapping = {};
      }
      if (!updatedQueryObj.returnFieldMapping[fieldName]) {
        updatedQueryObj.returnFieldMapping[fieldName] = { label: fieldLabel, displayName: displayName };
      } else {
        updatedQueryObj.returnFieldMapping[fieldName].displayName = displayName;
      }
      this.setState({ displayNameUpdate: true });
    } else {
      if (updatedQueryObj.returnFieldMapping && updatedQueryObj.returnFieldMapping[fieldName]) {
        delete updatedQueryObj.returnFieldMapping[fieldName].displayName;
        this.setState({ displayNameUpdate: true });
      }
    }

    this.context.onQueryObjectUpdate(updatedQueryObj);
    this.setState({ currentlyRenamingField: null });
    this.props.closeModal();
  };

  toggleRenameModal = (e, field) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ currentlyRenamingField: field });
    if (this.props.ui?.modal?.name === 'renamableModal') {
      this.props.closeModal();
    } else {
      this.props.openModal?.('renamableModal');
    }
  };

  closeRenamableModal = () => {
    this.setState({ currentlyRenamingField: null });
    this.props.closeModal();
  };

  returnFieldGroups = (forFilter) => {
    const objectFields = this.props.apiInfo?.objects?.[this.props.queryObj.selectedObject]?.['fields'] || [];

    const fieldGroups = [];
    const fieldOptions = [];

    // Loop through object's fields
    objectFields.forEach((field) => {
      // If field has Lookup Fields
      if (field.lookupFields) {
        // Lookup field is a Gainsight join, get its fields and create options
        const lookupOptions = [];
        field.lookupFields.forEach((lookupField) => {
          // Cannot filter on Lookup fields
          if (!forFilter || (forFilter && lookupField.datatype !== 'LOOKUP')) {
            lookupOptions.push({
              label: lookupField.label,
              type: lookupField.dataType,
              value: `${field.fieldName}.${lookupField.fieldName}`,
            });
          }
        });
        // Add the grouped Lookup Field options to fieldGroups
        fieldGroups.push({ type: 'group', label: field.label, options: lookupOptions });
      } else {
        // Return option for top level field group
        fieldOptions.push({ label: field.label, type: field.dataType, value: field.fieldName });
      }
    });

    // Insert the top level field group with populated fieldOptions
    fieldGroups.unshift({
      type: 'group',
      label: this.props.apiInfo.objects[this.props.queryObj.selectedObject].label,
      options: fieldOptions,
    });

    return fieldGroups;
  };
}

GainsightForm.contextType = DynamicContentContext;

GainsightForm.propTypes = {
  apiInfo: PropTypes.object,
  selectedObject: PropTypes.string,
  queryObj: PropTypes.object,
  renderOrderLimit: PropTypes.func,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  renderFilter: PropTypes.func,
  onFilterFieldChange: PropTypes.func,
  onFilterInputChange: PropTypes.func,
  toggleRenameModal: PropTypes.func,
  onLimitChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMappingOptions: PropTypes.array,
  onInputMappingUpdate: PropTypes.func,
  inputMapping: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  isInputPopoverDisabled: PropTypes.bool,
  formRef: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(GainsightForm);
