import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/shared/modal/Modal';
import HtmlBuilderInputsForm from './HtmlBuilderInputsForm';
import utils from '../../../lib/utils';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Constants from '../../Constants';
import { useTemplateContent } from 'lib/hooks/useTemplate';

function TestHtmlBuilderModal(props) {
  const tokenTestResult = null;
  const [tokenError, setTokenError] = useState('');
  const [tokenSuccess, setTokenSuccess] = useState('');
  const [tokenLoading, setTokenLoadingState] = useState(false);
  const { data: templateContent } = useTemplateContent(props.template?.id, props.template?.deleted);

  const tagTree = utils.getDynamicContentTags(props.template, templateContent, props.allDynamicContent);
  const tags = tagTree.getTagNodes();
  const matchingContent = tags.map((tag) => utils.getDynamicContentFromTag(tag.name));

  const updateTestResult = (resultData) => {
    if (resultData && resultData.status === 'error') {
      setTokenError('Invalid token');
      setTokenSuccess(null);
    } else if (resultData && resultData.status === 'success') {
      setTokenError(null);
      setTokenSuccess('Valid token');
    }

    this.setTokenLoading(false);
  };

  const setTokenLoading = (tokenLoading) => {
    setTokenLoadingState(tokenLoading);
  };

  const modalTitle =
    props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
      ? 'Preview Dynamic Content'
      : 'Generate Presentation';

  return (
    <Modal onClose={props.onClose} show={props.show} title={modalTitle}>
      <HtmlBuilderInputsForm
        allDynamicContent={props.allDynamicContent}
        entityId={props.template.id}
        entityType="template"
        existingInputValues={props.inputValues}
        inModal={true}
        onClose={props.onClose}
        onPresentationCreate={props.onPresentationCreate}
        setTokenLoading={setTokenLoading}
        template={props.template}
        templateContent={matchingContent}
        tokenError={tokenError}
        tokenLoading={tokenLoading}
        tokenSuccess={tokenSuccess}
        tokenTestResult={tokenTestResult}
        updateTestResult={updateTestResult}
        onEmailPreview={props.onEmailPreview}
        onEmailPreviewError={props.onEmailPreviewError}
        isEmailVisualBuilder={props.isEmailVisualBuilder}
        setVisualBuilderFetching={props.setVisualBuilderFetching}
      />
    </Modal>
  );
}

TestHtmlBuilderModal.propTypes = {
  allDynamicContent: PropTypes.object,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  template: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  inputValues: PropTypes.object,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  onEmailPreview: PropTypes.func,
  isTemplateTest: PropTypes.bool,
  onEmailPreviewError: PropTypes.func,
  isEmailVisualBuilder: PropTypes.bool,
  setVisualBuilderFetching: PropTypes.func,
};

function mapStateToProps(state) {
  return Object.assign({}, mapUiStateToProps(state));
}

export default connect(mapStateToProps, mapDispatchToProps)(TestHtmlBuilderModal);
