import React from 'react';
import PropTypes from 'prop-types';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';

const SalesforceApplyFormatting = (props) => {
  const applyFormatting = props.queryObj.apply_formatting ? props.queryObj.apply_formatting : false;

  const onChange = (e) => {
    const value = e.target.checked;
    const updatedQueryObj = Object.assign({}, props.queryObj);
    updatedQueryObj.apply_formatting = value;

    props.updateQueryObj(JSON.stringify(updatedQueryObj));
  };
  return (
    <div className="mtm">
      <CheckboxWithLabel
        checked={applyFormatting}
        id="salesforce-apply-formatting"
        name="salesforce-apply-formatting"
        disabled={props.isReadOnly}
        label="Apply Formatting"
        onChange={onChange}
      />
    </div>
  );
};

SalesforceApplyFormatting.propTypes = {
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
};

export default SalesforceApplyFormatting;
