import Constants from '../components/Constants';
import moment from 'moment';
import { find, isEmpty } from 'lodash';
import utils from './utils';
import RelativeDate from './RelativeDate';
import dataSources from './dataSources';

export default class QueryProcessor {
  constructor(queryString, inputs, inputValues, dataSource, source_type = null) {
    this.queryString = queryString;
    this.inputs = inputs;
    this.inputValues = inputValues;
    this.dataSource = dataSource;
    this.source_type = source_type;
    this.queryJSON = utils.isValidJSON(queryString) ? JSON.parse(queryString) : {};
  }

  inputsStillRequired() {
    const inputsStillRequired = [];
    for (let inputName in this.inputs) {
      const inputValue = this.inputValues[inputName];
      const input = this.inputs[inputName];
      if (
        (input.type !== Constants.InputTypes.BOOLEAN && (!inputValue || !inputValue.value)) ||
        (input.type === Constants.InputTypes.LIST && inputValue.value.length === 0)
      ) {
        inputsStillRequired.push(input.display_name ? input.display_name : input.name);
      }
    }

    return inputsStillRequired;
  }

  parameterizeQueryString() {
    let queryString = this.getQueryString();
    for (let inputName in this.inputs) {
      if (!this.inputValues[inputName]) {
        continue;
      }
      queryString = this._replaceQueryColumn(queryString, inputName);
      // It's important to add the word boundary at the end of the regex because we don't want
      // to match both &:test and &:test_1
      const regex = new RegExp('&:' + inputName + '\\b', 'g');
      // Add quotes if it's a string
      const input = this.inputs[inputName];
      let value =
        this.dataSource && this.dataSource.type !== Constants.DATA_SOURCE_TYPES.matik_logos
          ? this._formatValue(inputName, input)
          : this.inputValues[inputName].value;
      queryString = queryString.replace(regex, value);
      // Special case for null input values
      const sqlDataSources = Constants.DATA_SOURCE_TYPES_FOR_METHOD['query']
        .slice()
        .filter((dataSourceName) => dataSourceName !== 'salesforce');
      if (value === null && input.include_null_values && sqlDataSources.includes(this.dataSource.type)) {
        const nullRegExp = new RegExp(/[^!]=\s*null/);
        const notNullRegExp = new RegExp(/\s*!=\s*null/);
        queryString = queryString.replace(nullRegExp, ' is null');
        queryString = queryString.replace(notNullRegExp, ' is not null');
      }
      // Special case when we use % for a like clause
      const percentRegex = new RegExp(/(%|_?)'(.*)'(%|_?)/, 'g');
      queryString = queryString.replace(
        percentRegex,
        (str, percent1, paramVal, percent2) => `'${percent1}${paramVal}${percent2}'`,
      );
    }
    return queryString;
  }

  _replaceQueryColumn(queryString, inputName) {
    let quoteChar = '"';
    // BigQuery needs backticks instead
    if (this.dataSource?.type === Constants.DATA_SOURCE_TYPES.googlebq) {
      quoteChar = '`';
    }
    if (this.inputValues[inputName]) {
      const regex = new RegExp('\\s+(AS|as)\\s+(`|")?&:' + inputName + '\\b(`|")?', 'g');
      const value = ` AS ${quoteChar}${this.inputValues[inputName].value}${quoteChar}`;
      return queryString.replace(regex, value);
    }
  }

  getQueryString() {
    let queryString = this.queryString;
    if (
      this.dataSource?.type === Constants.DATA_SOURCE_TYPES.salesforce &&
      utils.isValidJSON(this.queryString) &&
      JSON.parse(this.queryString).source !== 'reports' &&
      JSON.parse(this.queryString).selectedObject
    ) {
      let queryJSON = JSON.parse(this.queryString);

      queryJSON['soql_string'] = dataSources.generateSOQLQueryString(this.queryString);
      queryString = JSON.stringify(queryJSON);
    }
    return queryString;
  }

  _formatValue(inputName, input) {
    let value = this.inputValues[inputName] ? this.inputValues[inputName].value : '';
    let label = this._getLabelFromValue(this.inputValues[inputName]) || '';
    if (this.inputValues[inputName].mappedOptions && !isEmpty(this.inputValues[inputName].mappedOptions)) {
      if (Array.isArray(value)) {
        value = value.map((val) => this.inputValues[inputName].mappedOptions[val]);
      } else {
        value = this.inputValues[inputName].mappedOptions[value];
      }
    }
    if (value === Constants.NULL_VALUE_INPUT_LABEL && input.include_null_values) {
      value = null;
    }
    if (input.type === Constants.InputTypes.STRING && input.source_type === Constants.InputSources.MATIK_USER) {
      value = this._quoteIfString(value, input, label);
    } else if (input.type === Constants.InputTypes.STRING && this._shouldQuoteIfString(value)) {
      value = this._quoteIfString(value, input);
    } else if (
      input.type === Constants.InputTypes.STRING &&
      [Constants.InputSources.API, Constants.InputSources.LIST, Constants.InputSources.QUERY].includes(
        input.source_type,
      )
    ) {
      value = this._formatSpecialCharacters(value);
    } else if (input.type === Constants.InputTypes.BOOLEAN) {
      value = value ? 'TRUE' : 'FALSE';
    } else if (input.type === Constants.InputTypes.DATE || input.type === Constants.InputTypes.DATE_TIME) {
      value = this._formatDateStringForQuery(value);
    } else if (
      input.type === Constants.InputTypes.DATE_RANGE &&
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
    ) {
      // get the fieldname to filter from the query string by getting the substring right before the input
      const inputIdx = this.queryString.indexOf(` &:${inputName}`);
      if (inputIdx && inputIdx > 0) {
        const strBeforeIdx = this.queryString.substring(0, inputIdx);
        const strArray = strBeforeIdx.split(' ');
        const fieldName = strArray[strArray.length - 1];
        value = `>= ${this._formatDateStringForQuery(value[0])} AND ${fieldName} <= ${this._formatDateStringForQuery(
          value[1],
        )}`;
      }
    } else if (
      input.type === Constants.InputTypes.DATE_RANGE &&
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.gainsight
    ) {
      value = this._formatDateStringForQuery(value[0]) + '^,' + this._formatDateStringForQuery(value[1]);
    } else if (input.type === Constants.InputTypes.DATE_RANGE) {
      value = `BETWEEN ${this._formatDateStringForQuery(value[0])} AND ${this._formatDateStringForQuery(value[1])}`;
    } else if (input.type === Constants.InputTypes.LIST) {
      value = Array.isArray(value) ? value : [value];
      value = this._formatList(input, value);
    }
    return value;
  }

  _getLabelFromValue(data) {
    if (!data.options || data.options.length === 0) {
      return null;
    }
    const matchingOption = data.options.find((option) => option.value === data.value);

    if (matchingOption) {
      return matchingOption.label;
    } else {
      return null;
    }
  }

  _shouldQuoteIfString(value, inList) {
    // Check for string value in salesforce date literals, they should not get quotes
    let isDateLiteral = false;
    let isSalesforcebool = false;
    let isSalesforceReport = false;
    if (
      value &&
      !inList &&
      typeof value === 'string' &&
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
    ) {
      if (value === 'true' || value === 'false') {
        isSalesforcebool = true;
      } else if (utils.isValidJSON(this.queryString) && JSON.parse(this.queryString).source === 'reports') {
        isSalesforceReport = true;
      }
      // Allow support for n values (LAST_N_YEARS:5)
      value = value.split(':')[0];
      if (Constants.SUPPORTED_DATE_LITERALS_BY_DATASOURCE.salesforce.includes(value)) {
        isDateLiteral = true;
      }
    }
    return (
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.tableau &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.api &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.google_sheet &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.looker &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.gainsight &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.productboard &&
      !isDateLiteral &&
      !isSalesforcebool &&
      !isSalesforceReport
    );
  }

  _formatDateStringForQuery(dateValue) {
    const relativeDate = RelativeDate.createFromString(dateValue);
    if (relativeDate) {
      dateValue = relativeDate.dateValue();
    }
    if (
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.presto ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.redshift ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.athena
    ) {
      return `date '${moment(dateValue).format('YYYY-MM-DD')}'`;
    }
    // Salesforce, gainsight, tableau, and looker requires dates to be formatted with no quotes
    if (
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.gainsight ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.looker ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.tableau ||
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet
    ) {
      return moment(dateValue).format('YYYY-MM-DD');
    }

    return `'${moment(dateValue).format('YYYY-MM-DD')}'`;
  }

  _quoteIfString(listItem, input, label = null) {
    if (input.source_type === Constants.InputSources.MATIK_USER) {
      if (label) {
        return `'${label.replaceAll("'", "\\'")}'`;
      } else if (typeof listItem === 'string') {
        return `'${listItem.replaceAll("'", "\\'")}'`;
      } else {
        return `'${listItem.label.replaceAll("'", "\\'")}'`;
      }
    }
    if (listItem && (isNaN(listItem) || listItem === 'Infinity') && !Array.isArray(listItem)) {
      if (this.dataSource.type === Constants.DATA_SOURCE_TYPES.presto) {
        return `'${listItem.replace("'", "''")}'`;
      } else if (
        this.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce ||
        this.dataSource.type === Constants.DATA_SOURCE_TYPES.hubspot
      ) {
        return `'${listItem.replace(/'/g, "\\\\'").replace(/"/g, '\\"')}'`;
      } else {
        return `'${listItem.replaceAll("'", "\\'")}'`;
      }
    }

    if (
      listItem &&
      !isNaN(listItem) &&
      (input.type === Constants.InputTypes.STRING || input.type === Constants.InputTypes.LIST) &&
      this.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
    ) {
      const matchingFilter = find(this.queryJSON.filters, { value: `&:${input.name}` });
      if (
        matchingFilter &&
        matchingFilter.type !== 'double' &&
        matchingFilter.type !== 'int' &&
        matchingFilter.type !== 'long'
      ) {
        return `'${listItem.replace(/'/g, "\\\\'").replace(/"/g, '\\"')}'`;
      }
    }

    return listItem;
  }

  _formatList(input, listValue) {
    let returnVal = listValue
      .sort()
      .filter((val) => !!val)
      .map((val) => this._formatSpecialCharacters(val))
      .map((val) => (this._shouldQuoteIfString(val, true) ? this._quoteIfString(val, input) : val));

    if (this.dataSource.type === Constants.DATA_SOURCE_TYPES.gainsight) {
      // Value split(',') in backend query will break values with commas
      returnVal = returnVal.join('^,');
    } else if (
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.looker &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.google_sheet &&
      this.dataSource.type !== Constants.DATA_SOURCE_TYPES.tableau
    ) {
      returnVal = returnVal.join(',');
      returnVal = `(${returnVal})`;
    } else {
      returnVal = returnVal.join(',');
    }
    return returnVal;
  }

  _formatSpecialCharacters(listItem) {
    if (listItem && isNaN(listItem)) {
      if (this.dataSource.type === Constants.DATA_SOURCE_TYPES.looker) {
        const regex = new RegExp(/[%_,^]/, 'g');
        return listItem.replace(regex, (char) => `^${char}`);
      } else {
        return listItem;
      }
    }

    return listItem;
  }
}
