import React, { useState } from 'react';
import PropTypes from 'prop-types';

import illustration from 'images/document_illustration_80x80.svg';
import GoogleDrivePicker from 'components/shared/GoogleDrivePicker';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import API from 'lib/api';

const DocumentNarrativeCard = ({ onTemplateAdd }) => {
  return (
    <div className="narrative-card">
      <img src={illustration} className="narrative-card-icon" />
      <div className="narrative-card-center">
        <p className="narrative-card-title">Document</p>
        <p className="narrative-card-subtitle">Add a Google Docs document</p>
      </div>
      <AddDocumentButton className="button is-secondary is-fullwidth" onTemplateAdd={onTemplateAdd}>
        Add Document Template
      </AddDocumentButton>
    </div>
  );
};
DocumentNarrativeCard.propTypes = {
  onTemplateAdd: PropTypes.func,
};

export default DocumentNarrativeCard;

const AddDocumentButton = ({ className, children, onTemplateAdd }) => {
  const [isAdding, setAdding] = useState(false);
  const { create } = useTemplateMutator();

  const handleSelect = (docs) => {
    setAdding(true);

    const file = docs[0];
    const templateData = {
      name: file.name,
      source_type: 'google_docs',
      source: file,
      description: file.description,
    };

    create(templateData, file)
      .then((newTemplateData) => onTemplateAdd(newTemplateData))
      .catch(API.defaultError)
      .finally(() => setAdding(false));
  };

  if (isAdding) {
    className = `${className || ''} is-loading`;
  }
  return (
    <GoogleDrivePicker className={className} onPicked={handleSelect} view="documents">
      {children}
    </GoogleDrivePicker>
  );
};
AddDocumentButton.propTypes = {
  className: PropTypes.string,
  onTemplateAdd: PropTypes.func,
  children: PropTypes.any,
};
