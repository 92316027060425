import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './FormInput';
import { ReactComponent as PaddingTopIcon } from '../../svg/padding_top.svg';
import { ReactComponent as PaddingRightIcon } from '../../svg/padding_right.svg';
import { ReactComponent as PaddingBottomIcon } from '../../svg/padding_bottom.svg';
import { ReactComponent as PaddingLeftIcon } from '../../svg/padding_left.svg';

function PaddingInput(props) {
  const paddingMin = 0;
  const paddingMax = 99;

  const onPaddingChange = (num, propName) => {
    num = parseInt(num, 10);
    num = Math.min(Math.max(num, paddingMin), paddingMax);
    props.onPaddingChange(num, propName);
  };

  return (
    <>
      <FormInput
        type="number"
        inputClass="module-settings-input pixel-input prefixed-input"
        value={props.paddingTop}
        min={paddingMin}
        max={paddingMax}
        prefix={<PaddingTopIcon />}
        suffix={<span>px</span>}
        onChange={(e) => {
          onPaddingChange(e.target.value, props.paddingTopName ? props.paddingTopName : 'paddingTop');
        }}
      />
      <FormInput
        type="number"
        inputClass="module-settings-input pixel-input prefixed-input"
        value={props.paddingRight}
        min={paddingMin}
        max={paddingMax}
        prefix={<PaddingRightIcon />}
        suffix={<span>px</span>}
        onChange={(e) => {
          onPaddingChange(e.target.value, props.paddingRightName ? props.paddingRightName : 'paddingRight');
        }}
      />
      <FormInput
        type="number"
        inputClass="module-settings-input pixel-input prefixed-input"
        value={props.paddingBottom}
        min={paddingMin}
        max={paddingMax}
        prefix={<PaddingBottomIcon />}
        suffix={<span>px</span>}
        onChange={(e) => {
          onPaddingChange(e.target.value, props.paddingBottomName ? props.paddingBottomName : 'paddingBottom');
        }}
      />
      <FormInput
        type="number"
        inputClass="module-settings-input pixel-input prefixed-input"
        value={props.paddingLeft}
        min={paddingMin}
        max={paddingMax}
        prefix={<PaddingLeftIcon />}
        suffix={<span>px</span>}
        onChange={(e) => {
          onPaddingChange(e.target.value, props.paddingLeftName ? props.paddingLeftName : 'paddingLeft');
        }}
      />
    </>
  );
}

PaddingInput.propTypes = {
  paddingTop: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingTopName: PropTypes.string,
  paddingRightName: PropTypes.string,
  paddingBottomName: PropTypes.string,
  paddingLeftName: PropTypes.string,
  onPaddingChange: PropTypes.func,
};

export default PaddingInput;
