import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithLoadingAndEmpty from '../../../shared/WithLoadingAndEmpty';
import WithInstantSearch from '../../../shared/search/WithInstantSearch';
import WithFilterListHeader from '../../../shared/WithFilterListHeader';
import FullPageList from '../../../shared/FullPageList';
import WithSelectedElement from '../../../shared/WithSelectedElement';
import { withRouter } from 'react-router-dom';

const SearchableListWithFilterHeader = WithInstantSearch(WithFilterListHeader(FullPageList));
const SearchableListWithFilterHeaderAndSelect = withRouter(
  WithSelectedElement(WithInstantSearch(WithFilterListHeader(FullPageList))),
);

class DynamicContentSelectEmptyWrapper extends Component {
  render() {
    if (this.props.withSelect) {
      return <SearchableListWithFilterHeaderAndSelect {...this.props} isSearchable={true} />;
    } else {
      return <SearchableListWithFilterHeader {...this.props} isSearchable={true} />;
    }
  }
}

DynamicContentSelectEmptyWrapper.propTypes = {
  withSelect: PropTypes.bool,
  currentEntity: PropTypes.object,
  startExpanded: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  context: PropTypes.string,
  entities: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  title: PropTypes.string,
  pagination: PropTypes.object,
  fetchItems: PropTypes.func,
  hideTopPagination: PropTypes.bool,
  loading: PropTypes.bool,
  entityType: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  buttonClass: PropTypes.string,
  newEntityPhrase: PropTypes.string,
  newEntityFunc: PropTypes.func,
  filtersAreShownInSidepane: PropTypes.bool,
};

export default WithLoadingAndEmpty(DynamicContentSelectEmptyWrapper);
