import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SmallLoader from '../SmallLoader';
import { each, isEmpty } from 'lodash';
import Constants from '../../Constants';
import PresentationInputsForm from './PresentationInputsForm';
import { Footer, Form } from 'react-bulma-components';
import { ReactComponent as ArrowRepeatAll } from '../../../svg/arrow_repeat_all.svg';
import withUserContext from '../WithUserContext';
import presentations from '../../../lib/presentations';
import Button from '../../lib/Button';

class PresentationDetailSidebar extends Component {
  render() {
    let body;
    if (this.props.areInputsLoading) {
      body = <SmallLoader />;
    } else {
      const presentationParams = this.props.presentation.params;
      const inputValues = {};
      each(presentationParams, (value, key) => {
        const input = this.props.inputs[key];
        if (input) {
          const val = { value };
          if (input.source_type === Constants.InputSources.QUERY || input.source_type === Constants.InputSources.API) {
            val.options = [value];
          }
          inputValues[key] = val;
        }
      });

      const regenerationTooltipMessage = presentations.getRegenerationTooltipMessage(
        this.props.presentation,
        this.props.canRead,
      );

      body = (
        <React.Fragment>
          <PresentationInputsForm
            entityId={this.props.presentation.id}
            entityType="presentation"
            inputs={this.props.inputs}
            isReadOnly={true}
            isPresentationView={true}
            existingInputValues={inputValues}
            template={this.props.presentation.template}
            templateContent={[]}
            allTemplateContent={[]}
            isTestSlide={false}
            source_type={this.props.presentation.presentation_type}
          />
          <Footer>
            <Form.Control>
              <div
                data-tooltip-id="matik-tooltip"
                data-tooltip-content={regenerationTooltipMessage ? regenerationTooltipMessage : null}
              >
                <Button
                  category="secondary"
                  width="fullWidth"
                  onClick={(e) => this.props.onRegenerateClick(e, this.props.presentation)}
                  status={
                    !this.props.canRead || isEmpty(this.props.presentation?.template) || regenerationTooltipMessage
                      ? 'disabled'
                      : 'default'
                  }
                >
                  <ArrowRepeatAll className="mrs" />
                  Regenerate
                </Button>
              </div>
            </Form.Control>
          </Footer>
        </React.Fragment>
      );
    }

    return <div className="create-sidebar is-flex">{body}</div>;
  }
}

PresentationDetailSidebar.propTypes = {
  presentation: PropTypes.object,
  inputs: PropTypes.object,
  areInputsLoading: PropTypes.bool,
  onRegenerateClick: PropTypes.func,
  canRead: PropTypes.bool,
};

export default withUserContext(PresentationDetailSidebar);
