import React, { useEffect } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import PropTypes from 'prop-types';
import { PickColor } from 'components/shared/PickColor';
import Column from './Column';
import ColumnModule from './ColumnModule';
import HtmlBuilderColumnEmptyState from './HtmlBuilderColumnEmptyState';
import PaddingInput from 'components/shared/PaddingInput';

function ColumnContainer({ children, style }) {
  const {
    connectors: { connect },
  } = useNode();

  return (
    <div ref={(dom) => connect(dom)} style={style} className="is-transparent">
      {!children && <HtmlBuilderColumnEmptyState />}
      {children}
    </div>
  );
}

ColumnContainer.craft = {
  rules: {
    canDrop: () => false,
    canDrag: () => false,
    canMoveIn: (incomingNodes, currentNode, nodeHelpers) => {
      const ancestorModuleName = nodeHelpers(nodeHelpers(currentNode.id).ancestors()[0]).get().data.name;

      switch (ancestorModuleName) {
        case 'ColumnModule':
          return incomingNodes.every((node) => node.data.type === Column);
        case 'Column':
          return incomingNodes.every(
            (node) =>
              node.data.type !== ColumnModule && node.data.type !== Column && node.data.type !== ColumnContainer,
          );
      }
    },
    canMoveOut: () => true,
  },
  related: {
    settings: ColumnContainerSettings,
  },
};

ColumnContainer.propTypes = {
  buttonId: PropTypes.number,
  children: PropTypes.object,
  style: PropTypes.object,
};

function ColumnContainerSettings() {
  const {
    actions: { setProp },
    background,
    id,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = useNode((node) => ({
    background: node.data.props.style.background,
    gridTemplateColumns: node.data.props.style.gridTemplateColumns,
    id: node.id,
    paddingBottom: parseInt(node.data.props.style.paddingBottom),
    paddingLeft: parseInt(node.data.props.style.paddingLeft),
    paddingRight: parseInt(node.data.props.style.paddingRight),
    paddingTop: parseInt(node.data.props.style.paddingTop),
  }));

  const gridTemplateColumnsSettings = [
    {
      // 1
      id: 0,
      gridTemplateColumns: '1fr',
    },
    {
      // 1 : 1
      id: 1,
      gridTemplateColumns: '1fr 1fr',
    },
    {
      // 1 : 1 : 1
      id: 2,
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    {
      // 1 : 1 : 1 : 1
      id: 3,
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    {
      // 1 : 2
      id: 4,
      gridTemplateColumns: '1fr 2fr',
    },
    {
      // 2 : 1
      id: 5,
      gridTemplateColumns: '2fr 1fr',
    },
    {
      // 1 : 3
      id: 6,
      gridTemplateColumns: '1fr 3fr',
    },
    {
      // 3 : 1
      id: 7,
      gridTemplateColumns: '3fr 1fr',
    },
  ];

  const { parentNode, isColumn, isColumnModule } = useEditor((state, query) => {
    let parentNode = {};
    let isColumn = false;
    let isColumnModule = false;

    parentNode = state.nodes[query.node(id).ancestors()[0]];
    isColumn = parentNode.data.name === 'Column';
    isColumnModule = parentNode.data.name === 'ColumnModule';

    return {
      parentNode,
      isColumn,
      isColumnModule,
    };
  });

  useEffect(() => {
    if (isColumnModule) {
      setProp((props) => {
        props.style.gridTemplateColumns =
          gridTemplateColumnsSettings[parentNode.data.props.buttonId].gridTemplateColumns;
      });
    }
  }, [parentNode.data.props.buttonId]);

  const onColorChange = (propName) => (color) => {
    const hex = color.hex;
    setProp((props) => {
      props.style[propName] = hex;
    });
  };

  const onNumberChange = (num, incomingProp) => {
    setProp((props) => {
      props.style[incomingProp] = `${num}px`;
    });
  };

  return (
    <div className={isColumn ? 'module-settings-container' : 'column-settings-container'}>
      <div className="module-setting">
        <label>Background color</label>
        <PickColor
          onChange={onColorChange('background')}
          hexColor={background}
          ancestorEl={document.querySelector('.template-components')}
        />
      </div>
      <div className="module-setting">
        <label>Padding</label>
        <div>
          <PaddingInput
            onPaddingChange={onNumberChange}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
          />
        </div>
      </div>
    </div>
  );
}

ColumnContainerSettings.propTypes = {
  style: PropTypes.object,
};

export default ColumnContainer;
