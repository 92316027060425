import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import utils from 'lib/utils';
import EditableField from '../EditableField';
import TemplateNameForm from './TemplateNameForm';

import { isEqual } from 'lodash';
import API from 'lib/api.js';
import { MAlert } from 'components/shared/Alerts';

class EditableTemplateName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: this.props.template.description,
      itemTags: this.props.template.item_tags,
    };
  }

  render() {
    return (
      <EditableField
        value={this.props.template.name}
        onSave={this.onEditableFieldSave}
        toolTip={this.getToolTipVal()}
        editableTextClassName="title is-3"
        canEdit={this.props.canEdit}
        id="templateName"
      >
        <TemplateNameForm
          itemTags={this.props.template.item_tags}
          description={this.props.template.description}
          onDescriptionChange={this.onDescriptionChange}
          onItemTagsChange={this.onItemTagsChange}
          orderedInputs={this.props.orderedInputs}
          onTemplateUpdateWithServerCall={this.props.onTemplateUpdateWithServerCall}
          template={this.props.template}
        />
      </EditableField>
    );
  }

  updateTemplate = async (nameVal, descriptionVal, itemTags, currentTemplate) => {
    if (!nameVal) {
      MAlert('Name is required. Please enter a name', 'Error', 'error');
      return;
    }

    if (!isEqual(currentTemplate.item_tags, itemTags)) {
      // Await item tags EP to get the template reindexed correctly with the tag updates before
      // onTemplateUpdateWithServerCall since the template will reindex again for the name/description
      // updates.
      await API.post(`/item_tags/update_template_tags/${currentTemplate.id}/`, { item_tags: itemTags });
    }
    const templateFieldsToUpdate = {
      id: currentTemplate.id,
      name: nameVal,
      description: descriptionVal,
    };
    this.props.onTemplateUpdateWithServerCall(templateFieldsToUpdate);
  };

  onEditableFieldSave = (nameVal) => {
    if (this.props.onSave) {
      this.props.onSave(nameVal, this.state.description, this.state.itemTags, this.props.template);
    }
    this.updateTemplate(nameVal, this.state.description, this.state.itemTags, this.props.template);
  };

  onDescriptionChange = (description) => {
    this.setState({ description: description });
  };

  onItemTagsChange = (newtags) => {
    this.setState(newtags);
  };

  getToolTipVal() {
    if (this.props.template.description) {
      if (utils.isValidJSON(this.props.template.description)) {
        const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(JSON.parse(this.props.template.description));
        return draftToHtml(descJSON);
      } else {
        return this.props.template.description;
      }
    } else {
      return 'No description';
    }
  }
}

EditableTemplateName.propTypes = {
  template: PropTypes.object,
  onSave: PropTypes.func,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default EditableTemplateName;
