import React, { useEffect, useState } from 'react';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { useDispatch } from 'react-redux';
import { closeFullScreenModal } from 'redux/ui/action';
import { Heading } from 'react-bulma-components';
import TabBar from 'components/lib/TabBar';
import VerticalTabItem from 'components/lib/VerticalTabItem';
import Constants from 'components/Constants';
import NameTemplateModalBody from 'components/shared/templates/NameTemplateModalBody';
import useTemplate from 'lib/hooks/useTemplate';
import PropTypes from 'prop-types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import TemplateConditionsForm from 'components/producer/templates/TemplateConditionsForm';
import FolderSettings from 'components/producer/templates/FolderSettings';

function GenerationSettingsModal({ currentTemplate, orderedInputs, show, onTemplateUpdateWithServerCall }) {
  const LDClient = useLDClient();
  const customFolderFlagIsEnabled = LDClient?.variation('customPresentationGenerationFolders', false) || false;
  const areGenerationConditionsEnabled = LDClient?.variation('non-email-template-conditions', false) || false;
  const generationSettingsTabs = Object.assign({}, Constants.GenerationSettingsTabs);
  // Default starting tab should only be either be "Folder" or "Naming Structure"
  const startingTab = () => {
    if (!areGenerationConditionsEnabled) {
      delete generationSettingsTabs.CONDITIONS;
    }

    if (!customFolderFlagIsEnabled) {
      delete generationSettingsTabs.FOLDER;
      return Constants.GenerationSettingsTabs.NAMING_STRUCTURE.label;
    }

    if (
      currentTemplate?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ||
      currentTemplate?.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT
    ) {
      delete generationSettingsTabs.FOLDER;
      return Constants.GenerationSettingsTabs.NAMING_STRUCTURE.label;
    }

    return Constants.GenerationSettingsTabs.FOLDER.label;
  };
  const [activeTab, setActiveTab] = useState(startingTab());

  useEffect(() => {
    setActiveTab(startingTab());
  }, [LDClient, currentTemplate]);

  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(true);
  const [primaryButtonOnClick, setPrimaryButtonOnClick] = useState();
  const dispatch = useDispatch();
  const headerCenter = <Heading className="full-screen-modal-title">Generation Settings</Heading>;
  const { invalidate: invalidateTemplate } = useTemplate();

  return (
    <FullScreenModal
      show={show}
      onClose={() => dispatch(closeFullScreenModal())}
      headerCenter={headerCenter}
      showDefaultFooter={true}
      backgroundColor="white"
      primaryButtonText={'Update Settings'}
      primaryButtonOnClick={primaryButtonOnClick}
      primaryButtonDisabled={primaryButtonDisabled}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => dispatch(closeFullScreenModal())}
    >
      <div className="three-column-modal-body">
        <div className="modal-sidebar">
          <TabBar>
            {Object.values(generationSettingsTabs).map((tab) => (
              <VerticalTabItem
                key={tab.label}
                label={tab.label}
                iconName={tab.icon}
                isActive={activeTab === tab.label}
                onClick={(label) => setActiveTab(label)}
              />
            ))}
          </TabBar>
        </div>
        <div className="modal-body-center p-0">
          {activeTab === Constants.GenerationSettingsTabs.FOLDER.label && (
            <FolderSettings
              currentTemplate={currentTemplate}
              onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
              setPrimaryButtonDisabled={setPrimaryButtonDisabled}
              setPrimaryButtonOnClick={setPrimaryButtonOnClick}
            />
          )}
          {activeTab === Constants.GenerationSettingsTabs.NAMING_STRUCTURE.label && (
            <NameTemplateModalBody
              template={currentTemplate}
              orderedInputs={orderedInputs}
              setPrimaryButtonDisabled={setPrimaryButtonDisabled}
              setPrimaryButtonOnClick={setPrimaryButtonOnClick}
              onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
            />
          )}
          {activeTab === Constants.GenerationSettingsTabs.CONDITIONS.label && (
            <TemplateConditionsForm
              currentTemplate={currentTemplate}
              onConditionsUpdated={() => {
                invalidateTemplate(currentTemplate.id);
              }}
              setPrimaryButtonOnClick={setPrimaryButtonOnClick}
              setPrimaryButtonDisabled={setPrimaryButtonDisabled}
              onClose={() => dispatch(closeFullScreenModal())}
            />
          )}
        </div>
      </div>
    </FullScreenModal>
  );
}

export default GenerationSettingsModal;

GenerationSettingsModal.propTypes = {
  currentTemplate: PropTypes.object,
  orderedInputs: PropTypes.array,
  show: PropTypes.bool,
  onTemplateUpdateWithServerCall: PropTypes.func,
};
