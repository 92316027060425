const UPDATE_INPUT_VALUES = 'UPDATE_INPUT_VALUES';
const INIT_INPUT_VALUES_FROM_INPUTS = 'INIT_INPUT_VALUES_FROM_INPUTS';
const UPDATE_INPUT_VALUES_FROM_INPUTS = 'UPDATE_INPUT_VALUES_FROM_INPUTS';
const UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS = 'UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS';
const CLEAR_INPUT_VALUE = 'CLEAR_ATTACHMENT_INPUT_VALUE';
const UPDATE_ATTACHMENT_INPUT_VALUES = 'UPDATE_ATTACHMENT_INPUT_VALUES';
const INIT_ATTACHMENT_INPUT_VALUES_FROM_INPUTS = 'INIT_ATTACHMENT_INPUT_VALUES_FROM_INPUTS';
const UPDATE_ATTACHMENT_INPUT_VALUES_FROM_INPUTS = 'UPDATE_ATTACHMENT_INPUT_VALUES_FROM_INPUTS';
const UPDATE_ATTACHMENT_INPUT_VALUES_AND_INIT_FROM_INPUTS = 'UPDATE_ATTACHMENT_INPUT_VALUES_AND_INIT_FROM_INPUTS';
const CLEAR_ATTACHMENT_INPUT_VALUE = 'CLEAR_ATTACHMENT_INPUT_VALUE';

export {
  UPDATE_INPUT_VALUES,
  INIT_INPUT_VALUES_FROM_INPUTS,
  UPDATE_INPUT_VALUES_FROM_INPUTS,
  UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS,
  CLEAR_INPUT_VALUE,
  UPDATE_ATTACHMENT_INPUT_VALUES,
  INIT_ATTACHMENT_INPUT_VALUES_FROM_INPUTS,
  UPDATE_ATTACHMENT_INPUT_VALUES_FROM_INPUTS,
  UPDATE_ATTACHMENT_INPUT_VALUES_AND_INIT_FROM_INPUTS,
  CLEAR_ATTACHMENT_INPUT_VALUE,
};
