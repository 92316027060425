import React, { Component } from 'react';
import google_slides_icon from '../../../images/google_slides_icon.svg';
import google_drive_logo from '../../../images/google_drive_logo.png';
import PropTypes from 'prop-types';
import WithGooglePicker from '../../shared/WithGooglePicker';
import { UserContext } from '../../UserContext';

class GoogleImportCard extends Component {
  render() {
    const loadingClass = this.props.isLoading ? 'is-loading' : '';
    return (
      <div>
        <img src={google_slides_icon}></img>
        <p>Select Google Slides from your Google Drive</p>
        <button className={`button is-secondary ${loadingClass}`} onClick={this.props.onGoogleSlidesClick}>
          <img src={google_drive_logo} className="import-icon"></img>
          Select from Google Drive
        </button>
      </div>
    );
  }
}

GoogleImportCard.contextType = UserContext;

GoogleImportCard.propTypes = {
  onGoogleSlidesClick: PropTypes.func,
  auth: PropTypes.any,
  handleSelectGoogleSheet: PropTypes.func,
  isLoading: PropTypes.bool,
  oathFunction: PropTypes.func,
  percentCompleted: PropTypes.number,
  processingStatus: PropTypes.string,
};

export default WithGooglePicker(GoogleImportCard);
