import API from 'lib/api';
import { useQuery } from '@tanstack/react-query';
import useMultiFetch from './useMultiFetch';

/** Fetch a single presentation */
const usePresentation = (id) => {
  const queryKey = ['presentation', parseInt(id)];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/presentations/${id}/`,
        (response) => response.data,
        (err) => {
          throw err;
        },
      );
    },
    enabled: !!id,
    cacheTime: 5 * 60 * 1000,
  });

  return {
    isPending: isLoading,
    isError,
    data,
    error,
  };
};

export default usePresentation;

export const usePresentations = (ids) => {
  const getQueryKey = (id) => ['presentation', parseInt(id)];
  const getFetchUri = (ids) => {
    return API.generate_paginated_url('/presentations/', 0, ids.length, null, `{"id":[${ids.join(',')}]}`);
  };
  const getEntityId = (presentation) => presentation.id;
  return useMultiFetch(ids, getQueryKey, getFetchUri, getEntityId, 5 * 60 * 1000);
};
