import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import Constants from 'components/Constants';

class CRMOauthForm extends Component {
  instanceOptions = [
    { label: 'Production Salesforce', value: '1' },
    { label: 'Salesforce Sandbox', value: '0' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      isProductionOption: this.instanceOptions[0],
    };
  }

  componentDidMount() {
    if (this.props.selectedDataSource && this.props.selectedDataSource.auth_info) {
      this.setState({
        isProductionOption: this.props.selectedDataSource.auth_info.is_production
          ? this.instanceOptions[0]
          : this.instanceOptions[1],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isProduction !== this.props.isProduction) {
      this.setState({
        isProductionOption: this.props.isProduction ? this.instanceOptions[0] : this.instanceOptions[1],
      });
    }
  }

  render() {
    if (this.props.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      return (
        <Form.Field>
          <Form.Label>Salesforce Instance Type</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              value={this.state.isProductionOption}
              onChange={this.sandboxChange}
              isDisabled={!!this.props.selectedDataSource}
              aria-label="Select Salesforce Instance Type"
              options={this.instanceOptions}
            />
          </Form.Control>
        </Form.Field>
      );
    } else {
      return '';
    }
  }

  sandboxChange = (obj) => {
    this.setState({ isProductionOption: obj });
    this.props.onIsProductionChange(obj.value);
  };
}

CRMOauthForm.propTypes = {
  selectedDataSource: PropTypes.object,
  isProduction: PropTypes.bool,
  onIsProductionChange: PropTypes.func,
  type: PropTypes.string,
};

export default CRMOauthForm;
