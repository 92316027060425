import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import Clause from '../../shared/Clause';
import { Form } from 'react-bulma-components';
import Constants from '../../Constants';
import { Select } from '../../shared/FormSelect';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';

function TemplateConditionClause({
  conditions,
  clause,
  conditionIndex,
  clauseIndex,
  generateEmptyClause,
  onConditionsUpdate,
}) {
  const { dynamicContentById } = useAllDynamicContentById();
  const allDynamicContent = Object.values(dynamicContentById || []).reduce((arr, dc) => {
    if (dc.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
      arr.push({ name: dc.name, id: dc.id });
    }
    return arr;
  }, []);

  const onClauseOperandChange = (e) => {
    e.preventDefault();
    onClauseChange(e.target.value, 'operand');
  };

  const onClauseChange = (newValue, fieldToUpdate) => {
    const updatedConditions = cloneDeep(conditions);
    if (!updatedConditions.conditions) {
      updatedConditions.conditions = [];
    }
    if (updatedConditions.conditions.length === 0) {
      updatedConditions.conditions.push({ clauses: [] });
    }
    if (updatedConditions.conditions[conditionIndex].clauses.length === clauseIndex) {
      updatedConditions.conditions[conditionIndex].clauses.push(generateEmptyClause());
    }
    updatedConditions.conditions[conditionIndex].clauses[clauseIndex][fieldToUpdate] = newValue;
    onConditionsUpdate(updatedConditions, true);
  };

  const onClauseOperatorChange = (obj, action) => {
    if (action.action === 'select-option') {
      onClauseChange(obj.value, 'operator');
    }
  };

  const onClauseDynamicContentName = (obj, action) => {
    if (action.action === 'select-option') {
      onClauseChange('', 'operand');
      onClauseChange(obj.value, 'dynamicContent');
    }
  };
  const dynamicContentId = clause.dynamicContent;
  const dynamicContent = dynamicContentById?.[dynamicContentId];
  const dynamicContentName = dynamicContent ? dynamicContent.name : '';
  const operator = clause.operator;
  const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditions;

  // TODO (zak): Replace with DynamicContentInput
  return (
    <Clause
      updateQuery={onConditionsUpdate}
      queryObj={conditions}
      generateEmptyClause={generateEmptyClause}
      clauseIndex={clauseIndex}
      conditionIndex={conditionIndex}
    >
      <Form.Control style={{ flex: '4 1' }}>
        <div data-tooltip-content={dynamicContentName} data-tooltip-id="matik-tooltip">
          <Select
            key={dynamicContentName}
            value={{ label: dynamicContentName, value: dynamicContentId }}
            onChange={onClauseDynamicContentName}
            classNamePrefix="matik-select"
            options={allDynamicContent.map((content) => ({ label: content.name, value: content.id }))}
          ></Select>
        </div>
      </Form.Control>
      <Form.Control style={{ flex: '2 1' }}>
        <Select
          value={{ label: operator, value: operator }}
          onChange={onClauseOperatorChange}
          classNamePrefix="matik-select"
          options={supported_operators.map((supported_operator) => ({
            label: supported_operator,
            value: supported_operator,
          }))}
        ></Select>
      </Form.Control>
      {operator !== 'is null' && operator !== 'is not null' && (
        <Form.Control style={{ flex: '3 1' }}>
          <Form.Input value={clause.operand} onChange={onClauseOperandChange} aria-label="Conditional Clause Operand" />
        </Form.Control>
      )}
    </Clause>
  );
}
TemplateConditionClause.propTypes = {
  clause: PropTypes.object,
  conditionIndex: PropTypes.number,
  onConditionsUpdate: PropTypes.func,
  conditions: PropTypes.object,
  generateEmptyClause: PropTypes.func,
  clauseIndex: PropTypes.number,
  allDynamicContentNamesById: PropTypes.object,
};

export default TemplateConditionClause;
