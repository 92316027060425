import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-bulma-components';
import { DayPickerSingleDateController } from 'react-dates';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import { CreatableInputOnly } from '../FormSelect';
import localeDateFormatterUtils from '../../../lib/localeDate';
import Constants from '../../Constants';
import OutsideHandler from '../OutsideHandler';
import Icon from '../../lib/Icon';

class DateListInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      dateFormat: Constants.DATE_FORMATS.usa,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      dateFormat: localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
        localeDateFormatterUtils.getUserLocale(),
      ),
    });
  }

  render() {
    let dates = this.props.inputValues[this.props.input.name].value;
    if (!(dates instanceof Array)) {
      dates = [];
    }

    const tags = dates.map((date) => {
      return {
        label: date.format(this.state.dateFormat),
        value: date.toISOString(),
      };
    });

    let popup = null;
    if (this.state.focused) {
      const transform = `translate3d(${this.boundingRect.x}px, ${this.boundingRect.y}px, 0px)`;
      popup = (
        <OutsideHandler onFocusOut={this.onFocusOut}>
          <Box className="day-picker-popup is-paddingless" style={{ transform }}>
            <DayPickerSingleDateController
              onDateChange={this.onDateChange}
              navPrev={
                <div role="button" className="left-navigation-button">
                  <Icon name="chevron_left" size={16} theme="regular" />
                </div>
              }
              navNext={
                <div role="button" className="right-navigation-button">
                  <ChevronRight />
                </div>
              }
              hideKeyboardShortcutsPanel={true}
              numberOfMonths={2}
            />
          </Box>
        </OutsideHandler>
      );
    }

    return (
      <>
        <div className="date-input-field" onFocus={this.onFocusIn} ref={this.inputRef}>
          <CreatableInputOnly onChange={this.onChange} value={tags} placeholder="Select multiple values" />
        </div>
        {popup}
      </>
    );
  }

  onFocusOut = () => {
    this.setState({ focused: false });
  };

  onFocusIn = () => {
    this.boundingRect = this.inputRef.current.getBoundingClientRect();
    if (window.innerHeight < this.boundingRect.bottom + 340 && this.boundingRect.bottom - 340 > 0) {
      this.boundingRect.y -= 340;
    } else {
      this.boundingRect.y += this.boundingRect.bottom - this.boundingRect.top + 4;
    }
    this.setState({ focused: true });
  };

  onChange = (obj, action) => {
    let updatedInputValues = Object.assign({}, this.props.inputValues);

    if (action.action === 'remove-value') {
      let oldValues = updatedInputValues[this.props.input.name].value;
      if (!(oldValues instanceof Array)) {
        oldValues = [];
      }

      updatedInputValues[this.props.input.name].value = oldValues.filter(
        (listVal) => !listVal.isSame(action.removedValue.value),
      );
      this.props.onChange(this.props.input.name, updatedInputValues);
    } else if (action.action === 'clear') {
      updatedInputValues[this.props.input.name].value = [];
      this.props.onChange(this.props.input.name, updatedInputValues);
    }

    // 'select-option' and 'create-option' actions are intentionally not handled
  };

  onDateChange = (date) => {
    let values = this.props.inputValues[this.props.input.name].value;
    if (!(values instanceof Array)) {
      values = [];
    }
    if (values.every((val) => !val.isSame(date))) {
      values.push(date);
      this.props.inputValues[this.props.input.name].value = values;
      this.props.onChange(this.props.input.name, this.props.inputValues);
    }
  };
}

DateListInputField.propTypes = {
  input: PropTypes.object,
  inputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  inputColor: PropTypes.string,
  onChange: PropTypes.func,
};

export default DateListInputField;
