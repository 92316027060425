import { React, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
// libs
import AccessManager from 'lib/AccessManager';
import dynamicContent from 'lib/dynamicContent';
import InputsList from 'lib/inputsList';
// components
import AccessModal from 'components/shared/AccessModal';
import EmailSendBody from '../producer/email/EmailSendBody';
import EmailTemplateHeader from 'components/producer/email/EmailTemplateHeader';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import TestQueryHandlingInputsForm, {
  findTemplateContentTagsAndInputs,
} from 'components/consumer/TestQueryHandlingInputsForm';
import DataSourceLoggedIn from 'components/consumer/DataSourceLoggedIn';
// contexts
import { UserContext } from 'components/UserContext';
import { PresentationContext } from 'components/shared/presentations/PresentationContext';
import { useTemplateContent } from 'lib/hooks/useTemplate';
import { useLDClient } from 'launchdarkly-react-client-sdk';

function EmailPresentationCreator({
  toggleTab,
  template,
  attachedTemplate,
  onTemplateUpdateWithServerCall,
  userDataSources,
  onUserDataSourceAdd,
  accessesByTemplateId,
  onPresentationCreate,
  activeTab,
  allDynamicContentByName,
}) {
  const userContext = useContext(UserContext);
  const presentationContext = useContext(PresentationContext);

  const location = useLocation();

  const ui = useSelector((state) => state.ui);

  const ldClient = useLDClient();
  const showAdminGenerateTab = ldClient?.variation('show-admin-generate-tab', false);

  const [allInputsFilledOut, setAllInputsFilledOut] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [isTestSlide, setIsTestSlide] = useState(false);
  const [triggerEmailGeneration, setTriggerEmailGeneration] = useState(false);

  // Seed input values from querystring or when regenerating a presentation.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let paramValues = {};
    for (let param of params.entries()) {
      paramValues[param[0]] = { value: param[1] };
    }
    if (!isEqual(paramValues, inputValues)) {
      setInputValues(paramValues);
    }
  }, []);
  useEffect(() => {
    if (presentationContext?.regenerate && presentationContext?.presentationToRegenerate) {
      let contextInputValues = presentationContext.getInputValues();
      if (!isEqual(contextInputValues, inputValues)) {
        setInputValues(contextInputValues);
      }
    }
  }, [presentationContext?.regenerate, presentationContext?.presentationToRegenerate]);

  const { data: templateContent } = useTemplateContent(template?.id, template?.deleted);
  const { data: attachedTemplateContent } = useTemplateContent(attachedTemplate?.id, attachedTemplate?.deleted);

  const allDynamicContent = {};
  if (templateContent) {
    dynamicContent.setAllDynamicContentByName(templateContent, allDynamicContent);
  }
  if (attachedTemplateContent) {
    dynamicContent.setAllDynamicContentByName(attachedTemplateContent, allDynamicContent);
  }

  let orderedInputs;
  if (template) {
    const { inputs: bodyMatchingInputs } = findTemplateContentTagsAndInputs(
      template,
      templateContent,
      [],
      allDynamicContent,
      {
        1: true,
      },
    );
    let inputsList = new InputsList(bodyMatchingInputs, template.params_order);
    orderedInputs = inputsList.getSortedList();
  }

  const accessObj = new AccessManager(template?.id, accessesByTemplateId, userContext.user);
  const canEdit = accessObj.can('edit');

  const submitTestEmail = () => {
    setIsTestSlide(true);
    setTriggerEmailGeneration(true);
  };

  return (
    <>
      {template && (
        <>
          <EmailTemplateHeader
            template={template}
            orderedInputs={orderedInputs}
            onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
            canEdit={canEdit}
            accessesByTemplateId={accessesByTemplateId}
            toggleTab={toggleTab}
            onSendEmailClick={() => setTriggerEmailGeneration(true)}
            allInputsFilledOut={allInputsFilledOut}
            submitTestEmail={submitTestEmail}
            activeTab={activeTab}
            hideSendButton={showAdminGenerateTab}
          />
          <div className="is-flex consumer-template-main">
            <div className="create-sidebar">
              <DataSourceLoggedIn
                template={template}
                attachedTemplate={attachedTemplate}
                userDataSources={userDataSources}
                onUserDataSourceAdd={onUserDataSourceAdd}
              >
                <TestQueryHandlingInputsForm
                  template={template}
                  allDynamicContent={allDynamicContent}
                  attachedTemplate={attachedTemplate}
                  inputValues={inputValues}
                  isTestSlide={isTestSlide}
                  onPresentationCreate={onPresentationCreate}
                  setAllInputsFilledOut={setAllInputsFilledOut}
                  setTriggerEmailGeneration={setTriggerEmailGeneration}
                  triggerEmailGeneration={triggerEmailGeneration}
                  onSendEmailClick={() => setTriggerEmailGeneration(true)}
                  allInputsFilledOut={allInputsFilledOut}
                  submitTestEmail={submitTestEmail}
                  showEmailSendButton={showAdminGenerateTab}
                />
              </DataSourceLoggedIn>
            </div>
            <EmailSendBody
              template={template}
              attachedTemplate={attachedTemplate}
              onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
              allDynamicContent={allDynamicContentByName || allDynamicContent}
              canEdit={canEdit}
              enterprise={userContext.user?.enterprise}
            />
          </div>
          <AccessModal accesses={accessObj} item={template} show={ui.modal?.name === 'accessModal'} />
        </>
      )}
    </>
  );
}

EmailPresentationCreator.propTypes = {
  toggleTab: PropTypes.func,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  userDataSources: PropTypes.object,
  onUserDataSourceAdd: PropTypes.func,
  accessesByTemplateId: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  activeTab: PropTypes.string,
  allDynamicContentByName: PropTypes.object,
};

export default WithLoadingAndEmpty(EmailPresentationCreator);
