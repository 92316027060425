import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Form } from 'react-bulma-components';
import Constants from '../Constants';
import inputs from '../../lib/inputs';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

class ConditionalClauseInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsByInputName: {},
      fetchErrorsByInputName: {},
      isLoading: false,
      dataTS: null,
    };
  }

  render() {
    let options = undefined;
    if (
      this.props.inputObj.type === Constants.InputTypes.LIST &&
      this.props.inputObj.source_list &&
      this.props.inputObj.source_list.length > 0
    ) {
      options = this.props.inputObj.source_list;
    } else if (this.state.optionsByInputName[this.props.inputObj.name]) {
      options = this.state.optionsByInputName[this.props.inputObj.name];
    }
    let fetchError = '';
    if (this.state.fetchErrorsByInputName[this.props.inputObj.name]) {
      fetchError = this.state.fetchErrorsByInputName[this.props.inputObj.name];
    }
    const inputValues = {
      [this.props.inputObj.name]: {
        value: this.props.operand,
        options: options,
        fetch_error: fetchError,
        isLoading: this.state.isLoading,
        dataTS: this.state.dataTS,
        onSyncButtonClick: () =>
          inputs.loadOptionsFromQuery(
            this.props.inputObj,
            inputValues,
            this.onInputLoadSuccess,
            this.onInputLoadError,
            null,
            () => this.setState({ isLoading: true }),
            null,
            null,
          ),
      },
    };
    return (
      <Form.Control>
        {inputs.getFieldFromInputType(
          this.props.inputObj,
          inputValues,
          null,
          this.props.onChange,
          this.onInputLoadSuccess,
          this.onInputLoadError,
          null,
          () => this.setState({ isLoading: true }),
          this.props.isReadOnly,
          true,
          null,
          true,
          this.props.formRef,
        )}
      </Form.Control>
    );
  }

  onInputLoadSuccess = (
    name,
    options,
    _,
    startingNestedInputValues,
    currentNestedInputValues,
    mappedOptions,
    inputMappingOptions,
    isStillLoading,
    dataTS,
  ) => {
    if (isEqual(startingNestedInputValues, currentNestedInputValues)) {
      const updatedOptions = Object.assign({}, this.state.optionsByInputName);
      updatedOptions[name] = options;
      if (options.length > 0) {
        if (this.shouldUpdateQuery()) {
          const updatedQueryObj = Object.assign({}, this.props.queryObj);
          if (this.props.inputObj && this.props.inputObj.type === Constants.InputTypes.LIST) {
            updatedQueryObj.conditions[this.props.conditionIndex].clauses[this.props.clauseIndex].operand = [
              options[0],
            ];
          } else {
            updatedQueryObj.conditions[this.props.conditionIndex].clauses[this.props.clauseIndex].operand = options[0];
          }
          this.props.updateQuery(updatedQueryObj);
        }
      }
      this.setState({ optionsByInputName: updatedOptions, isLoading: isStillLoading, dataTS: dataTS });
    }
  };

  shouldUpdateQuery = () => {
    return (
      this.props.queryObj.conditions &&
      this.props.queryObj.conditions.length > this.props.conditionIndex &&
      this.props.queryObj.conditions[this.props.conditionIndex].clauses &&
      this.props.queryObj.conditions[this.props.conditionIndex].clauses.length > this.props.clauseIndex &&
      !this.props.queryObj.conditions[this.props.conditionIndex].clauses[this.props.clauseIndex].operand
    );
  };

  onInputLoadError = (err, inputName) => {
    const updatedFetchErrorByInputName = Object.assign({}, this.state.fetchErrorsByInputName);
    updatedFetchErrorByInputName[inputName] = err.message;
    this.setState({ fetchErrorsByInputName: updatedFetchErrorByInputName, isLoading: false, dataTS: null });
  };
}

ConditionalClauseInputField.propTypes = {
  inputObj: PropTypes.object,
  clauseIndex: PropTypes.number,
  conditionIndex: PropTypes.number,
  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  onChange: PropTypes.func,
  operand: PropTypes.any,
  updateQuery: PropTypes.func,
  flags: PropTypes.object,
  formRef: PropTypes.object,
};

export default withLDConsumer()(ConditionalClauseInputField);
