import React, { useContext, useEffect } from 'react';
import { UserContext } from 'components/UserContext';
import { MAlert } from '../Alerts';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { AssistantLongRequest } from '../../../lib/longRequest';
import { API } from '../../../lib/api';
import BrandingForm from 'components/shared/templateBuilder/BrandingForm';

export default function BrandingFormWithWaterfall(props) {
  const userContext = useContext(UserContext);
  const { user, updateUser } = useContext(UserContext);

  const executeBrandingWaterfall = async (initialBrandingInfo) => {
    const isThemeComplete = (branding) => {
      return Boolean(
        branding.theme && branding.theme.light_color && branding.theme.dark_color && branding.theme.link_color,
      );
    };

    props.setIsLoading(true);

    const brandfetchBackfillPostRequest = () => {
      const onBrandfetchSuccess = (response) => {
        if (!isThemeComplete(initialBrandingInfo)) {
          initialBrandingInfo['theme'] = response.data.theme;
        }
        if (!initialBrandingInfo.logo) {
          return API.post(
            `/enterprises/${userContext.user.enterprise_id}/upload_branding_logo/`,
            { logo_url: response.data.logo_url },
            (response) => {
              initialBrandingInfo['logo'] = response.data.enterprise_settings.branding_info.logo;
              initialBrandingInfo['logo_url'] = response.data.enterprise_settings.branding_info.logo_url;
            },
          );
        }
      };

      const handleBrandfetchError = (err) => {
        MAlert('An unexpected error occurred while trying to fetch your company brand info. ' + err, 'Error', 'error');
      };

      return API.post(
        '/queries/matik_logos/',
        { domain: props.domainName },
        onBrandfetchSuccess,
        handleBrandfetchError,
      );
    };

    const adjustLightColorFromBranding = (theme) => {
      if (theme?.light_color?.toLowerCase() === '#ffffff') {
        theme.light_color = '#F6F6F6';
      }
      return theme;
    };

    const settingsAfterUpdate = (updatedBrandingInfo) => {
      onBrandingInfoChange(updatedBrandingInfo);
      props.setIsLoading(false);
    };

    const runAssistantLongRequest = () => {
      return new Promise((resolve, reject) => {
        const handleSuccess = (response, onComplete) => {
          if (response.data.status === 'success') {
            initialBrandingInfo['theme'] = response.data.result;
            onComplete();
            resolve();
          } else if (response.data.status === 'error') {
            onComplete();
            reject();
          }
        };

        const handleError = (err) => {
          reject(err);
        };

        const assistantBrandingPostData = { business_url: props.domainName };
        const assistantBrandingLongRequest = new AssistantLongRequest('/branding');
        assistantBrandingLongRequest.post(assistantBrandingPostData, handleSuccess, handleError);
      });
    };

    if (!isThemeComplete(initialBrandingInfo)) {
      try {
        await runAssistantLongRequest();
      } catch {
        // no need to handle this error since we want to proceed to the next part of the waterfall
      }
    }
    if (!isThemeComplete(initialBrandingInfo) || !initialBrandingInfo.logo) {
      try {
        await brandfetchBackfillPostRequest();
      } catch {
        // same here
      }
    }
    settingsAfterUpdate(initialBrandingInfo);
    initialBrandingInfo['theme'] = adjustLightColorFromBranding(initialBrandingInfo.theme);
  };

  const onBrandingInfoChange = (updatedBrandingInfo) => {
    props.onBrandingInfoChange(updatedBrandingInfo);
    API.put('/enterprises/current/', { branding_info: updatedBrandingInfo }, () => {
      const copyUser = cloneDeep(user);
      copyUser.enterprise.enterprise_settings.branding_info = updatedBrandingInfo;
      updateUser(copyUser);
    });
  };

  const populateBrandingInfo = () => {
    const brandingInfoClone = cloneDeep(userContext?.user?.enterprise?.enterprise_settings?.branding_info) || {};
    if (!brandingInfoClone.logo && userContext?.user?.enterprise?.logo) {
      brandingInfoClone.logo = userContext?.user?.enterprise?.logo;
      brandingInfoClone.logo_url = userContext?.user?.enterprise?.logo_url;
    }
    return brandingInfoClone;
  };

  useEffect(() => {
    props.setIsLoading(false);
    const brandingInfoClone = populateBrandingInfo();
    executeBrandingWaterfall(brandingInfoClone);
  }, []);

  useEffect(() => {
    if (props.forceUpdate > 0 && !props.isLoading) {
      executeBrandingWaterfall({});
    }
  }, [props.forceUpdate]);

  return (
    <BrandingForm
      darkColorDescription={props.darkColorDescription}
      accentColorDescription={props.accentColorDescription}
      brandingInfo={props.brandingInfo}
      onBrandingInfoChange={onBrandingInfoChange}
      isLoading={props.isLoading}
    />
  );
}

BrandingFormWithWaterfall.propTypes = {
  darkColorDescription: PropTypes.string,
  accentColorDescription: PropTypes.string,
  brandingInfo: PropTypes.object,
  onBrandingInfoChange: PropTypes.func,
  domainName: PropTypes.string,
  companyName: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  forceUpdate: PropTypes.number,
};
