import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../../lib/utils';
import { NameCell } from '../shared/FullPageListCells';
import moment from 'moment';
import { Container, Form, Heading, Hero } from 'react-bulma-components';
import FullPageList from '../shared/FullPageList';
import { mapDispatchToProps as mapGroupDispatchToProps } from '../../redux/groups/dispatchers';
import { mapGroupStateToProps } from '../../redux/groups/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import GroupModal from './GroupModal';
import WithNotifications from '../shared/WithNotifications';
import API from '../../lib/api';
import loading_icon from '../../images/loading.gif';
import groups_empty_icon from '../../images/groups_empty.svg';
import { ReactComponent as Search } from '../../svg/search_filled.svg';
import Button from '../lib/Button';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      selectedGroup: null,
      filterTerm: '',
      fetchingUsers: true,
    };
  }

  componentDidMount() {
    this.props.fetchGroupsIfNeeded().then(this.props.fetchGroupMembers);
    API.get('/enterprises/users/?all=true', (response) => {
      this.setState({ allUsers: response.data, fetchingUsers: false });
    });
  }

  render() {
    if (this.props.isFetching) {
      return (
        <Container className="has-text-centered">
          <img src={loading_icon} width="100rem" alt="loading" />
        </Container>
      );
    }

    let body = '';
    if (!this.props.groups || this.props.groups.length === 0) {
      body = this.renderEmpty();
    } else {
      let groupsToDisplay = this.props.groups;
      if (this.state.filterTerm) {
        const lowerCaseFilter = this.state.filterTerm.toLowerCase();
        groupsToDisplay = groupsToDisplay.filter((group) => {
          return group.name.toLowerCase().indexOf(lowerCaseFilter) >= 0;
        });
      }
      const columns = [
        { id: 'name', Header: utils.tableHeader('Name'), accessor: (d) => (d.name ? d.name : d.email), Cell: NameCell },
        {
          id: 'description',
          Header: utils.tableHeader('Description'),
          accessor: (d) => (d.description ? d.description : '--'),
        },
        {
          id: 'user_count',
          Header: utils.tableHeader('User Count'),
          accessor: (d) => (d.members === undefined || this.props.isFetchingMembers ? 'Loading...' : d.members.length),
        },
        {
          id: 'created_on',
          Header: utils.tableHeader('Date Added'),
          accessor: (d) =>
            typeof d.created_on === 'number' ? moment.unix(d.created_on).local() : moment(d.created_on).local(),
        },
      ];
      body = (
        <Container className="mbl is-fluid">
          <Form.Field kind="group">
            <Form.Control className="has-icons-left flex-1">
              <Form.Input
                type="text"
                size="medium"
                placeholder="Find group"
                value={this.state.filterTerm}
                onChange={this.onFilter}
                aria-label="Find group"
              />
              <span className="icon is-small is-left has-text-grey-dark">
                <Search />
              </span>
            </Form.Control>
            <Form.Control>
              <Button onClick={this.onAddGroupClick}>Add New Group</Button>
            </Form.Control>
          </Form.Field>
          <FullPageList entitiesToRender={groupsToDisplay} columns={columns} onRowClick={this.onRowClick} />
        </Container>
      );
    }

    return (
      <React.Fragment>
        {body}
        <GroupModal
          key={this.state.selectedGroup ? this.state.selectedGroup : -1}
          addGroup={this.props.addGroup}
          allUsers={this.state.allUsers}
          deleteGroup={this.props.deleteGroup}
          group={this.state.selectedGroup}
          isFetchingMembers={this.props.isFetchingMembers || this.state.fetchingUsers}
          onClose={this.onModalClose}
          updateGroup={this.props.updateGroup}
        />
      </React.Fragment>
    );
  }

  renderEmpty = () => {
    return (
      <Container className="is-fluid">
        <Hero>
          <Hero.Body>
            <Container className="has-text-centered">
              <Heading size={5} subtitle>
                <img width="256px" src={groups_empty_icon} alt="Empty Groups" />
              </Heading>
              <Heading size={4} className="has-text-grey-dark">
                No Groups
              </Heading>
              <Heading size={5} subtitle className="has-text-grey-dark">
                No groups yet, add your first one
              </Heading>
              <Button onClick={this.onAddGroupClick}>Add New Group</Button>
            </Container>
          </Hero.Body>
        </Hero>
      </Container>
    );
  };

  onModalClose = () => {
    this.setState({ selectedGroup: null });
    this.props.closeModal();
  };

  onAddGroupClick = (e) => {
    e.preventDefault();
    this.setState({ selectedGroup: null });
    this.props.openModal('groupModal');
  };

  onRowClick = (groupId) => {
    const group = this.props.groups.filter((group) => group.id === groupId)[0];
    this.setState({ selectedGroup: group });
    this.props.openModal('groupModal');
  };

  onFilter = (e) => {
    const filterTerm = e.target.value;
    this.setState({ filterTerm: filterTerm });
  };
}

Groups.propTypes = {
  addGroup: PropTypes.func,
  deleteGroup: PropTypes.func,
  enterprise: PropTypes.object,
  fetchGroupsIfNeeded: PropTypes.func,
  fetchGroupMembers: PropTypes.func,
  groups: PropTypes.array,
  isFetching: PropTypes.bool,
  isFetchingMembers: PropTypes.bool,
  updateGroup: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapGroupDispatchToProps(state, ownProps), mapUiDispatchToProps(state, ownProps));
}

export default connect(mapGroupStateToProps, mapDispatchToProps)(WithNotifications(Groups));
