import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Level, Section } from 'react-bulma-components';
import EmailBodyHtml from '../email/EmailBodyHtml';
import EmailAttachmentPopUp from './EmailAttachmentPopUp';
import EmailAttachmentCard from './EmailAttachmentCard';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import Icon from 'components/lib/Icon';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import EmailFieldsSetup from './EmailFieldsSetup';
import { useLDClient } from 'launchdarkly-react-client-sdk';

function EmailTemplateBody({
  allDynamicContent,
  currentTemplate,
  onTemplateUpdateWithServerCall,
  attachedTemplate,
  sendgridInfo,
  openAuthModal,
  enterprise,
  canEdit,
  jsonNodes,
  onEditorSaveClick,
  onTemplateTest,
  emailPreviewValues,
  isPreviewLoading,
  emailHtml,
  dynamicRecipientsName,
}) {
  const ldClient = useLDClient();
  const enableDynamicSenderFeatures = ldClient?.variation('enable-dynamic-sender-features', false);

  return (
    <div className="email-template-body setup">
      {!enableDynamicSenderFeatures && (
        <EmailFieldsSetup
          currentTemplate={currentTemplate}
          allDynamicContent={allDynamicContent}
          canEdit={canEdit}
          onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
          emailHtml={emailHtml}
          sendgridInfo={sendgridInfo}
          openAuthModal={openAuthModal}
          enterprise={enterprise}
          dynamicRecipientsName={dynamicRecipientsName}
        />
      )}
      <Section className="email-html-container">
        <EmailBodyHtml
          emailHtml={emailHtml}
          subject={currentTemplate.source.subject}
          currentTemplate={currentTemplate}
          onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
          canEdit={canEdit}
          jsonNodes={jsonNodes}
          onEditorSaveClick={onEditorSaveClick}
          onTemplateTest={onTemplateTest}
          emailPreviewValues={emailPreviewValues}
          isPreviewLoading={isPreviewLoading}
          dynamicRecipientsName={dynamicRecipientsName}
        />
        <Level className="add-attachment">
          {currentTemplate.attached_template_id ? (
            <Level className="email-attachments-list">
              <div>
                <Icon name="attach" size={20} />
                <span>Attachments</span>
              </div>
              <EmailAttachmentCard
                currentTemplate={currentTemplate}
                onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
                attachedTemplate={attachedTemplate}
              />
            </Level>
          ) : null}
          <EmailAttachmentPopUp
            entityType="template"
            currentTemplate={currentTemplate}
            filters={'source_type:powerpoint OR source_type:google_slides OR source_type:google_docs'}
            onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
          />
        </Level>
      </Section>
    </div>
  );
}

EmailTemplateBody.propTypes = {
  currentTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  attachedTemplate: PropTypes.object,
  allDynamicContent: PropTypes.object,
  openFullScreenModal: PropTypes.func,
  ui: PropTypes.object,
  closeFullScreenModal: PropTypes.func,
  sendgridInfo: PropTypes.object,
  openAuthModal: PropTypes.func,
  enterprise: PropTypes.object,
  canEdit: PropTypes.bool,
  jsonNodes: PropTypes.string,
  onEditorSaveClick: PropTypes.func,
  onTemplateTest: PropTypes.func,
  emailPreviewValues: PropTypes.object,
  isPreviewLoading: PropTypes.bool,
  emailHtml: PropTypes.string,
  dynamicRecipientsName: PropTypes.string,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(EmailTemplateBody);
