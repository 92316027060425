import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import inputs from 'lib/inputs';
import utils from 'lib/utils';
import InputsList from 'lib/inputsList';

import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';

import { UserContext } from 'components/UserContext';
import { MInsufficientPerms } from 'components/shared/Alerts';
import { PresentationContext } from 'components/shared/presentations/PresentationContext';
import DocumentTemplateComponents from 'components/producer/templates/DocumentTemplateComponents';
import { useTemplateContent, useTemplateResyncStatus } from 'lib/hooks/useTemplate';
import PresentationTemplateHeader from 'components/producer/templates/PresentationTemplateHeader';
import PresentationCreator from 'components/consumer/PresentationCreator';

const DocumentTemplateBody = ({
  currentTemplate,
  allDynamicContent,
  onTemplateUpdateWithServerCall,
  canEdit,
  accessesByTemplateId,
  status,
  onTemplateTest,
  onTemplateDelete,
  onTemplateArchive,
  onUpdateTemplateFile,
  onContentClick,
  onInputClick,
  onPresentationCreate,
}) => {
  const [activeTab, setActiveTab] = useState('setup');
  const presentationContext = useContext(PresentationContext);
  const userContext = useContext(UserContext);
  const { ui } = useSelector((state) => mapUiStateToProps(state));

  const dispatch = useDispatch();
  const { openSidepane } = mapUiDispatchToProps(dispatch);
  const { hideWarning: hideResyncWarning } = useTemplateResyncStatus(currentTemplate);

  useEffect(() => {
    return () => {
      if (currentTemplate?.id && presentationContext?.regenerate && presentationContext?.presentationToRegenerate) {
        presentationContext.clearPresentationContext();
      }
    };
  }, []);

  const { data: templateContent, isPending: isContentFetching } = useTemplateContent(
    currentTemplate?.id,
    currentTemplate?.deleted,
  );

  const inputsInMatchingContent = () => {
    const tagTree = utils.getDynamicContentTags(currentTemplate, templateContent, allDynamicContent);
    return inputs.getAllInputs(tagTree.getTagNodes(), allDynamicContent, currentTemplate, templateContent, null);
  };

  const matchingInputs = inputsInMatchingContent();
  const inputsList = new InputsList(matchingInputs, currentTemplate.params_order);
  const orderedInputs = inputsList.getSortedList();

  const syncTemplate = () => {
    let needsToConnect = false;
    if (currentTemplate.source_type === 'google_docs') {
      const googleIntegration = utils.googleIntegration(userContext.user);
      needsToConnect = !googleIntegration || !googleIntegration.has_necessary_scopes;
    }

    if (needsToConnect) {
      const showPermsAlert = () => {
        const title = 'Grant Google Drive permissions to Matik';
        const message = 'In order to sync your template, you will need to select allow in the authentication window.';
        MInsufficientPerms(title, message, () => {
          utils.reconnectGoogle(userContext.user, userContext.updateUser, syncTemplate, showPermsAlert);
        });
      };

      utils.connectGoogle(userContext.user, userContext.updateUser, syncTemplate, showPermsAlert);
    } else {
      onUpdateTemplateFile();
      hideResyncWarning();
    }
  };

  const reviewPermissions = () => {
    if (!ui.sidepane) {
      openSidepane({ entityType: 'permissions' }, 'template-sidepane');
    }
  };

  const toggleTab = (label) => {
    setActiveTab(label.toLowerCase());
  };

  return (
    <>
      <PresentationTemplateHeader
        currentTemplate={currentTemplate}
        allOrderedInputs={orderedInputs}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
        canEdit={canEdit}
        status={status}
        accessesByTemplateId={accessesByTemplateId}
        onTemplateTest={onTemplateTest}
        onTemplateDelete={onTemplateDelete}
        onTemplateArchive={onTemplateArchive}
        syncTemplate={syncTemplate}
        activeTab={activeTab}
        toggleTab={toggleTab}
      />
      {activeTab === 'generate' ? (
        <PresentationCreator onPresentationCreate={onPresentationCreate} hideHeader={true} />
      ) : (
        <DocumentTemplateComponents
          currentTemplate={currentTemplate}
          allDynamicContent={allDynamicContent}
          isContentFetching={isContentFetching}
          onSyncTemplate={syncTemplate}
          onReviewPermissions={reviewPermissions}
          onContentClick={onContentClick}
          onInputClick={onInputClick}
        />
      )}
    </>
  );
};

DocumentTemplateBody.propTypes = {
  currentTemplate: PropTypes.object,
  onTemplateTest: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
  onUpdateTemplateFile: PropTypes.func,
  canEdit: PropTypes.bool,
  allDynamicContent: PropTypes.object,
  accessesByTemplateId: PropTypes.object,
  status: PropTypes.object,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  onPresentationCreate: PropTypes.func,
};

export default DocumentTemplateBody;
