import {
  CLEAR_INPUT_VALUE,
  INIT_INPUT_VALUES_FROM_INPUTS,
  UPDATE_INPUT_VALUES,
  UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS,
  UPDATE_INPUT_VALUES_FROM_INPUTS,
} from './actionTypes';
import inputs from '../../lib/inputs';

export function inputValuesByEntity(state = {}, action) {
  const entity = `${action.entityType}_${action.entityId}`;
  switch (action.type) {
    case UPDATE_INPUT_VALUES:
    case INIT_INPUT_VALUES_FROM_INPUTS:
    case UPDATE_INPUT_VALUES_FROM_INPUTS:
    case CLEAR_INPUT_VALUE:
    case UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS:
      return Object.assign({}, state, {
        [entity]: inputValues(state[entity], action),
      });
    default:
      return state;
  }
}

function inputValues(state = {}, action) {
  switch (action.type) {
    // TODO: Cache input values per template/input/dynamic_content
    case UPDATE_INPUT_VALUES:
      return Object.assign({}, action.inputValues);
    case INIT_INPUT_VALUES_FROM_INPUTS:
      return initInputValuesFromInputs(action.entityId, action.inputs);
    case UPDATE_INPUT_VALUES_FROM_INPUTS:
      return updateInputValuesFromInputs(state, action.inputs);
    case UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS:
      return updateInputValuesAndInitFromInputs(action.inputValues, action.inputs);
    case CLEAR_INPUT_VALUE:
      return clearInputValue(state, action.input);
    default:
      return state;
  }
}

function initInputValuesFromInputs(templateId, initialInputs) {
  const inputValues = {};
  initialInputs.forEach((input) => {
    let templateLoops = input.loops ? input.loops.map((loop) => loop.template_id) : null;
    if (templateLoops && templateLoops.indexOf(templateId) !== -1) {
      inputValues[input.name] = inputs.initInputNullValue(input);
    } else {
      inputValues[input.name] = inputs.initInputValue(input);
    }
  });
  return inputValues;
}

function clearInputValue(currentInputValues, input) {
  const updatedInputValues = Object.assign({}, currentInputValues);
  if (!updatedInputValues[input.name]) {
    updatedInputValues[input.name] = inputs.initInputValue(input);
  } else {
    if (inputs.initialValue(input) === '') {
      updatedInputValues[input.name].value = null;
    } else {
      updatedInputValues[input.name].value = inputs.initialValue(input);
    }
  }

  return updatedInputValues;
}

function updateInputValuesFromInputs(currentInputValues, currentInputs) {
  const updatedInputValues = {};
  // Remove inputValues for removed inputs
  const inputsByName = {};
  currentInputs.forEach((input) => (inputsByName[input.name] = input));
  Object.keys(currentInputValues).forEach((inputName) => {
    if (inputsByName[inputName]) {
      let currentInputValue = currentInputValues[inputName];

      // currentInputValues might only have a "value" attribute and we want a more complete version
      // e.g. when we're seeding input values from a presentation
      if (Object.keys(currentInputValue).length === 1 && Object.keys(currentInputValue)[0] === 'value') {
        currentInputValue = inputs.initInputNullValue(inputsByName[inputName]);
        currentInputValue.value = currentInputValues[inputName].value;
      }

      updatedInputValues[inputName] = currentInputValue;
    }
  });
  // Add inputValues for added inputs
  currentInputs.forEach((input) => {
    if (!updatedInputValues[input.name]) {
      updatedInputValues[input.name] = inputs.initInputValue(input);
    }
    if (input.loops?.length > 0) {
      if (updatedInputValues[input.name].value && updatedInputValues[input.name].value.length === 0) {
        updatedInputValues[input.name].value = null;
      }
    }
  });
  return updatedInputValues;
}

function updateInputValuesAndInitFromInputs(inputValues, inputs) {
  const updatedInputValues = Object.assign({}, inputValues);
  return updateInputValuesFromInputs(updatedInputValues, inputs);
}
