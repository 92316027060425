import React from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { ReactComponent as ChevronRight } from '../../../svg/chevron_right.svg';
import { default as Banner } from '../../shared/Banner.js';
import dataSources from 'lib/dataSources';

const DataSourcesGrid = (props) => {
  let filteredAndSortedSelectOptions = dataSources.getPublicDataSourceSelectOptions();
  let categorizedSelectOptions = dataSources.categorizeDataSourceSelectOptions(filteredAndSortedSelectOptions);

  return (
    <div className="data-source-main">
      {categorizedSelectOptions.map((dataSourceCategory) => (
        <React.Fragment key={dataSourceCategory.label}>
          <div className="data-source-grid-header-title">{dataSourceCategory.label}</div>
          <div className={props.shouldAddFirst ? 'data-source-grid-add-first' : 'data-source-grid'}>
            {dataSourceCategory.options.map((dataSource) => (
              <div key={dataSource.label} onClick={() => props.onClick(dataSource.value)} className="data-source-item">
                <Level>
                  <Level.Side align="left">
                    <Level.Item>
                      <img src={dataSource.icon} alt={dataSource.label} className="data-source-icon" />
                    </Level.Item>
                    <Level.Item>{dataSource.label}</Level.Item>
                  </Level.Side>
                  <Level.Side align="right">
                    <Level.Item>
                      <ChevronRight />
                    </Level.Item>
                  </Level.Side>
                </Level>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
      <div className={props.shouldAddFirst ? 'info-banner-add-first' : 'info-banner'}>
        <Banner
          bannerType="info"
          text="Is your Data Source missing?"
          primaryButtonLabel="Request Data Source"
          onPrimaryButtonClick={() =>
            window.open(
              'https://portal.productboard.com/ytu2rk1ktm2k9jh3ctodnwky/c/17-additional-datasources',
              '_blank',
            )
          }
        />
      </div>
    </div>
  );
};

DataSourcesGrid.propTypes = {
  onClick: PropTypes.func,
  shouldAddFirst: PropTypes.bool,
};

export default DataSourcesGrid;
