import {
  RECEIVE_ADD_GROUP,
  RECEIVE_DELETE_GROUP,
  RECEIVE_GROUP_MEMBERS,
  RECEIVE_GROUPS,
  RECEIVE_UPDATE_GROUP,
  REQUEST_ADD_GROUP,
  REQUEST_DELETE_GROUP,
  REQUEST_GROUP_MEMBERS,
  REQUEST_GROUPS,
  REQUEST_UPDATE_GROUP,
} from './actionTypes';
import API from '../../lib/api';
import { addNotificationSuccess } from '../notifications/action';

function requestGroups() {
  return {
    type: REQUEST_GROUPS,
  };
}

function receiveGroups(groups) {
  return {
    type: RECEIVE_GROUPS,
    groups,
  };
}

function requestAddGroup() {
  return {
    type: REQUEST_ADD_GROUP,
  };
}

function receiveAddGroup(groupToAdd) {
  return {
    type: RECEIVE_ADD_GROUP,
    groupToAdd,
  };
}

function requestUpdateGroup(groupId) {
  return {
    type: REQUEST_UPDATE_GROUP,
    groupId,
  };
}

function receiveUpdateGroup(groupId, updatedGroup) {
  return {
    type: RECEIVE_UPDATE_GROUP,
    updatedGroup,
    groupId,
  };
}

function requestDeleteGroup(groupId) {
  return {
    type: REQUEST_DELETE_GROUP,
    groupId,
  };
}

function receiveDeleteGroup(groupId) {
  return {
    type: RECEIVE_DELETE_GROUP,
    groupId,
  };
}

function fetchGroups() {
  return (dispatch) => {
    dispatch(requestGroups());
    return API.get('/groups/', (response) => {
      dispatch(receiveGroups(response.data));
    }).then(() => true); // Signal that we've successfully fetched the groups to subsequent callers
  };
}

function requestGroupMembers() {
  return {
    type: REQUEST_GROUP_MEMBERS,
  };
}

function receiveGroupMembers(membersByGroupId) {
  return {
    type: RECEIVE_GROUP_MEMBERS,
    membersByGroupId,
  };
}

export function fetchGroupsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.groups || !state.groups.groups) {
      return dispatch(fetchGroups());
    }

    return Promise.resolve(false);
  };
}

export function fetchGroupMembers(shouldFetch) {
  return (dispatch) => {
    if (shouldFetch) {
      dispatch(requestGroupMembers());
      API.get('/groups/users/', (response) => {
        dispatch(receiveGroupMembers(response.data));
      });
    }

    return Promise.resolve();
  };
}

export function addGroup(groupData) {
  return (dispatch) => {
    dispatch(requestAddGroup());
    API.post('/groups/', groupData, (response) => {
      dispatch(receiveAddGroup(response.data));
      dispatch(fetchGroupMembers(true));
      dispatch(addNotificationSuccess(`Added ${response.data.name}`));
    });
  };
}

export function updateGroup(groupId, updateData) {
  return (dispatch) => {
    dispatch(requestUpdateGroup(groupId));
    API.put(`/groups/${groupId}/`, updateData, (response) => {
      dispatch(receiveUpdateGroup(groupId, response.data));
      dispatch(fetchGroupMembers(true));
      dispatch(addNotificationSuccess(`Updated ${response.data.name}`));
    });
  };
}

export function deleteGroup(groupId) {
  return (dispatch) => {
    dispatch(requestDeleteGroup(groupId));
    API.delete(`/groups/${groupId}/`, (response) => {
      dispatch(receiveDeleteGroup(groupId, response.data));
      dispatch(addNotificationSuccess(`Deleted ${response.data.name}`));
    });
  };
}
