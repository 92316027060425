import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty, isEqual } from 'lodash';
import { Form } from 'react-bulma-components';
import { Select } from '../../shared/FormSelect';
import { ReactComponent as Eye } from '../../../svg/eye.svg';
import { ReactComponent as ArrowHook } from '../../../svg/arrow_hook.svg';
import InputLoading from '../../shared/InputLoading';
import QueryLoading from '../../shared/QueryLoading';

class InputMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKeyObj: {},
      currentValueObj: {},
    };
  }

  componentDidMount() {
    if (this.props.inputMapping && !isEmpty(this.props.inputMapping) && this.props.returnFieldsArray.length > 1) {
      this.initCurrentKeyValue(Object.keys(this.props.inputMapping)[0], Object.values(this.props.inputMapping)[0]);
    } else if (this.props.returnFieldsArray && this.props.returnFieldsArray.length > 1) {
      this.initCurrentKeyValue(this.props.returnFieldsArray[0], this.props.returnFieldsArray[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.inputMapping, this.props.inputMapping) ||
      !isEqual(prevProps.returnFieldsArray, this.props.returnFieldsArray)
    ) {
      if (this.props.inputMapping && !isEmpty(this.props.inputMapping) && this.props.returnFieldsArray.length > 1) {
        this.initCurrentKeyValue(Object.keys(this.props.inputMapping)[0], Object.values(this.props.inputMapping)[0]);
      } else if (this.props.returnFieldsArray && this.props.returnFieldsArray.length > 1) {
        this.initCurrentKeyValue(this.props.returnFieldsArray[0], this.props.returnFieldsArray[0]);
      }
    }
  }

  render() {
    let isReadOnly = true;
    const options = this.props.returnFieldsArray.map((option) => ({
      label: option.displayName || option.label || option,
      value: option.value || option,
    }));
    if (this.props.returnFieldsArray.length > 1) {
      isReadOnly = false;
    }

    return (
      <Form.Field className="mbl">
        <Form.Label>
          <span className="mrs">Input Mapping</span>
          <QueryLoading
            isLoading={this.props.isLoading}
            dataTS={this.props.dataTS}
            onSyncButtonClick={this.props.onResync}
          />
        </Form.Label>
        {this.props.inputSourceType === 'query' && (
          <Form.Help>(Note: Input mapping options will update after testing the query)</Form.Help>
        )}
        <Form.Field kind="group" className="mbl">
          <Form.Control className="flex-1">
            <Form.Help className="mbxs">Select which field is visible to the End User</Form.Help>
            {!this.props.returnFieldsArray?.length ? (
              <InputLoading />
            ) : (
              <div
                data-tooltip-id="matik-tooltip"
                data-tooltip-content={isReadOnly ? 'Select more than one field to activate' : ''}
              >
                <Select
                  id="input-mapping-key"
                  classNamePrefix="matik-select"
                  className="icon-select"
                  isDisabled={isReadOnly}
                  options={options}
                  name="input-mapping-key"
                  onChange={this.onSelect}
                  iconSelect={true}
                  iconElement={<Eye className="icon-select-icon" />}
                  value={
                    isReadOnly
                      ? {}
                      : {
                          label: this.state.currentKeyObj.displayName || this.state.currentKeyObj.label,
                          value: this.state.currentKeyObj.value,
                        }
                  }
                  menuPortalTarget={this.props.formRef}
                />
              </div>
            )}
          </Form.Control>
          <Form.Control className="flex-1">
            <Form.Help className="mbxs">Select which field is actually inserted</Form.Help>
            {!this.props.returnFieldsArray?.length ? (
              <InputLoading />
            ) : (
              <div
                data-tooltip-id="matik-tooltip"
                data-tooltip-content={isReadOnly ? 'Select more than one field to activate' : ''}
              >
                <Select
                  id="input-mapping-value"
                  classNamePrefix="matik-select"
                  className="icon-select"
                  isDisabled={isReadOnly}
                  options={options}
                  name="input-mapping-value"
                  onChange={this.onSelect}
                  iconSelect={true}
                  iconElement={<ArrowHook className="icon-select-icon" />}
                  value={
                    isReadOnly
                      ? {}
                      : {
                          label: this.state.currentValueObj.displayName || this.state.currentValueObj.label,
                          value: this.state.currentValueObj.value,
                        }
                  }
                  menuPortalTarget={this.props.formRef}
                />
              </div>
            )}
          </Form.Control>
        </Form.Field>
      </Form.Field>
    );
  }

  initCurrentKeyValue(key, value) {
    if (key && value && !isEqual(key, value)) {
      const keyObj = find(this.props.returnFieldsArray, { value: key }) || { label: key, value: key };
      const valueObj = find(this.props.returnFieldsArray, { value: value }) || { label: value, value: value };
      if (keyObj && valueObj) {
        this.setState({
          currentKeyObj: keyObj,
          currentValueObj: valueObj,
        });
      }
    }
  }

  onSelect = (value, action) => {
    if (action.name === 'input-mapping-key') {
      this.setState({ currentKeyObj: value }, () => {
        if (this.state.currentValueObj) {
          this.updateInputMapping();
        }
      });
    } else {
      this.setState({ currentValueObj: value }, () => {
        if (this.state.currentKeyObj) {
          this.updateInputMapping();
        }
      });
    }
  };

  updateInputMapping = (key, value) => {
    let currentKey = key ? key : this.state.currentKeyObj;
    let currentValue = value ? value : this.state.currentValueObj;
    if (currentKey && currentValue) {
      // this seems to be necessary only for salesforce reports
      if (this.props.mapOnLabel) {
        this.props.onInputMappingUpdate({ [currentKey.label]: currentValue.label });
      } else {
        this.props.onInputMappingUpdate({ [currentKey.value]: currentValue.value });
      }
    } else {
      this.props.onInputMappingUpdate({});
    }
  };
}

InputMapping.propTypes = {
  onInputMappingUpdate: PropTypes.func,
  input: PropTypes.object,
  inputSourceType: PropTypes.any,
  inputMapping: PropTypes.object,
  isLoading: PropTypes.bool,
  dataTS: PropTypes.object, // Should be a Moment
  onResync: PropTypes.func,
  returnFieldsArray: PropTypes.array,
  formRef: PropTypes.func,
  mapOnLabel: PropTypes.bool,
};

export default InputMapping;
