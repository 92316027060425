import { Level } from 'react-bulma-components';
import PhotoWithUpload from '../PhotoWithUpload';
import { PickColor } from '../PickColor';
import React, { useContext } from 'react';
import { UserContext } from 'components/UserContext';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { cloneDeep } from 'lodash';
import utils from '../../../lib/utils';

const BrandingForm = (props) => {
  const ancestorEl = document.querySelector('.branding-container');
  const userContext = useContext(UserContext);

  const onPhotoChange = (updatedBrandingInfo) => {
    props.onBrandingInfoChange(updatedBrandingInfo);
    utils.notify('Logo updated');
  };

  const onColorPickerChangeFactory = (colorName) => (color) => {
    let updatedBrandingInfo = cloneDeep(props.brandingInfo);
    if (!updatedBrandingInfo['theme']) {
      updatedBrandingInfo['theme'] = {};
    }
    updatedBrandingInfo['theme'][colorName] = color.hex;
    props.onBrandingInfoChange(updatedBrandingInfo);
  };

  return (
    <div className="branding-container">
      <Level>
        <div className="has-text-black align-center ptm">
          {' '}
          Logo
          <div className="help pts pbl"> Your Company Logo </div>
          {props.isLoading ? (
            <Skeleton count={1} width="80px" height="80px" />
          ) : (
            <PhotoWithUpload
              uploadText={userContext?.user?.enterprise?.enterprise_settings?.logo_url ? 'Update logo' : 'Add logo'}
              uploadUrl={`/enterprises/${userContext.user.enterprise.id}/upload_branding_logo/`}
              onUpload={(data) => onPhotoChange(data.enterprise_settings.branding_info)}
              photoUrl={props.brandingInfo.logo_url}
            />
          )}
        </div>
      </Level>
      <Level>
        <Level.Item>
          <div className="App container color-name">
            Primary Dark Color
            <div className="description-text">
              {props.darkColorDescription}
              <PickColor
                hexColor={props.brandingInfo?.theme?.dark_color}
                onChange={onColorPickerChangeFactory('dark_color')}
                isLoading={props.isLoading}
                ancestorEl={ancestorEl}
              />
            </div>
          </div>
        </Level.Item>
      </Level>

      <Level>
        <Level.Item>
          <div className="App container color-name">
            Accent Color
            <div className="description-text">
              {props.accentColorDescription}
              <PickColor
                hexColor={props.brandingInfo?.theme?.light_color}
                onChange={onColorPickerChangeFactory('light_color')}
                isLoading={props.isLoading}
                ancestorEl={ancestorEl}
              />
            </div>
          </div>
        </Level.Item>
      </Level>

      <Level>
        <Level.Item>
          <div className="App container color-name">
            Link Color
            <div className="description-text">
              <PickColor
                hexColor={props.brandingInfo?.theme?.link_color}
                onChange={onColorPickerChangeFactory('link_color')}
                isLoading={props.isLoading}
                ancestorEl={ancestorEl}
              />
            </div>
          </div>
        </Level.Item>
      </Level>
    </div>
  );
};

BrandingForm.propTypes = {
  darkColorDescription: PropTypes.string,
  accentColorDescription: PropTypes.string,
  brandingInfo: PropTypes.object,
  onBrandingInfoChange: PropTypes.func,
  isLoading: PropTypes.bool,
};
export default BrandingForm;
