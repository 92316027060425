import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import FormFromInputs from '../FormFromInputs';
import { Form, Level } from 'react-bulma-components';
import { cloneDeep, find, findIndex, isEmpty, isEqual } from 'lodash';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import API from 'lib/api';
import inputs from 'lib/inputs';
import PageLoader from 'components/shared/PageLoader';
import WithTestQuery from 'components/shared/WithTestQuery';
import {
  MAlert,
  MConfirm,
  MProductUpsell,
  MInsufficientPerms,
  MInsufficientPermissions,
} from 'components/shared/Alerts';
import { mapInputsStateToProps } from 'redux/inputs/stateMappers';
import { mapInputDispatchToProps } from 'redux/inputs/dispatchers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import ConditionalContentProcessor from 'lib/conditionalContentProcessor';
import { UserContext } from 'components/UserContext';
import ScheduledFlowModal from 'components/shared/flows/ScheduledFlowModal';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import slides from 'lib/slides';
import no_inputs_image from 'images/NoInputsInSlideSelection.png';
import connect_to_google from 'images/ConnectGoogleSlides.png';
import connect_to_microsoft from 'images/empty_state_small_microsoft_365.png';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import QueryProcessor from 'lib/queryProcessor';
import UpsellGenericModal from 'components/payments/UpsellGenericModal';
import teams from 'lib/teams';
import withPresentationContextConsumer from 'components/shared/presentations/WithPresentationContextConsumer';
import CsvBulkUploadForm from 'components/shared/presentations/CsvBulkUploadForm';
import LongRequest from 'lib/longRequest';
import Button from 'components/lib/Button';
import Icon from 'components/lib/Icon';
import ButtonGroup from 'components/lib/ButtonGroup';
import PopupMenu from 'components/shared/PopupMenu';
import GeneratePresentationPopup from '../GeneratePresentationPopup';
import { useDynamicContentInputList } from '../../../lib/hooks/useInputList';
import SmallLoader from '../SmallLoader';
import dynamicContent from 'lib/dynamicContent';
import Banner, { toastBanner } from 'components/shared/Banner';
import EmailSendDropdown from 'components/producer/email/EmailSendDropdown';

const TABS = { INPUTS: 'inputs', UPLOAD: 'upload' };
class PresentationInputsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isBulk: false,
      currentBulkInputName: null,
      processingStatus: 'Processing',
      presentationGenerationStarted: null,
      schedule: null,
      includePdf: false,
      testIsLoading: false,
      testResult: null,
      authTokenByDynamicContent: {},
      buttonActive: false,
      showGenerateDropdown: false,
      timeConstructed: Date.now(),
      isEmailTemplate: false,
      activeTab: TABS.INPUTS,
      customFolder: {},
      customName: '',
      customFolderPlaceholder: '/Matik Presentations/',
      userBasedInputsIsLoading: false,
    };
    this.popupMenuRef = React.createRef();
    this.statusInterval = null;
    this.setUserBasedInputsIsLoading = this.setUserBasedInputsIsLoading.bind(this);
  }

  CSV_MODAL_NAME = 'csvBulkUploadModal';

  componentDidMount() {
    let inputValuesWithDefaults = this.props.inputValues;
    // If the inputs have defaults, we want to set the inputValues to those defaults so that conditional
    // content will correctly evaluate on mount and show all inputs as necessary.
    if ((!this.props.inputValues || Object.values(this.props.inputValues).length === 0) && this.props.sortedInputs) {
      inputValuesWithDefaults = {};
      this.props.sortedInputs.forEach((input) => {
        inputValuesWithDefaults[input.name] = inputs.initInputValue(input);
      });
    }

    if (this.props.existingInputValues && !isEmpty(this.props.existingInputValues)) {
      this.props.updateInputValuesAndInitFromInputs(
        this.props.entityType,
        this.props.entityId,
        this.props.existingInputValues,
        this.props.sortedInputs,
      );
    } else {
      this.props.initInputValuesFromInputs(this.props.entityType, this.props.entityId, this.props.sortedInputs);
    }

    // for mail templates, only the first (non-nested) dynamic recipients input is bulkable so we set it to bulk for the user
    this.checkMailInputs(this.props.sortedInputs);

    this.checkInputsAreFilledOut(this.props.sortedInputs);
    if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      this.setState({ isEmailTemplate: true });
    }

    utils.getDefaultIntegrationFolderName(this.props.template?.id, (response) => {
      if (response?.length > 0) {
        this.setState({ customFolderPlaceholder: response });
      }
    });

    if (!this.props.template) {
      toastBanner(
        <Banner
          bannerType="error"
          text="Presentation's template info does not exist"
          sublineText="This is likely due to the template being deleted."
        />,
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggerEmailGeneration) {
      this.onFormSubmit();
      this.props.untriggerEmailGeneration();
    }

    if (
      (this.props.sortedInputs && !isEqual(this.props.sortedInputs, prevProps.sortedInputs)) ||
      (this.props.attachmentInputs && !isEqual(this.props.attachmentInputs, prevProps.attachmentInputs)) ||
      !isEqual(this.props.inputValues, prevProps.inputValues) ||
      !isEqual(this.props.allDynamicContent, prevProps.allDynamicContent)
    ) {
      this.props.updateInputValuesAndInitFromInputs(
        this.props.entityType,
        this.props.entityId,
        this.props.inputValues,
        this.props.sortedInputs,
      );
      this.checkMailInputs(this.props.sortedInputs);

      if (this.props.existingInputValues && !isEmpty(this.props.existingInputValues)) {
        this.props.updateInputValuesAndInitFromInputs(
          this.props.entityType,
          this.props.entityId,
          this.props.existingInputValues,
          this.props.sortedInputs,
        );
      }
    }
    this.checkInputsAreFilledOut(this.props.sortedInputs);
  }

  render() {
    if (
      this.props.sortedInputs?.length > 0 &&
      (!this.props.inputValues || Object.keys(this.props.inputValues).length === 0)
    ) {
      return null;
    }

    const inputsComponent = this.renderInputsComponent();
    const buttonActive = inputsComponent.buttonActive ?? this.state.buttonActive;

    let buttons;
    let primaryButtonStatus = 'default';
    if (!buttonActive) {
      primaryButtonStatus = 'disabled';
    } else if (this.state.isLoading) {
      primaryButtonStatus = 'loading';
    }

    const endUserGenerateButton = (
      <Button
        category="primary"
        type="button"
        onClick={() => {
          API.track('open_generate_popup');
        }}
        status={buttonActive ? 'default' : 'disabled'}
        ref={this.anchorRef}
      >
        {inputsComponent.buttonText}
      </Button>
    );

    const templateType =
      this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
        ? 'document'
        : this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
        ? 'email'
        : 'presentation';

    const disabledFolderOptions =
      this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ? ['google'] : ['microsoft'];

    const namePreview = utils.getNameTemplatePreview(
      this.props.template,
      this.props.sortedInputs,
      this.props.inputValues,
    );

    let isSendingDisabled = true;
    let tooltip = '';
    if (templateType === 'email') {
      const subjectFilledOut = this.props.template.source.subject?.length > 0;
      const recipientsFilledOut = Boolean(this.props.template.source.recipients?.dynamicRecipients);
      const fromFilledOut =
        [
          this.props.template.source.from?.fromEmail,
          this.props.template.source.from?.replyEmail,
          this.props.template.source.from?.name,
        ].every((field) => field !== '') || Boolean(this.props.template.source.from?.senderContent);

      const allEmailFieldsFilledOut = subjectFilledOut && recipientsFilledOut && fromFilledOut;
      isSendingDisabled = !(this.props.allInputsFilledOut && allEmailFieldsFilledOut);
      if (this.props.allInputsFilledOut === false) {
        tooltip = 'Some inputs are missing';
      }
      if (allEmailFieldsFilledOut === false) {
        tooltip = 'Some email fields are incomplete: ';
        const missingEmailFields = [];
        if (!this.props.template.source.subject?.length) {
          missingEmailFields.push('Subject');
        }
        if (!Object.values(this.props.template.source.recipients).some((field) => field.length > 0)) {
          missingEmailFields.push('To');
        }
        if (!Object.values(this.props.template.source.from).every((field) => field !== '')) {
          missingEmailFields.push('From');
        }
        if (missingEmailFields.length) {
          tooltip += missingEmailFields.join(', ');
        }
      }
    }

    let endUserButtons = null;
    if (templateType === 'email' && this.props.showEmailSendButton) {
      endUserButtons = (
        <ButtonGroup>
          <Button category="secondary" status={buttonActive ? 'default' : 'disabled'} onClick={this.onScheduleOpen}>
            <Icon name="calendar" size={20} theme="regular" />
            Schedule
          </Button>
          <EmailSendDropdown
            submitTestEmail={this.props.submitTestEmail}
            onSendEmailClick={this.props.onSendEmailClick}
            isSendingDisabled={isSendingDisabled}
            tooltip={tooltip}
            hideScheduleOption={true}
          />
        </ButtonGroup>
      );
    } else if (templateType !== 'email' && this.props.flags.enableGenerationPopup) {
      endUserButtons = (
        <ButtonGroup>
          <Button category="secondary" status={buttonActive ? 'default' : 'disabled'} onClick={this.onScheduleOpen}>
            <Icon name="calendar" size={20} theme="regular" />
            Schedule
          </Button>
          <PopupMenu ref={this.popupMenuRef} trigger={endUserGenerateButton} offset={-93} position="x">
            <GeneratePresentationPopup
              onPdfToggle={this.onPdfToggle}
              includePdf={this.state.includePdf}
              templateType={templateType}
              sourceType={this.props.source_type}
              popupMenuRef={this.popupMenuRef}
              showConditionsBanner={
                Boolean(this.props.template?.conditions?.length) && !this.props.presentationContext.overrideConditions
              }
              primaryButtonOnClick={this.onFormSubmit}
              primaryButtonText={inputsComponent.buttonText}
              primaryButtonActive={buttonActive}
              onCustomFolderUpdate={this.onCustomFolderUpdate}
              customFolder={this.state.customFolder}
              customFolderPlaceholder={this.state.customFolderPlaceholder}
              onCustomNameUpdate={this.onCustomNameUpdate}
              customName={this.state.customName}
              disabledFolderOptions={disabledFolderOptions}
              namePreview={namePreview}
            />
          </PopupMenu>
        </ButtonGroup>
      );
    } else if (templateType !== 'email') {
      endUserButtons = (
        <ButtonGroup>
          <Button category="secondary" status={buttonActive ? 'default' : 'disabled'} onClick={this.onScheduleOpen}>
            <Icon name="calendar" size={20} theme="regular" />
            Schedule
          </Button>
          <Button
            category="primary"
            type="button"
            onClick={this.onFormSubmit}
            status={buttonActive ? 'default' : 'disabled'}
            ref={this.anchorRef}
          >
            {inputsComponent.buttonText}
          </Button>
        </ButtonGroup>
      );
    }
    const hideButtons = templateType === 'email' && !this.props.showEmailSendButton;

    if (!this.props.isReadOnly && !hideButtons) {
      buttons = (
        <Form.Field className="fixed-bottom-button">
          <Form.Control className="block-button">
            <Level>
              <Level.Side align="left">
                <Level.Item>
                  {!this.needsToConnectToIntegration() &&
                    !this.state.isEmailTemplate &&
                    (!this.props.flags.enableGenerationPopup || this.props.isProducer) && (
                      <CheckboxWithLabel
                        checked={this.state.includePdf}
                        id="include-pdf-checkbox"
                        label="Include PDF"
                        onChange={this.onPdfToggle}
                      />
                    )}
                </Level.Item>
              </Level.Side>
              <Level.Side align="right">
                {this.props.isProducer && (
                  <Level.Item>
                    <Button category="secondary" onClick={this.onClose}>
                      Cancel
                    </Button>
                  </Level.Item>
                )}
                <Level.Item>
                  {this.props.isProducer ? (
                    <Button id="template-create-button" type="submit" status={primaryButtonStatus}>
                      {inputsComponent.buttonText}
                    </Button>
                  ) : (
                    endUserButtons
                  )}
                </Level.Item>
              </Level.Side>
            </Level>
          </Form.Control>
        </Form.Field>
      );
      if (this.needsToConnectToIntegration() && !this.props.isProducer) {
        buttons = null;
      }
    }

    return (
      <React.Fragment>
        <form
          onSubmit={this.onFormSubmit}
          className={`presentation-input-form ${this.state.isEmailTemplate ? 'email' : ''}`}
        >
          {inputsComponent.body}
          <PageLoader
            isActive={this.state.isLoading}
            title={this.state.processingStatus}
            showLottie={!this.state.isEmailTemplate}
          />
          {this.state.activeTab !== TABS.UPLOAD && buttons}
        </form>
        {templateType === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && (
          <CsvBulkUploadForm
            template={this.props.template}
            isReadOnly={this.props.isReadOnly}
            modalName={this.CSV_MODAL_NAME}
          />
        )}
        <ScheduledFlowModal
          canRefreshSelectAllValues={this.canRefreshSelectAllValues()}
          onScheduleSubmit={this.onScheduleTrigger}
          schedule={this.state.schedule}
          template={this.props.template}
          type="create"
          customFolderPlaceholder={this.state.customFolderPlaceholder}
          includePdf={this.state.includePdf}
          onPdfToggle={this.onPdfToggle}
          customFolder={this.state.customFolder}
          onCustomFolderUpdate={this.onCustomFolderUpdate}
        />
      </React.Fragment>
    );
  }

  setUserBasedInputsIsLoading(userBasedInputsIsLoading) {
    this.setState({ userBasedInputsIsLoading });
  }

  handleTabClick = (e, tab) => {
    e.preventDefault();
    this.setState({ activeTab: tab });
  };

  canRefreshSelectAllValues() {
    return Object.values(this.props.sortedInputs).some((input) =>
      inputs.canInputRefreshSelectAll(input, this.props.inputValues[input.name], this.state.currentBulkInputName),
    );
  }

  checkMailInputs(sortedInputs) {
    if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && sortedInputs.length) {
      if (
        this.props.template?.source?.recipients?.bulkedInput &&
        this.props.template?.source?.recipients?.bulkedInput !== 'None'
      ) {
        this.setState({ currentBulkInputName: this.props.template?.source?.recipients?.bulkedInput, isBulk: true });
      } else {
        for (const input of sortedInputs) {
          if (input.dynamic_content?.some((dc) => dc.dynamic_content_type === 'recipients')) {
            this.setState({ currentBulkInputName: input.name, isBulk: true });
            break;
          }
        }
      }
    }
  }

  checkInputsAreFilledOut = (sortedInputs) => {
    const shouldButtonBeActive = this.shouldButtonBeActive(sortedInputs);
    const buttonActive = !this.props.isContentFetching && !this.props.areInputsLoading && shouldButtonBeActive;

    if (buttonActive && this.state.timeConstructed && !this.state.buttonActive) {
      API.track('generatePresentationActive', {
        durationMs: Date.now() - this.state.timeConstructed,
        templateId: this.props.template?.id,
        numInputs: sortedInputs.length,
      });
    }

    if (buttonActive !== this.state.buttonActive) {
      this.setState({ buttonActive: buttonActive });
      this.props.setAllInputsFilledOut?.(buttonActive);
    }
  };

  shouldButtonBeActive = (sortedInputs) => {
    const sortedInputsByName = {};
    sortedInputs.forEach((input) => (sortedInputsByName[input.name] = input));
    return (
      inputs.areInputsFilledOut(this.props.inputValues, sortedInputsByName) && !this.state.userBasedInputsIsLoading
    );
  };

  onTestAccess = (e, dataSource, dynamicContent, apiInputValues, authToken) => {
    if (Object.keys(dynamicContent.parameters).length !== Object.keys(apiInputValues).length) {
      MAlert('Please fill out all inputs for REST API before testing access', 'Error', 'error');
      this.props.updateTestResult(null);
      return;
    }
    this.props.setTokenLoading();
    const queryProcessor = new QueryProcessor(
      dynamicContent.query_obj.query_string,
      dynamicContent.parameters,
      apiInputValues,
      dataSource,
    );
    const parameterizedQuery = queryProcessor.parameterizeQueryString();
    this.props.testQuery(e, parameterizedQuery, dataSource, dynamicContent, authToken);
  };

  updateInputValues = (inputValues) => {
    this.props.updateInputValuesAndInitFromInputs(
      this.props.entityType,
      this.props.entityId,
      inputValues,
      this.props.sortedInputs,
    );
  };

  onBulkInputToggle = (inputName, shouldBeBulk) => {
    const input = find(this.props.sortedInputs, (input) => input.name === inputName);
    this.props.clearInputValue(this.props.entityType, this.props.entityId, input);
    API.track('toggle_bulk_input', { input_name: inputName });
    this.setState({
      isBulk: shouldBeBulk,
      currentBulkInputName: shouldBeBulk ? inputName : null,
    });
  };

  onPdfToggle = (e) => {
    API.track('toggle_include_pdf', { include_pdf: e.target.checked });
    this.setState({ includePdf: e.target.checked });
  };

  onCustomFolderUpdate = (result) => {
    if (!result || Object.keys(result).length === 0) {
      API.track('remove_run_custom_folder');
      this.setState({ customFolder: {} });
      return;
    }
    const folder = {
      folder_type: 'template',
      template_id: this.props.template?.id,
      ...result,
    };
    API.track('set_run_custom_folder', { folder: folder });
    this.setState({ customFolder: folder });
  };

  onCustomNameUpdate = (name) => {
    API.track('edit_presentation_name', { name: name });
    this.setState({ customName: name });
  };

  hasPresentationsRemaining = () => {
    if (
      this.context.user &&
      this.context.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_team.tier_id &&
      this.context.user.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.active &&
      this.context.user.enterprise.monthly_presentations_remaining !== undefined
    ) {
      return this.context.user.enterprise.monthly_presentations_remaining > 0;
    }
    return true;
  };

  isSchedulingEnabledForEnterprise = () => {
    return (
      this.context.user &&
      this.context.user.enterprise &&
      this.context.user.enterprise.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id
    );
  };

  fetchNoPresentationsHtml = () => {
    const planName = Constants.MATIK_TIERS[this.context.user.enterprise.plan_id].display_name;

    return ReactDOMServer.renderToStaticMarkup(
      <UpsellGenericModal
        featureHeader={`Get more Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
        featureDescription={`Your team has already generated ${Constants.MONTHLY_PRESENTATIONS} presentations this month, 
          which is the maximum allowed for the ${planName} plan.`}
      />,
    );
  };

  onFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.hasPresentationsRemaining()) {
      return this.handlePresentationUpsell();
    }

    if (this.needsToConnectToIntegration()) {
      return this.connectIntegration();
    }

    if (this.props.presentationContext.regenerate && this.props.presentationContext.regenerateTemplateChanged) {
      MConfirm(
        'This template has changed',
        `This template has changed since it was last used to generate a presentation.  Matik may not be able to 
            generate a presentation correctly using these inputs.`,
        'warning',
        (result) => {
          if (!result) {
            return false;
          } else {
            return this.buildAndSubmitPresentation();
          }
        },
        'Continue',
      );
    } else if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && this.props.isTemplateTest) {
      const contentPreview = () => {
        const previewValues = {};
        const templateDynamicContent = Object.values(this.props.template?.matching_dynamic_content_by_slide)[0];
        const previewDynamicContentValues = Object.values(templateDynamicContent);
        const filterPreviewDC = previewDynamicContentValues.filter((previewDynamicContentValue) => {
          if (
            previewDynamicContentValue.dynamic_content_type !== Constants.DynamicContentTypes.RECIPIENTS &&
            previewDynamicContentValue.dynamic_content_method !== Constants.DynamicContentMethods.STATIC
          ) {
            return previewDynamicContentValue;
          }
        });

        for (let key in templateDynamicContent) {
          const dynamicContent = templateDynamicContent[key];
          if (dynamicContent.dynamic_content_type !== Constants.DynamicContentTypes.RECIPIENTS) {
            const formatResponseByDataSourceType = (response, datasourceType) => {
              switch (datasourceType) {
                case Constants.DATA_SOURCE_TYPES.google_sheet:
                case Constants.DATA_SOURCE_TYPES.excel:
                  if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
                    if (!response.data.query_json.output?.columns) {
                      response.data.result.splice(0, 0, ['result']);
                    }
                  }
                  break;
                case Constants.DATA_SOURCE_TYPES.matik_content:
                  if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
                    response = dynamicContent?.query_obj?.query_string;
                  }
                  break;
              }

              return response;
            };

            if (dynamicContent.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
              if (!isEmpty(dynamicContent.parameters)) {
                const firstInputKey = Object.keys(dynamicContent.parameters)[0];
                previewValues[dynamicContent.name] = this.props.inputValues[firstInputKey].value;
              } else if (this.props.inputValues[dynamicContent.name]) {
                previewValues[dynamicContent.name] = this.props.inputValues[dynamicContent.name].value;
              } else {
                let datasourceType = dynamicContent?.query_obj?.data_source?.type;
                previewValues[dynamicContent.name] = formatResponseByDataSourceType(dynamicContent, datasourceType);
              }
            } else {
              const queryProcessor = new QueryProcessor(
                dynamicContent.query_obj.query_string,
                dynamicContent.parameters,
                this.props.inputValues,
                dynamicContent.query_obj.data_source,
              );
              const parameterizedQuery = queryProcessor.parameterizeQueryString();

              const queryData = {
                data_source: { id: dynamicContent.query_obj.data_source.id },
                dynamic_content_type: dynamicContent.dynamic_content_method,
                query_string: parameterizedQuery,
                values_by_param_name: this.props.inputValues,
              };

              const onResponse = (response, onComplete) => {
                if (response.data.result) {
                  let datasourceType = dynamicContent?.query_obj?.data_source?.type;
                  let formattedResponse = formatResponseByDataSourceType(response, datasourceType);

                  if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TABLE) {
                    previewValues[dynamicContent.name] = formattedResponse.data.result;
                  } else if (dynamicContent?.query_obj?.id) {
                    previewValues[dynamicContent.name] = formattedResponse.data.result[1][0];
                  } else {
                    previewValues[dynamicContent.name] = formattedResponse.data.result[0][0];
                  }
                  this.props.onEmailPreview(previewValues, filterPreviewDC);
                  onComplete(dynamicContent.id);
                }
              };

              const longRequest = new LongRequest('/queries');
              longRequest.post(
                queryData,
                onResponse,
                this.props.onEmailPreviewError,
                undefined,
                undefined,
                `test/${dynamicContent.query_obj.data_source.type}`,
              );
            }
          }
        }
        this.props.onEmailPreview?.(previewValues, filterPreviewDC);
        this.props.closeModal();
      };
      this.checkIncompleteTags(contentPreview);
    } else {
      this.checkIncompleteTags(this.buildAndSubmitPresentation);
    }
  };

  checkIncompleteTags = (callback) => {
    let matches = null;
    let allMatches = '';
    if (this.props.template?.slides?.[0]?.slide_xml) {
      matches = this.props.template?.slides?.[0]?.slide_xml.match(/{{\w+[<\s]/g);
    }
    if (matches) {
      allMatches = matches.shift();
      allMatches = allMatches.slice(0, -1);
      for (let match of matches) {
        allMatches = allMatches + ', ' + match.slice(0, -1);
      }
      MConfirm(
        'Incomplete Tags',
        `The email template contains incomplete dynamic content tags: '${allMatches} tag'. Resolve the tags to ensure your dynamic content populates correctly.`,
        'warning',
        (result) => {
          if (!result) {
            return false;
          } else {
            return callback();
          }
        },
        'Continue',
      );
    } else {
      callback();
    }
  };

  buildAndSubmitPresentation = () => {
    const [data, attachmentData] = this.buildDataObject();

    if (this.state.isBulk && this.state.currentBulkInputName) {
      // enter the bulk path iff we actually have a bulk input or if it's a test email, which still goes through the bulk flow
      this.submitBulkPresentation(data, attachmentData);
    } else {
      data.values_by_param_name = this.removeOptionsAndReplaceMappedValuesForInputs(this.props.inputValues);
      this.submitPresentation(data, attachmentData);
    }
  };

  onScheduleTrigger = (schedule, skipPreview, refreshSelectAllValues) => {
    if (!this.hasPresentationsRemaining()) {
      return this.handlePresentationUpsell();
    }
    if (this.needsToConnectToIntegration()) {
      return this.connectIntegration();
    }

    const [data, attachmentData] = this.buildDataObject();
    schedule.task_params = {
      ...data,
      ...attachmentData,
      should_refresh_select_all_values: refreshSelectAllValues,
    };
    const previewData = {
      ...data,
      ...attachmentData,
    };
    if (schedule.csv_task_id) {
      if (skipPreview) {
        this.submitSchedule(schedule);
      } else {
        previewData.values_by_param_name = cloneDeep(schedule.previewData);
        delete schedule.previewData;
        this.submitScheduledPreview(previewData, schedule);
      }
    } else {
      // fashion the params for the scheduled generation
      const bulkValuesByParamName = this.state.isBulk
        ? this.getBulkPresentationData(refreshSelectAllValues)
        : [this.removeOptionsAndReplaceMappedValuesForInputs(this.props.inputValues, refreshSelectAllValues)];
      schedule.task_params.bulk_values_by_param_name = bulkValuesByParamName;
      if (skipPreview) {
        this.submitSchedule(schedule);
      } else {
        previewData.values_by_param_name = bulkValuesByParamName[0];
        this.submitScheduledPreview(previewData, schedule);
      }
    }
  };

  submitSchedule = (schedule) => {
    this.checkIncompleteTags(() => {
      this.setState({ isLoading: true });
      API.post(
        '/scheduled_tasks/',
        schedule,
        () => {
          this.setState({ isLoading: false });
          this.props.closeModal();
          utils.notify('Successfully scheduled generation');
        },
        this.onSubmitError,
      );
    });
  };

  submitScheduledPreview = (previewData, schedule) => {
    this.checkIncompleteTags(() => {
      this.setState({ schedule });
      previewData['is_test_slide'] = true;
      if (this.state.isBulk || schedule.csv_task_id) {
        API.track('scheduled_preview_click');
      } else {
        API.track('submit_non_bulk_schedule');
      }
      this.submitPresentation(previewData);
    });
  };

  handlePresentationUpsell = () => {
    API.track('tier_limit_warning', {
      type: 'no_presentations_remaining',
      tier: this.context.user.enterprise.plan_id,
    });
    const noPresentationsHtml = this.fetchNoPresentationsHtml();
    API.track('enterprise_upsell_shown', { from: 'no_presentations_remaining' });
    MProductUpsell(`${noPresentationsHtml}`, false, (result) => {
      if (!result.dismiss) {
        API.track('discover_matik_enterprise_click', { from: 'no_presentations_remaining' });
        const demoUrl = teams.buildRequestDemoUrl(
          this.context.user,
          'presentation_limit',
          this.context.user.enterprise.trial_days_remaining,
        );
        window.open(demoUrl, '_blank');
      }
    });
    return null;
  };

  buildDataObject = () => {
    const data = {
      bulk_input_name: this.state.currentBulkInputName,
      template_id: this.props.template?.id,
      content_conditions: this.contentByConditional('body'),
      producer_triggered: this.props.test,
      include_pdf: this.state.includePdf,
      is_test_slide: this.props.isTestSlide,
      custom_folder: this.state.customFolder,
      custom_name: this.state.customName,
      override_presentation_id: this.props.presentationContext.overrideConditions
        ? this.props.presentationContext.presentationToRegenerate.id
        : null,
    };

    let attachmentData = {};
    if (this.props.template?.attached_template_id && this.props.attachedTemplate) {
      attachmentData = {
        attachment_template_id: this.props.attachedTemplate.id,
        attachment_content_conditions: this.contentByConditional('attachment'),
      };
    }

    if (this.props.addedSlides) {
      data['added_library_slides'] = slides.parseAddedSlides(this.props.addedSlides);
    }

    if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && this.props.attachedTemplate) {
      data.slide_idxs = this.props.attachedTemplate.slides.map((slide) =>
        findIndex(this.props.attachedTemplate.slides, { id: slide.id }),
      );
    } else if (this.props.slideIdxs) {
      data.slide_idxs = this.props.slideIdxs;
    } else {
      data.slide_idxs = this.props.template?.slides.map((slide) =>
        findIndex(this.props.template?.slides, { id: slide.id }),
      );
    }

    if (!isEmpty(this.state.authTokenByDynamicContent)) {
      data.auth_token_by_dynamic_content = this.state.authTokenByDynamicContent;
    }
    return [data, attachmentData];
  };

  submitPresentation = (data, attachmentData = {}) => {
    this.setState({ isLoading: true, presentationGenerationStarted: Date.now(), processingStatus: 'Processing' });
    const allData = {
      ...data,
      ...attachmentData,
    };
    API.post(
      '/presentations/',
      allData,
      (response) => {
        this.statusInterval = setInterval(() => this.getStatus(response.data.new_entity.id), 1500);
      },
      this.onSubmitError,
    );
  };

  getBulkPresentationData = (refreshSelectAllValues = false) => {
    const bulkInputName = this.state.currentBulkInputName;
    //
    // Unroll the bulkifiedInput into an array of input value objects, one per presentation
    //
    // For example:
    //  this.state.currentBulkInputName: 'e'
    //  this.props.inputValues:          { a: 1, b: 2, ..., e: {value: [3, 4, 5]} }
    //
    // Produces the following list of expandedValues:
    //  [
    //    { a: 1, b: 2, ..., e: {value: 3} },
    //    { a: 1, b: 2, ..., e: {value: 4} },
    //    { a: 1, b: 2, ..., e: {value: 5} }
    //  ]
    //
    // Each member is the params list for one presentation.
    //

    // for email tests, we still want to go through the bulk process, so we insert the first bulk value into an array
    let bulkifiedInput;

    if (this.props.isTestSlide && this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      bulkifiedInput = [this.props.inputValues[bulkInputName].value[0]];
    } else {
      bulkifiedInput = this.props.inputValues[bulkInputName].value;
    }

    if (bulkifiedInput.length > 1000 && this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      MAlert('Bulk email list is limited to 1000 recipients');
    }
    const inputsByName = {};
    if (this.props.sortedInputs) {
      this.props.sortedInputs.forEach((input) => (inputsByName[input.name] = input));
    }
    const expandedValues = bulkifiedInput.map((value) => {
      const r = cloneDeep(this.props.inputValues);
      if (inputsByName[bulkInputName].type === Constants.InputTypes.LIST) {
        r[this.state.currentBulkInputName].value = [value];
      } else {
        r[this.state.currentBulkInputName].value = value;
      }
      return r;
    });
    const mappedValues = expandedValues.map((exVal) => {
      return this.removeOptionsAndReplaceMappedValuesForInputs(exVal, refreshSelectAllValues);
    });
    return mappedValues;
  };

  submitBulkPresentation = (baseData, attachmentData) => {
    const expandedValues = this.getBulkPresentationData();
    const previewValues = cloneDeep(expandedValues[0]);
    expandedValues[0]['is_preview'] = true;

    const previewData = {
      values_by_param_name: previewValues,
      is_test_slide: this.props.isTestSlide,
      ...baseData,
      ...attachmentData,
    };

    const bulkData = {
      bulk_values_by_param_name: expandedValues,
      ...baseData,
      ...attachmentData,
    };

    API.track('submit_bulk_presentation');
    API.post(
      '/bulk_presentations/',
      bulkData,
      (response) => {
        // kick off the preview job
        previewData['bulk_presentation_id'] = response.data.id;
        this.submitPresentation(previewData);
      },
      this.onSubmitError,
    );
  };

  getStatus = (presentationId) => {
    API.get(
      `/presentations/${presentationId}/get_status/`,
      (response) => {
        if (
          (response.data.presentation_status === 'done' ||
            response.data.presentation_status === 'non_email_failed_condition') &&
          this.state.processingStatus !== 'done'
        ) {
          this.setState({ isLoading: false, processingStatus: 'done' });
          const timeElapsed = Date.now() - this.state.presentationGenerationStarted;
          API.track('presentation_generate_on_tab', {
            elapsed_ms: timeElapsed,
            producer_triggered: !!this.props.test,
            presentation_id: presentationId,
          });
          clearInterval(this.statusInterval);
          const presentation = response.data.new_entity;
          const attachedPresentation = response.data.new_attachment_entity;
          // Only error and warning logs are passed through here, and the info logs are fetched if needed.
          if (presentation.logs.length > 0) {
            if (this.props.test) {
              window.location.assign(`/presentations/${presentationId}?logs=true`);
              return;
            }
          } else if (response.data.presentation_status === 'non_email_failed_condition') {
            if (this.props.test) {
              window.location.assign(`/presentations/${presentationId}`);
              return;
            }
          }
          this.props.onPresentationCreate(presentation, this.state.schedule, attachedPresentation);
        } else if (response.data.presentation_status === 'unknown') {
          // This may happen if there is a race condition: another user loads the templates page in between status checks
          clearInterval(this.statusInterval);
          window.location.reload();
        } else if (response.data.presentation_status === 'error') {
          clearInterval(this.statusInterval);
          this.setState({ isLoading: false });
          if (this.props.history.location.pathname.includes('create')) {
            window.location.assign(`/create/presentations/${presentationId}?logs=true`);
          } else {
            window.location.assign(`/presentations/${presentationId}?logs=true`);
          }
        } else if (response.data.attachment_status === 'error') {
          clearInterval({ isLoading: false });
          if (this.props.history.location.pathname.includes('create')) {
            window.location.assign(`/create/presentations/${presentationId}?logs=true`);
          } else {
            window.location.assign(`/presentations/${presentationId}?logs=true`);
          }
        } else {
          this.setState({ processingStatus: response.data.presentation_status });
        }
      },
      this.onSubmitError,
    );
  };

  onSubmitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };

  contentByConditional = (templateType) => {
    const contentByConditional = {};
    let conditionalContent;
    if (templateType === 'attachment') {
      conditionalContent = this.props.attachmentContent.filter((content) => {
        const contentObj = this.props.allDynamicContent ? this.props.allDynamicContent[content] : null;
        return contentObj && contentObj.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL;
      });
    } else {
      conditionalContent = this.props.templateContent.filter((content) => {
        const contentObj = this.props.allDynamicContent ? this.props.allDynamicContent[content] : null;
        return contentObj && contentObj.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL;
      });
    }

    for (let conditional of conditionalContent) {
      const contentObj = this.props.allDynamicContent[conditional];
      const conditionalContentProcessor = new ConditionalContentProcessor(
        JSON.parse(contentObj.query_obj.query_string),
        this.props.inputValues,
      );
      contentByConditional[contentObj.name] = conditionalContentProcessor.getOutputContent();
    }

    return contentByConditional;
  };

  needsToConnectToIntegration = () => {
    if (this.context.user && this.props.template) {
      if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
        const microsoftIntegration = utils.microsoftIntegration(this.context.user);
        return !microsoftIntegration;
      }
      const googleIntegration = utils.googleIntegration(this.context.user);
      return (
        this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES &&
        (!googleIntegration || !googleIntegration.has_necessary_scopes)
      );
    }

    return false;
  };

  removeOptionsAndReplaceMappedValuesForInputs = (inputValues, refreshSelectAllValues = false) => {
    const updatedInputValues = cloneDeep(inputValues);
    const inputsByName = {};
    if (this.props.sortedInputs) {
      this.props.sortedInputs.forEach((input) => (inputsByName[input.name] = input));
    }
    for (let key in updatedInputValues) {
      if (
        refreshSelectAllValues &&
        inputs.canInputRefreshSelectAll(inputsByName[key], this.props.inputValues[key], this.state.currentBulkInputName)
      ) {
        updatedInputValues[key].should_refresh_value = true;
      }
      if (updatedInputValues[key].mappedOptions && !isEmpty(updatedInputValues[key].mappedOptions)) {
        const valueToReplace = updatedInputValues[key].value;
        updatedInputValues[key].unmapped_value = valueToReplace;
        if (Array.isArray(valueToReplace)) {
          updatedInputValues[key].value = valueToReplace.map((val) => {
            return updatedInputValues[key].mappedOptions[val];
          });
        } else {
          updatedInputValues[key].value = updatedInputValues[key].mappedOptions[valueToReplace];
        }
        delete updatedInputValues[key].mappedOptions;
      }
      delete updatedInputValues[key].options;
      if (updatedInputValues[key].value === Constants.NULL_VALUE_INPUT_LABEL) {
        updatedInputValues[key].value = null;
      }
    }
    return updatedInputValues;
  };

  setAuthTokenByDynamicContent = (dynamicContent, token) => {
    const updatedObj = Object.assign({}, this.state.authTokenByDynamicContent);
    dynamicContent.forEach((content) => {
      updatedObj[content.name] = token;
    });
    this.setState({ authTokenByDynamicContent: updatedObj });
  };

  getDataSourcesWithAuthToken = () => {
    const dataSourcesWithAuthToken = [];
    if (!isEmpty(this.props.template) && !isEmpty(this.props.templateContentData)) {
      const dataSourcesById = utils.getDataSourcesForTemplate(this.props.template, this.props.templateContentData);
      if (dataSourcesById) {
        for (let dataSourceId in dataSourcesById) {
          const dataSource = dataSourcesById[dataSourceId];
          if (dataSource.has_auth_token) {
            dataSourcesWithAuthToken.push(dataSource);
          }
        }
      }
    }
    return dataSourcesWithAuthToken;
  };

  onScheduleOpen = (e) => {
    e.preventDefault();
    if (this.isSchedulingEnabledForEnterprise()) {
      API.track('click_schedule_button');
      this.props.openModal('scheduledFlowModal');
    } else {
      const planName = Constants.MATIK_TIERS[this.context.user.enterprise.plan_id].display_name;
      const noSchedulingHtml = ReactDOMServer.renderToStaticMarkup(
        <UpsellGenericModal
          featureHeader={`Schedule Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
          featureDescription={`Scheduling is not available for the ${planName} plan. Please upgrade to schedule presentations.`}
        />,
      );
      API.track('enterprise_upsell_shown', { from: 'no_scheduling' });
      MProductUpsell(`${noSchedulingHtml}`, false, (result) => {
        if (!result.dismiss) {
          API.track('discover_matik_enterprise_click', { from: 'no_scheduling' });
          const demoUrl = teams.buildRequestDemoUrl(
            this.context.user,
            'scheduling',
            this.context.user.enterprise.trial_days_remaining,
          );
          window.open(demoUrl, '_blank');
        }
      });
    }
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message =
      'In order to generate your presentation, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectIntegration);
  };

  showMSPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message =
      'To generate PowerPoint presentations from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', this.reconnectIntegration);
  };

  renderInputsComponent = () => {
    const dataSourcesWithAuthToken =
      !this.props.isContentFetching && !this.props.isReadOnly ? this.getDataSourcesWithAuthToken() : [];

    let body = '';
    let buttonText = 'Generate';
    let buttonActive;
    if (this.needsToConnectToIntegration()) {
      const isMS = this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365,
        image = isMS ? connect_to_microsoft : connect_to_google,
        title = isMS ? 'Connect to Microsoft 365' : 'Connect to Google Slides',
        text = isMS
          ? 'You need to connect to Microsoft 365 before you can generate this PowerPoint presentation.'
          : 'You need to connect to Google Slides before you can generate this presentation.',
        primary = isMS ? 'Connect to Microsoft 365' : 'Connect To Google';
      const button = !this.props.isProducer ? (
        <div className="connect-to-google-button">
          <Button id="template-create-button" type="submit" status="default">
            {primary}
          </Button>
        </div>
      ) : null;
      body = (
        <div className="connect-to-google">
          <div className="connect-to-google-inner">
            <div className="image-container">
              <img src={image} alt="Connect to Google" />
            </div>
            <p className="connect-to-google-title">{title}</p>
            <p className="connect-to-google-text">{text}</p>
            {button}
          </div>
        </div>
      );
      buttonText = primary;
      buttonActive = true;
    } else {
      if (
        !this.props.areInputsLoading &&
        (this.props.sortedInputs?.length > 0 || dataSourcesWithAuthToken.length > 0)
      ) {
        let matik_user_banner_text = '';
        const hasMatikUser = utils.hasMatikUserInputs(this.props.sortedInputs);
        const isNotEndUserView = location.pathname.indexOf('create') === -1;
        const allDynamicContentOnTemplate = {};
        if (this.props.templateContentData) {
          dynamicContent.setAllDynamicContentByName(this.props.templateContentData, allDynamicContentOnTemplate);
        }

        if (hasMatikUser) {
          const entityTypeToBannerTextMap = {
            template: 'Template',
            input: 'Input',
            dynamic_content: 'Dynamic Content',
          };

          matik_user_banner_text = entityTypeToBannerTextMap[this.props.entityType] || '';
        }
        body = (
          <>
            {hasMatikUser && isNotEndUserView && !this.props.isPresentationView && (
              <div className="pb-4">
                <Banner
                  bannerType="info"
                  text={`This ${matik_user_banner_text} uses an input that populates based on the 
                current user. To proceed, select the user you would like to impersonate.`}
                />
              </div>
            )}
            <FormFromInputs
              allowBulk={this.props.allowBulk}
              currentBulkInputName={this.state.currentBulkInputName}
              inputs={this.props.sortedInputs}
              inputValues={this.props.inputValues}
              isReadOnly={this.props.isReadOnly}
              isPresentationView={this.props.isPresentationView}
              onBulkInputToggle={this.onBulkInputToggle}
              slideIdsByInputName={this.props.slideIdsByInputName}
              updateInputValues={this.updateInputValues}
              template={this.props.template}
              attachedTemplate={this.props.attachedTemplate}
              dataSourcesWithAuthToken={dataSourcesWithAuthToken}
              onTestAccess={this.onTestAccess}
              allDynamicContent={allDynamicContentOnTemplate}
              tokenError={this.props.tokenError}
              tokenSuccess={this.props.tokenSuccess}
              tokenLoading={this.props.tokenLoading}
              authTokenByDynamicContent={this.state.authTokenByDynamicContent}
              setAuthTokenByDynamicContent={this.setAuthTokenByDynamicContent}
              isProducer={this.props.isProducer}
              inModal={this.props.inModal}
              isTestSlide={this.props.isTestSlide}
              entityId={this.props.entityId}
              entityType={this.props.entityType}
              test={this.props.test}
              handleTabClick={this.handleTabClick}
              activeTab={this.state.activeTab}
              setUserBasedInputsIsLoading={this.setUserBasedInputsIsLoading}
            />
          </>
        );
        if (this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
          buttonText = 'Send Email';
        } else if (this.state.isBulk && !this.props.isTestSlide) {
          buttonText = 'Preview Bulk Pack';
        } else {
          buttonText = 'Generate';
        }
      } else if (this.props.isContentFetching || this.props.areInputsLoading) {
        body = (
          <div>
            <SmallLoader />
          </div>
        );
      } else if (!this.props.isContentFetching) {
        body = (
          <div className="no-inputs">
            <div className="no-inputs-inner">
              <img src={no_inputs_image} alt="No Inputs" />
              <p className="no-inputs-text">No inputs detected</p>
            </div>
          </div>
        );
        buttonText =
          this.props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'Send Email' : 'Generate';
      }
    }
    return { body, buttonText, buttonActive };
  };

  connectIntegration = () => {
    if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      utils.connectMicrosoft(this.context.user, this.context.updateUser, () => {}, this.showMSPermsAlert);
      return;
    }
    utils.connectGoogle(this.context.user, this.context.updateUser, () => {}, this.showPermsAlert);
  };

  reconnectIntegration = () => {
    if (this.props.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      utils.reconnectMicrosoft(this.context.user, this.context.updateUser, () => {}, this.showMSPermsAlert);
      return;
    }
    utils.reconnectGoogle(this.context.user, this.context.updateUser, () => {}, this.showPermsAlert);
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };
}

PresentationInputsForm.propTypes = {
  addedSlides: PropTypes.array,
  allDynamicContent: PropTypes.object,
  allowBulk: PropTypes.bool,
  clearInputValue: PropTypes.func,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  existingInputValues: PropTypes.object,
  existingAttachmentInputValues: PropTypes.object,
  flags: PropTypes.object,
  initInputValuesFromInputs: PropTypes.func,
  inputs: PropTypes.object,
  attachmentInputs: PropTypes.object,
  inputValues: PropTypes.object,
  attachmentInputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  onPresentationCreate: PropTypes.func,
  slideIdxs: PropTypes.array,
  slideIdsByInputName: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  templateContent: PropTypes.array,
  attachmentContent: PropTypes.array,
  test: PropTypes.bool,
  updateInputValuesAndInitFromInputs: PropTypes.func,
  isTestSlide: PropTypes.bool,
  updateTestResult: PropTypes.func,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  setTokenLoading: PropTypes.func,
  testQuery: PropTypes.func,
  onClose: PropTypes.func,
  isProducer: PropTypes.bool,
  history: PropTypes.object,
  isContentFetching: PropTypes.bool,
  inModal: PropTypes.bool,
  presentationContext: PropTypes.object,
  triggerEmailGeneration: PropTypes.bool,
  untriggerEmailGeneration: PropTypes.func,
  setAllInputsFilledOut: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  onEmailPreview: PropTypes.func,
  isTemplateTest: PropTypes.bool,
  onEmailPreviewError: PropTypes.func,
  templateContentData: PropTypes.object,
  sortedInputs: PropTypes.array,
  areInputsLoading: PropTypes.bool,
  source_type: PropTypes.string,
  allInputsFilledOut: PropTypes.bool,
  showEmailSendButton: PropTypes.bool,
  submitTestEmail: PropTypes.func,
  onSendEmailClick: PropTypes.func,
};

PresentationInputsForm.contextType = UserContext;

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapInputDispatchToProps(state, ownProps), mapUiDispatchToProps(state));
}

const PresentationInputsFormWrapper = (props) => {
  const allContent = props.templateContent.concat(props.attachmentContent || []);
  const inputValues =
    (Object.keys(props.inputValues || {}).length > 0 && props.inputValues) || props.existingInputValues || {};
  const { isLoading, orderedInputList } = useDynamicContentInputList(
    props.allTemplateContent?.content_by_slide,
    allContent,
    inputValues,
    props.template.params_order,
    props.inputs,
  );
  return (
    <PresentationInputsForm
      {...props}
      templateContentData={props.allTemplateContent}
      areInputsLoading={isLoading}
      sortedInputs={orderedInputList}
    />
  );
};
PresentationInputsFormWrapper.propTypes = {
  allTemplateContent: PropTypes.object,
  attachmentContent: PropTypes.array,
  existingInputValues: PropTypes.object,
  inputs: PropTypes.object,
  inputValues: PropTypes.object,
  template: PropTypes.object,
  templateContent: PropTypes.array,
};

const ConnectedPresentationInputsForm = connect(
  mapInputsStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(WithTestQuery(withRouter(withPresentationContextConsumer(PresentationInputsFormWrapper)))));

// WithTestQuery calls back to the 'updateTestResult' prop it expects to be passed in.
function TestQueryResultHandler({ ...props }) {
  const [tokenError, setTokenError] = useState('');
  const [tokenSuccess, setTokenSuccess] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);

  const updateTestResult = (resultData) => {
    if ((resultData && resultData.status === 'error') || !resultData) {
      setTokenError('Invalid token');
      setTokenSuccess(null);
    } else if (resultData && resultData.status === 'success') {
      setTokenSuccess('Valid Token');
      setTokenError(null);
    }
    setTokenLoading(false);
  };

  const handleTokenLoading = () => {
    setTokenLoading(true);
    setTokenSuccess(null);
    setTokenError(null);
  };

  return (
    <ConnectedPresentationInputsForm
      {...props}
      tokenLoading={tokenLoading}
      setTokenLoading={handleTokenLoading}
      tokenError={tokenError}
      tokenSuccess={tokenSuccess}
      updateTestResult={updateTestResult}
    />
  );
}
TestQueryResultHandler.displayName = 'PresentationInputsForm.TestQueryResultHandler';

export default TestQueryResultHandler;
