import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Hero, Heading } from 'react-bulma-components';

import dynamic_content_illustration from 'images/dynamic_content_illustration_180x120.png';

import TabHeader from 'components/producer/templates/TabHeader';
import TemplateContentList from 'components/producer/templates/TemplateContentList';
import TemplateInputsList from 'components/producer/templates/TemplateInputsList';

const TABS = { INPUTS: 'inputs', CONTENT: 'content' };

function DocumentTemplateSidebar({
  currentTemplate,
  inputsInMatchingContent,
  dynamicContentTags,
  isContentFetching,
  selectedSlides,
  onContentClick,
  onInputClick,
}) {
  const [currentTab, setCurrentTab] = useState(TABS.CONTENT);

  const inputsLength = Object.values(inputsInMatchingContent).length;

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };

  const tabs = [
    {
      label: `Content ${dynamicContentTags ? dynamicContentTags.length : 0}`,
      selected: currentTab === TABS.CONTENT,
      onClick: (e) => handleTabClick(e, TABS.CONTENT),
    },
    {
      label: `Inputs ${inputsInMatchingContent ? inputsLength : 0}`,
      selected: currentTab === TABS.INPUTS,
      onClick: (e) => handleTabClick(e, TABS.INPUTS),
    },
  ];

  return (
    <div className="components-wrapper">
      <TabHeader tabs={tabs} />
      {currentTab === TABS.CONTENT ? (
        <TemplateContentList
          currentTemplate={currentTemplate}
          dynamicContentTags={dynamicContentTags}
          isContentFetching={isContentFetching}
          entityType="dynamic_content"
          filters={`templates:"${currentTemplate.name}"`}
          handleClick={onContentClick}
          emptyContent={<EmptyContent />}
        />
      ) : (
        <TemplateInputsList
          inputsInMatchingContent={inputsInMatchingContent}
          currentTemplate={currentTemplate}
          selectedSlides={selectedSlides}
          entityType="input"
          filters={`templates:"${currentTemplate.name}"`}
          handleClick={onInputClick}
        />
      )}
    </div>
  );
}

DocumentTemplateSidebar.propTypes = {
  dynamicContentTags: PropTypes.array,
  isContentFetching: PropTypes.bool,
  inputsInMatchingContent: PropTypes.object,
  currentTemplate: PropTypes.object,
  selectedSlides: PropTypes.array,
  openModal: PropTypes.func,
  toggleSlideSelect: PropTypes.func,
  handleHighlightSlideNums: PropTypes.func,
  onInputLoopClick: PropTypes.func,
  isBuilderSettingsVisible: PropTypes.bool,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
};

export default DocumentTemplateSidebar;

const EmptyContent = () => {
  return (
    <div>
      <Hero>
        <Hero.Body className="has-text-centered no-dynamic-content-body">
          <Heading size={5} subtitle>
            <img src={dynamic_content_illustration} alt="No Content Pieces Connected" />
            <p className="no-dynamic-content-title">No Dynamic Content Added Yet</p>
          </Heading>
          <p className="no-dynamic-content-text">
            Add dynamic content by adding tags to your connected Google Docs document. To add tags, type the name of a
            piece of dynamic content within {'{{}}'}.
          </p>
          <p className="mtl">
            <a
              href="https://help.matik.io/hc/en-us/articles/7993351735579-Tags-Overview"
              target="_blank"
              rel="noreferrer"
              className="how-to-text link"
            >
              Learn more
            </a>
          </p>
        </Hero.Body>
      </Hero>
    </div>
  );
};
EmptyContent.propTypes = {
  currentTemplate: PropTypes.object.isRequired,
};
