import React, { Component } from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import { Select } from '../../../shared/FormSelect';

class ApiSorts extends Component {
  render() {
    const sorts =
      !this.props.queryObj.query.sorts || this.props.queryObj.query.sorts.length === 0
        ? []
        : this.props.queryObj.query.sorts;
    let body = (
      <a href="#dummy" onClick={this.addSort}>
        Add Sorting
      </a>
    );
    if (sorts && sorts.length > 0) {
      body = sorts.map((sort, idx) => {
        const sortArray = this.getSortAndDirection(sort);
        const sortField = sortArray[0];
        const sortDir = sortArray[1];

        return this.renderSort(sortField, sortDir, sorts, idx);
      });
    }
    return (
      <Form.Field className="mbl">
        <Form.Label>Result Sorting</Form.Label>
        <Form.Help>Select fields to order by.</Form.Help>
        <Form.Control>{body}</Form.Control>
      </Form.Field>
    );
  }

  renderSort(sortField, sortDir, sorts, idx) {
    return (
      <Form.Field kind="group" key={`${idx}_sort`}>
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === sorts.length - 1}
          removeEntity={this.removeSort}
          addEntity={this.addSort}
          isReadOnly={this.props.isReadOnly}
        >
          <Form.Control style={{ flex: '5.5 1' }}>
            <Select
              value={{ label: sortField, value: sortField }}
              classNamePrefix="matik-select"
              isDisabled={this.props.isReadOnly}
              onChange={(obj, action) => this.onSortFieldChange(obj, action, idx)}
              aria-label="Select Field to Order By"
              options={this.props.fields.map((field) => ({ label: field, value: field }))}
            />
          </Form.Control>
          <Form.Control style={{ flex: '1 1' }}>
            <Select
              aria-label="Select Sort Direction"
              isDisabled={this.props.isReadOnly}
              value={{ label: sortDir.toUpperCase(), value: sortDir }}
              classNamePrefix="matik-select"
              onChange={(obj, action) => this.onSortDirChange(obj, action, idx)}
              options={[
                { label: 'ASC', value: 'asc' },
                { label: 'DESC', value: 'desc' },
              ]}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }

  onSortFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const fieldName = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const sortArray = this.getSortAndDirection(updatedQueryObj.query.sorts[idx]);
      updatedQueryObj.query.sorts[idx] = fieldName + ' ' + sortArray[1];
      this.props.updateQueryObj(updatedQueryObj);
    }
  };

  onSortDirChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const direction = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const sortArray = this.getSortAndDirection(updatedQueryObj.query.sorts[idx]);
      updatedQueryObj.query.sorts[idx] = sortArray[0] + ' ' + direction;
      this.props.updateQueryObj(updatedQueryObj);
    }
  };

  removeSort = (e, idx) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    let sorts = [];
    for (let i = 0; i < updatedQueryObj.query.sorts.length; i++) {
      if (i !== idx) {
        sorts.push(updatedQueryObj.query.sorts[i]);
      }
    }
    updatedQueryObj.query.sorts = sorts;
    this.props.updateQueryObj(updatedQueryObj);
  };

  addSort = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (!updatedQueryObj.query.sorts) {
      updatedQueryObj.query.sorts = [];
    }
    updatedQueryObj.query.sorts.push(this.props.fields[0] + ' ASC');
    this.props.updateQueryObj(updatedQueryObj);
  };

  getSortAndDirection = (sortString) => {
    if (!sortString) {
      return [null, null];
    }
    const delimiterIdx = sortString.lastIndexOf(' ');
    let sort;
    let dir;
    if (delimiterIdx >= 0) {
      sort = sortString.substr(0, delimiterIdx);
      dir = sortString.substr(delimiterIdx + 1);
    } else {
      sort = sortString;
      dir = 'ASC';
    }

    return [sort, dir];
  };
}

ApiSorts.propTypes = {
  queryObj: PropTypes.object,
  fields: PropTypes.array,
  isReadOnly: PropTypes.bool,
  updateQueryObj: PropTypes.func,
};

export default ApiSorts;
