import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bulma-components';
import InputLoading from '../InputLoading';
import { Select } from '../FormSelect';
import { mapInputsStateToProps } from '../../../redux/inputs/stateMappers';
import { mapInputDispatchToProps } from '../../../redux/inputs/dispatchers';
import API from 'lib/api';
import { Auth } from 'lib/auth';
import Constants from 'components/Constants';
import Banner from '../Banner';

function MatikUserInputField({
  inputs,
  inputValues,
  setInputOptionsFromQuery,
  updateInputValuesFromInputs,
  userContext,
  entityId,
  entityType,
  isPresentationView,
  setUserBasedInputsIsLoading = () => {},
}) {
  const [matikUserInputOptions, setMatikUserInputOptions] = useState([]);
  const [matikUserInputSelectedUser, setMatikUserInputSelectedUser] = useState(null);
  const [doesGeneratedUserNotExist, setDoesGeneratedUserNotExist] = useState(false);
  const [userBasedInputsStringList, setUserBasedInputsStringList] = useState('');

  const matikUserInputNames = [
    'MatikUser_Email',
    'MatikUser_Name',
    'MatikUser_Phone',
    'MatikUser_Profile-Image',
    'MatikUser_Title',
  ];

  useEffect(() => {
    setUserBasedInputsIsLoading(true);
    if (inputs.some((input) => input.source_type === 'matik_user')) {
      API.get(
        '/enterprises/users/?all=true',
        (response) => {
          if (response.data && Array.isArray(response.data)) {
            const options = response.data.map((user) => ({
              label: user.name,
              value: user,
            }));
            setMatikUserInputOptions(options);

            const initialUser = options.find((option) => option.value.id === userContext.user.id);
            const currentUser = findAndSetCurrentUserFromInputValue(options);

            if (!isPresentationView) {
              if (Object.keys(currentUser).length === 0 && initialUser) {
                setMatikUserInputSelectedUser(initialUser);
                updateMatikUserInputValues(userContext.user);
              }
              // Just for show in presentation view if user does not exist
            } else if (Object.keys(currentUser).length === 0) {
              setMatikUserInputSelectedUser({});
              setDoesGeneratedUserNotExist(true);
              const formattedString = formatUserBasedInputsString(inputs, matikUserInputNames);
              setUserBasedInputsStringList(formattedString);
            }
          } else {
            handleFetchError('Error fetching data');
          }
        },
        (error) => {
          handleFetchError(error);
        },
      ).finally(() => setUserBasedInputsIsLoading(false));
    }
  }, []);

  const findAndSetCurrentUserFromInputValue = (options) => {
    let currentUser = {};
    for (const key in inputValues) {
      if (matikUserInputNames.includes(key) && inputValues[key].value) {
        const foundUser = getUserFromInputValue(options, inputValues[key].value);
        if (foundUser) {
          currentUser = foundUser;
          setMatikUserInputSelectedUser(foundUser);
          if (!isPresentationView) {
            updateMatikUserInputValues(foundUser.value);
          }
          break;
        }
      }
    }
    return currentUser;
  };

  const getUserFromInputValue = (options, inputValue) => {
    if (typeof inputValue === 'string' && inputValue.trim() !== '') {
      return options.find((option) => {
        const { email, name, phone, photo_url, title } = option.value;
        return [email, name, phone, photo_url, title].includes(inputValue);
      });
    } else if (typeof inputValue === 'object' && inputValue.value) {
      return options.find((option) => option.value.id === inputValue.value);
    }
  };

  const handleFetchError = (error) => {
    API.defaultError(error);
    setMatikUserInputOptions([]);
  };

  const getUserAttributeFromInput = (inputName, user) => {
    const attributeMap = {
      MatikUser_Email: 'email',
      MatikUser_Name: 'name',
      MatikUser_Phone: 'phone',
      'MatikUser_Profile-Image': 'photo_url',
      MatikUser_Title: 'title',
    };

    const userAttributeKey = attributeMap[inputName];
    return user[userAttributeKey];
  };

  const updateMatikUserInputValues = (selectedOption) => {
    const updatedInputValuesObject = { ...inputValues };

    inputs.forEach((input) => {
      if (input.source_type === 'matik_user') {
        const userAttributeValue = getUserAttributeFromInput(input.name, selectedOption);

        if (userAttributeValue !== undefined) {
          const newValue = {
            value: selectedOption.id,
            label: userAttributeValue,
          };

          updatedInputValuesObject[input.name] = newValue;
          setInputOptionsFromQuery(input.name, [newValue], input.type, {}, {}, {}, {}, false, new Date());
        }
      }
    });
    const updatedInputValuesArray = Object.entries(updatedInputValuesObject).map(([key, value]) => ({
      name: key,
      ...value,
    }));
    updateInputValuesFromInputs(entityType, entityId, updatedInputValuesArray);
  };

  const onMatikUserInputChange = (selectedOption) => {
    setMatikUserInputSelectedUser(selectedOption);
    updateMatikUserInputValues(selectedOption.value);
  };

  function formatUserBasedInputsString(inputs, matikUserInputNames) {
    const inputNames = inputs.filter((input) => matikUserInputNames.includes(input.name)).map((input) => input.name);

    if (inputNames.length > 1) {
      const lastInput = inputNames.pop();
      return inputNames.join(', ') + ', and ' + lastInput;
    } else if (inputNames.length === 1) {
      return inputNames[0];
    } else {
      return '';
    }
  }

  const isAdmin = Auth.hasRole(Constants.PRODUCER_ROLE) || Auth.hasRole(Constants.PRODUCER_ADMIN_ROLE);
  const isEndUserView = location.pathname.indexOf('create') === 1;
  const hideFormMatikUserInput = !isAdmin || isEndUserView || isPresentationView;

  return (
    <>
      {doesGeneratedUserNotExist && (
        <div className="pb-4">
          <Banner
            bannerType="warning"
            text={`The user who generated this content no longer exists. The user-based inputs 
              (${userBasedInputsStringList}) below display their values at the time of the generation.`}
          />
        </div>
      )}
      {!hideFormMatikUserInput && (
        <Form.Field key="Matik User Inputs Field" className="input-field">
          <div className="is-flex is-vertical-centered gap-2">
            <Form.Label>Matik User to Impersonate</Form.Label>
          </div>
          <Form.Control>
            {matikUserInputOptions.length > 0 ? (
              <Select
                classNamePrefix="matik-select"
                value={matikUserInputSelectedUser}
                onChange={onMatikUserInputChange}
                options={matikUserInputOptions}
                isDisabled={isPresentationView}
                isClearable={false}
              />
            ) : (
              <InputLoading />
            )}
          </Form.Control>
        </Form.Field>
      )}
    </>
  );
}

MatikUserInputField.propTypes = {
  inputs: PropTypes.array,
  inputValues: PropTypes.object,
  setInputOptionsFromQuery: PropTypes.func,
  updateInputValuesFromInputs: PropTypes.func,
  userContext: PropTypes.object,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  setUserBasedInputsIsLoading: PropTypes.func,
  isPresentationView: PropTypes.bool,
};

export default connect(mapInputsStateToProps, mapInputDispatchToProps)(MatikUserInputField);
