import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs } from 'react-bulma-components';
import { ReactComponent as DoorArrowLeft } from '../../../images/door_arrow_left.svg';

function AuthTabs(props) {
  if (!props.tabs || props.tabs.length === 0) {
    return null;
  }

  return (
    <div>
      <Box className="is-shadowless mtl p-0 auth-tabs" style={props.centered ? { margin: '0 auto' } : null}>
        <Tabs>
          {props.tabs.map((tab) => {
            const TabIcon = tab.TabIcon || DoorArrowLeft;
            return (
              <Tabs.Tab
                key={tab.name}
                className="auth-tab"
                onClick={() => props.setActiveAuthTab(tab.name)}
                active={props.activeAuthTab === tab.name}
              >
                <TabIcon className="mrs" />
                <span id={tab.name} className="pvm auth-tab-trigger">
                  {tab.title} {tab.isOptional && <span className="optional-text">(Optional)</span>}
                </span>
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
}

AuthTabs.propTypes = {
  activeAuthTab: PropTypes.string,
  centered: PropTypes.bool,
  setActiveAuthTab: PropTypes.func,
  tabs: PropTypes.array,
};

export default AuthTabs;
