import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { ReactComponent as TemplatesLogo } from '../../svg/templates.svg';
import { ReactComponent as PresentationsLogo } from '../../svg/presentations.svg';
import { ReactComponent as FlowsLogo } from 'images/icons/arrow_clockwise-20x20-regular.svg';
import { ReactComponent as ScheduledLogo } from '../../svg/calendar.svg';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Constants from '../Constants';
import logo from '../../images/logo.svg';
import teams from '../../lib/teams';
import SharedUserNav from '../shared/nav/SharedUserNav';

function ConsumerNav(props) {
  const LDClient = useLDClient();
  const areFlowsEnabled = LDClient?.variation('enable-jobs-tab', false) || false;
  const { user } = useContext(UserContext);
  return (
    <nav className="navbar is-fixed-top">
      <Navbar.Brand className="matik-nav-brand">
        <Link to="/create" className="navbar-item" id="nav-end-user-logo">
          <img src={logo} alt="Matik logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container align="left">
          <Link to="/create" id="nav-end-user-templates">
            <Navbar.Item renderAs="span" active={props.activeTab === Constants.Tabs.TEMPLATES}>
              <div
                className={`is-flex navbar-container ${
                  props.activeTab === Constants.Tabs.TEMPLATES ? '' : 'has-text-unselected'
                }`}
              >
                <TemplatesLogo className="image" />
                <span>Templates</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/create/presentations" id="nav-end-user-presentations">
            <Navbar.Item renderAs="span" active={props.activeTab === Constants.Tabs.PRESENTATIONS}>
              <div
                className={`is-flex navbar-container ${
                  props.activeTab === Constants.Tabs.PRESENTATIONS ? '' : 'has-text-unselected'
                }`}
              >
                <PresentationsLogo className="image" />
                <span>Presentations</span>
              </div>
            </Navbar.Item>
          </Link>
          {areFlowsEnabled ? (
            <Link to="/create/flows" id="nav-end-user-jobs">
              <Navbar.Item renderAs="span" active={props.activeTab === Constants.Tabs.FLOWS}>
                <div
                  className={`is-flex navbar-container ${
                    props.activeTab === Constants.Tabs.FLOWS ? '' : 'has-text-unselected'
                  }`}
                >
                  <FlowsLogo className="image" />
                  <span>Flows</span>
                </div>
              </Navbar.Item>
            </Link>
          ) : (
            user &&
            !teams.isTeamsUser(user.enterprise.plan_id) && (
              <Link to="/create/scheduled" id="nav-end-user-scheduled">
                <Navbar.Item renderAs="span" active={props.activeTab === Constants.Tabs.SCHEDULED}>
                  <div
                    className={`is-flex navbar-container ${
                      props.activeTab === Constants.Tabs.SCHEDULED ? '' : 'has-text-unselected'
                    }`}
                  >
                    <ScheduledLogo className="image" />
                    <span>Scheduled</span>
                  </div>
                </Navbar.Item>
              </Link>
            )
          )}
        </Navbar.Container>
        <SharedUserNav role={Constants.CONSUMER_ROLE} />
      </Navbar.Menu>
    </nav>
  );
}

ConsumerNav.propTypes = {
  user: PropTypes.object,
  activeTab: PropTypes.string,
};

export default ConsumerNav;
