import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SpreadSheetDisplay from './SpreadSheetDisplay';
import { ReactComponent as Close } from '../../../svg/x_shape.svg';
import WithSpreadSheetContext from './WithSpreadSheetContext';
import API from '../../../lib/api';
import { mapDispatchToProps } from '../../../redux/ui/dispatchers';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';

class SpreadSheetContainer extends Component {
  constructor(props) {
    super(props);
  }

  SHEET_HEIGHT = 350;
  componentDidMount() {
    this.mountContainer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.showContainer();
      } else {
        this.hideContainer();
      }
    }
  }

  componentWillUnmount() {
    this.hideContainer();
    this.unmountContainer();
  }

  render() {
    const horizontalHandle = <div className="spreadsheet-drawer-horizontal"></div>;
    const verticalHandle = (
      <React.Fragment>
        <div className="vertical-drawer-handle" onClick={this.props.SpreadSheetContext.openDrawer}>
          <div className="spreadsheet-drawer-vertical"></div>
        </div>
      </React.Fragment>
    );

    let handleObject = horizontalHandle;
    let containerClass = 'horizontal-spreadsheet-container';
    if (this.props.SpreadSheetContext.placement === 'left') {
      handleObject = verticalHandle;
      containerClass = 'vertical-spreadsheet-container';
    }

    return (
      <div className="spreadsheet-container" id="spreadsheet-container">
        <div className={containerClass}>
          {handleObject}
          <a href="#dummy" onClick={this.handleClose}>
            <div className="drawer-close">
              <Close className="drawer-close-icon" />
            </div>
          </a>
          <SpreadSheetDisplay />
        </div>
      </div>
    );
  }

  mountContainer = () => {
    const docBody = document.getElementsByClassName('body')[0];
    const container = document.getElementById('spreadsheet-container');
    docBody.appendChild(container);
    this.hideContainer();
  };

  showContainer = () => {
    if (this.props.isOpen) {
      this.props.openGoogleSheetDrawer();
    }
    const container = document.getElementById('spreadsheet-container');

    let wrapper = document.querySelector('.wrapper');
    if (wrapper) {
      wrapper.style.height = `calc(100vh - ${this.SHEET_HEIGHT}px)`;
    } else {
      // Different numbers for the case when it's the first piece of content we're adding
      wrapper = document.querySelector('.hero.is-fullheight-with-navbar');
      wrapper.style.minHeight = '20px';
      const body = document.querySelector('.container .hero-body');
      body.style.height = `calc(100vh - ${this.SHEET_HEIGHT - 46}px)`;
      body.style.alignItems = 'flex-start';
    }
    if (container) {
      container.style.visibility = 'visible';
      container.style.height = `${this.SHEET_HEIGHT}px`;
      container.scrollIntoView();
    }
    API.track('show_google_sheet_contents');
  };

  hideContainer = () => {
    const container = document.getElementById('spreadsheet-container');
    container.style.visibility = 'hidden';
    container.style.height = '0';
    let wrapper = document.querySelector('.wrapper');
    if (wrapper) {
      wrapper.style.height = null;
    } else {
      // Different numbers for the case when it's the first piece of content we're adding
      wrapper = document.querySelector('.hero.is-fullheight-with-navbar');
      wrapper.style.minHeight = null;
      const body = document.querySelector('.container .hero-body');
      body.style.height = null;
      body.style.alignItems = null;
    }
    this.props.closeGoogleSheetDrawer();
  };

  unmountContainer = () => {
    const docBody = document.getElementsByClassName('body')[0];
    const container = document.getElementById('spreadsheet-container');
    if (docBody.contains(container)) {
      docBody.removeChild(container);
    }
  };

  handleClose = (e) => {
    e.preventDefault();
    this.props.closeGoogleSheetDrawer();
  };
}

SpreadSheetContainer.propTypes = {
  dataSource: PropTypes.object,
  SpreadSheetContext: PropTypes.object,
  isOpen: PropTypes.bool,
  ui: PropTypes.object,
  updateGlobalUiState: PropTypes.func,
  openGoogleSheetDrawer: PropTypes.func,
  closeGoogleSheetDrawer: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(WithSpreadSheetContext(SpreadSheetContainer));
