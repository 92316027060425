import { bindActionCreators } from 'redux';
import {
  updateInputValues,
  initInputValuesFromInputs,
  updateInputValuesFromInputs,
  updateInputValuesAndInitFromInputs,
  clearInputValue,
} from './action';

const mapInputDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateInputValues,
      initInputValuesFromInputs,
      updateInputValuesFromInputs,
      updateInputValuesAndInitFromInputs,
      clearInputValue,
    },
    dispatch,
  );
};

export { mapInputDispatchToProps };
