import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bulma-components';
import { isEmpty } from 'lodash';
import AddRemoveButtons from 'components/shared/AddRemoveButtons';
import InputWithOptionalInputs from 'components/shared/InputWithOptionalInputs';
import InputWithError from 'components/shared/InputWithError';
import SpreadSheetContainer from 'components/shared/spreadsheets/SpreadSheetContainer';
import { SpreadSheetContext } from 'components/shared/spreadsheets/SpreadSheetContext';
import InputWithSpreadSheet from 'components/shared/InputWithSpreadSheet';
import spreadsheetUtils from 'lib/spreadsheetUtils';
import InputMapping from 'components/producer/dynamicContent/InputMapping';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import UniqueValuesFilter from 'components/producer/dynamicContent/UniqueValuesFilter';
import { DynamicContentContext } from '../DynamicContentContext';
import Constants from 'components/Constants';

const SpreadSheetCalculatorForm = (props) => {
  const [placement, setPlacement] = useState('bottom');
  const [sheets, setSheets] = useState([]);
  const [worksheetIndex, setWorksheetIndex] = useState(0);
  const [cellSelection, setCellSelection] = useState(null);
  const [inputEditing, setInputEditing] = useState(null);

  useEffect(() => {
    if (cellSelection && sheets && props.queryObj?.output && (inputEditing === null || inputEditing < 0)) {
      const updatedCellSelection = {
        ...cellSelection,
        sheet: sheets[worksheetIndex]?.title,
      };
      const splitOutput = props.queryObj.output.cells.split('!');
      const updatedQueryObj = {
        ...props.queryObj,
        output: {
          ...props.queryObj.output,
          cells: sheets[worksheetIndex]?.title + '!' + splitOutput[1],
        },
      };
      props.updateQueryObj(updatedQueryObj);
      setCellSelection(updatedCellSelection);
    }
  }, [worksheetIndex]);

  useEffect(() => {
    if (props.isSenderContent) {
      const updatedQueryObj = Object.assign({}, props.queryObj);
      if (!updatedQueryObj.output) {
        updatedQueryObj.output = {};
      }
      updatedQueryObj.output['columns'] = 'from_name, from_email, reply_email';
      props.updateQueryObj(updatedQueryObj);
    }
  }, [props.isSenderContent]);

  useEffect(() => {
    const output = props.queryObj.output ? props.queryObj.output : {};
    // If we update the output columns to fewer than two columns, clear any existing input mapping as it is not supported
    if (!isEmpty(output) && output.columns) {
      const splitColumns = output.columns.split(',');
      if (splitColumns.length < 2 && props.inputMapping && Object.keys(props.inputMapping).length > 0) {
        props.onInputMappingUpdate({});
      }
    }
  }, [JSON.stringify(props.queryObj.output), JSON.stringify(props.inputMapping), props.onInputMappingUpdate]);

  const addInput = (e) => {
    e.preventDefault();
    if (props.isReadOnly) {
      return false;
    }
    const updatedQueryObj = Object.assign({}, props.queryObj);
    if (!updatedQueryObj.inputs) {
      updatedQueryObj.inputs = [];
    }
    updatedQueryObj.inputs.push({ location: '', val: '' });
    props.updateQueryObj(updatedQueryObj);
  };

  const updateInputLocation = (loc, idx) => {
    const updatedQueryObj = Object.assign({}, props.queryObj);
    updatedQueryObj.inputs = [...updatedQueryObj.inputs];
    updatedQueryObj.inputs[idx] = Object.assign({}, updatedQueryObj.inputs[idx]);

    updatedQueryObj.inputs[idx]['location'] = loc;
    props.updateQueryObj(updatedQueryObj);
  };

  const updateOutputCells = (cells) => {
    const updatedQueryObj = Object.assign({}, props.queryObj);
    if (!updatedQueryObj.output) {
      updatedQueryObj.output = { columns: '', cells: '' };
    } else {
      updatedQueryObj.output = Object.assign({}, updatedQueryObj.output);
    }
    updatedQueryObj.output['cells'] = cells;
    props.updateQueryObj(updatedQueryObj);
  };

  const changeInput = (idx, inputSelection) => {
    if (idx !== inputEditing) {
      if (idx >= 0) {
        setInputEditing(idx);
      } else {
        setInputEditing(idx);
      }
      setCellSelection(inputSelection ? spreadsheetUtils.inputStringToSelection(inputSelection) : null);

      const scrollBottom = document.querySelector('.scroll-bottom');
      scrollBottom.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const closeDrawer = (e) => {
    e.preventDefault();
    setCellSelection(null);
    setInputEditing(null);
    props.closeGoogleSheetDrawer();
  };

  const openDrawer = (idx, inputSelection) => {
    if (!props.ui.googleSheetDrawer || idx !== inputEditing) {
      changeInput(idx, inputSelection);
      props.openGoogleSheetDrawer();
    }
  };

  const handleCellUpdate = (val) => {
    if (isNaN(inputEditing)) {
      return;
    }
    if (inputEditing >= 0) {
      updateInputLocation(val, inputEditing);
    } else {
      updateOutputCells(val);
    }
  };

  const handleInputLocationChange = (e, idx, elRef) => {
    e.preventDefault();
    const loc = e.target.value;
    updateInputLocation(loc, idx);

    const cellSelection = loc ? spreadsheetUtils.inputStringToSelection(loc) : null;
    if (cellSelection) {
      cellSelection.focusEl = elRef.current;
    }
    setCellSelection(cellSelection);
  };

  const handleInputValChange = (val, idx) => {
    const updatedQueryObj = Object.assign({}, props.queryObj);
    updatedQueryObj.inputs = [...updatedQueryObj.inputs];
    updatedQueryObj.inputs[idx] = Object.assign({}, updatedQueryObj.inputs[idx]);

    updatedQueryObj.inputs[idx]['val'] = val;
    props.updateQueryObj(updatedQueryObj);
  };

  const handleRemoveInput = (e, idx) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, props.queryObj);
    let inputs = [];
    for (let i = 0; i < updatedQueryObj.inputs.length; i++) {
      if (i !== idx) {
        inputs.push(updatedQueryObj.inputs[i]);
      }
    }
    updatedQueryObj.inputs = inputs;
    props.updateQueryObj(updatedQueryObj);
  };

  return (
    <div>
      <SpreadSheetContext.Provider
        value={{
          dataSource: props.dataSource,
          placement: placement,
          dockOnLeft: () => setPlacement('left'),
          dockOnBottom: () => setPlacement('bottom'),
          setSheets: setSheets,
          sheets: sheets,
          worksheetIndex: worksheetIndex,
          setCurrentWorksheetIndex: setWorksheetIndex,
          onCellUpdate: handleCellUpdate,
          isOpen: props.ui.googleSheetDrawer,
          openDrawer: openDrawer,
          closeDrawer: closeDrawer,
          cellSelection: cellSelection,
        }}
      >
        <Form.Field className="mbl">
          <Form.Label>Sheet Inputs</Form.Label>
          <Form.Help>
            Enter sheet cells and inputs to insert to those cells. Note: cells should be of the form Sheet1!A1
          </Form.Help>
          <Form.Control>
            {!props.queryObj.inputs || props.queryObj.inputs.length === 0 ? (
              <a href="#dummy" onClick={addInput}>
                Add Input
              </a>
            ) : (
              props.queryObj.inputs.map((input, idx) => (
                <MemoFriendlySheetInput
                  key={idx}
                  input={input}
                  idx={idx}
                  includeAdd={idx === props.queryObj.inputs.length - 1}
                  inputEditing={inputEditing}
                  onChangeInputEditing={changeInput}
                  onAdd={addInput}
                  onRemove={handleRemoveInput}
                  onLocationChange={handleInputLocationChange}
                  onValueChange={handleInputValChange}
                  onOpenDrawer={openDrawer}
                  isReadOnly={props.isReadOnly}
                  inputs={props.inputs}
                  isInputPopoverDisabled={props.isInputPopoverDisabled}
                />
              ))
            )}
          </Form.Control>
        </Form.Field>
        <SheetOutput
          {...props}
          inputEditing={inputEditing}
          onInputChange={changeInput}
          onUpdateQueryObjCells={updateOutputCells}
          onOpenDrawer={openDrawer}
          onCellSelection={setCellSelection}
        />
        <span className="scroll-bottom"></span>
        <SpreadSheetContainer dataSource={props.dataSource} isOpen={!!props.ui.googleSheetDrawer} />
      </SpreadSheetContext.Provider>
    </div>
  );
};
SpreadSheetCalculatorForm.propTypes = {
  contents: PropTypes.object,
  dataSource: PropTypes.object,
  isReadOnly: PropTypes.bool,
  inputs: PropTypes.object,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
  queryStringError: PropTypes.string,
  columnNameError: PropTypes.string,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  ui: PropTypes.object,
  openModal: PropTypes.func,
  closeGoogleSheetDrawer: PropTypes.func,
  openGoogleSheetDrawer: PropTypes.func,
  isInputPopoverDisabled: PropTypes.bool,
  isSenderContent: PropTypes.bool,
};

const SheetInput = ({
  value,
  location,
  idx,
  includeAdd,
  onAdd,
  onRemove,
  onLocationChange,
  onValueChange,
  inputEditing,
  onChangeInputEditing,
  onOpenDrawer,
  isReadOnly,
  inputs,
  isInputPopoverDisabled,
}) => {
  return (
    <Form.Field kind="group">
      <AddRemoveButtons
        idx={idx}
        addEntity={onAdd}
        includeAdd={includeAdd}
        removeEntity={onRemove}
        isReadOnly={isReadOnly}
      >
        <Form.Control style={{ flex: '3 1' }}>
          <InputWithSpreadSheet
            aria-label="Input location"
            isSelected={inputEditing === idx}
            isReadOnly={isReadOnly}
            name="input_location"
            onChange={(e, elRef) => onLocationChange(e, idx, elRef)}
            onOpenDrawer={() => onOpenDrawer(idx, location)}
            onFocus={() => onChangeInputEditing(idx, location)}
            placeholder="Input location (e.g. Sheet1!A1)"
            value={location || ''}
          />
        </Form.Control>
        <Form.Control style={{ flex: '3 1' }}>
          <InputWithOptionalInputs
            onChange={(newValue) => onValueChange(newValue, idx)}
            value={value || ''}
            placeholder="Input value"
            inputs={inputs}
            isDynamicContentSuggested
            isReadOnly={isReadOnly}
            isInputPopoverDisabled={isInputPopoverDisabled}
          />
        </Form.Control>
      </AddRemoveButtons>
    </Form.Field>
  );
};
SheetInput.propTypes = {
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,

  value: PropTypes.string,
  location: PropTypes.string,
  onLocationChange: PropTypes.func,
  onValueChange: PropTypes.func,

  inputEditing: PropTypes.number,
  onChangeInputEditing: PropTypes.func,
  idx: PropTypes.number,
  includeAdd: PropTypes.bool,

  onOpenDrawer: PropTypes.func,
  isReadOnly: PropTypes.bool,
  inputs: PropTypes.object,
  isInputPopoverDisabled: PropTypes.bool,
};

const MemoizedSheetInput = memo(SheetInput);
const MemoFriendlySheetInput = (props) => {
  /* This ugliness is so we can stabilize references to the function props.
   * We use a callback to memoize the function so the references don't change from render to render and spoil
   * our component memoization.
   * We use a ref in which 'current' is updated with each render so we always actually call the latest passed in
   * prop function (so any closures, etc, are up-to-date) and not the directly memoized function, which would
   * otherwise be locked in on memoized, stale data.
   */
  const callbacks = {};
  // the array values are static, so we don't violate the rule of hooks.
  ['onAdd', 'onRemove', 'onLocationChange', 'onValueChange', 'onChangeInputEditing', 'onOpenDrawer'].forEach((fn) => {
    const ref = useRef();
    ref.current = props[fn];
    callbacks[fn] = useCallback((...args) => ref.current(...args), [ref]);
  });

  return (
    <MemoizedSheetInput
      onAdd={callbacks.onAdd}
      onRemove={callbacks.onRemove}
      value={props.input?.val}
      location={props.input?.location}
      onValueChange={callbacks.onValueChange}
      onLocationChange={callbacks.onLocationChange}
      inputEditing={props.inputEditing}
      onChangeInputEditing={callbacks.onChangeInputEditing}
      idx={props.idx}
      includeAdd={props.includeAdd}
      onOpenDrawer={callbacks.onOpenDrawer}
      isReadOnly={props.isReadOnly}
      inputs={props.inputs}
      isInputPopoverDisabled={props.isInputPopoverDisabled}
    />
  );
};
MemoFriendlySheetInput.propTypes = SheetInput.propTypes;

const SheetOutput = ({
  onInputChange,
  onUpdateQueryObjCells,
  onOpenDrawer,
  onCellSelection,
  inputEditing,
  ...props
}) => {
  const output = props.queryObj.output ? props.queryObj.output : {};
  const helpText = props.isSenderContent
    ? 'The cell(s) whose output is read. Note: Sender dynamic content must return three fields: from_name, from_email, and reply_email.'
    : 'The cell(s) whose output is read. Note: make sure to include column headers, either by including them in the source spreadsheet or by populating the column names form field below.';
  let splitColumns = [];
  if (!isEmpty(output) && output.columns) {
    splitColumns = output.columns.split(',');
  }
  const dynamicContentContext = useContext(DynamicContentContext);
  const isText = dynamicContentContext.dynamicContentType === Constants.DynamicContentTypes.TEXT;

  const handleOutputColumnChange = (e) => {
    e.preventDefault();
    const output = e.target.value;
    const updatedQueryObj = Object.assign({}, props.queryObj);
    if (!updatedQueryObj.output) {
      updatedQueryObj.output = { columns: '', cells: '' };
    } else {
      updatedQueryObj.output = Object.assign({}, updatedQueryObj.output);
    }
    updatedQueryObj.output['columns'] = output;
    props.updateQueryObj(updatedQueryObj);
  };

  const handleExcludeBlankRows = (e) => {
    const updatedQueryObj = Object.assign({}, props.queryObj);
    updatedQueryObj.output = Object.assign({}, updatedQueryObj.output);

    const exclude = e.target.checked;
    if (exclude) {
      updatedQueryObj.output.exclude_blank_rows = true;
    } else {
      delete updatedQueryObj.output.exclude_blank_rows;
    }
    props.updateQueryObj(updatedQueryObj);
  };
  const returnFirstRowHeaders = (e) => {
    const updatedQueryObj = Object.assign({}, props.queryObj);
    updatedQueryObj.output = Object.assign({}, updatedQueryObj.output);
    const includeHeaders = e.target.checked;
    includeHeaders ? (updatedQueryObj.output.include_headers = true) : delete updatedQueryObj.output.include_headers;
    props.updateQueryObj(updatedQueryObj);
  };

  const handleOutputCellChange = (e, elRef) => {
    e.preventDefault();
    const cells = e.target.value;
    onUpdateQueryObjCells(cells);
    const cellSelection = cells ? spreadsheetUtils.inputStringToSelection(cells) : null;
    if (cellSelection) {
      cellSelection.focusEl = elRef.current;
    }
    onCellSelection(cellSelection);
  };

  const filterDuplicates = !!props.queryObj.filterDuplicates;
  const toggleFilterDuplicates = (bool) => {
    const updatedQueryObj = { ...props.queryObj, filterDuplicates: bool };
    props.updateQueryObj(updatedQueryObj);
  };

  return (
    <React.Fragment>
      <Form.Field className="mbl">
        <Form.Label>Sheet Output</Form.Label>
        <Form.Help>{helpText}</Form.Help>
        <Form.Control>
          <Form.Field kind="group">
            <Form.Control className="flex-1">
              <InputWithError
                value={output.columns}
                name="column_names"
                onChange={handleOutputColumnChange}
                placeholder="Comma-separated list of column names"
                disabled={props.isReadOnly || props.isSenderContent}
                error={props.columnNameError}
              />
            </Form.Control>
            <Form.Control className="flex-1">
              <InputWithSpreadSheet
                ariaLabel="Output Cells"
                isSelected={inputEditing === -1}
                isReadOnly={props.isReadOnly}
                name="cells"
                onChange={handleOutputCellChange}
                onOpenDrawer={() => onOpenDrawer(-1, output.cells)}
                onFocus={() => onInputChange(-1, output.cells)}
                placeholder="Cells in sheet (e.g. Sheet1!A1:B2)"
                value={output.cells}
                error={props.queryStringError}
              />
            </Form.Control>
          </Form.Field>
        </Form.Control>
      </Form.Field>
      {props.entityType && props.entityType === 'input' && (
        <InputMapping
          queryObj={props.queryObj}
          onInputMappingUpdate={props.onInputMappingUpdate}
          input={props.input}
          inputMapping={props.inputMapping}
          returnFieldsArray={splitColumns}
        />
      )}
      <UniqueValuesFilter filterDuplicates={filterDuplicates} toggleFilterDuplicates={toggleFilterDuplicates} />
      <Form.Field className="mbl">
        <CheckboxWithLabel
          id="exclude"
          checked={!!props.queryObj.output?.exclude_blank_rows}
          label={<span className="border-b border-grey-400 border-dotted">Exclude empty rows</span>}
          onChange={handleExcludeBlankRows}
          tooltip="When enabled, any rows with empty values will be excluded from the results."
          dataPlace="top"
          className="inline-block"
        />
      </Form.Field>
      <Form.Field className="mbl">
        {isText && (
          <CheckboxWithLabel
            id="include"
            checked={!!props.queryObj.output?.include_headers}
            label={<span className="border-b border-grey-400 border-dotted">Return the first row as headers</span>}
            onChange={returnFirstRowHeaders}
            tooltip="When selected, the first row of results will return as headers. This will only apply when the Sheet Output list of column names is empty."
            dataPlace="top"
            className="inline-block"
          />
        )}
      </Form.Field>
    </React.Fragment>
  );
};
SheetOutput.propTypes = {
  onInputChange: PropTypes.func,
  onUpdateQueryObjCells: PropTypes.func,
  onOpenDrawer: PropTypes.func,
  onCellSelection: PropTypes.func,
  inputEditing: PropTypes.number,

  isReadOnly: PropTypes.bool,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
  queryStringError: PropTypes.string,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  columnNameError: PropTypes.string,
  onInputMappingUpdate: PropTypes.func,
  isSenderContent: PropTypes.bool,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(SpreadSheetCalculatorForm);
