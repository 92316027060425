import React from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/lib/ListItem';
import Constants from 'components/Constants';
import LogoPill from '../../lib/LogoPill';
import IconPill from '../../lib/IconPill';
import { SizeToDimension } from '../../lib/Pill';

function ListItemDynamicContent({
  content,
  isSubtitleInline = false,
  subtitle,
  size = 'm',
  metadata,
  status = 'default',
}) {
  if (!content) {
    return null;
  }

  const icon = (
    <div className="flex items-center gap-1">
      {content.dynamic_content_type !== Constants.DynamicContentTypes.CONDITIONAL ? (
        <>
          <SourceIcon content={content} size={size} />
          <TypeIcon content={content} size={size} status={status} />
        </>
      ) : (
        <>
          <TypeIcon content={content} size={size} status={status} />
          <SourceIcon content={content} size={size} />
        </>
      )}
    </div>
  );

  return (
    <ListItem
      icon={icon}
      title={content.name}
      subtitle={subtitle}
      isSubtitleInline={isSubtitleInline}
      metadata={metadata}
      size={size}
      status={status}
    />
  );
}

ListItemDynamicContent.propTypes = {
  isSubtitleInline: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm']),
  subtitle: PropTypes.node,
  metadata: PropTypes.node,

  content: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    dynamic_content_type: PropTypes.string,
    dynamic_content_method: PropTypes.string,
    query_obj: PropTypes.shape({
      data_source: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }),
  status: PropTypes.string,
};

export default ListItemDynamicContent;

const SourceIcon = ({ content, size }) => {
  // "small" list item size means "xs" icon size.
  const iconSize = size === 's' ? 'xs' : 's';
  if (
    [Constants.DynamicContentMethods.API, Constants.DynamicContentMethods.QUERY].includes(
      content.dynamic_content_method,
    )
  ) {
    const dataSourceLogo = Constants.DATA_SOURCE_TYPE_DISPLAY[content.query_obj?.data_source?.type]?.logoName;
    if (dataSourceLogo) {
      return (
        <LogoPill
          name={dataSourceLogo}
          size={iconSize}
          theme="square"
          text={Constants.DATA_SOURCE_TYPE_DISPLAY[content.query_obj?.data_source?.type]?.text}
        />
      );
    }
  }

  if (content.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
    // If it's static and not data-source-driven, we'll show the "static" icon as the source
    return (
      <IconPill
        size={iconSize}
        theme="square"
        luminosity="medium"
        color="white"
        iconName="static"
        text={Constants.DYNAMIC_CONTENT_METHOD_DISPLAY.static.text}
      />
    );
  }

  if (content.dynamic_content_method === Constants.DynamicContentMethods.INSIGHTS) {
    // Or if it's insights...
    return (
      <IconPill
        size={iconSize}
        theme="square"
        luminosity="medium"
        color="white"
        iconName="ai"
        text={Constants.DYNAMIC_CONTENT_METHOD_DISPLAY.insights.text}
      />
    );
  }

  // No source? add a spacer
  return <div className={`${SizeToDimension[iconSize]} inline-block`} />;
};
SourceIcon.propTypes = {
  size: ListItemDynamicContent.propTypes.size,
  content: ListItemDynamicContent.propTypes.content,
};

const TypeIcon = ({ content, size, status }) => {
  // "small" list item size means "xs" icon size.
  const iconSize = size === 's' ? 'xs' : 's';
  const typeIcon = Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.dynamic_content_type]?.iconName;
  const typeText = Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.dynamic_content_type]?.text;
  if (typeIcon) {
    return (
      <IconPill
        size={iconSize}
        theme="square"
        luminosity="medium"
        color="white"
        iconName={typeIcon}
        iconColor={status === 'disabled' ? 'grey-500' : 'matik-black'}
        text={typeText}
      />
    );
  }
  // No type? add a spacer
  return <div className={`${SizeToDimension[iconSize]} inline-block`} />;
};
TypeIcon.propTypes = {
  size: ListItemDynamicContent.propTypes.size,
  status: ListItemDynamicContent.propTypes.status,
  content: ListItemDynamicContent.propTypes.content,
};
