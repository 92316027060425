import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { updateSuccessBanner, updateErrorBanner, deleteSuccessBanner } from 'components/shared/AccessUpdateBanners';

/** Fetch the accesses for a given type. If no IDs are given, it will fetch accesses for all of that type.
 * @param itemType e.g. `template`, `dynamic_content`, `presentation`
 * @param itemIds an array of item IDs to fetch the accesses for. e.g. the template id. When empty, fetch
 * accesses for all.
 */
const useAccesses = (itemType, itemIds) => {
  const queryClient = useQueryClient();
  if (!itemIds) {
    itemIds = [];
  }
  const queryKey = ['access', itemType, itemIds];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () => {
      return API.get(
        `/accesses/all/${itemType}/${itemIds.length > 0 ? `?filter=${JSON.stringify({ id: itemIds })}` : ''}`,
        (response) => response.data, // like { <item id>: [{ access data }, ...  ], ... }
        API.defaultError,
      );
    },
    enabled: !!itemType,
  });

  const invalidate = (type) => {
    const queryKey = ['access'];
    if (type ?? itemType) {
      queryKey.push(type ?? itemType);
    }
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    isPending: isLoading,
    isError,
    data,
    error,
    invalidate,
  };
};

export default useAccesses;

export const useAccessMutator = () => {
  const queryClient = useQueryClient();

  const del = (accesses) => {
    const byItemType = {};
    accesses.forEach((access) => {
      const ids = byItemType[access.item_type] || [];
      ids.push(access.id);
      byItemType[access.item_type] = ids;
    });

    const deletes = Object.keys(byItemType).map((itemType) => {
      return API.post('/accesses/bulk_delete/', { item_type: itemType, access_ids: byItemType[itemType] }).then(
        (data) => {
          queryClient.invalidateQueries({ queryKey: ['access', itemType] });
          return data;
        },
      );
    });
    return Promise.all(deletes).then(() => {
      deleteSuccessBanner({ accesses });
    });
  };

  const update = (itemType, itemIds, permission, accessorsToAdd) => {
    const data = {
      item_type: itemType,
      item_ids: itemIds,
      permission: permission,
      accesses: accessorsToAdd,
    };
    return API.post(
      '/accesses/bulk/',
      data,
      (response) => {
        queryClient.invalidateQueries({ queryKey: ['access', itemType] });
        updateSuccessBanner({ itemIds, permission, accessorsToAdd });
        return response.data;
      },
      (err) => {
        updateErrorBanner({ err, itemIds, permission, accessorsToAdd });
      },
    );
  };

  return {
    del,
    update,
  };
};
