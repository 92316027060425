import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import InputWithOptionalInputs from '../../../shared/InputWithOptionalInputs';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import Constants from '../../../Constants';
import { CreatableSelect, Select } from '../../../shared/FormSelect';
import info_icon from '../../../../images/info_icon.svg';

class ApiFilters extends Component {
  render() {
    let body = (
      <a href="#dummy" onClick={(e) => this.addFilter(e, this.props.fields)}>
        Add Filter
      </a>
    );
    if (this.props.queryObj.query.filters && this.props.queryObj.query.filters.length > 0) {
      body = this.props.queryObj.query.filters.map((filter, idx) => {
        const filterValue = filter.val;
        const filterField = filter.field;
        const filterOperator = filter.operator;
        return this.renderFilter(filterField, filterOperator, filterValue, idx);
      });
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Filters</Form.Label>
          <Form.Help>
            Select fields and inputs to filter by.
            <span
              className="mls filter-hint-tooltip"
              data-tooltip-id="matik-tooltip"
              data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
              data-tooltip-place="right"
            >
              <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
            </span>
          </Form.Help>
          <Form.Control>{body}</Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  }
  renderFilter(filterField, filterOperator, filterValue, idx) {
    return (
      <Form.Field kind="group" key={`${filterField}_${idx}`}>
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === this.props.queryObj.query.filters.length - 1}
          addEntity={this.addFilter}
          removeEntity={this.removeFilter}
          isReadOnly={this.props.isReadOnly}
        >
          <Form.Control style={{ flex: '3 1' }}>
            <CreatableSelect
              value={filterField ? { label: filterField, value: filterField } : null}
              name="filterField"
              classNamePrefix="matik-select"
              isDisabled={this.props.isReadOnly}
              noOptionsMessage={() => (this.props.noOptionsMessage ? this.props.noOptionsMessage : 'No options')}
              onChange={(obj, action) => this.onFilterFieldChange(obj, action, idx)}
              aria-label="Select Filter Field on Object"
              options={this.props.fields
                .filter((field) => !this.filtersContainsField(field) || field === filterField)
                .map((field) => ({ label: field, value: field }))}
            />
          </Form.Control>
          {this.renderOperators(filterOperator, idx)}
          <Form.Control style={{ flex: '3 1' }}>
            <InputWithOptionalInputs
              onChange={(newValue) => this.onFilterInputChange(filterField, newValue, idx)}
              value={filterValue}
              inputs={this.props.inputs}
              isReadOnly={this.props.isReadOnly}
              isInputPopoverDisabled={this.props.isInputPopoverDisabled}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }

  renderOperators(filterOperator, idx) {
    const supportedOperators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE[this.props.apiSource];
    const style = { flex: '.5 1' };
    if (!supportedOperators) {
      return (
        <span className="prl plm is-size-4" style={style}>
          =
        </span>
      );
    }

    if (supportedOperators.length === 1) {
      return (
        <span className="prl plm is-size-4" style={style}>
          {supportedOperators[0]}
        </span>
      );
    }

    if (!filterOperator) {
      filterOperator = '=';
    }
    return (
      <Form.Control style={style}>
        <Select
          value={{ label: filterOperator, value: filterOperator }}
          classNamePrefix="matik-select"
          onChange={(obj, action) => this.onOperatorChange(obj, action, idx)}
          isDisabled={this.props.isReadOnly}
          options={supportedOperators.map((supportedOperator) => ({
            label: supportedOperator,
            value: supportedOperator,
          }))}
        />
      </Form.Control>
    );
  }

  filtersContainsField(field) {
    const matches = this.props.queryObj.query.filters.filter((filter) => filter[field] === field);
    return matches.length > 0;
  }

  onFilterFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option' || action.action === 'create-option') {
      const field = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.query.filters[idx]['field'] = field;
      this.props.updateQueryObj(updatedQueryObj);
    }
  };

  onFilterInputChange = (filterField, newValue, idx) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const inputToChange = updatedQueryObj.query.filters[idx];
    if (!inputToChange.field) {
      inputToChange['field'] = filterField;
    }
    inputToChange['val'] = newValue;
    this.props.updateQueryObj(updatedQueryObj);
  };

  onOperatorChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const operator = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.query.filters[idx]['operator'] = operator;
      this.props.updateQueryObj(updatedQueryObj);
    }
  };

  removeFilter = (e, idx) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    let filters = [];
    for (let i = 0; i < updatedQueryObj.query.filters.length; i++) {
      if (i !== idx) {
        filters.push(updatedQueryObj.query.filters[i]);
      }
    }
    updatedQueryObj.query.filters = filters;
    this.props.updateQueryObj(updatedQueryObj);
  };

  addFilter = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    if (!updatedQueryObj.query.filters) {
      updatedQueryObj.query.filters = [];
    }
    const filtersNotUsed = this.props.fields.filter((field) => !this.filtersContainsField(field));
    updatedQueryObj.query.filters.push({ field: filtersNotUsed[0], val: '' });
    this.props.updateQueryObj(updatedQueryObj);
  };
}

ApiFilters.propTypes = {
  apiSource: PropTypes.string,
  fields: PropTypes.array,
  isReadOnly: PropTypes.bool,
  inputs: PropTypes.object,
  noOptionsMessage: PropTypes.string,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
  isInputPopoverDisabled: PropTypes.bool,
};

export default ApiFilters;
