import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TemplateUpload from './TemplateUpload';
import API from '../../../lib/api';
import Modal from '../../shared/modal/Modal';
import LongRequest from '../../../lib/longRequest';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class UpdateTemplateFileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      percentCompleted: 0,
      selectedFile: { name: this.props.template.source.original_filename },
      selectedFileError: '',
    };

    this.templateForm = null;
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        title="Upload A New Template File"
        onClose={this.onClose}
        showDefaultFooter={true}
        footerIsSticky={false}
        primaryButtonText="Update Template File"
        primaryButtonOnClick={this.onFormSubmit}
        primaryButtonLoading={this.state.isLoading}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={this.onClose}
      >
        <form onSubmit={this.onFormSubmit} ref={(form) => (this.templateForm = form)}>
          <TemplateUpload
            isLoading={this.state.isLoading}
            handleSelectFile={this.handleSelect}
            hideGoogle={true}
            selectedFileError={this.state.selectedFileError}
            selectedFile={this.state.selectedFile}
            percentCompleted={this.state.percentCompleted}
          />
        </form>
      </Modal>
    );
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.selectedFile) {
      this.setState({ selectedFileError: 'File is required' });
      return;
    }
    this.setState({ isLoading: true });
    const file = this.state.selectedFile;
    const fileExtension = this.state.selectedFile.name.split('.').pop();

    if (fileExtension !== 'ppt' && fileExtension !== 'pptx') {
      this.setState({ selectedFileError: 'File is required', isLoading: false });
      return;
    }

    const templateData = {
      name: this.props.template.name,
      description: this.props.template.description,
      source_type: fileExtension,
    };
    const onSuccess = (response, onComplete) => {
      if (response.data.template_status === 'done') {
        this.setState({ isLoading: false });
        API.track('template_update');
        if (this.templateForm) {
          this.templateForm.reset();
        }
        this.onClose();
        this.props.onTemplateInvalidate(response.data.new_entity.id, response.data.new_entity);
        onComplete();
      } else if (response.data.template_status === 'done_with_pdf') {
        this.props.onTemplateInvalidate(response.data.new_entity.id, response.data.new_entity);
        onComplete();
      } else if (response.data.template_status === 'unknown') {
        // This may happen if there is a race condition: another user loads the templates page in between status checks
        onComplete();
        window.location.reload();
      } else {
        this.setState({ processingStatus: response.data.template_status });
      }
    };

    const longRequest = new LongRequest('/templates');
    longRequest.put(this.props.template.id, templateData, onSuccess, this.submitError, file, this.onProgress);
  };

  onProgress = (progressEvent) => {
    this.setState({ percentCompleted: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
  };

  handleSelect = (e) => {
    this.setState({ selectedFile: e.target.files[0], selectedFileError: '' });
  };

  submitError = (err) => {
    this.setState({ isLoading: false });
    API.defaultError(err);
  };

  onClose = () => {
    this.props.closeModal();
  };
}

UpdateTemplateFileModal.propTypes = {
  show: PropTypes.bool,
  template: PropTypes.object,
  onClose: PropTypes.func,
  onTemplateInvalidate: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(UpdateTemplateFileModal);
