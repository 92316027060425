import { MAlert } from 'components/shared/Alerts';
import { PublicClientApplication } from '@azure/msal-browser';

export const combine = (...paths) => {
  return paths
    .map((path) => path.replace(/^[\\|/]/, '').replace(/[\\|/]$/, ''))
    .join('/')
    .replace(/\\/g, '/');
};

/**
 * HELPER PROPERTIES & FUNCTIONS
 */

export const scopes = ['User.Read', 'offline_access', 'openid', 'Files.ReadWrite.All', 'Sites.Manage.All'];

export const microsoftLoginError = (err, callback = defaultErrorHandler) => {
  MAlert(`There was an error logging you in: ${err}`, 'error', 'error', () => {});
  callback();
};

export const getTokens = async (command) => {
  const isPersonal = !command.resource || command.type === 'MSA';
  const authority = isPersonal
    ? 'https://login.microsoftonline.com/consumers'
    : 'https://login.microsoftonline.com/common';
  const msalParams = {
    auth: {
      authority: authority,
      clientId: process.env.REACT_APP_OFFICE_365_CLIENT_ID, // eslint-disable-line no-undef,
      redirectUri: window.location.origin,
    },
  };
  const app = new PublicClientApplication(msalParams);
  await app.initialize();
  let accessToken = '';
  const authParams = !isPersonal
    ? { scopes: [`${combine(command.resource, '.default')}`] }
    : { scopes: ['OneDrive.ReadWrite'] };
  try {
    // see if we have already the idtoken saved
    const resp = await app.acquireTokenSilent(authParams);
    accessToken = resp.accessToken;
  } catch (e) {
    // per examples we fall back to popup
    const resp = await app.loginPopup(authParams);
    app.setActiveAccount(resp.account);
    if (resp.idToken) {
      const resp2 = await app.acquireTokenSilent(authParams);
      accessToken = resp2.accessToken;
    } else {
      // throw the error that brought us here
      throw e;
    }
  }
  return accessToken;
};

const defaultErrorHandler = () => {
  /* do nothing */
};
