import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import utils from '../../../lib/utils';
import {
  ContentNameCell,
  DataSourceCell,
  DynamicContentTypeCell,
  getDataSourceName,
  ListShareCellFactory,
} from '../../shared/FullPageListCells';
import moment from 'moment';
import { UserContext } from '../../UserContext';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import AccessModal from '../../shared/AccessModal';
import AccessManager from '../../../lib/AccessManager';
import { Redirect } from 'react-router-dom';
import FullPageListWithBulkActions from '../bulkAction/FullPageListWithBulkActions';
import BulkAccessButton from '../accesses/BulkAccessButton';
import BulkTagButton from '../ItemTag/BulkTagButton';
import BulkUpdateDataSourceButton from './BulkUpdateDataSourceButton';
import BulkDeleteButton from './BulkDeleteButton';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps } from '../../../redux/ui/dispatchers';
import useAccesses from 'lib/hooks/useAccess';

const PageWithFilterHeaderAndBulkActions = WithFilterListHeader(FullPageListWithBulkActions);

class DynamicContentFullPageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: {},
      redirectToContentId: null,
    };
  }

  render() {
    if (this.state.redirectToContentId) {
      return <Redirect push to={`/dynamic_content/${this.state.redirectToContentId}`} />;
    }

    // We need this getMethod for sorting, because React Table sorts by row.value. If we just have a cell for this
    // column, then React Table doesn't know how to sort it.
    const getMethod = (dynamicContent) => {
      if (dynamicContent.dynamic_content_type === 'conditional') {
        return '--';
      }

      return Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[dynamicContent.dynamic_content_method]?.text;
    };
    let columns = [
      {
        id: 'dynamic_content_type',
        Header: utils.tableHeader('Type'),
        accessor: 'dynamic_content_type',
        Cell: DynamicContentTypeCell,
        className: 'pvm',
        maxWidth: 250,
      },
      {
        id: 'name',
        Header: utils.tableHeader('Name'),
        Cell: ContentNameCell,
        accessor: 'name',
        className: 'min-width-0',
      },
      { id: 'dynamic_content_method', Header: utils.tableHeader('Method'), accessor: getMethod, maxWidth: 200 },
      {
        id: 'data_source_name',
        Header: utils.tableHeader('Data Source'),
        Cell: DataSourceCell,
        accessor: getDataSourceName,
      },
      {
        id: 'user_id',
        Header: utils.tableHeader('Share Status'),
        Cell: ListShareCellFactory(this.props.accessesByItemId, this.showAccessModal, this.context.user, 32),
        className: 'plm',
        maxWidth: 300,
      },
      {
        id: 'updated_on',
        Header: utils.tableHeader('Last Updated'),
        accessor: (d) => moment(d.updated_on),
      },
    ];

    const bulkActionComponents = [
      (props) => {
        return (
          <BulkAccessButton
            key="access"
            selectedItemIds={props.selectedItems.map((dc) => dc.id)}
            entityType="dynamic_content"
          />
        );
      },
      (props) => {
        return (
          <BulkTagButton
            key="itemTag"
            selectedItemIds={props.selectedItems.map((dc) => dc.id)}
            entityType="dynamic_content"
          />
        );
      },
      (props) => {
        return <BulkUpdateDataSourceButton key="dataSource" selectedDynamicContent={props.selectedItems} />;
      },
      (props) => {
        return <BulkDeleteButton key="delete" selectedItemIds={props.selectedItems.map((dc) => dc.id)} />;
      },
    ];
    return (
      <div className="below-header dynamic-content-list">
        <PageWithFilterHeaderAndBulkActions
          context="dynamic_content"
          entities={this.props.dynamicContent}
          columns={columns}
          onRowClick={this.onRowClick}
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          filterPlaceholder="Search Dynamic Content"
          newEntityUrl="/dynamic_content/new"
          title="Your Dynamic Content"
          loading={this.props.isPaginating}
          buttonClass="is-primary"
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
          accessesByItemId={this.props.accessesByItemId}
          bulkActionComponents={bulkActionComponents}
        />
        <AccessModal
          accesses={
            new AccessManager(this.props.ui?.modal?.content?.id, this.props.accessesByItemId, this.context.user)
          }
          item={this.props.ui?.modal?.name === 'accessModal' ? this.props.ui.modal.content : null}
          show={this.props.ui?.modal?.name === 'accessModal'}
        />
      </div>
    );
  }

  onRowClick = (contentId) => {
    this.setState({ redirectToContentId: contentId });
  };

  showAccessModal = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openModal('accessModal', item);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.DYNAMIC_CONTENT_PAGE_SIZE;
    this.props.fetchItems(offset, Constants.DYNAMIC_CONTENT_PAGE_SIZE, sort);
  };
}

DynamicContentFullPageList.propTypes = {
  accessesByItemId: PropTypes.object,
  dynamicContent: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  ui: PropTypes.object,
  openModal: PropTypes.func,
};

DynamicContentFullPageList.contextType = UserContext;

const DynamicContentFullPageListWrapper = (props) => {
  const { data: accessesByItemId } = useAccesses('dynamic_content');
  return <DynamicContentFullPageList {...props} accessesByItemId={accessesByItemId} />;
};

export default connect(mapUiStateToProps, mapDispatchToProps)(DynamicContentFullPageListWrapper);
