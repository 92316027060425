import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, find, filter } from 'lodash';
import InputLoading from '../InputLoading';
import Constants from '../../Constants';
import inputs from '../../../lib/inputs';
import { CreatableInputOnly, Select } from '../FormSelect';
import { CreatableSelect } from '../FormSelect';
import info_icon from '../../../images/info.svg';
import DependentOnBulkInput from './DependentOnBulkInput';

function ListInputField(props) {
  const [fallbackValue, setFallbackValue] = useState('Loading...');

  const onChange = (obj, action) => {
    const updatedInputValues = Object.assign({}, props.inputValues);
    if (action.action === 'select-option') {
      let selectedName = '';
      selectedName = action.option.value;

      // Hack here: when fallback isn't passed in, it means that we fallback to a freeform multi-select, so whatever is
      // entered will be kept.
      if (
        !props.fallback ||
        (updatedInputValues[props.input.name].options &&
          updatedInputValues[props.input.name].options.indexOf(selectedName) >= 0)
      ) {
        if (
          !updatedInputValues[props.input.name].value ||
          !(updatedInputValues[props.input.name].value instanceof Array)
        ) {
          updatedInputValues[props.input.name].value = [];
        }
        updatedInputValues[props.input.name].value.push(selectedName);
      }
      props.onChange(props.input.name, updatedInputValues);
    } else if (action.action === 'select-all-options') {
      const options = obj.map((option) => option.value);
      updatedInputValues[props.input.name].value = Array.isArray(options) ? [...options] : options;
      props.onChange(props.input.name, updatedInputValues);
    } else if (action.action === 'remove-value') {
      if (updatedInputValues[props.input.name].value) {
        updatedInputValues[props.input.name].value = updatedInputValues[props.input.name].value.filter(
          (listVal) => listVal !== action.removedValue.value,
        );
      }
      props.onChange(props.input.name, updatedInputValues);
    } else if (action.action === 'clear') {
      updatedInputValues[props.input.name].value = [];
      props.onChange(props.input.name, updatedInputValues);
    } else if (action.action === 'create-option') {
      const createdVal = find(obj, (el) => !!el.__isNew__);
      if (!updatedInputValues[props.input.name].value) {
        updatedInputValues[props.input.name].value = [];
      }
      updatedInputValues[props.input.name].value.push(createdVal.value);
      props.onChange(props.input.name, updatedInputValues);
    } else if (action.action === 'create-option-bulk') {
      const createdVals = filter(obj, (el) => !!el.__isNew__);
      if (!updatedInputValues[props.input.name].value) {
        updatedInputValues[props.input.name].value = [];
      }
      createdVals.forEach((createdVal) => updatedInputValues[props.input.name].value.push(createdVal.value));
      props.onChange(props.input.name, updatedInputValues);
    }
  };

  useEffect(() => {
    let inputValue = props.inputValues[props.input.name];
    let fallback = props.fallback;
    if (!props.fallback) {
      fallback = () => userInput;
    }

    if (
      (props.input.source_type === Constants.InputSources.QUERY ||
        props.input.source_type === Constants.InputSources.API) &&
      !inputValue.isLoading &&
      !inputValue.fetch_error &&
      !inputValue.options
    ) {
      if (!props.isForConditionalDC || (isEmpty(props.input.nested_parameters) && props.isForConditionalDC)) {
        setFallbackValue(
          inputs.loadOptionsFromQuery(
            props.input,
            props.inputValues,
            props.onLoadSuccess,
            props.onLoadFailure,
            fallback,
            props.beforeLoad,
            null,
            props.currentBulkInputName,
          ),
        );
      }
    }
  }, [props.inputValues, props.input, props.currentBulkInputName]);

  let inputValue = props.inputValues[props.input.name];

  if (typeof inputValue.value === 'string') {
    inputValue.value = inputValue.value.split(',');
  }
  // newly looped fields can sometimes end up with a leading empty string in the array
  if (inputValue.value instanceof Array && inputValue.value[0] === '') {
    inputValue.value = inputValue.value.shift();
  }

  const tags =
    inputValue.value && inputValue.value.map
      ? inputValue.value.map((tagName) => (tagName ? { label: tagName, value: tagName } : false))
      : [];
  const placeholder = 'List of values';
  const userInput = (
    <CreatableSelect
      value={tags}
      onChange={onChange}
      classNamePrefix="matik-select"
      placeholder={placeholder}
      isMulti={true}
      isDisabled={props.isReadOnly}
    />
  );

  // Query
  if (
    props.input.source_type === Constants.InputSources.QUERY ||
    props.input.source_type === Constants.InputSources.API
  ) {
    let SelectComponent = Select;
    let formatCreateLabel = null;
    if (props.input.allow_user_override || (!isEmpty(props.input.nested_parameters) && props.isForConditionalDC)) {
      SelectComponent = CreatableSelect;
      // eslint-disable-next-line react/display-name
      formatCreateLabel = (selectedVal) => <span>Use {selectedVal}</span>;
    }
    if (inputValue.fetch_error) {
      return (
        <div className="has-text-danger is-flex">
          There was an error loading this input
          <span className="is-flex mls" data-tooltip-id="matik-tooltip" data-tooltip-content={inputValue.fetch_error}>
            <img src={info_icon} alt="Error message" height="15px" />
          </span>
        </div>
      );
    } else if (inputValue.options) {
      if (props.currentBulkInputName && inputs.inputIsDependentOnBulkInput(props.input, props.currentBulkInputName)) {
        return <DependentOnBulkInput input={props.input} placeholder="All matching values" />;
      } else {
        const suggestions = inputValue.options.map((optionName) => {
          return { label: optionName, value: optionName };
        });
        const loadingData = props.isForConditionalDC
          ? {
              dataTS: inputValue.dataTS,
              isLoading: inputValue.isLoading,
              onSyncButtonClick: inputValue.onSyncButtonClick,
            }
          : undefined;
        return (
          <SelectComponent
            options={suggestions}
            onChange={onChange}
            isMulti={true}
            isPasteable={true}
            placeholder={placeholder}
            classNamePrefix="matik-select"
            className="matik-select-container"
            formatCreateLabel={formatCreateLabel}
            value={tags}
            isDisabled={props.isReadOnly}
            loadingData={loadingData}
            isClearable={true}
          />
        );
      }
    } else if (!isEmpty(props.input.nested_parameters) && props.isForConditionalDC) {
      return (
        <SelectComponent
          classNamePrefix="matik-select"
          className="matik-select-container"
          aria-label="Dynamic Content Type"
          isMulti={true}
          placeholder={placeholder}
          isPasteable={true}
          value={tags}
          onChange={onChange}
          isDisabled={props.isReadOnly}
          formatCreateLabel={formatCreateLabel}
          isClearable={true}
        />
      );
    } else if (!inputValue.options?.length) {
      return <InputLoading />;
    } else {
      return <span>{fallbackValue}</span>;
    }
  }

  // List
  if (props.input.source_type === Constants.InputSources.LIST) {
    const suggestions = props.input.source_list
      ? props.input.source_list.map((optionName) => ({ label: optionName, value: optionName }))
      : [];
    return (
      <Select
        options={suggestions}
        onChange={onChange}
        isMulti={true}
        placeholder={placeholder}
        classNamePrefix="matik-select"
        className="matik-select-container"
        value={tags}
        isClearable={true}
        isDisabled={props.isReadOnly}
      />
    );
  }

  // User input
  if (props.input.source_type === Constants.InputSources.USER_INPUT) {
    return (
      <CreatableInputOnly
        onChange={onChange}
        value={tags}
        isDisabled={props.isReadOnly}
        placeholder="Enter multiple values separated by tab"
        isClearable={true}
      />
    );
  }
}

ListInputField.propTypes = {
  beforeLoad: PropTypes.func,
  isBulk: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  input: PropTypes.object,
  inputValues: PropTypes.object,
  onChange: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  onLoadFailure: PropTypes.func,
  fallback: PropTypes.func,
  currentBulkInputName: PropTypes.string,
  isForConditionalDC: PropTypes.bool,
};

export default ListInputField;
