import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'react-bulma-components';
import FullScreenModal from '../../shared/modal/FullScreenModal';
import API from '../../../lib/api';
import utils from '../../../lib/utils';
import { UserContext } from '../../UserContext';
import WithGooglePicker from '../../shared/WithGooglePicker';
import GoogleImportCard from './GoogleImportCard';
import { ComputerImportCard } from './ComputerImportCard';
import { withRouter } from 'react-router-dom';
import { MAlert, MInsufficientPerms } from 'components/shared/Alerts';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ModalTemplateLibrary from './ModalTemplateLibrary';
import Constants from 'components/Constants';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import { MConnect } from 'components/shared/Alerts';

class AddPresentationTemplateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percentCompleted: 0,
      googleIsLoading: false,
      pptIsLoading: false,
      ppt365IsLoading: false,
      processingStatus: 'Uploading',
      submitError: '',
    };
    this.oauthUpdate = null;
  }

  render() {
    let googleAuth = null;
    const googleIntegration = utils.googleIntegration(this.context.user);
    if (googleIntegration) {
      googleAuth = {
        access_token: googleIntegration.access_token,
        expires_on: googleIntegration.expires_on,
        hasNecessaryScopes: googleIntegration.has_necessary_scopes,
      };
    }

    const headerCenter = <Heading className="full-screen-modal-title">Add Presentation Template</Heading>;
    return (
      <FullScreenModal
        show={this.props.show}
        onClose={this.props.onClose}
        showDefaultFooter={false}
        headerCenter={headerCenter}
        onBackClick={this.props.onPresentationBackClick}
      >
        <div className="add-template-body">
          <div className="is-fullwidth">
            <div className="mbs mtxxxl is-4">Upload Template</div>
            <div className="add-template-cards">
              <GoogleImportCard
                oauthFunction={this.oauthFunction}
                auth={googleAuth}
                handleSelectGoogleSheet={this.handleSelectGoogleSheet}
                percentCompleted={this.state.percentCompleted}
                processingStatus={this.state.processingStatus}
                isLoading={this.state.googleIsLoading}
              />
              <ComputerImportCard
                percentCompleted={this.state.percentCompleted}
                processingStatus={this.state.processingStatus}
                isLoading={this.state.pptIsLoading}
                is365Loading={this.state.ppt365IsLoading}
                handleSelectFile={this.handleSelectFile}
                handle365SelectFile={this.handle365SelectFile}
              />
            </div>
            <ModalTemplateLibrary />
          </div>
        </div>
      </FullScreenModal>
    );
  }

  handleFormSubmit = (file) => {
    const templateData = { name: file.name };
    if (file instanceof File) {
      if (this.pptValidate(file)) {
        this.setState({ pptIsLoading: true, processingStatus: 'Uploading' });
        templateData['source_type'] = 'powerpoint';
        templateData['name'] = file.name.split('.').shift();
      }
    } else if (file.type && file.type === Constants.MICROSOFT.powerpoint_type) {
      this.setState({ ppt365IsLoading: true, processingStatus: 'Uploading' });
      templateData['source_type'] = 'powerpoint_365';
      templateData['source'] = file;
      templateData['name'] = file.name;
    } else {
      this.setState({ googleIsLoading: true, processingStatus: 'Uploading' });
      templateData['source_type'] = 'google_slides';
      templateData['source'] = file;
      templateData['name'] = file.name;
      templateData['description'] = file.description;
    }

    this.props
      .onTemplateCreate(
        templateData,
        file,
        (status) => {
          this.setState({ processingStatus: status });
        },
        this.onProgress,
      )
      .then((newTemplateData) => {
        this.setState({
          googleIsLoading: false,
          pptIsLoading: false,
          ppt365IsLoading: false,
          selectedFile: null,
          selectedGooglePresentation: null,
          name: '',
          description: '',
          processingStatus: 'done',
        });
        this.props.onTemplateAdd(newTemplateData);
      })
      .catch(this.submitError);
  };

  submitError = (err) => {
    this.setState({ googleIsLoading: false, pptIsLoading: false, ppt365IsLoading: false });
    API.defaultError(err);
  };

  onProgress = (progressEvent) => {
    this.setState({ percentCompleted: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
  };

  pptValidate = (file) => {
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension !== 'ppt' && fileExtension !== 'pptx') {
        MAlert('Please select a PowerPoint file.');
        return false;
      }
      if (file.size > 1024 * 1024 * 60) {
        MAlert('The PowerPoint you selected exceeds our maximum allowed file size of 60MB');
        return false;
      }
    }

    return true;
  };

  handleSelectFile = (e) => {
    if (e.target.files[0]) {
      this.handleFormSubmit(e.target.files[0]);
    }
  };

  handleSelectGoogleSheet = (data) => {
    if (data.docs) {
      this.handleFormSubmit(data.docs[0]);
    }
  };

  handle365SelectFile = (data) => {
    const item = data.items[0];
    const file = {
      ...item,
      type: Constants.MICROSOFT.powerpoint_type,
      name: item.name.split('.').shift(),
      mimeType: item.name.split('.').pop(),
      url: item.webUrl,
      driveItemUrl: item.webDavUrl,
      driveId: item.parentReference?.driveId,
    };
    this.handleFormSubmit(file);
  };

  oauthFunction = (onUpdate) => {
    this.oauthUpdate = onUpdate;
    const title = 'Connect to Google Drive',
      message = 'You must grant Matik Google permissions to be able to connect to Google Drive',
      type = 'google',
      confirm = 'Connect to Google Drive';
    const callback = (confirm) => {
      confirm && utils.connectGoogle(this.context.user, this.context.updateUser, onUpdate, this.showPermsAlert);
    };
    MConnect(title, message, type, callback, confirm);
  };

  reconnectGoogle = () => {
    utils.reconnectGoogle(this.context.user, this.context.updateUser, this.oauthUpdate, this.showPermsAlert);
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to import your template, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectGoogle);
  };
}

AddPresentationTemplateModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onTemplateCreate: PropTypes.func,
  onGoogleSlidesClick: PropTypes.func,
  history: PropTypes.object,
  onPresentationBackClick: PropTypes.func,
};

AddPresentationTemplateModal.contextType = UserContext;

const AddPresentationTemplateModalWrapper = (props) => {
  const { create } = useTemplateMutator();
  return <AddPresentationTemplateModal {...props} onTemplateCreate={create} />;
};

export default withLDConsumer()(withRouter(WithGooglePicker(AddPresentationTemplateModalWrapper)));
