import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { find } from 'lodash';
import InputWithError from '../shared/InputWithError';
import { MAlert, MConfirm } from '../shared/Alerts';
import { Select } from '../shared/FormSelect';
import { connect } from 'react-redux';
import { mapEnterpriseUsersStateToProps } from '../../redux/users/stateMappers';
import { mapUsersDispatchToProps } from '../../redux/users/dispatchers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import LoadingOverlay from '../shared/LoadingOverlay';
import WithUserContext from '../shared/WithUserContext';
import Constants from '../Constants';
import Modal from '../shared/modal/Modal';
import utils from 'lib/utils';
import useAccesses from 'lib/hooks/useAccess';

class UserModal extends Component {
  constructor(props) {
    super(props);
    const active = this.props.selectedUser ? this.props.selectedUser.active : false;
    this.state = {
      active: active,
      email: this.props.selectedUser ? this.props.selectedUser.email : '',
      emailError: '',
      emailReadOnly: active,
      name: this.props.selectedUser ? this.props.selectedUser.name : '',
      nameError: '',
      phone: this.props.selectedUser ? this.props.selectedUser.phone : '',
      roleIds: this.props.selectedUser ? this.props.selectedUser.role_ids : [],
      roles: this.props.selectedUser ? this.props.selectedUser.roles : [],
      title: this.props.selectedUser && this.props.selectedUser.title ? this.props.selectedUser.title : '',
    };
  }

  render() {
    const suggestions = this.getSuggestions();
    const roleTags = this.getRoleTags();
    const modalTitle = this.props.selectedUser ? 'Update User' : 'Add User';
    const primaryButtonText = this.props.selectedUser ? 'Update User' : 'Add User';
    const secondaryButtonText =
      this.props.selectedUser &&
      this.props.userContext &&
      this.props.userContext.user &&
      this.props.userContext.user.id !== this.props.selectedUser.id
        ? 'Delete User'
        : 'Cancel';
    const secondaryButtonOnClick = secondaryButtonText === 'Delete User' ? this.deleteUser : this.onClose;

    return (
      <Modal
        show={this.props.ui.modal?.name === 'userModal'}
        title={modalTitle}
        onClose={this.onClose}
        showDefaultFooter={true}
        footerIsSticky={false}
        primaryButtonText={primaryButtonText}
        primaryButtonOnClick={this.onFormSubmit}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonOnClick}
      >
        <LoadingOverlay loading={this.props.isWorking} />
        <Form.Field>
          <Form.Label>Name</Form.Label>
          <Form.Control>
            <InputWithError
              error={this.state.nameError}
              type="text"
              value={this.state.name}
              onChange={this.onChange}
              name="name"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Email</Form.Label>
          <Form.Control>
            <InputWithError
              error={this.state.emailError}
              className={this.state.emailReadOnly ? 'is-static' : ''}
              readOnly={this.state.emailReadOnly}
              isStatic={this.state.emailReadOnly}
              help={this.state.emailReadOnly ? 'Email cannot be edited on an active account' : ''}
              type="text"
              value={this.state.email}
              onChange={this.onChange}
              name="email"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Title</Form.Label>
          <Form.Control>
            <Form.Input type="text" value={this.state.title} onChange={this.onChange} name="title" />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Phone</Form.Label>
          <Form.Control>
            <Form.Input type="text" value={this.state.phone} onChange={this.onChange} name="phone" />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Roles</Form.Label>
          <Form.Control>
            <Select
              options={suggestions}
              onChange={this.onRoleChange}
              isMulti={true}
              placeholder="Enter roles for user"
              classNamePrefix="matik-select"
              value={roleTags}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Status</Form.Label>
          <Form.Control>
            <Form.Select
              value={this.state.active ? '1' : '0'}
              name="active"
              className="is-fullwidth"
              onChange={this.onChange}
            >
              <option value="0">Inactive</option>
              <option value="1">Active</option>
            </Form.Select>
          </Form.Control>
        </Form.Field>
      </Modal>
    );
  }

  getSuggestions = () => {
    const allRoles = this.props.roles ? this.props.roles : [];
    const rolesNotIncludingUserRoles = allRoles.filter((role) => this.state.roleIds.indexOf(role.id) < 0);
    return rolesNotIncludingUserRoles.map((role) => ({
      value: `${role.id}`,
      label: Constants.ROLE_DISPLAY_MAPPING[role.name],
    }));
  };

  getRoleTags = () => {
    const rolesById = {};
    for (let role of this.props.roles) {
      rolesById[role.id] = role;
    }
    return this.state.roleIds.map((roleId) => ({
      value: `${roleId}`,
      label: Constants.ROLE_DISPLAY_MAPPING[rolesById[roleId].name],
    }));
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.name === 'active' ? e.target.value === '1' : e.target.value;
    this.setState({ [name]: value });
    if (this.state[name + 'Error']) {
      this.setState({ [name + 'Error']: '' });
    }
  };

  onRoleChange = (obj, action) => {
    const updatedRoleIds = this.state.roleIds.slice();
    const updatedRoles = this.state.roles.slice();
    if (action.action === 'select-option') {
      const roleTag = action.option;
      updatedRoleIds.push(parseInt(roleTag.value));
      updatedRoles.push(roleTag.label);
      this.setState({ roles: updatedRoles, roleIds: updatedRoleIds });
    } else if (action.action === 'select-all-options') {
      const values = action.option.map((option) => parseInt(option.value));
      const labels = action.option.map((option) => option.label);
      updatedRoleIds.push(...values);
      updatedRoles.push(...labels);
      this.setState({ roles: updatedRoles, roleIds: updatedRoleIds });
    } else if (action.action === 'remove-value') {
      const roleId = parseInt(action.removedValue.value);
      this.removeRole(roleId);
    } else if (action.action === 'clear') {
      this.setState({ roles: [], roleIds: [] });
    }
  };

  removeRole = (roleId) => {
    const matchingRole = find(this.props.roles, (role) => role.id === roleId);
    const updatedRoleIds = [];
    for (let i = 0; i < this.state.roleIds.length; i++) {
      const roleIdToTest = this.state.roleIds[i];
      if (roleIdToTest !== matchingRole.id) {
        updatedRoleIds.push(roleIdToTest);
      }
    }
    const updatedRoles = [];
    for (let i = 0; i < this.state.roles.length; i++) {
      const roleName = this.state.roles[i];
      if (roleName !== matchingRole.name) {
        updatedRoles.push(roleName);
      }
    }
    this.setState({ roles: updatedRoles, roleIds: updatedRoleIds });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm() && this.canActivate()) {
      const data = {
        name: this.state.name,
        email: this.state.email,
        title: this.state.title,
        phone: this.state.phone,
        role_ids: this.state.roleIds,
        active: this.state.active,
      };
      if (this.props.selectedUser) {
        const oldUser = this.props.selectedUser;
        this.props.onUserUpdate(this.props.selectedUser.id, data, (updatedUser) => {
          this.props.updateEnterpriseCounts(updatedUser, oldUser);
        });
      } else {
        const oldUser = null;
        this.props.onUserAdd(data, (response) => {
          this.props.updateEnterpriseCounts(response, oldUser);
          this.setState({
            email: '',
            emailError: '',
            name: '',
            nameError: '',
            phone: '',
            roleIds: [],
            roles: [],
            title: '',
            active: false,
          });
          this.onClose();
        });
      }
    }
  };

  validateForm = () => {
    if (!this.state.name) {
      this.setState({ nameError: 'Name is required' });
      return false;
    }

    if (!this.state.email) {
      this.setState({ emailError: 'Email is required' });
      return false;
    }

    if (!utils.isEmailValid(this.state.email)) {
      this.setState({ emailError: 'Email address is invalid' });
      return false;
    }

    if (!this.state.roles || this.state.roles.length === 0) {
      MAlert('You need to add roles to this user in order to create them', 'Roles Error', 'error');
      return false;
    }
    if (this.state.roles.length === 1 && this.state.roles[0] === 'producer_admin') {
      MAlert(
        'You need to add the admin role as well as producer_admin role in order for the user to be able to log in',
        'Roles Error',
        'error',
      );
      return false;
    }

    return true;
  };

  canActivate = () => {
    // When a Team Enterprise is activating a User, we need to check if they have too many active users first.
    if (
      this.props.selectedUser &&
      this.props.selectedUser.active !== this.state.active &&
      this.state.active &&
      !this.props.canAddUser()
    ) {
      this.props.showUserLimitWarning();
      return false;
    }
    return true;
  };

  deleteUser = (e) => {
    e.preventDefault();
    MConfirm('Delete User?', 'Are you sure you want to delete this user?', 'warning', (confirmed) => {
      if (confirmed) {
        const oldUser = this.props.selectedUser;
        this.props.onUserDelete(this.props.selectedUser.id, () => {
          // The deleted user's content just got moved to a new user. Easiest to just invalidate all accesses.
          this.props.invalidateAccesses();
          this.props.updateEnterpriseCounts(null, oldUser);
          this.onClose();
        });
      }
    });
  };

  onClose = () => {
    this.props.closeModal();
  };
}

UserModal.propTypes = {
  isWorking: PropTypes.bool,
  onUserAdd: PropTypes.func,
  onUserDelete: PropTypes.func,
  onUserUpdate: PropTypes.func,
  roles: PropTypes.array,
  show: PropTypes.bool,
  updateEnterpriseCounts: PropTypes.func,
  selectedUser: PropTypes.object,
  userContext: PropTypes.object,
  canAddUser: PropTypes.func,
  showUserLimitWarning: PropTypes.func,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  invalidateAccesses: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return Object.assign({}, mapEnterpriseUsersStateToProps(state, ownProps), mapUiStateToProps(state));
}

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapUsersDispatchToProps(state, ownProps), mapUiDispatchToProps(state, ownProps));
}

const UserModalWrapper = (props) => {
  const { invalidate } = useAccesses();
  return <UserModal {...props} invalidateAccesses={invalidate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(WithUserContext(UserModalWrapper));
