import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormFromInputs from '../FormFromInputs';
import { Form, Level } from 'react-bulma-components';
import { isEmpty, isEqual } from 'lodash';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import API from 'lib/api';
import inputs from 'lib/inputs';
import WithTestQuery from 'components/shared/WithTestQuery';
import { MAlert, MConfirm } from 'components/shared/Alerts';
import { mapInputsStateToProps } from 'redux/inputs/stateMappers';
import { mapInputDispatchToProps } from 'redux/inputs/dispatchers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import { UserContext } from 'components/UserContext';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import no_inputs_image from 'images/NoInputsInSlideSelection.png';
import QueryProcessor from 'lib/queryProcessor';
import withPresentationContextConsumer from 'components/shared/presentations/WithPresentationContextConsumer';
import LongRequest from 'lib/longRequest';
import Button from 'components/lib/Button';
import Banner from '../Banner';
import { useTemplateContent, useTemplatesUnsavedContent } from 'lib/hooks/useTemplate';
import { useDynamicContentInputList } from '../../../lib/hooks/useInputList';

class HtmlBuilderInputsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isError: false,
      processingStatus: 'Processing',
      authTokenByDynamicContent: {},
      buttonActive: false,
      timeConstructed: Date.now(),
    };

    this.statusInterval = null;
  }

  componentDidMount() {
    this.checkInputsAreFilledOut(this.props.sortedInputs);
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.inputValues, prevProps.inputValues) ||
      !isEqual(this.props.sortedInputs, prevProps.sortedInputs)
    ) {
      this.props.updateInputValuesAndInitFromInputs(
        this.props.entityType,
        this.props.entityId,
        this.props.inputValues,
        this.props.sortedInputs,
      );

      if (this.props.existingInputValues && !isEmpty(this.props.existingInputValues)) {
        this.props.updateInputValuesAndInitFromInputs(
          this.props.entityType,
          this.props.entityId,
          this.props.existingInputValues,
          this.props.sortedInputs,
        );
      }
    }
    this.checkInputsAreFilledOut(this.props.sortedInputs);
  }

  render() {
    const inputsComponent = this.renderInputsComponent();
    const buttonActive =
      inputsComponent.buttonActive !== undefined ? inputsComponent.buttonActive : this.state.buttonActive;

    let buttons;
    let primaryButtonStatus = 'default';
    if (!buttonActive) {
      primaryButtonStatus = 'disabled';
    } else if (this.props.isContentFetching) {
      primaryButtonStatus = 'loading';
    }
    buttons = (
      <Form.Field className="fixed-bottom-button">
        <Form.Control className="block-button">
          <Level justifyContent="flex-end">
            <Level.Side align="right">
              <Level.Item>
                <Button category="secondary" onClick={this.onClose}>
                  Cancel
                </Button>
              </Level.Item>
              <Level.Item>
                <Button id="template-create-button" type="submit" status={primaryButtonStatus}>
                  {inputsComponent.buttonText}
                </Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </Form.Control>
      </Form.Field>
    );

    return (
      <React.Fragment>
        <form onSubmit={this.onFormSubmit} className={'presentation-input-form'}>
          {inputsComponent.body}
          {buttons}
        </form>
      </React.Fragment>
    );
  }

  checkInputsAreFilledOut = (sortedInputs) => {
    const shouldButtonBeActive = this.shouldButtonBeActive(sortedInputs);
    const buttonActive = !this.props.isContentFetching && shouldButtonBeActive;

    if (buttonActive && this.state.timeConstructed && !this.props.isContentFetching && !this.state.buttonActive) {
      API.track('generatePresentationActive', {
        durationMs: Date.now() - this.state.timeConstructed,
        templateId: this.props.template.id,
        numInputs: sortedInputs.length,
      });
    }

    if (buttonActive !== this.state.buttonActive) {
      this.setState({ buttonActive: buttonActive });
      this.props.setAllInputsFilledOut?.(buttonActive);
    }
  };

  shouldButtonBeActive = (sortedInputs) => {
    const sortedInputsByName = {};
    sortedInputs.forEach((input) => (sortedInputsByName[input.name] = input));
    return inputs.areInputsFilledOut(this.props.inputValues, sortedInputsByName);
  };

  onTestAccess = (e, dataSource, dynamicContent, apiInputValues, authToken) => {
    if (Object.keys(dynamicContent.parameters).length !== Object.keys(apiInputValues).length) {
      MAlert('Please fill out all inputs for REST API before testing access', 'Error', 'error');
      this.props.updateTestResult(null);
      return;
    }
    const queryProcessor = new QueryProcessor(
      dynamicContent.query_obj.query_string,
      dynamicContent.parameters,
      apiInputValues,
      dataSource,
    );
    const parameterizedQuery = queryProcessor.parameterizeQueryString();
    this.props.testQuery(e, parameterizedQuery, dataSource, dynamicContent, authToken);
  };

  updateInputValues = (inputValues) => {
    this.props.updateInputValuesAndInitFromInputs(
      this.props.entityType,
      this.props.entityId,
      inputValues,
      this.props.sortedInputs,
    );
  };

  transformDynamicContentObject = (originalArray) => {
    const transformed = {};

    originalArray.forEach((item) => {
      const transformedParameters = {};

      Object.entries(item.parameters).forEach(([paramName, param]) => {
        transformedParameters[paramName] = {
          ...param,
          creator_user_id: param.creator_user_id,
          type: param.type,
          loops: param.loops || [],
          nested_parameters: param.nested_parameters || {},
          parameters: param.parameters || {},
          query_obj: param.query_obj || {},
          source_list: param.source_list || [],
        };

        delete transformedParameters[paramName].creator;
        delete transformedParameters[paramName].enterprise;
        delete transformedParameters[paramName].parameter_type;
        delete transformedParameters[paramName].slide_loops;
      });

      transformed[item.name] = {
        ...item,
        parameters: transformedParameters,
        type: 'dynamic_content',
        query_obj: {
          ...item.query_obj,
          data_source: {
            ...item.query_obj.data_source,
            has_auth_token: item.query_obj.data_source.has_auth_token,
            name: item.query_obj.data_source.name,
            requires_consumer_login: item.query_obj.data_source.requires_consumer_login,
          },
          id: item.id,
        },
      };
    });

    return transformed;
  };

  onFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const contentPreview = () => {
      this.props.setVisualBuilderFetching(true);
      const previewValues = {};
      const allDynamicContentTransformed = this.transformDynamicContentObject(this.props.dynamicContentList);

      for (let key in allDynamicContentTransformed) {
        const dynamicContent = allDynamicContentTransformed[key];
        const formatResponseByDataSourceType = (response, datasourceType) => {
          switch (datasourceType) {
            case Constants.DATA_SOURCE_TYPES.google_sheet:
            case Constants.DATA_SOURCE_TYPES.excel:
              if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
                if (!response.data.query_json.output?.columns) {
                  response.data.result.splice(0, 0, ['result']);
                }
              }
              break;
            case Constants.DATA_SOURCE_TYPES.matik_content:
              if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TEXT) {
                response = dynamicContent?.query_obj?.query_string;
              }
              break;
          }

          return response;
        };

        if (dynamicContent.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
          if (dynamicContent.dynamic_content_type === 'text') {
            let content = dynamicContent.query_obj.query_string;

            Object.keys(dynamicContent.parameters).forEach((paramKey) => {
              let value = this.props.inputValues[paramKey]?.value;
              if (paramKey in Constants.MATIK_USER_INPUTS) {
                if (value.label !== undefined) {
                  content = content.replace(`&:${paramKey}`, value.label);
                }
              }
              if (value !== undefined) {
                content = content.replace(`&:${paramKey}`, value);
              }
            });

            previewValues[dynamicContent.name] = content;
          } else {
            if (!isEmpty(dynamicContent.parameters)) {
              const firstInputKey = Object.keys(dynamicContent.parameters)[0];
              if (utils.hasMatikUserInputs(dynamicContent)) {
                previewValues[dynamicContent.name] = this.props.inputValues[firstInputKey].value.label;
              } else {
                previewValues[dynamicContent.name] = this.props.inputValues[firstInputKey].value;
              }
            } else if (this.props.inputValues[dynamicContent.name]) {
              previewValues[dynamicContent.name] = this.props.inputValues[dynamicContent.name].value.label;
            } else {
              let datasourceType = dynamicContent?.query_obj?.data_source?.type;
              previewValues[dynamicContent.name] = formatResponseByDataSourceType(dynamicContent, datasourceType);
            }
          }
        } else {
          const queryProcessor = new QueryProcessor(
            dynamicContent.query_obj.query_string,
            dynamicContent.parameters,
            this.props.inputValues,
            dynamicContent.query_obj.data_source,
          );
          const parameterizedQuery = queryProcessor.parameterizeQueryString();

          const queryData = {
            data_source: { id: dynamicContent.query_obj.data_source.id },
            dynamic_content_type: dynamicContent.dynamic_content_method,
            query_string: parameterizedQuery,
            values_by_param_name: this.props.inputValues,
          };

          const onResponse = (response, onComplete) => {
            if (response.data.result) {
              let datasourceType = dynamicContent?.query_obj?.data_source?.type;
              let formattedResponse = formatResponseByDataSourceType(response, datasourceType);

              if (dynamicContent?.query_obj.data_source.type === 'matik_logos') {
                previewValues[dynamicContent.name] = `data:image/png;base64,${formattedResponse.data.result}`;
              } else if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.TABLE) {
                previewValues[dynamicContent.name] = formattedResponse.data.result;
              } else if (
                dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.IMAGE &&
                formattedResponse.data.result.length === 1
              ) {
                previewValues[dynamicContent.name] = formattedResponse.data.result[0][0];
              } else if (dynamicContent?.dynamic_content_type === Constants.DynamicContentTypes.CHART) {
                previewValues[dynamicContent.name] = formattedResponse.data.result;
              } else if (dynamicContent?.query_obj?.id) {
                previewValues[dynamicContent.name] = formattedResponse.data.result[1][0];
              } else {
                previewValues[dynamicContent.name] = formattedResponse.data.result[0][0];
              }
              this.props.onEmailPreview(previewValues);
              onComplete(dynamicContent.id);
            }
          };

          const longRequest = new LongRequest('/queries');
          longRequest.post(
            queryData,
            onResponse,
            this.props.onEmailPreviewError,
            undefined,
            undefined,
            `test/${dynamicContent.query_obj.data_source.type}`,
          );
        }
      }
      this.props.onEmailPreview?.(previewValues);
      this.props.closeModal();
    };
    this.checkIncompleteTags(contentPreview);
  };

  checkIncompleteTags = (callback) => {
    let matches = null;
    let allMatches = '';
    if (this.props.template?.slides?.[0]?.slide_xml) {
      matches = this.props.template?.slides?.[0]?.slide_xml.match(/{{\w+[<\s]/g);
    }
    if (matches) {
      allMatches = matches.shift();
      allMatches = allMatches.slice(0, -1);
      for (let match of matches) {
        allMatches = allMatches + ', ' + match.slice(0, -1);
      }
      MConfirm(
        'Incomplete Tags',
        `The email template contains incomplete dynamic content tags: '${allMatches} tag'. Resolve the tags to ensure your dynamic content populates correctly.`,
        'warning',
        (result) => {
          if (!result) {
            return false;
          } else {
            return callback();
          }
        },
        'Continue',
      );
    } else {
      callback();
    }
  };

  setAuthTokenByDynamicContent = (dynamicContent, token) => {
    const updatedObj = Object.assign({}, this.state.authTokenByDynamicContent);
    dynamicContent.forEach((content) => {
      updatedObj[content.name] = token;
    });
    this.setState({ authTokenByDynamicContent: updatedObj });
  };

  getDataSourcesWithAuthToken = () => {
    const dataSourcesWithAuthToken = [];
    if (!isEmpty(this.props.template) && !isEmpty(this.props.templateContentData)) {
      const dataSourcesById = utils.getDataSourcesForTemplate(this.props.template, this.props.templateContentData);
      if (dataSourcesById) {
        for (let dataSourceId in dataSourcesById) {
          const dataSource = dataSourcesById[dataSourceId];
          if (dataSource.has_auth_token) {
            dataSourcesWithAuthToken.push(dataSource);
          }
        }
      }
    }
    return dataSourcesWithAuthToken;
  };

  renderInputsComponent = () => {
    const dataSourcesWithAuthToken =
      !this.props.isContentFetching && !this.props.isReadOnly ? this.getDataSourcesWithAuthToken() : [];

    let body = '';
    let buttonText = 'Generate';
    let buttonActive;
    if (this.props.sortedInputs?.length > 0 || dataSourcesWithAuthToken.length > 0) {
      let matik_user_banner_text = '';
      const hasMatikUser = utils.hasMatikUserInputs(this.props.sortedInputs);
      const isNotEndUserView = location.pathname.indexOf('create') === -1;

      if (hasMatikUser) {
        const entityTypeToBannerTextMap = {
          template: 'Template',
          input: 'Input',
          dynamic_content: 'Dynamic Content',
        };

        matik_user_banner_text = entityTypeToBannerTextMap[this.props.entityType] || '';
      }
      body = (
        <>
          {hasMatikUser && isNotEndUserView && (
            <div className="pb-4">
              <Banner
                bannerType="info"
                text={`This ${matik_user_banner_text} uses an input that populates based on the 
                current user. To test, select the user you would like to impersonate.`}
              />
            </div>
          )}
          <FormFromInputs
            allowBulk={false}
            currentBulkInputName={this.state.currentBulkInputName}
            inputs={this.props.sortedInputs}
            inputValues={this.props.inputValues}
            isReadOnly={this.props.isReadOnly}
            onBulkInputToggle={this.onBulkInputToggle}
            slideIdsByInputName={this.props.slideIdsByInputName}
            updateInputValues={this.updateInputValues}
            template={this.props.template}
            dataSourcesWithAuthToken={dataSourcesWithAuthToken}
            onTestAccess={this.onTestAccess}
            allDynamicContent={this.props.allDynamicContent}
            tokenTestResult={this.props.tokenTestResult}
            tokenError={this.props.tokenError}
            tokenSuccess={this.props.tokenSuccess}
            tokenLoading={this.props.tokenLoading}
            setTokenLoading={this.props.setTokenLoading}
            authTokenByDynamicContent={this.state.authTokenByDynamicContent}
            setAuthTokenByDynamicContent={this.setAuthTokenByDynamicContent}
            isProducer={this.props.isProducer}
            inModal={this.props.inModal}
            isEmailVisualBuilder={this.props.isEmailVisualBuilder}
            entityId={this.props.entityId}
            entityType={this.props.entityType}
            test={true}
          />
        </>
      );
      buttonText = 'Generate';
    } else if (!this.props.isContentFetching) {
      body = (
        <div className="no-inputs">
          <div className="no-inputs-inner">
            <img src={no_inputs_image} alt="No Inputs" />
            <p className="no-inputs-text">No inputs detected</p>
          </div>
        </div>
      );
      buttonText = 'Generate';
    }
    return { body, buttonText, buttonActive };
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };
}

HtmlBuilderInputsForm.propTypes = {
  allDynamicContent: PropTypes.object,
  dynamicContentList: PropTypes.array,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  existingInputValues: PropTypes.object,
  sortedInputs: PropTypes.array,
  inputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  slideIdsByInputName: PropTypes.object,
  template: PropTypes.object,
  templateContent: PropTypes.array,
  updateInputValuesAndInitFromInputs: PropTypes.func,
  updateTestResult: PropTypes.func,
  tokenTestResult: PropTypes.any,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  setTokenLoading: PropTypes.func,
  testQuery: PropTypes.func,
  isContentFetching: PropTypes.bool,
  inModal: PropTypes.bool,
  setAllInputsFilledOut: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  isProducer: PropTypes.bool,
  onEmailPreview: PropTypes.func,
  onEmailPreviewError: PropTypes.func,
  templateContentData: PropTypes.object,
  isEmailVisualBuilder: PropTypes.bool,
  setVisualBuilderFetching: PropTypes.func,
};

HtmlBuilderInputsForm.contextType = UserContext;

function mapDispatchToProps(state, ownProps) {
  return Object.assign({}, mapInputDispatchToProps(state, ownProps), mapUiDispatchToProps(state));
}

const HtmlBuilderInputsFormWrapper = (props) => {
  const { data: templateContentData, isPending: isContentFetching } = useTemplateContent(
    props.template?.id,
    props.template?.deleted,
  );

  const taggedDynamicContentNames = Object.keys(props.allDynamicContent || {});
  const { isLoading: isContentLoading, dynamicContent } = useTemplatesUnsavedContent(
    props.template?.id,
    taggedDynamicContentNames,
  );
  const { isLoading: areInputsLoading, orderedInputList } = useDynamicContentInputList(
    dynamicContent || [],
    taggedDynamicContentNames,
    props.inputValues || {},
    props.template.params_order,
  );
  return (
    <HtmlBuilderInputsForm
      {...props}
      templateContentData={templateContentData}
      dynamicContentList={dynamicContent}
      isContentFetching={isContentFetching || areInputsLoading || isContentLoading}
      sortedInputs={orderedInputList}
    />
  );
};
HtmlBuilderInputsFormWrapper.propTypes = {
  allDynamicContent: PropTypes.object,
  inputValues: PropTypes.object,
  template: PropTypes.object,
};

export default connect(
  mapInputsStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(WithTestQuery(withRouter(withPresentationContextConsumer(HtmlBuilderInputsFormWrapper)))));
