import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { isEmpty } from 'lodash';
import InputLoading from '../InputLoading';
import Constants from '../../Constants';
import inputs from '../../../lib/inputs';
import { CreatableSelect, Select } from '../FormSelect';
import info_icon from '../../../images/info.svg';
import DependentOnBulkInput from './DependentOnBulkInput';
import { useDebouncedEffect } from 'lib/hooks/useDebouncedEffect';

function StringInputField(props) {
  const [fallbackValue, setFallbackValue] = useState('Loading...');

  const onTextChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let updatedInputValues = props.inputValues;
    updatedInputValues[name].value = value;
    updatedInputValues[name].error = '';
    props.onChange(name, updatedInputValues);
  };

  const onChange = (obj, action) => {
    if (action.action === 'select-option' || action.action === 'create-option' || action.action === 'clear') {
      const value = obj.value;
      const inputValue = props.inputValues[props.input.name];
      if (inputValue !== value) {
        let updatedInputValues = props.inputValues;
        updatedInputValues[props.input.name].value = value;
        updatedInputValues[props.input.name].error = '';
        props.onChange(props.input.name, updatedInputValues);
      }
    }
  };

  // using this custom effect hook to wait for user input to stop for 2000ms before firing any queries
  useDebouncedEffect(
    () => {
      let inputValue = props.inputValues[props.input.name];

      if (
        (props.input.source_type === Constants.InputSources.QUERY ||
          props.input.source_type === Constants.InputSources.API) &&
        !inputValue.isLoading &&
        !inputValue.fetch_error &&
        !inputValue.options
      ) {
        if (!props.isForConditionalDC || isEmpty(props.input.nested_parameters)) {
          setFallbackValue(
            inputs.loadOptionsFromQuery(
              props.input,
              props.inputValues,
              props.onLoadSuccess,
              props.onLoadFailure,
              fallback,
              props.beforeLoad,
              null,
              props.currentBulkInputName,
            ),
          );
        }
      }
    },
    [props.inputValues, props.input, props.currentBulkInputName],
    500,
  );

  const inputValue = props.inputValues[props.input.name];

  const userInput = (
    <Form.Input
      name={props.input.name}
      value={inputValue.value || ''}
      onChange={onTextChange}
      color={props.inputColor}
      disabled={props.isReadOnly}
    />
  );
  let fallback = props.fallback;
  if (!props.fallback) {
    fallback = () => userInput;
  }

  // Matik user based input
  if (props.input.source_type === Constants.InputSources.MATIK_USER) {
    if (inputValue.options) {
      const [firstElement] = inputValue.options;

      return (
        <Form.Input
          name={props.input.name}
          value={firstElement.value.label || ''}
          color={props.inputColor}
          disabled={true}
        />
      );
    } else {
      return (
        <Form.Input
          name={props.input.name}
          value={inputValue.value.label || ''}
          color={props.inputColor}
          disabled={true}
        />
      );
    }
  }

  // User input
  if (props.input.source_type === Constants.InputSources.USER_INPUT) {
    return userInput;
  }

  // Query
  if (
    props.input.source_type === Constants.InputSources.QUERY ||
    props.input.source_type === Constants.InputSources.API
  ) {
    let SelectComponent = Select;
    let formatCreateLabel = null;
    if (props.input.allow_user_override || (!isEmpty(props.input.nested_parameters) && props.isForConditionalDC)) {
      SelectComponent = CreatableSelect;
      // eslint-disable-next-line react/display-name
      formatCreateLabel = (selectedVal) => <span>Use {selectedVal}</span>;
    }
    if (inputValue.fetch_error) {
      return (
        <div className="has-text-danger is-flex">
          There was an error loading this input
          <span className="is-flex mls" data-tooltip-content={inputValue.fetch_error} data-tooltip-id="matik-tooltip">
            <img src={info_icon} alt="Error message" height="15px" />
          </span>
        </div>
      );
    } else if (inputValue.options) {
      let options = [];
      if (inputValue.options.length === 0 && !props.input.allow_user_override) {
        return <span className="has-text-danger">No options returned</span>;
      } else if (
        props.currentBulkInputName &&
        inputs.inputIsDependentOnBulkInput(props.input, props.currentBulkInputName)
      ) {
        return <DependentOnBulkInput input={props.input} />;
      } else {
        options = inputValue.options.map((option) => ({ label: option, value: option }));
      }
      const loadingData = props.isForConditionalDC
        ? {
            dataTS: inputValue.dataTS,
            isLoading: inputValue.isLoading,
            onSyncButtonClick: inputValue.onSyncButtonClick,
          }
        : undefined;
      return (
        <SelectComponent
          classNamePrefix="matik-select"
          className="matik-select-container"
          value={{ value: inputValue.value, label: inputValue.value }}
          onChange={onChange}
          aria-label="Dynamic Content Type"
          isDisabled={props.isReadOnly}
          formatCreateLabel={formatCreateLabel}
          options={options}
          loadingData={loadingData}
          menuPortalTarget={props.formRef}
          isClearable={props.isClearable}
        />
      );
    } else if (!isEmpty(props.input.nested_parameters) && props.isForConditionalDC) {
      return userInput;
    } else if (!inputValue.options?.length && props.inputValues[props.input.name].isLoading) {
      return <InputLoading />;
    } else {
      return <span>{fallbackValue}</span>;
    }
  }

  // List
  if (props.input.source_type === Constants.InputSources.LIST) {
    return (
      <Select
        classNamePrefix="matik-select"
        className="matik-select-container"
        value={{ value: inputValue.value, label: inputValue.value }}
        onChange={onChange}
        aria-label="Dynamic Content Type"
        isDisabled={props.isReadOnly}
        options={props.input.source_list.map((option) => ({ label: option, value: option }))}
        isClearable={props.isClearable}
      />
    );
  }
}

StringInputField.propTypes = {
  beforeLoad: PropTypes.func,
  isReadOnly: PropTypes.bool,
  input: PropTypes.object,
  inputValues: PropTypes.object,
  onChange: PropTypes.func,
  inputColor: PropTypes.string,
  onLoadSuccess: PropTypes.func,
  onLoadFailure: PropTypes.func,
  fallback: PropTypes.func,
  currentBulkInputName: PropTypes.string,
  isForConditionalDC: PropTypes.bool,
  formRef: PropTypes.object,
  isClearable: PropTypes.bool,
};

export default StringInputField;
