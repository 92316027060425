import React from 'react';
import PropTypes from 'prop-types';
import './HowTo.sass';
import { ReactComponent as ChatIcon } from '../../svg/chat_icon.svg';
import { ReactComponent as HelpIcon } from '../../svg/help_icon.svg';

function HowTo({ helpLink = 'https://help.matik.io', children }) {
  return (
    <div className="how-to">
      {children}
      <div className="how-to-questions">
        <p className="how-to-text subheader">Do you have questions?</p>
        <a target="_blank" rel="noreferrer" href={helpLink} className="how-to-text link">
          <HelpIcon />
          Help Center
        </a>
        <a href={null} onClick={() => window.zE.activate()} className="how-to-text link">
          <ChatIcon />
          Chat With Us
        </a>
      </div>
    </div>
  );
}

HowTo.propTypes = {
  helpLink: PropTypes.string,
  children: PropTypes.array,
};

export default HowTo;
