import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import FullPageList from '../../../shared/FullPageList';
import Constants from '../../../Constants';
import utils from '../../../../lib/utils';
import { ContentNameCell, DataSourceTypeCell } from '../../../shared/FullPageListCells';
import { UserContext } from '../../../UserContext';
import WithFilterListHeader from '../../../shared/WithFilterListHeader';
import WithInstantSearch from '../../../shared/search/WithInstantSearch';
import API from '../../../../lib/api';
import SmallLoader from '../../../shared/SmallLoader';
import chevron_down_icon from '../../../../images/chevron_down.svg';
import DynamicContentDataSourceOptions from './DynamicContentDataSourceOptions';

const SearchableListWithFilterHeader = WithInstantSearch(WithFilterListHeader(FullPageList));

class DataSourceSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSources: [],
      isExpanded: !this.props.selectedDataSource,
      isLoading: true,
      oldDataSource: null,
      pagination: null,
    };
  }

  componentDidMount() {
    if (!this.state.dataSources || this.state.dataSources.length === 0) {
      this.fetchDataSources();
    }
  }

  fetchDataSources = () => {
    let baseUrl = '/data_sources/?lite=true';
    if (this.props.dataSourceTypeFilter && this.props.dataSourceTypeFilter.length) {
      baseUrl += `&filter=${encodeURIComponent(JSON.stringify({ type: this.props.dataSourceTypeFilter }))}`;
    } else {
      baseUrl += '&all=true';
    }
    API.fetchContent(
      baseUrl,
      () => this.setState({ isLoading: true }),
      (dataSources, pagination) => {
        this.setState({ dataSources, pagination, isLoading: false });
      },
      0,
      Constants.PAGE_SIZE,
      null,
      null,
    );
  };

  actionsCell = (row) => {
    return <DynamicContentDataSourceOptions selectedDataSource={row.original} />;
  };

  render() {
    const columns = [
      {
        id: 'type',
        accessor: 'type',
        Cell: DataSourceTypeCell,
        className: 'pvm is-size-6',
        maxWidth: 70,
      },
      {
        id: 'name',
        Cell: ContentNameCell,
        accessor: 'name',
        className: 'min-width-0 has-text-dark is-size-6',
      },
      {
        id: 'actions',
        Header: '',
        Cell: this.actionsCell,
        maxWidth: 50,
        className: 'is-paddingless overflow-visible',
      },
    ];

    const dataSources = this.state.dataSources.filter((dataSource) => dataSource.id !== -2 && dataSource.id !== -4);
    let body;
    if (this.state.isLoading) {
      body = <SmallLoader />;
    } else if (this.state.isExpanded || !this.props.selectedDataSource) {
      body = (
        <React.Fragment>
          {!this.props.omitHeadline && (
            <Level>
              <Level.Side align="left">
                <Level.Item className="label ptl pll">Connect a Data Source</Level.Item>
              </Level.Side>
              {this.state.oldDataSource && (
                <Level.Side align="right">
                  <Level.Item className="ptl prl">
                    <button type="button" className="button is-secondary" onClick={this.onCancelExpand}>
                      Cancel
                    </button>
                  </Level.Item>
                </Level.Side>
              )}
            </Level>
          )}
          <SearchableListWithFilterHeader
            context="data_source"
            entities={dataSources}
            columns={columns}
            onRowClick={this.onRowClick}
            pagination={this.state.pagination}
            fetchItems={this.fetchItems}
            hideTopPagination={true}
            loading={this.state.isLoading}
            entityType="data_source"
            filterPlaceholder="Search Data Sources"
            buttonClass="is-primary"
            displayFilterHeader={true}
          />
        </React.Fragment>
      );
    } else {
      // Collapsed view is just a contrived FullPageList with a single element, no headers, and other minor tweaks

      let downChevronCell;
      if (this.props.isReadOnly) {
        downChevronCell = () => <span />;
      } else {
        downChevronCell = () => (
          <span className="is-flex is-vertical-centered">
            <img src={chevron_down_icon} width="16px" alt="Parameter Type" className="mrm" />
          </span>
        );
      }

      const collapsedColumns = columns.filter((column) => column.id !== 'actions');

      collapsedColumns.push({ id: 'down_chevron', Cell: downChevronCell, className: 'right-aligned' });

      body = (
        <FullPageList
          entitiesToRender={[this.props.selectedDataSource]}
          columns={collapsedColumns}
          hideHeader={true}
          onRowClick={!this.props.isReadOnly ? this.onExpandClick : () => {}}
          buttonClass="is-primary"
        />
      );
    }

    return <div className="has-background-white dynamic-content-list">{body}</div>;
  }

  onRowClick = (id) => {
    this.setState({ isExpanded: false, isLoading: true });
    utils.getItemFromId(
      id,
      this.props.selectedDataSource,
      this.state.dataSources,
      '/data_sources/',
      (dataSource) => {
        this.props.onDataSourceSelect(dataSource);
        this.setState({ isLoading: false });
      },
      API.defaultError,
    );
  };

  onExpandClick = () => {
    this.setState({ isExpanded: true });
    if (this.props.selectedDataSource) {
      this.setState({ oldDataSource: this.props.selectedDataSource });
    }
  };

  onCancelExpand = () => {
    this.setState({ isExpanded: false });
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    API.fetchContent(
      '/data_sources/',
      () => this.setState({ isLoading: true }),
      (dataSources, pagination) => {
        this.setState({ dataSources, pagination, isLoading: false });
      },
      offset,
      Constants.PAGE_SIZE,
      sort,
      null,
    );
  };
}

DataSourceSelect.propTypes = {
  isReadOnly: PropTypes.bool,
  selectedDataSource: PropTypes.object,
  onDataSourceSelect: PropTypes.func,
  dataSourceTypeFilter: PropTypes.array,
  omitHeadline: PropTypes.bool,
};

DataSourceSelect.contextType = UserContext;

export default DataSourceSelect;
