import {
  CLEAR_INPUT_VALUE,
  INIT_INPUT_VALUES_FROM_INPUTS,
  UPDATE_INPUT_VALUES,
  UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS,
  UPDATE_INPUT_VALUES_FROM_INPUTS,
} from './actionTypes';

export function updateInputValues(entityType, entityId, inputValues) {
  return {
    type: UPDATE_INPUT_VALUES,
    inputValues,
    entityId,
    entityType,
  };
}

export function updateInputValuesFromInputs(entityType, entityId, inputs) {
  return {
    type: UPDATE_INPUT_VALUES_FROM_INPUTS,
    inputs,
    entityId,
    entityType,
  };
}

export function updateInputValuesAndInitFromInputs(entityType, entityId, inputValues, inputs) {
  return {
    type: UPDATE_INPUT_VALUES_AND_INIT_FROM_INPUTS,
    inputs,
    inputValues,
    entityId,
    entityType,
  };
}

export function initInputValuesFromInputs(entityType, entityId, inputs) {
  return {
    type: INIT_INPUT_VALUES_FROM_INPUTS,
    inputs,
    entityId,
    entityType,
  };
}

export function clearInputValue(entityType, entityId, input) {
  return {
    type: CLEAR_INPUT_VALUE,
    input,
    entityId,
    entityType,
  };
}
