import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import utils from '../../../../lib/utils';

class ApiLimit extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Result Limit</Form.Label>
          <Form.Help>Maximum number of results to return</Form.Help>
          <Form.Control>
            <Form.Input
              type="text"
              value={this.props.limit}
              onChange={this.onLimitChange}
              name="limit"
              style={{ maxWidth: '50px' }}
              onKeyPress={utils.preventSubmit}
              aria-label="Result limit"
              disabled={this.props.isReadOnly}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  }

  onLimitChange = (e) => {
    e.preventDefault();
    const limit = e.target.value;
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    updatedQueryObj.query.limit = limit;
    this.props.updateQueryObj(updatedQueryObj);
  };
}

ApiLimit.propTypes = {
  isReadOnly: PropTypes.bool,
  limit: PropTypes.string,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
};

export default ApiLimit;
