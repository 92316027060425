import React, { Component } from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Constants from '../../Constants';
import TestResult from '../../shared/TestResult';
import QueryEditor from './DynamicContentQueryEditor';
import SampleData from './SampleData';
import WithTestQuery from '../../shared/WithTestQuery';
import { Select } from '../../shared/FormSelect';
import InputMapping from './InputMapping';
import inputs from '../../../lib/inputs';
import Button from '../../lib/Button';

class InputFormQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputMappingOptions: [],
      inputMappingIsLoading: false,
      inputMappingDataTS: null,
    };
  }

  componentDidMount() {
    if (this.props.input) {
      this.fetchInputMappingOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.input && !isEqual(prevProps.input, this.props.input)) {
      this.fetchInputMappingOptions();
    }
    if (
      this.props.testResult &&
      this.props.testResult.result &&
      !isEqual(prevProps.testResult, this.props.testResult)
    ) {
      this.setState({ inputMappingOptions: this.props.testResult.result[0], inputMappingIsLoading: false });
    }
  }

  render() {
    const queryDataSources = this.props.dataSources.filter(
      (dataSource) => Constants.DATA_SOURCE_TYPES_FOR_METHOD['query'].indexOf(dataSource.type) >= 0,
    );
    const queryString =
      this.props.query_obj && this.props.query_obj.query_string ? this.props.query_obj.query_string : '';
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Data Source</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              value={{ label: this.props.query_obj?.data_source?.name, value: this.props.query_obj?.data_source?.id }}
              onChange={this.props.onDataSourceUpdate}
              options={queryDataSources.map((dataSource) => ({ label: dataSource.name, value: dataSource.id }))}
              menuPortalTarget={this.props.formRef?.current}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label className="label-with-button">Query</Form.Label>
          <Form.Control>
            <div style={{ border: '1px solid #ddd' }}>
              <QueryEditor
                mode="sql"
                inputs={this.props.existingInputs}
                queryString={queryString}
                inputsInQueryString={this.props.inputsInQueryString}
                dataSource={this.props.query_obj?.data_source}
                isInputPopoverDisabled
              />
            </div>
          </Form.Control>
          <span className="is-flex end-align mtl">
            <Button
              category="secondary"
              onClick={(e) => this.props.testQuery(e, queryString)}
              status={this.props.testIsLoading ? 'loading' : 'default'}
              id="test-input-query"
            >
              Test Query
            </Button>
          </span>
          {this.props.testResult && (
            <Form.Field>
              <div className="mbs">
                <TestResult testResult={this.props.testResult} noPadding={true} />
              </div>
              <SampleData testResult={this.props.testResult} width={this.props.width} />
            </Form.Field>
          )}
          <Form.Field className="mtl">
            <InputMapping
              queryObj={this.props.query_obj}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              input={this.props.input}
              inputMapping={this.props.inputMapping}
              inputSourceType="query"
              isLoading={this.state.inputMappingIsLoading}
              dataTS={this.state.inputMappingDataTS}
              onResync={this.fetchInputMappingOptions}
              returnFieldsArray={this.state.inputMappingOptions}
              formRef={this.props.formRef}
            />
          </Form.Field>
        </Form.Field>
      </React.Fragment>
    );
  }

  fetchInputMappingOptions = () => {
    inputs.loadOptionsFromQuery(
      this.props.input,
      [],
      this.setInputMappingOptions,
      () => this.setState({ inputMappingIsLoading: false, inputMappingDataTS: null }),
      () => this.setState({ inputMappingIsLoading: false, inputMappingDataTS: null }),
      () => this.setState({ inputMappingIsLoading: true }),
      this.props.query_obj,
    );
  };

  setInputMappingOptions = (
    inputName,
    inputOptions,
    inputType,
    startingNestedInputValues,
    currentNestedInputValues,
    mappedOptions,
    inputMappingOptions,
    isStillLoading,
    resultTS,
  ) => {
    this.setState({ inputMappingOptions, inputMappingIsLoading: isStillLoading, inputMappingDataTS: resultTS });
  };
}

InputFormQuery.propTypes = {
  dataSources: PropTypes.array,
  query_obj: PropTypes.object,
  onDataSourceUpdate: PropTypes.func,
  testResult: PropTypes.object,
  testQuery: PropTypes.func,
  testIsLoading: PropTypes.bool,
  existingInputs: PropTypes.object,
  onQueryStringUpdate: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  width: PropTypes.number,
  input: PropTypes.object,
  inputMappingOptions: PropTypes.array,
  onInputMappingUpdate: PropTypes.func,
  inputMapping: PropTypes.object,
  formRef: PropTypes.object,
};

export default WithTestQuery(InputFormQuery);
