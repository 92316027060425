import React from 'react';
import ppt_icon from '../../../images/ppt_icon.svg';
import PropTypes from 'prop-types';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import { ReactComponent as UploadIcon } from 'images/icons/arrow_upload-20x20-regular.svg';

export const ComputerImportCard = (props) => {
  const loadingClass = props.isLoading ? 'is-loading' : '';
  const cardLabel = 'Select PowerPoint from Microsoft 365 or upload from your computer';
  return (
    <div>
      <img src={ppt_icon}></img>
      <p>{cardLabel}</p>
      <span>
        <MicrosoftPicker
          className="inline-flex mrm"
          onPicked={props.handle365SelectFile}
          filters={['.ppt', '.pptx']}
          isLoading={props.is365Loading}
        />
        <label className={`button is-secondary ${loadingClass}`}>
          <UploadIcon className="import-icon" />
          Upload
          <input
            className="template-file-picker-input"
            type="file"
            name="file"
            onChange={props.handleSelectFile}
            accept=".ppt,.pptx"
          />
        </label>
      </span>
    </div>
  );
};

ComputerImportCard.propTypes = {
  handleSelectFile: PropTypes.func,
  handle365SelectFile: PropTypes.func,
  isLoading: PropTypes.bool,
  is365Loading: PropTypes.bool,
  percentCompleted: PropTypes.number,
  processingStatus: PropTypes.string,
};
