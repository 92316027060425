import React from 'react';
import PropTypes from 'prop-types';

import inputs from 'lib/inputs';
import utils from 'lib/utils';

import PresentationInputsForm from 'components/shared/presentations/PresentationInputsForm';
import { useTemplateContent, useTemplatesById, useTemplatesContent } from 'lib/hooks/useTemplate';

export const findTemplateContentTagsAndInputs = (
  template,
  templateContent,
  addedSlides,
  allDynamicContent,
  selectedSlidesBySlideNum,
) => {
  let matchingInputs = {};
  let matchingContent = [];
  if (template) {
    let tagTree;
    let bodyTags;

    const allSlides = template ? [...template.slides] : [];
    if (addedSlides) {
      for (let addSlide of addedSlides) {
        allSlides.splice(addSlide.idx, 0, addSlide.slide);
      }
    }

    tagTree = utils.getDynamicContentTagsForSelectedSlides(
      template,
      templateContent,
      allSlides,
      selectedSlidesBySlideNum,
      allDynamicContent,
    );
    bodyTags = tagTree.getTagNodes();
    matchingInputs = inputs.getAllInputsForSelectedSlide(
      bodyTags,
      allDynamicContent,
      template,
      templateContent,
      allSlides,
      selectedSlidesBySlideNum,
    );
    matchingContent = bodyTags.map((tag) => utils.getDynamicContentFromTag(tag.name));
  }
  return {
    inputs: matchingInputs,
    content: matchingContent,
  };
};

function TestQueryHandlingInputsForm({
  addedSlides,
  selectedSlidesBySlideNum,
  template,
  allDynamicContent,
  attachedTemplate,
  inputValues,
  isTestSlide,
  onPresentationCreate,
  setAllInputsFilledOut,
  setTriggerEmailGeneration,
  triggerEmailGeneration,
  onSendEmailClick,
  allInputsFilledOut,
  submitTestEmail,
  showEmailSendButton,
}) {
  // addedSlides contains only ID references, but we need full slide data and related template content
  // to find referenced inputs
  const externalTemplateIds = new Set();
  addedSlides?.forEach((added) => {
    externalTemplateIds.add(added.template.id);
  });
  const externalTemplatesById = useTemplatesById([...externalTemplateIds]);
  const externalTemplateContentById = useTemplatesContent([...externalTemplateIds]);

  let { data: templateContent, isPending: isTemplateContentFetching } = useTemplateContent(
    template?.id,
    template?.deleted,
  );
  const { data: attachedTemplateContent, isPending: isAttachedContentFetching } = useTemplateContent(
    attachedTemplate?.id,
    attachedTemplate?.deleted,
  );
  const isContentFetching =
    isTemplateContentFetching ||
    (attachedTemplate?.id && isAttachedContentFetching) ||
    (externalTemplateIds.size > 0 && !!Object.values(externalTemplateContentById).find((result) => result.isPending));

  const handlePresentationCreate = (presentation, schedule, attachedPresentation = {}) => {
    onPresentationCreate(presentation, schedule, attachedPresentation);
  };

  // fill out addedSlides pointers with full data
  if (addedSlides) {
    addedSlides = addedSlides.map((addSlide) => {
      const templateResult = externalTemplatesById[addSlide.template.id];
      if (templateResult && templateResult.data) {
        const slide = templateResult.data.slides.find((s) => s.id === addSlide.slide.id);
        return { ...addSlide, slide: slide };
      } else {
        return addSlide;
      }
    });

    // integrate addedSlides template content
    if (templateContent) {
      templateContent = { ...templateContent };
      Object.values(externalTemplateContentById)
        .map((result) => result.data)
        .filter((data) => !!data)
        .forEach((addedContent) => {
          templateContent.content_by_slide = [...templateContent.content_by_slide, ...addedContent.content_by_slide];
        });
    }
  }

  const presentationSlides = template?.slides ? [...template.slides] : [];
  if (addedSlides) {
    for (let addSlide of addedSlides) {
      presentationSlides.splice(addSlide.idx, 0, addSlide.slide);
    }
  }

  let slideIdsByInputName = {};
  if (templateContent && allDynamicContent) {
    slideIdsByInputName = inputs.slideNumsByInputName(templateContent, presentationSlides, allDynamicContent);
  }

  // None selected == All selected
  if (!selectedSlidesBySlideNum || Object.values(selectedSlidesBySlideNum).indexOf(true) < 0) {
    selectedSlidesBySlideNum = {};
    for (let num = 1; num <= presentationSlides.length; num++) {
      selectedSlidesBySlideNum[num] = true;
    }
  }

  const { inputs: bodyMatchingInputs, content: bodyMatchingContent } = findTemplateContentTagsAndInputs(
    template,
    templateContent,
    addedSlides,
    allDynamicContent,
    selectedSlidesBySlideNum,
  );

  const attachmentSelectedSlides = {};
  if (attachedTemplate) {
    for (let num = 1; num <= attachedTemplate.slides.length; num++) {
      attachmentSelectedSlides[num] = true;
    }
  }
  const { inputs: attachmentMatchingInputs, content: attachmentMatchingContent } = findTemplateContentTagsAndInputs(
    attachedTemplate,
    attachedTemplateContent,
    null,
    allDynamicContent,
    attachmentSelectedSlides,
  );

  let slideIdxs = [];
  if (selectedSlidesBySlideNum) {
    for (const num of Object.keys(selectedSlidesBySlideNum)) {
      if (selectedSlidesBySlideNum[num]) {
        slideIdxs.push(num - 1);
      }
    }
  } else {
    slideIdxs = [...presentationSlides.keys()];
  }

  const allTemplateContent = attachedTemplateContent
    ? {
        loops: [...templateContent.loops, ...attachedTemplateContent.loops],
        content_by_slide: [...templateContent.content_by_slide, ...attachedTemplateContent.content_by_slide],
        template_condition_content: [
          ...templateContent.template_condition_content,
          ...attachedTemplateContent.template_condition_content,
        ],
      }
    : templateContent;

  return (
    <PresentationInputsForm
      addedSlides={addedSlides}
      allDynamicContent={allDynamicContent}
      allowBulk={true}
      attachedTemplate={attachedTemplate}
      attachmentContent={attachmentMatchingContent}
      attachmentInputs={attachmentMatchingInputs}
      entityId={template.id}
      entityType="template"
      existingInputValues={inputValues}
      inputs={bodyMatchingInputs}
      isContentFetching={isContentFetching}
      isTestSlide={isTestSlide}
      onPresentationCreate={handlePresentationCreate}
      setAllInputsFilledOut={setAllInputsFilledOut}
      slideIdxs={slideIdxs}
      slideIdsByInputName={slideIdsByInputName}
      template={template}
      allTemplateContent={allTemplateContent}
      templateContent={bodyMatchingContent}
      triggerEmailGeneration={triggerEmailGeneration}
      untriggerEmailGeneration={() => setTriggerEmailGeneration(false)}
      source_type={template.source_type}
      onSendEmailClick={onSendEmailClick}
      allInputsFilledOut={allInputsFilledOut}
      submitTestEmail={submitTestEmail}
      showEmailSendButton={showEmailSendButton}
    />
  );
}
TestQueryHandlingInputsForm.propTypes = {
  addedSlides: PropTypes.array,
  selectedSlidesBySlideNum: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  allDynamicContent: PropTypes.object,
  inputValues: PropTypes.object,
  isTestSlide: PropTypes.bool,
  onPresentationCreate: PropTypes.func,
  setAllInputsFilledOut: PropTypes.func,
  triggerEmailGeneration: PropTypes.bool,
  setTriggerEmailGeneration: PropTypes.func,
  onSendEmailClick: PropTypes.func,
  submitTestEmail: PropTypes.func,
  allInputsFilledOut: PropTypes.bool,
  showEmailSendButton: PropTypes.bool,
};

export default TestQueryHandlingInputsForm;
