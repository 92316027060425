import API from 'lib/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import useTemplate from 'lib/hooks/useTemplate';
import useMultiFetch from 'lib/hooks/useMultiFetch';

/** Get all conditions in the enterprise.
 */
const useAllConditions = (offset = 0, limit = Constants.PAGE_SIZE) => {
  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['conditions', { offset, limit }],
    queryFn: () => {
      return API.get(
        API.generate_paginated_url('/conditions/', offset, limit, null, null),
        (response) => {
          // pre-cache individual conditions
          response.data.forEach((condition) => {
            queryClient.setQueryData(['condition', condition.id], condition);
          });

          return {
            count: API.getCountFromResponse(response),
            elements: response.data,
          };
        },
        (err) => {
          throw err;
        },
      );
    },
  });

  const pagination = data ? utils.getPaginationFromRequest(data?.count, limit, offset, null) : undefined;
  return {
    isPending: isLoading,
    isError,
    count: data?.count,
    elements: data?.elements,
    pagination,
    error,
  };
};

export default useAllConditions;

export const useCondition = (conditionId) => {
  const results = useConditions(conditionId ? [conditionId] : []);
  if (conditionId) {
    return results[conditionId];
  } else {
    return {
      isPending: true,
      isError: false,
      data: undefined,
      error: undefined,
    };
  }
};

export const useConditionMutator = () => {
  const queryClient = useQueryClient();
  const { invalidate: invalidateTemplate, invalidateMatching: invalidateMatchingTemplate } = useTemplate();

  const create = (condition) => {
    return API.post(
      '/conditions/',
      condition,
      (response) => {
        const newCondition = response.data.new_entity;

        // cache new data and invalidate search results
        queryClient.setQueryData(['condition', parseInt(newCondition.id)], newCondition);
        queryClient.invalidateQueries({ queryKey: ['conditions'] });

        return newCondition;
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };

  const update = (condition) => {
    return API.put(
      `/conditions/${condition.id}/`,
      condition,
      (response) => {
        const updatedCondition = response.data.updated_entity;

        // cache updated data and invalidate search results
        queryClient.setQueryData(['condition', parseInt(updatedCondition.id)], updatedCondition);
        queryClient.invalidateQueries({ queryKey: ['conditions'] });

        return updatedCondition;
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };
  const del = (condition) => {
    return API.delete(
      `/conditions/${condition.id}/`,
      () => {
        queryClient.removeQueries(['condition', parseInt(condition.id)]);
        queryClient.invalidateQueries({ queryKey: ['conditions'] });

        const isInConditionClauses = (condition_clauses) => {
          return condition_clauses?.or?.some((or) => or.and?.includes(condition.id));
        };

        // If the condition has targets, those should be removed from the cache, too.
        queryClient.removeQueries({
          queryKey: ['condition_target'],
          predicate: (query) => {
            if (query.queryKey.length === 3 && query.state.data) {
              return isInConditionClauses(query.state.data.condition_clauses);
            } else {
              return false;
            }
          },
        });

        // invalidate templates since they have refs to condition targets on the data
        condition.targets[Constants.ConditionTargetType.TEMPLATE]?.forEach((targetId) => {
          invalidateTemplate(targetId);
        });

        // and on the slides (template.slides[].condition_clauses)
        invalidateMatchingTemplate((template) =>
          template.slides?.some?.((slide) => isInConditionClauses(slide.condition_clauses)),
        );
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };

  return {
    create,
    update,
    del,
  };
};

export const useConditions = (conditionIds) => {
  const getQueryKey = (id) => ['condition', parseInt(id)];
  const getFetchUri = (ids) => `/conditions/?all=1&id=${ids.join('&id=')}`;
  const getEntityId = (condition) => condition.id;

  return useMultiFetch(conditionIds, getQueryKey, getFetchUri, getEntityId);
};

const useConditionTargets = (targetType, targetIds) => {
  const getQueryKey = (id) => ['condition_target', targetType, parseInt(id)];
  const getFetchUri = (ids) => `/conditions/targets/${targetType}/?target_id=${ids.join('&target_id=')}`;
  const getEntityId = (condition_target) => condition_target.condition_target_id;

  return useMultiFetch(targetIds, getQueryKey, getFetchUri, getEntityId);
};
export const useSlideConditionTargets = (slideIds) =>
  useConditionTargets(Constants.ConditionTargetType.SLIDE, slideIds);

/** Convenience method for fetching all conditions referenced by a given template */
export const useConditionsInTemplate = (template) => {
  const slideIdsOnTemplate = template?.slides?.map((slide) => slide.id);
  const conditionTargetsBySlideId = useSlideConditionTargets(slideIdsOnTemplate);

  const conditionIds = new Set();
  Object.values(conditionTargetsBySlideId).forEach(({ data }) => {
    if (data) {
      data.condition_clauses.or?.forEach((or) => or.and?.forEach((conditionId) => conditionIds.add(conditionId)));
    }
  });
  const templateConditionResults = useConditions([...conditionIds]);
  return Object.values(templateConditionResults)
    .map((result) => result.data)
    .filter((el) => !!el);
};

const getConditionIds = (target) => (target.condition_clauses.or || []).flatMap((or) => or.and || []);

/** Update condition targets.
 */
export const useConditionTargetMutator = () => {
  const { invalidate: invalidateTemplate, invalidateMatching: invalidateMatchingTemplate } = useTemplate();
  const queryClient = useQueryClient();

  /** Bulk add or update the conditions for a set of condition-targets.
   *
   * @param targetType  e.g. 'slide'
   * @param conditionsByTargetId
   * ```
   * {
   *   'target id': { "or": ... },
   *   ...
   * }
   * ```
   */
  const upsert = (targetType, conditionsByTargetId) => {
    return API.put(
      `/conditions/targets/${targetType}/`,
      conditionsByTargetId,
      (response) => {
        /* 
        { 
          updated_entities: [ 
            { id: 1, condition_target_type: "slide", condition_target_id: 123, condition_clauses: { or: [...] } },
            ... 
          ]
        }
        */

        const conditionIds = new Set();

        response.data.updated_entities?.forEach((conditionTarget) => {
          // Update/pre-cache any target data
          queryClient.setQueryData(
            ['condition_target', conditionTarget.condition_target_type, parseInt(conditionTarget.condition_target_id)],
            (cachedData) => {
              // We want to invalidate all related conditions, including those that might have been removed.
              if (cachedData) {
                getConditionIds(cachedData).forEach((id) => conditionIds.add(id));
              }
              getConditionIds(conditionTarget).forEach((id) => conditionIds.add(id));
              return conditionTarget;
            },
          );

          // Since we return condition/target info with template data, we need to invalidate any cached
          // templates so we don't have any stale condition data in there.
          if (conditionTarget.condition_target_type === Constants.ConditionTargetType.TEMPLATE) {
            invalidateTemplate(conditionTarget.condition_target_id);
          } else if (conditionTarget.condition_target_type === Constants.ConditionTargetType.SLIDE) {
            invalidateMatchingTemplate(
              (template) =>
                template.slides?.findIndex?.((slide) => slide.id === conditionTarget.condition_target_id) > -1,
            );
          }
        });

        conditionIds.forEach((id) => {
          queryClient.invalidateQueries({ queryKey: ['condition', parseInt(id)] });
        });

        return response.data;
      },
      (err) => {
        API.defaultError(err);
      },
    );
  };

  return {
    /** Bulk add or update the conditions for a set of slides.
     * @param conditionsBySlideId ```
     * {
     *   "slide id": { "or": ... },
     *   ...
     * }
     * ```
     */
    upsertSlides: (conditionsBySlideId) => upsert(Constants.ConditionTargetType.SLIDE, conditionsBySlideId),
  };
};
