import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-bulma-components';
import Lottie from 'react-lottie';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { ReactComponent as ScheduledFlowIcon } from 'images/icons/arrow_repeat_all-16x16-regular.svg';
import { ReactComponent as ManualFlowIcon } from 'images/icons/arrow_repeat_all_off-16x16-regular.svg';
import { ReactComponent as CalendarClock } from 'svg/calendar_clock.svg';
import { ReactComponent as CheckMark } from 'svg/check_mark_circle.svg';
import { ReactComponent as Error } from 'svg/error_circle_filled.svg';
import { ReactComponent as Search } from 'svg/search_filled.svg';
import { ReactComponent as Warning } from 'svg/warning_triangle_solid.svg';
import pulsing from 'lottie/pulsing-indicator.json';

import Constants from 'components/Constants';
import API from 'lib/api';
import localeDateFormatterUtils from 'lib/localeDate';
import scheduler from 'lib/scheduler';
import utils from 'lib/utils';

import { closeModal, openModal, closeSidepane, openSidepane } from 'redux/ui/action';

import { MConfirm } from 'components/shared/Alerts';
import FullPageList from 'components/shared/FullPageList';
import { NameCell } from 'components/shared/FullPageListCells';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';
import WithLoadingAndEmpty from 'components/shared/WithLoadingAndEmpty';
import { flowStatus, flowTypes, runStatus } from './Constants';
import FlowsSidepane from './FlowsSidepane';
import FlowActionDropdown from './FlowActionDropdown';
import ScheduledFlowModal from 'components/shared/flows/ScheduledFlowModal';
import TemplateIcon from 'components/shared/TemplateIcon';

function FlowsList({ baseUrl, flows, onFlowDelete, pagination, paginateContent }) {
  const [filterTerm, setFilterTerm] = useState('');
  const [currentFlow, setCurrentFlow] = useState(null);
  const [includePdf, setPdfToggle] = useState(false);
  const [customFolder, onCustomFolderUpdate] = useState({});

  const history = useHistory();
  const searchParams = history?.location?.search;
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const onPdfToggle = (e) => setPdfToggle(e?.target?.checked);

  useEffect(() => {
    return () => {
      dispatch(closeModal());
      dispatch(closeSidepane());
    };
  }, []);

  useEffect(() => {
    if (searchParams) {
      const [flowType, flowId] = getSearchParamFlowTypeAndId();
      const flow = flows.find((flow) => flow.flow_type === flowType && flow.id === flowId);
      if (flow) {
        setCurrentFlow(flow);
        if (!ui.sidepane && !ui.modal) {
          dispatch(openSidepane({ entityType: 'flows' }, 'flows'));
        }
      }
    } else {
      if (currentFlow) {
        setCurrentFlow(null);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentFlow) {
      setPdfToggle(currentFlow?.params?.include_pdf);
      onCustomFolderUpdate(currentFlow?.params?.custom_folder);
    }
  }, [currentFlow]);

  const getSearchParamFlowTypeAndId = () => {
    const params = new URLSearchParams(searchParams);
    let flowType, flowId;
    if (params.size) {
      if (params.has(flowTypes.SCHEDULED)) {
        flowType = flowTypes.SCHEDULED;
      } else if (params.has(flowTypes.MANUAL)) {
        flowType = flowTypes.MANUAL;
      }
      if (flowType) {
        flowId = parseInt(params.get(flowType));
      }
    }
    return [flowType, flowId];
  };

  const onFilter = (e) => {
    setFilterTerm(e.target.value);
  };

  const onRowClick = (flowType, flowId) => {
    history.replace(`?${flowType}=${flowId}`);
    dispatch(openSidepane({ entityType: 'flows' }, 'flows'));
  };

  const onUpdateClick = (flow) => {
    if (flow.flow_type === flowTypes.SCHEDULED) {
      if (searchParams !== `?${flow.flow_type}=${flow.id}`) {
        history.replace(`?${flow.flow_type}=${flow.id}`);
      }
      dispatch(openModal('scheduledFlowModal'));
    }
  };

  const onUpdateScheduleSubmit = (schedule) => {
    const updateData = {
      ...schedule,
      update_type: 'schedule',
      params: {
        custom_folder: customFolder,
        include_pdf: includePdf,
      },
    };

    API.put(
      `/scheduled_tasks/${currentFlow.id}/`,
      updateData,
      () => {
        MConfirm('Schedule Update', 'Scheduled flow updated successfully.', 'confirm', () => window.location.reload());
        dispatch(closeModal());
      },
      API.defaultError,
    );
  };

  const fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    paginateContent(offset, Constants.PAGE_SIZE, sort);
  };

  const flowNameCell = (row) => {
    return (
      <span className="text-overflow-ellipsis mrm">
        <TextOverflowWithTooltip className="text-overflow-ellipsis" text={row.original.name} />
        <div className="recurrence">
          {row.original.flow_type === flowTypes.SCHEDULED ? (
            <>
              <ScheduledFlowIcon /> {scheduler.getSentenceFromSchedule(row.original)}
            </>
          ) : (
            <>
              <ManualFlowIcon /> Runs one time
            </>
          )}
        </div>
      </span>
    );
  };

  const templateCell = (row) => {
    const templateType =
      row.original.template.source_type === 'email'
        ? 'email'
        : row.original.template.source_type === 'google_docs'
        ? 'document'
        : 'presentation';
    return (
      <span className="template-cell text-overflow-ellipsis mrm">
        <TemplateIcon templateType={templateType} className="mrs" />
        <TextOverflowWithTooltip className="text-overflow-ellipsis" text={row.original.template.name} />
      </span>
    );
  };

  const statusCell = (row) => {
    const style = { height: 20, width: 20, marginLeft: -3, marginRight: 3 };
    const lottieDefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: pulsing,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMin',
        runExpressions: false,
      },
    };
    let indicator = <div className={'status-indicator green-status'}></div>;
    let text = 'Completed';
    if (![runStatus.DONE, flowStatus.COMPLETED].includes(row.original.status)) {
      text = utils.toTitleCase(row.original.status);
    }
    if ([runStatus.PROCESSING, flowStatus.PROCESSING].includes(row.original.status)) {
      indicator = <Lottie options={lottieDefaultOptions} style={style} />;
    } else if ([runStatus.CANCELED, runStatus.NOT_STARTED].includes(row.original.status)) {
      indicator = <div className={'status-indicator grey-status'}></div>;
    } else if ([runStatus.ERROR].includes(row.original.status)) {
      indicator = <div className={'status-indicator red-status'}></div>;
    }
    return (
      <div className="status-cell">
        {indicator} {text}
      </div>
    );
  };

  const performanceCell = (row) => {
    let icon = <CheckMark className="performance-icon green-checkmark" />;
    let text = 'All smooth';
    if (row.original.status_message === flowStatus.QUEUED) {
      icon = <CalendarClock className="performance-icon warning" />;
      text = "Hasn't run yet";
    } else if (row.original.status_message === 'warning') {
      icon = <Warning className="performance-icon warning" />;
      text = 'Warning occurred';
    } else if (row.original.status_message === 'error' || row.original.status === runStatus.ERROR) {
      icon = <Error className="performance-icon error" />;
      text = 'Error occurred';
    }
    return (
      <div className="performance-cell">
        {icon} {text}
      </div>
    );
  };

  const dropdownTrigger = <span className="more-dots">...</span>;
  const actionsCell = (row) => (
    <span className="is-flex overflow-visible">
      <FlowActionDropdown
        flow={row.original}
        onFlowDelete={onFlowDelete}
        dropdownTrigger={dropdownTrigger}
        isHoverable={true}
        onUpdateClick={onUpdateClick}
      />
    </span>
  );

  const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
    localeDateFormatterUtils.getUserLocale(),
  );
  const calendarFormat = {
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastDay: '[Yesterday at] LT',
    lastWeek: function (now) {
      if (now.week() === this.week()) {
        return 'dddd [at] LT';
      }
      return `${localeDateFormat}`;
    },
    sameElse: localeDateFormat,
  };

  const columns = [
    { id: 'name', Header: utils.tableHeader('Name'), Cell: flowNameCell },
    { id: 'template', Header: utils.tableHeader('Template'), Cell: templateCell },
    { id: 'status', Header: utils.tableHeader('Status'), maxWidth: 150, Cell: statusCell },
    { id: 'status_message', Header: utils.tableHeader('Performance'), Cell: performanceCell },
    {
      id: 'updated_on',
      Header: utils.tableHeader('Last Run'),
      accessor: (d) =>
        d.flow_type === flowTypes.MANUAL || d.runs.length ? (
          moment.utc(d.updated_on).local().calendar(calendarFormat)
        ) : (
          <span
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={moment.utc(d.updated_on).local().calendar(calendarFormat)}
          >
            Scheduled
          </span>
        ),
      Cell: (cell) => cell.value,
    },
    {
      id: 'user',
      Header: utils.tableHeader('Created by'),
      accessor: (d) => (d.user.name ? d.user.name : d.user.email),
      Cell: (row) => NameCell({ original: row.original.user, value: row.value }),
    },
    { id: 'actions', Header: '', Cell: actionsCell, maxWidth: 50, className: 'is-paddingless overflow-visible' },
  ];

  const LDClient = useLDClient();
  const areFlowsEnabled = LDClient?.variation('enable-jobs-tab', false) || false;
  if (!areFlowsEnabled) {
    return <Redirect push to={baseUrl} />;
  }

  let flowsToDisplay = flows.filter((flow) => !!flow.template);

  if (flowsToDisplay.length === 0) {
    return null;
  }

  if (filterTerm) {
    const lowerCaseFilter = filterTerm.toLowerCase();
    flowsToDisplay = flowsToDisplay.filter((scheduledFlow) => {
      return scheduledFlow.name.toLowerCase().indexOf(lowerCaseFilter) >= 0;
    });
  }

  return (
    <div className="flows main phn">
      <Form.Field className="search">
        <Form.Control className="has-icons-left">
          <Form.Input type="text" size="medium" placeholder="Filter" value={filterTerm} onChange={onFilter} />
          <span className="icon is-small is-left">
            <Search />
          </span>
        </Form.Control>
      </Form.Field>
      <FullPageList
        columns={columns}
        currentFlow={currentFlow}
        entitiesToRender={flowsToDisplay}
        fetchItems={fetchItems}
        hideTopPagination={true}
        onRowClick={onRowClick}
        pagination={pagination}
      />
      {currentFlow && currentFlow.flow_type === flowTypes.SCHEDULED && (
        <ScheduledFlowModal
          canRefreshSelectAllValues={currentFlow.params?.should_refresh_select_all_values ?? false}
          schedule={currentFlow}
          template={currentFlow.template}
          onScheduleSubmit={onUpdateScheduleSubmit}
          type="update"
          includePdf={includePdf}
          onPdfToggle={onPdfToggle}
          customFolder={customFolder}
          onCustomFolderUpdate={onCustomFolderUpdate}
        />
      )}
      <FlowsSidepane flow={currentFlow} onFlowDelete={onFlowDelete} onUpdateClick={onUpdateClick} />
    </div>
  );
}

FlowsList.propTypes = {
  baseUrl: PropTypes.string,
  flows: PropTypes.array,
  onFlowDelete: PropTypes.func,
  pagination: PropTypes.object,
  paginateContent: PropTypes.func,
};

export default WithLoadingAndEmpty(FlowsList);
