import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bulma-components';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactComponent as AnalyticsLogo } from '../../../svg/analytics.svg';
import { ReactComponent as DataSourcesLogo } from '../../../svg/data_sources.svg';
import { ReactComponent as ParamsLogo } from '../../../svg/params.svg';
import { ReactComponent as TemplatesLogo } from '../../../svg/templates.svg';
import { ReactComponent as PresentationsLogo } from '../../../svg/presentations.svg';
import { ReactComponent as FlowsLogo } from 'images/icons/arrow_clockwise-20x20-regular.svg';
import SharedUserNav from '../../shared/nav/SharedUserNav';
import logo from '../../../images/logo.svg';
import Constants from '../../Constants';
import Icon from '../../lib/Icon';

function ProducerNav({ activeTab }) {
  const LDClient = useLDClient();
  const areFlowsEnabled = LDClient?.variation('enable-jobs-tab', false) || false;
  return (
    <nav id="producer-navbar" className="navbar is-fixed-top">
      <Navbar.Brand className="matik-nav-brand">
        <Link className="navbar-item" to="/" id="nav-logo-link">
          <img src={logo} alt="Matik logo" />
        </Link>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container align="left" id="navbar-main">
          <Link to="/analytics" id="nav-analytics">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.ANALYTICS}>
              <div className={`is-flex navbar-container ${activeTab === 'analytics' ? '' : 'has-text-unselected'}`}>
                <AnalyticsLogo className="image" />
                <span>Analytics</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/data_sources" id="nav-data-sources">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.DATA_SOURCES}>
              <div className={`is-flex navbar-container ${activeTab === 'data_sources' ? '' : 'has-text-unselected'}`}>
                <DataSourcesLogo className="image" />
                <span>Data Sources</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/dynamic_content" id="nav-dynamic-content">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.DYNAMIC_CONTENT}>
              <div
                className={`is-flex navbar-container ${activeTab === 'dynamic_content' ? '' : 'has-text-unselected'}`}
              >
                <div className="mr-2 flex">
                  <Icon name="dynamic_content" />
                </div>
                <span>Dynamic Content</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/inputs" id="nav-inputs">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.INPUTS}>
              <div
                className={`is-flex navbar-container ${
                  activeTab === Constants.Tabs.INPUTS ? '' : 'has-text-unselected'
                }`}
              >
                <ParamsLogo className="image" />
                <span>Inputs</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/templates" id="nav-templates">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.TEMPLATES}>
              <div className={`is-flex navbar-container ${activeTab === 'templates' ? '' : 'has-text-unselected'}`}>
                <TemplatesLogo className="image" />
                <span>Templates</span>
              </div>
            </Navbar.Item>
          </Link>
          <Link to="/presentations" id="nav-presentations">
            <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.PRESENTATIONS}>
              <div className={`is-flex navbar-container ${activeTab === 'presentations' ? '' : 'has-text-unselected'}`}>
                <PresentationsLogo className="image" />
                <span>Presentations</span>
              </div>
            </Navbar.Item>
          </Link>
          {areFlowsEnabled && (
            <Link to="/flows" id="nav-flows">
              <Navbar.Item renderAs="span" active={activeTab === Constants.Tabs.FLOWS}>
                <div
                  className={`is-flex navbar-container ${
                    activeTab === Constants.Tabs.FLOWS ? '' : 'has-text-unselected'
                  }`}
                >
                  <FlowsLogo className="image" />
                  <span>Flows</span>
                </div>
              </Navbar.Item>
            </Link>
          )}
        </Navbar.Container>
        <SharedUserNav role={Constants.PRODUCER_ROLE} />
      </Navbar.Menu>
    </nav>
  );
}

ProducerNav.propTypes = {
  activeTab: PropTypes.string,
};

export default ProducerNav;
