import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApiFilters from './ApiFilters';
import ApiSorts from './ApiSorts';
import ApiReturnFields from './ApiReturnFields';
import LookerPivots from './LookerPivots';
import ApiLimit from './ApiLimit';
import LookerTimezone from './LookerTimezone';
import LookerApplyFormatting from './LookerApplyFormatting';
import LookerOutputScreenshot from './LookerOutputScreenshot';
import Constants from '../../../Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function LookerQueryForm({
  dynamicFields,
  entityType,
  fields,
  input,
  inputMapping,
  onInputMappingUpdate,
  inputsInQueryString,
  queryObj,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);

  const limit = !queryObj.query.limit ? '' : queryObj.query.limit.toString();

  return (
    <React.Fragment>
      <ApiFilters
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        queryObj={queryObj}
        fields={fields}
        inputs={dynamicContentContext.existingInputs}
        inputsInQueryString={inputsInQueryString}
        isReadOnly={dynamicContentContext.isReadOnly}
        apiSource="looker"
        isInputPopoverDisabled={entityType === 'input'}
      />
      <ApiSorts
        fields={fields}
        queryObj={queryObj}
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        isReadOnly={dynamicContentContext.isReadOnly}
      />
      <LookerPivots
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        queryObj={queryObj}
        fields={fields}
        isReadOnly={dynamicContentContext.isReadOnly}
      />
      <ApiLimit
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        queryObj={queryObj}
        limit={limit}
        isReadOnly={dynamicContentContext.isReadOnly}
      />
      <LookerTimezone
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        queryObj={queryObj}
        timezone={queryObj.query.query_timezone}
        isReadOnly={dynamicContentContext.isReadOnly}
      />
      <ApiReturnFields
        queryObj={queryObj}
        fields={fields}
        dynamicFields={dynamicFields}
        entityType={entityType}
        input={input}
        inputMapping={inputMapping}
        onInputMappingUpdate={onInputMappingUpdate}
      />
      <LookerApplyFormatting
        queryObj={queryObj}
        updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
        isReadOnly={dynamicContentContext.isReadOnly}
      />
      {dynamicContentContext.dynamicContentType === Constants.DynamicContentTypes.IMAGE && (
        <LookerOutputScreenshot
          queryObj={queryObj}
          updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
          isReadOnly={dynamicContentContext.isReadOnly}
        />
      )}
    </React.Fragment>
  );
}

LookerQueryForm.contextType = DynamicContentContext;

LookerQueryForm.propTypes = {
  apiInfo: PropTypes.object,
  dynamicFields: PropTypes.array,
  fields: PropTypes.array,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
};

export default LookerQueryForm;
