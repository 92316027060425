import React, { useContext, useState } from 'react';
import { Auth } from '../../../lib/auth';
import { Navbar } from 'react-bulma-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { ReactComponent as NavigationHelp } from '../../../svg/navigation_help.svg';
import Constants from '../../Constants';
import UserAvatar from 'react-user-avatar';
import { UserContext } from '../../UserContext';
import PlanInfoTag from '../../producer/nav/PlanInfoTag';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import ProducerNavPlanInfo from '../../producer/nav/ProducerNavPlanInfo';
import NotificationCenter from '../notifications/NotificationCenter';
import HelpMenu from './HelpMenu';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Icon from 'components/lib/Icon';
function SharedUserNav(props) {
  const { user } = useContext(UserContext);
  const [showHelp, setShowHelp] = useState(false);
  const LDClient = useLDClient();
  const match = useRouteMatch();

  const onLogoutClick = (e) => {
    e.preventDefault();
    Auth.logOut(() => window.location.reload());
  };

  const avatarSrc = user && user.photo_url ? user.photo_url : '';
  const isProducerNavAndIsProducerAdmin =
    props.role === Constants.PRODUCER_ROLE && Auth.hasRole(Constants.PRODUCER_ADMIN_ROLE);
  const showProducerNavPlanInfo = props.flags.enableSelfServeNavbarItems && isProducerNavAndIsProducerAdmin;
  const showUnifiedNav = LDClient?.variation('show-admin-generate-tab', false) || false;
  const showNewEndUserToggle = showUnifiedNav && Auth.hasRole(Constants.PRODUCER_ADMIN_ROLE);

  const toggleHelp = (e, bool) => {
    e.preventDefault();
    setShowHelp(bool);
  };

  const showAdminView = match.path.indexOf('create') === -1;
  const userDropdown = (
    <div className="border-l border-grey-300 flex px-4 min-w-40 justify-between items-center">
      <UserAvatar
        colors={Constants.AVATAR_COLORS}
        src={avatarSrc}
        name={user?.name ? user?.name : user?.email}
        size="35"
      />
      <div className="flex flex-col">
        <span className="text-matik-black text-sm">{user?.name ? user?.name : user?.email}</span>
        <span className="text-grey-600 text-xs">{showAdminView ? 'Admin' : 'End User'}</span>
      </div>
      <Icon name="three_dots" size={20} theme="regular" color="matik-black" />
    </div>
  );

  return (
    <Navbar.Container align="right">
      {props.role === Constants.PRODUCER_ROLE && user && <PlanInfoTag user={user} />}
      {user?.roles?.length > 1 && !showUnifiedNav && (
        <div className="navbar-item prn">
          <a
            className={props.role === Constants.PRODUCER_ROLE ? 'button navbar-selected phm' : 'navbar-unselected phm'}
            href="/"
            id="nav-admin-admin"
          >
            Admin
          </a>
          <a
            className={props.role === Constants.CONSUMER_ROLE ? 'button navbar-selected phm' : 'navbar-unselected phm'}
            href="/create"
            id="nav-admin-end-user"
          >
            End User
          </a>
        </div>
      )}
      <Navbar.Item renderAs="div" className="has-dropdown">
        <Navbar.Link className="navbar-unselected prs plm" arrowless={true} onClick={(e) => toggleHelp(e, true)}>
          <NavigationHelp className="image" />
        </Navbar.Link>
        {showHelp && <HelpMenu show={showHelp} toggleHelp={toggleHelp} />}
      </Navbar.Item>
      <Navbar.Item renderAs="div" className="has-dropdown">
        {user && <NotificationCenter role={props.role} user={user} />}
      </Navbar.Item>
      <Navbar.Item className="is-hoverable navbar-avatar">
        <Navbar.Link className="navbar-white-hover" arrowless={true}>
          {user && showNewEndUserToggle ? (
            userDropdown
          ) : user ? (
            <UserAvatar
              colors={Constants.AVATAR_COLORS}
              src={avatarSrc}
              name={user.name ? user.name : user.email}
              size="35"
            />
          ) : null}
        </Navbar.Link>
        <Navbar.Dropdown className="is-right">
          <div className="pbs vertical-center border-bottom">
            <Link
              to={props.role === Constants.PRODUCER_ROLE ? '/user_profile' : '/create/user_profile'}
              className="has-text-dark navbar-item full-width"
              id="nav-admin-profile"
            >
              <Icon name="person" size={20} theme="regular" />
              <span className="plm">Account Settings</span>
            </Link>
          </div>
          {isProducerNavAndIsProducerAdmin && (
            <div className="pbs pts vertical-center border-bottom">
              <Link
                to="/enterprise_settings"
                className="has-text-dark navbar-item full-width"
                id="nav-enterprise-settings"
              >
                <Icon name="briefcase" size={20} theme="regular" />
                <span className="plm">Enterprise Settings</span>
              </Link>
            </div>
          )}
          {showProducerNavPlanInfo && <ProducerNavPlanInfo />}
          {showNewEndUserToggle && (
            <div className="pbs vertical-center border-bottom">
              <Link
                to={showAdminView ? '/create' : '/'}
                className="has-text-dark navbar-item full-width"
                id="nav-end-user-toggle"
              >
                <Icon name="eye" size={20} theme="regular" />
                <span className="plm">{showAdminView ? 'End User View' : 'Admin View'}</span>
              </Link>
            </div>
          )}
          <div className="pts vertical-center">
            <Navbar.Item href="#" onClick={onLogoutClick} className="has-text-dark full-width">
              <Icon name="arrow_circle_right" size={20} theme="regular" />
              <span className="plm">Logout</span>
            </Navbar.Item>
          </div>
        </Navbar.Dropdown>
      </Navbar.Item>
    </Navbar.Container>
  );
}

SharedUserNav.propTypes = {
  flags: PropTypes.object,
  role: PropTypes.string,
};

export default withLDConsumer()(SharedUserNav);
