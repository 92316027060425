import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';
import { apiInfoByDataSourceId } from './redux/dataSources/reducers';
import { notifications } from './redux/notifications/reducers';
import { inputValuesByEntity } from './redux/inputs/reducers';
import { fetchedAnalyticsUsers, fetchedEnterpriseUsers } from './redux/users/reducers';
import thunkMiddleware from 'redux-thunk';
import { groups } from './redux/groups/reducers';
import { libraries } from './redux/libraries/reducers';
import { ui } from './redux/ui/reducers';

export default (adminAuthProvider, adminDataProvider, history) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    apiInfoByDataSourceId,
    notifications,
    inputValuesByEntity,
    fetchedAnalyticsUsers,
    fetchedEnterpriseUsers,
    groups,
    libraries,
    ui,
  });
  const resettableAppReducer = (state, action) => reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(adminDataProvider, adminAuthProvider),
        // add your own sagas here
      ].map(fork),
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    // eslint-disable-next-line no-undef
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const middlewares = [thunkMiddleware];
  // eslint-disable-next-line no-undef

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(sagaMiddleware, routerMiddleware(history), ...middlewares),
      // add your own enhancers here
    ),
  );
  sagaMiddleware.run(saga);
  return store;
};
