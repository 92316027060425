import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import { capitalize, isEqual } from 'lodash';
import Constants from '../../../Constants';
import SalesforceApplyFormatting from './SalesforceApplyFormatting';
import InputMapping from '../InputMapping';
import info_icon from '../../../../images/info_icon.svg';
import dataSources from '../../../../lib/dataSources';
import { connect } from 'react-redux';
import { mapApiInfoStateToProps } from '../../../../redux/dataSources/stateMappers';
import { mapDispatchToProps } from '../../../../redux/dataSources/dispatchers';
import API from '../../../../lib/api';
import Button from '../../../lib/Button';
import { MAlert } from '../../../shared/Alerts';
import SenderApiReturnFields from './SenderApiReturnFields';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import { components } from 'react-select';

class CRMObjectsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnFields: [],
      showFormFields: false,
      searchObjectOnly: false,
      searchValue: this.props.queryObj.selectedObject || '',
    };
  }

  componentDidMount() {
    if (
      this.props.apiInfo.objects &&
      this.props.queryObj.selectedObject &&
      this.props.apiInfo.objects[this.props.queryObj.selectedObject]
    ) {
      const returnFields = dataSources.buildCRMReturnFields(
        this.props.apiInfo,
        'objects',
        this.props.queryObj.selectedObject,
        'fields',
      );
      this.setState({ showFormFields: true, returnFields });
    }
    if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      if (this.props.objectCount && this.props.objectCount > Constants.MAX_SALESFORCE_REPORTS) {
        this.setState({ searchObjectOnly: true });
      }
      if (this.props.queryObj.selectedObject && !this.props.apiInfo.objects) {
        this.getObjectDetailsFromSalesforceSearch(this.props.queryObj.selectedObject, false);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.apiInfo, this.props.apiInfo) ||
      prevProps.queryObj?.selectedObject !== this.props.queryObj?.selectedObject
    ) {
      if (
        this.props.apiInfo.objects &&
        this.props.queryObj?.selectedObject &&
        this.props.apiInfo.objects[this.props.queryObj.selectedObject]
      ) {
        const returnFields = dataSources.buildCRMReturnFields(
          this.props.apiInfo,
          'objects',
          this.props.queryObj.selectedObject,
          'fields',
        );
        this.setState({ returnFields });
      }
    }
    if (!isEqual(prevProps.apiInfo, this.props.apiInfo) || !isEqual(prevProps.queryObj, this.props.queryObj)) {
      if (
        this.props.apiInfo.objects &&
        this.props.queryObj?.selectedObject &&
        this.props.apiInfo.objects[this.props.queryObj.selectedObject]
      ) {
        this.setState({ showFormFields: true });
      }

      if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
        if (this.props.objectCount && this.props.objectCount > Constants.MAX_SALESFORCE_REPORTS) {
          this.setState({ searchObjectOnly: true });
        }
        if (this.props.queryObj.selectedObject && !this.props.apiInfo.objects) {
          this.getObjectDetailsFromSalesforceSearch(this.props.queryObj.selectedObject, false);
        }
      }
    }
  }

  render() {
    const objectOptions = this.props.apiInfo.objects
      ? Object.keys(this.props.apiInfo.objects).map((objectName) => ({ value: objectName, label: objectName }))
      : [];

    let objectValue = null;
    if (this.props.queryObj.selectedObject) {
      objectValue = { label: this.props.queryObj.selectedObject, value: this.props.queryObj.selectedObject };
    }
    let dataSourceType = this.props.dataSource.type || '';

    return (
      <div>
        {this.props.source === 'objects' && (
          <React.Fragment>
            <Form.Field className="mbl">
              <Form.Label>
                {this.state.searchObjectOnly
                  ? `Enter ${capitalize(dataSourceType)} Object Name`
                  : `Select ${capitalize(dataSourceType)} Object`}
              </Form.Label>
              <Form.Control>
                {this.state.searchObjectOnly ? (
                  <div className="salesforce-search-container">
                    <Form.Input
                      type="text"
                      className="salesforce-search"
                      value={this.state.searchValue}
                      onChange={(e) => this.handleSearchInputChange(e)}
                      name="selectedObject"
                      placeholder="Enter Salesforce Object Name"
                      aria-label="Salesforce Object Name"
                    />
                    <Button
                      id="search-salesforce-object"
                      onClick={this.handleSearchButtonClick}
                      status={!this.state.searchValue ? 'disabled' : 'default'}
                    >
                      Find Object
                    </Button>
                  </div>
                ) : (
                  <Select
                    aria-label={`Select ${capitalize(dataSourceType)} Object`}
                    classNamePrefix="matik-select"
                    className="matik-select-container"
                    isDisabled={this.context.isReadOnly}
                    value={objectValue}
                    name="selectedObject"
                    onChange={(obj, action) => this.selectObject(obj, action)}
                    placeholder={`Select ${capitalize(dataSourceType)} Object`}
                    options={objectOptions}
                    isLoading={!objectOptions.length}
                    menuPortalTarget={this.props.formRef?.current}
                  ></Select>
                )}
              </Form.Control>
              {this.props.queryObj.selectedObject &&
                this.props.apiInfo.objects &&
                !this.props.apiInfo.objects[this.props.queryObj.selectedObject] && (
                  <Form.Help color="danger">
                    Selected Object &quot;{this.props.queryObj.selectedObject}&quot; missing in{' '}
                    {capitalize(dataSourceType)}
                  </Form.Help>
                )}
            </Form.Field>
            {this.state.showFormFields && (
              <React.Fragment>
                {this.renderReturnFields(this.props.queryObj)}
                {this.renderFilters(this.props.queryObj)}
                {this.renderOrderLimit(this.props.queryObj)}
                {this.props.renderUniqueValuesFilter()}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  handleSearchInputChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearchButtonClick = (e) => {
    e.preventDefault();
    this.getObjectDetailsFromSalesforceSearch(this.state.searchValue, true);
  };

  getObjectDetailsFromSalesforceSearch = (queryText, updateQueryObj, updateFilterObj = null) => {
    if (typeof queryText !== 'string' || queryText.length > Constants.SALESFORCE_REPORT_CHARACTER_LIMIT) {
      throw new Error('Invalid input');
    }

    this.props.setIsFetching(this.props.dataSource.id, true);
    API.get(
      `/data_sources/${this.props.dataSource.id}/search/Object/${encodeURIComponent(queryText)}/`,
      (response) => {
        if (updateQueryObj) {
          const objectDetails = response.data;
          const queryObj = Object.assign({}, this.props.queryObj);
          queryObj.selectedObject = Object.keys(objectDetails)[0];
          queryObj.selectedReport = null;
          queryObj.returnFieldsByName = {};

          queryObj.filters = [];
          queryObj.orderByField = null;
          this.context.onQueryStringUpdate(JSON.stringify(queryObj));
        }
        response.data = { ...response.data, ...this.props.apiInfo.objects };
        this.props.updateApiInfoWithObject(this.props.dataSource.id, response);

        if (updateFilterObj) {
          this.updateFilterObject(
            updateFilterObj.filterObject,
            updateFilterObj.idx,
            updateFilterObj.filters,
            response.data,
          );
        }
      },
      (error) => {
        this.props.setIsFetching(this.props.dataSource.id, false);
        if (!window.location.pathname.includes('new')) {
          const queryObj = Object.assign({}, this.props.queryObj);
          queryObj.selectedObject = null;
          queryObj.returnFieldsByName = {};
          queryObj.filters = [];
          queryObj.orderByField = null;
          this.context.onQueryStringUpdate(JSON.stringify(queryObj));
          MAlert('The selected object was not found in Salesforce.  Resetting fields.', 'Object Not Found', 'warning');
        } else {
          API.defaultError(error);
        }
      },
    );
  };

  selectObject = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj.selectedObject = value;
      updatedQueryObj.selectedReport = null;
      updatedQueryObj.selectedReportMetadata = null;
      updatedQueryObj.selectedReportExtendedMetadata = null;
      updatedQueryObj.returnFieldsByName = {};
      updatedQueryObj.filters = [];
      updatedQueryObj.orderByField = null;
      if (!updatedQueryObj.source) {
        updatedQueryObj.source = JSON.parse(this.props.queryObj.query_string).source;
      }

      this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
    }
  };

  defaultSortField(queryObj) {
    let field = {};

    if (queryObj.orderByObject && this.props.apiInfo?.objects?.[queryObj.orderByObject[1][0]]) {
      field = this.props.apiInfo.objects[queryObj.orderByObject[1][0]]['fields'][0];
    } else if (queryObj.selectedObject && this.props.apiInfo?.objects?.[queryObj.selectedObject]) {
      field = this.props.apiInfo.objects[queryObj.selectedObject]['fields'][0];
    }

    return { label: field.name, value: field.name };
  }

  renderReturnFields(queryObj) {
    const returnFieldsByName = queryObj.returnFieldsByName || {};

    const returnFieldsArray =
      Object.keys(returnFieldsByName).length > 0
        ? Object.keys(returnFieldsByName)
            .filter((fieldName) => returnFieldsByName[fieldName])
            .map((fieldName) =>
              dataSources.createReturnFieldLabel(
                queryObj,
                fieldName,
                this.state.returnFields,
                this.props.toggleRenameModal,
              ),
            )
        : [];

    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      return (
        <SenderApiReturnFields
          formRef={this.props.formRef}
          options={this.returnFieldGroups(queryObj, false)}
          onReturnFieldChange={this.onReturnFieldChange}
          queryObj={queryObj}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Form.Field className="mbl">
            <Form.Label>Fields To Return</Form.Label>
            <Form.Help>Select field(s) to return</Form.Help>
            <Form.Control>
              <Select
                aria-label="Select Return Fields"
                classNamePrefix="matik-select"
                value={returnFieldsArray}
                isMulti={true}
                isRenamable={true}
                options={this.returnFieldGroups(queryObj, false)}
                onChange={(obj, action) => this.onReturnFieldChange(obj, action, queryObj)}
                isDisabled={this.context.isReadOnly}
                menuPortalTarget={this.props.formRef?.current}
                componentsToAdd={{ Option: components.Option, ValueContainer: components.ValueContainer }}
              />
            </Form.Control>
            {this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce && (
              <Form.Control>
                <SalesforceApplyFormatting
                  isReadOnly={this.context.isReadOnly}
                  queryObj={queryObj}
                  updateQueryObj={this.context.onQueryStringUpdate}
                  entityType={this.props.entityType}
                />
              </Form.Control>
            )}
          </Form.Field>
          {this.props.entityType && this.props.entityType === 'input' && (
            <InputMapping
              queryObj={queryObj}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              input={this.props.input}
              inputMapping={this.props?.displayNameUpdate ? {} : this.props.inputMapping}
              returnFieldsArray={returnFieldsArray}
            />
          )}
        </React.Fragment>
      );
    }
  }

  returnFieldGroups = (queryObj) => {
    const fieldGroups = [];
    if (!this.props?.apiInfo?.objects?.[queryObj.selectedObject]) {
      return fieldGroups;
    }
    fieldGroups.push({
      type: 'group',
      label: queryObj.selectedObject,
      options: this.props.apiInfo.objects[queryObj.selectedObject]['fields'].map((field) => {
        const fieldName = field.name;
        return { label: field.label, value: fieldName };
      }),
    });
    if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce) {
      this.props.apiInfo.objects[queryObj.selectedObject]['relationships'].forEach((relationship) => {
        if (!relationship[1] || relationship[1].length === 0) {
          return null;
        }
        let relationshipOptions = [];
        if (this.props.apiInfo.objects[relationship[1][0]]?.fields) {
          relationshipOptions = this.props.apiInfo.objects[relationship[1][0]]['fields'].map((field) => {
            const fieldName = field.name;
            const qualifiedFieldName = `${relationship[0]}.${fieldName}`;
            return { label: field.label, value: qualifiedFieldName };
          });
        }
        if (relationship[1].length > 1 && this.props.apiInfo.objects[relationship[1][1]]?.fields) {
          relationshipOptions = relationshipOptions.concat(
            this.props.apiInfo.objects[relationship[1][1]]['fields'].map((field) => {
              const fieldName = field.name;
              const qualifiedFieldName = `${relationship[0]}.${fieldName}`;
              return { label: field.label, value: qualifiedFieldName };
            }),
          );
        }
        if (relationshipOptions.length > 0) {
          fieldGroups.push({ type: 'group', label: relationship[0], options: relationshipOptions });
        }
      });
    }

    return fieldGroups;
  };

  onReturnFieldChange = async (obj, action, queryObj) => {
    const updatedQueryObj = Object.assign({}, queryObj) || {};
    if (this.context.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      await this.context.onSenderFieldUpdate(action.name, obj.value);
      const senderReturnFields = new Set(Object.values(this.context.multiFieldMapping));
      updatedQueryObj.returnFieldsByName = {};
      senderReturnFields.forEach((field) => (updatedQueryObj.returnFieldsByName[field] = true));
    } else {
      if (action.action === 'select-option') {
        updatedQueryObj.returnFieldsByName[action.option.value] = true;
      } else if (action.action === 'select-all-options') {
        const entries = action.option.map((option) => [option.value, true]);
        updatedQueryObj.returnFieldsByName = Object.fromEntries(entries);
      } else if (action.action === 'remove-value') {
        // Javascript is weird and while objects properties don't have order they kinda do -
        // By deleting the key and re-adding it with the value false we reorder the property in the object
        delete updatedQueryObj.returnFieldsByName[action.removedValue.value];
        updatedQueryObj.returnFieldsByName[action.removedValue.value] = false;
        if (updatedQueryObj.returnFieldMapping && action.removedValue.value in updatedQueryObj.returnFieldMapping) {
          delete updatedQueryObj.returnFieldMapping[action.removedValue.value].displayName;
        }
      } else if (action.action === 'clear') {
        updatedQueryObj.returnFieldsByName = {};
      }
    }
    this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
  };

  renderOrderLimit = (queryObj) => {
    let orderOptions = [];
    if (!this.props?.apiInfo?.objects?.[queryObj.selectedObject]) {
      return orderOptions;
    }
    const orderOptionFields =
      this.props.apiInfo.objects[queryObj.orderByObject ? queryObj.orderByObject[1][0] : queryObj.selectedObject]?.[
        'fields'
      ];

    if (Array.isArray(orderOptionFields)) {
      orderOptions = orderOptionFields.map((field) => ({ label: field.label, value: field.name }));
    }

    let options =
      this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
        ? [{ value: queryObj.selectedObject, label: queryObj.selectedObject }].concat(
            this.props.apiInfo.objects[queryObj.selectedObject]['relationships'].map((relationship) => ({
              value: relationship[0],
              label: relationship[0],
            })),
          )
        : [{ value: queryObj.selectedObject, label: queryObj.selectedObject }];

    const orderByObjectField = (
      <Form.Control style={{ flex: '3 1' }}>
        <Select
          value={
            queryObj.orderByObject
              ? { label: queryObj.orderByObject[0], value: queryObj.orderByObject[0] }
              : { label: queryObj.selectedObject, value: queryObj.selectedObject }
          }
          classNamePrefix="matik-select"
          onChange={(obj, action) => this.onOrderByObjectChange(obj, action, queryObj)}
          aria-label={`Select ${capitalize(this.props.dataSource.type)} Object to Order By`}
          isDisabled={this.context.isReadOnly}
          options={options}
          menuPortalTarget={this.props.formRef?.current}
        />
      </Form.Control>
    );

    const helpText = 'Select field to order by.';

    return this.props.renderOrderLimit(
      queryObj,
      orderOptions,
      orderByObjectField,
      null,
      helpText,
      this.defaultSortField(queryObj),
      this.context.isReadOnly,
      this.onSortChange,
    );
  };

  renderFilters = (queryObj) => {
    const queryFilters = queryObj.filters || [];
    let defaultFilterArg = this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.hubspot ? 'equal' : '=';
    let body = (
      <a href="#dummy" onClick={(e) => this.props.addFilter(e, queryObj, defaultFilterArg)}>
        Add Filter
      </a>
    );
    if (queryFilters.length > 0) {
      let options =
        this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
          ? [{ value: queryObj.selectedObject, label: queryObj.selectedObject }].concat(
              this.props.apiInfo.objects[queryObj.selectedObject]['relationships'].map((relationship) => ({
                value: relationship[0],
                label: relationship[0],
              })),
            )
          : [{ value: queryObj.selectedObject, label: queryObj.selectedObject }];

      body = queryFilters.map((filter, idx) => {
        const objectFilter = (
          <Form.Control style={{ flex: '3 1' }}>
            <Select
              aria-label={`Select Filter ${capitalize(this.props.dataSource.type)} Object`}
              classNamePrefix="matik-select"
              isDisabled={this.context.isReadOnly}
              value={
                filter['object'] && filter['object'][0]
                  ? { value: filter['object'][0], label: filter['object'][0] }
                  : { value: queryObj.selectedObject, label: queryObj.selectedObject }
              }
              name="filterObject"
              onChange={(obj, action) => this.onFilterObjectChange(obj, action, queryObj, idx)}
              options={options}
              menuPortalTarget={this.props.formRef?.current}
            ></Select>
          </Form.Control>
        );

        if (!filter['operator']) {
          filter['operator'] = '=';
        }

        let operators =
          this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.salesforce
            ? Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.salesforce_objects
            : Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.hubspot_objects;

        return this.props.renderFilter(
          idx,
          queryObj,
          'fields',
          filter,
          operators,
          objectFilter,
          this.defaultFilterField(queryObj),
          this.filterOptions(filter['object'], queryObj),
          this.context.isReadOnly,
          this.context.existingInputs,
        );
      });
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Filters</Form.Label>
          <Form.Help>
            Select fields and inputs to filter by.
            <span
              className="mls filter-hint-tooltip"
              data-tooltip-id="matik-tooltip"
              data-tooltip-html="Hint: Create & insert inputs using &:&lt;input_name&gt;"
              data-tooltip-place="right"
            >
              <img src={info_icon} alt="Filter Info" width="14px" className="icon-pull-down" />
            </span>
          </Form.Help>
          <Form.Control>{body}</Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  filterOptions = (filterObject, queryObj) => {
    const primaryObjKey = filterObject && filterObject[1] ? filterObject[1][0] : queryObj.selectedObject;
    const primaryObj = this.props.apiInfo.objects[primaryObjKey] ?? {};

    const fields = (primaryObj?.fields ?? []).map((field) => ({
      value: `${field.name}_${field.type}`,
      label: field.label,
    }));

    let relationshipFields = [];
    if (filterObject && filterObject[1] && filterObject[1].length > 1) {
      const relationshipObjKey = filterObject[1][1];
      const relationshipObj = this.props.apiInfo.objects[relationshipObjKey] ?? {};

      relationshipFields = (relationshipObj?.fields ?? []).map((field) => ({
        value: `${field.name}_${field.type}`,
        label: field.label,
      }));
    }

    return fields.concat(relationshipFields);
  };

  defaultFilterField = (queryObj) => {
    let defaultField = null;
    if (
      queryObj.selectedObject &&
      this.props.apiInfo?.objects?.[queryObj.selectedObject] &&
      this.props.apiInfo.objects[queryObj.selectedObject]['fields']
    ) {
      const field = this.props.apiInfo.objects[queryObj.selectedObject]['fields'][0];
      defaultField = { value: `${field.name}_${field.type}`, label: field.label };
    }

    return defaultField;
  };

  onOrderByObjectChange = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      const orderByObjectName = obj.value;
      let orderByObject;
      if (this.props.queryObj.selectedObject === orderByObjectName) {
        orderByObject = [orderByObjectName, [orderByObjectName]];
      } else {
        orderByObject = this.props.apiInfo.objects[updatedQueryObj.selectedObject]['relationships'].filter(
          (relationship) => relationship[0] === orderByObjectName,
        )[0];
      }
      const orderByField = orderByObject
        ? this.props.apiInfo.objects[orderByObject[1][0]]['fields'][0].name
        : this.props.apiInfo.objects[updatedQueryObj.selectedObject]['fields'][0].name;
      updatedQueryObj.orderByObject = orderByObject;
      updatedQueryObj.orderByField = orderByField;

      if (orderByObject && !this.props.apiInfo.objects[orderByObject[1][0]]) {
        this.getObjectDetailsFromSalesforceSearch(orderByObject[1][0], false, updatedQueryObj);
      } else {
        this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
      }
    }
  };

  onFilterObjectChange = (obj, action, queryObj, idx) => {
    if (action.action === 'select-option') {
      const filterObjectName = obj.value;
      let filterObject;
      if (queryObj.selectedObject === obj.value) {
        filterObject = [filterObjectName, [filterObjectName]];
      } else {
        filterObject = this.props.apiInfo.objects[queryObj.selectedObject]['relationships'].filter(
          (relationship) => relationship[0] === filterObjectName,
        )[0];
      }

      const filters = queryObj.filters || [];
      if (filterObject && !this.props.apiInfo.objects[filterObject[1][0]]) {
        let updateFilterObj = {
          filterObject: filterObject,
          idx: idx,
          filters: filters,
        };
        this.getObjectDetailsFromSalesforceSearch(filterObject[1][0], false, updateFilterObj);
      } else {
        this.updateFilterObject(filterObject, idx, filters, this.props.apiInfo.objects);
      }
    }
  };

  updateFilterObject = (filterObject, idx, filters, crmObjects) => {
    const updatedQueryObj = Object.assign({}, this.props.queryObj);
    const filterFields =
      filterObject && crmObjects[filterObject[1][0]]
        ? crmObjects[filterObject[1][0]]['fields']
        : crmObjects[this.props.queryObj.selectedObject]['fields'];
    const fieldIdx = idx < filters.length ? filterFields.findIndex((field) => field.name === filters[idx].field) : -1;
    const fieldObj = fieldIdx > -1 ? filterFields[fieldIdx] : filterFields[0];
    const field = fieldObj.name;
    const filterType = fieldObj.type;
    if (idx === filters.length) {
      filters.push({ object: filterObject, field: field, value: '', type: filterType });
    } else {
      filters[idx].object = filterObject;
      filters[idx].field = field;
      filters[idx].type = filterType;
    }
    updatedQueryObj.filters = filters;
    this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
  };

  onSortChange = (obj, action, name) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, this.props.queryObj);
      updatedQueryObj[name] = obj.value;

      this.context.onQueryStringUpdate(JSON.stringify(updatedQueryObj));
    }
  };
}

CRMObjectsForm.contextType = DynamicContentContext;

CRMObjectsForm.propTypes = {
  apiInfo: PropTypes.object,
  selectedObject: PropTypes.string,
  queryObj: PropTypes.object,
  updateQuery: PropTypes.func,
  selectSalesforceSourceApi: PropTypes.func,
  renderOrderLimit: PropTypes.func,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  renderFilter: PropTypes.func,
  source: PropTypes.any,
  onFilterFieldChange: PropTypes.func,
  onFilterInputChange: PropTypes.func,
  toggleRenameModal: PropTypes.func,
  onLimitChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onOperatorChange: PropTypes.func,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMappingOptions: PropTypes.array,
  onInputMappingUpdate: PropTypes.func,
  inputMapping: PropTypes.object,
  dataSource: PropTypes.object,
  displayNameUpdate: PropTypes.bool,
  formRef: PropTypes.object,
  objectCount: PropTypes.number,
  setIsFetching: PropTypes.func,
  updateApiInfoWithObject: PropTypes.func,
  renderUniqueValuesFilter: PropTypes.func,
};

export default connect(mapApiInfoStateToProps, mapDispatchToProps)(CRMObjectsForm);
