import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import ListItemDynamicContent from './ListItemDynamicContent';
import { Select } from 'components/shared/FormSelect';
import { components } from 'react-select';
import Constants from '../../Constants';
import Button from '../../lib/Button';
import Icon from '../../lib/Icon';

const InputDynamicContent = ({
  acceptedContentTypes,
  content,
  isLoading,
  allDynamicContentNamesById,
  onChange,
  showSubtitle,
  onEditClick,
}) => {
  if (isLoading) {
    return <Skeleton height="2.25rem" />;
  }
  const handleDynamicContentChange = (value) => onChange(value.dynamicContent);

  const allDynamicContent = Object.values(allDynamicContentNamesById || []);

  // sort such that content matching dcTypes is shown first
  let sortedDynamicContent = allDynamicContent.sort((contentA, contentB) => {
    const contentAMatchesType = acceptedContentTypes.includes(contentA.dynamic_content_type);
    const contentBMatchesType = acceptedContentTypes.includes(contentB.dynamic_content_type);
    if (contentAMatchesType && !contentBMatchesType) {
      return -1;
    }
    if (!contentAMatchesType && contentBMatchesType) {
      return 1;
    }
    return 0;
  });

  sortedDynamicContent = sortedDynamicContent
    .filter((dc) => !content || dc.id !== content.id)
    .map((dc) => ({
      dynamicContent: dc,
      label: dc.name,
      value: dc.id,
      isDisabled: !acceptedContentTypes.includes(dc.dynamic_content_type),
    }));

  const IconOption = (optionProps) => {
    const subtitle =
      Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[optionProps.data.dynamicContent?.dynamic_content_method]?.text;
    return (
      <components.Option {...optionProps}>
        <MemoListItemDynamicContent
          content={optionProps.data.dynamicContent}
          subtitle={subtitle}
          isSubtitleInline={true}
          size="s"
          status={optionProps.isDisabled ? 'disabled' : 'default'}
        />
      </components.Option>
    );
  };

  const ContentValue = ({ ...controlProps }) => {
    const subtitle = Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[content?.dynamic_content_method]?.text;
    const metadata = (
      <div className="flex items-center">
        {onEditClick && (
          <Button
            category="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              onEditClick(content);
            }}
          >
            <Icon name="pencil" size={20} />
          </Button>
        )}
      </div>
    );
    return (
      <div
        className="col-start-1 col-span-2 row-span-full truncate flex flex-row items-center justify-between -mr-4 ml-2"
        onClick={() =>
          controlProps.selectProps.menuIsOpen
            ? controlProps.selectProps.onMenuClose()
            : controlProps.selectProps.onMenuOpen()
        }
      >
        {content && Object.keys(content).length !== 0 ? (
          <MemoListItemDynamicContent
            content={content}
            subtitle={showSubtitle ? subtitle : undefined}
            isSubtitleInline={true}
            metadata={metadata}
          />
        ) : (
          <p className="text-grey-600 ml-2">Select Dynamic Content</p>
        )}
      </div>
    );
  };

  return (
    <Select
      key={content ? content.name : 0}
      value={content ? { label: content.name, value: content.value } : undefined}
      onChange={handleDynamicContentChange}
      componentsToAdd={{ Option: IconOption, SingleValue: ContentValue }}
      classNamePrefix="matik-select"
      options={sortedDynamicContent}
      placeholder="Select Dynamic Content"
    ></Select>
  );
};
InputDynamicContent.propTypes = {
  acceptedContentTypes: PropTypes.array,
  allDynamicContentNamesById: PropTypes.object,
  content: ListItemDynamicContent.propTypes.content,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  showSubtitle: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default InputDynamicContent;

const MemoListItemDynamicContent = memo(ListItemDynamicContent, (prev, next) => {
  // Restricting equality check to only those elements of `content` directly referenced by ListItemDynamicContent
  // (query_obj can be huge and we don't care if anything changes except query_obj.data_source.type).
  return (
    prev.content?.name === next.content?.name &&
    prev.content?.description === next.content?.description &&
    prev.content?.dynamic_content_type === next.content?.dynamic_content_type &&
    prev.content?.dynamic_content_method === next.content?.dynamic_content_method &&
    prev.content?.query_obj?.data_source?.type === next.content?.query_obj?.data_source?.type
  );
});
