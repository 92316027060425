import React from 'react';
import UserAvatar from 'react-user-avatar';
import AccessManager from '../../lib/AccessManager';
import MultiAvatar from './MultiAvatar';
import Tag from '../lib/Tag';
import CheckboxWithLabel from './CheckboxWithLabel';
import Constants from '../Constants';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';

const ListShareCellFactory = (accessesByItemId, showAccessModal, user, size = 50) => {
  const ListShareCell = (row) => {
    if (accessesByItemId === undefined || !user) {
      return <span>Loading...</span>;
    }
    const accessObj = new AccessManager(row.original.id, accessesByItemId, user);
    if (!accessObj.isShared()) {
      return (
        <span style={{ color: 'red' }} className="mtm is-flex">
          Private
        </span>
      );
    }
    const content = (
      <MultiAvatar
        avatars={accessObj.bigListSharedColumnAvatars()}
        title={accessObj.bigListSharedColumnText()}
        size={size}
      />
    );
    return (
      <a href="#dummy" onClick={(e) => showAccessModal(e, row.original, accessObj)}>
        {content}
      </a>
    );
  };
  return ListShareCell;
};

const RoleCell = (row) => {
  if (!row.original.roles) {
    return '--';
  }
  return (
    <div className="flex gap-2 flex-wrap">
      {row.original.roles.map((role) => {
        return <Tag key={role} label={Constants.ROLE_DISPLAY_MAPPING[role]} />;
      })}
    </div>
  );
};

const GroupCellFactory = (groupIdsToNames) => {
  const GroupCell = (row) => {
    if (!row.original.group_ids) {
      return '--';
    }
    return (
      <div className="flex gap-2 flex-wrap">
        {groupIdsToNames(row.original.group_ids).map((groupName) => {
          return <Tag key={groupName} label={groupName} />;
        })}
      </div>
    );
  };
  return GroupCell;
};

const NameCell = (row) => {
  return (
    <span className="is-flex is-vertical-centered pvs">
      <UserAvatar
        colors={Constants.AVATAR_COLORS}
        size={32}
        name={row.value}
        src={row.original.photo_url}
        className="mrm has-text-white"
      />
      {row.value}
    </span>
  );
};

const DynamicContentTypeCell = (row) => (
  <span className="is-flex is-vertical-centered">
    <img
      src={
        Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[row.value]
          ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[row.value].icon
          : null
      }
      width="32px"
      alt="Dynamic Content Type"
      className="mrm"
    />
    {Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[row.value] ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[row.value].text : null}
  </span>
);

const CheckboxCellFactory = (checkboxStates, onChangeFactory) => {
  const CheckboxCell = (row) => {
    const checkboxState = checkboxStates[row.original.id.toString()];
    if (!checkboxState) {
      return null;
    }
    const checked = checkboxState['checked'];
    const disabled = checkboxState['disabled'];
    const tooltip = disabled ? 'request edit permission to edit this item' : '';
    return (
      <CheckboxWithLabel
        tooltip={tooltip}
        checked={checked}
        disabled={disabled}
        id={`dc_checkbox_${row.original.id}`}
        label="&nbsp;"
        onChange={onChangeFactory(row.original.id)}
      />
    );
  };
  return CheckboxCell;
};

const ContentShortTypeCell = (row) => (
  <span className="is-flex is-vertical-centered">
    <img
      src={Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[row.value]?.icon}
      width="32px"
      alt="Dynamic Content Type"
      className="mrm"
    />
  </span>
);

const DataSourceTypeCell = (row) => (
  <span className="is-flex is-vertical-centered">
    {row.value && Constants.DATA_SOURCE_TYPE_DISPLAY[row.value] && (
      <img
        src={Constants.DATA_SOURCE_TYPE_DISPLAY[row.value].icon}
        width="32px"
        alt="Data Source Type"
        className="mrs"
      />
    )}
  </span>
);

const InputTypeCell = (row) => (
  <span className="is-flex is-vertical-centered">
    <img
      src={Constants.INPUT_TYPES_FOR_DISPLAY[row.value] ? Constants.INPUT_TYPES_FOR_DISPLAY[row.value].icon : null}
      width="32px"
      alt="Parameter Type"
      className="mrm"
    />
  </span>
);

const ContentNameCell = (row) => {
  return <TextOverflowWithTooltip className="text-overflow-ellipsis" text={row.value} />;
};

const DataSourceCell = (row) => {
  if (row.value !== '--') {
    return (
      <span className="is-flex min-width-0 is-vertical-centered">
        <img
          src={
            Constants.DATA_SOURCE_TYPE_DISPLAY[row.original.query_obj.data_source.type]
              ? Constants.DATA_SOURCE_TYPE_DISPLAY[row.original.query_obj.data_source.type].icon
              : null
          }
          width="32px"
          className="mrm"
          alt="Data Source Type"
        />
        <TextOverflowWithTooltip className="text-overflow-ellipsis" text={row.original.query_obj.data_source.name} />
      </span>
    );
  }

  return '--';
};

const getDataSourceName = (content) => {
  if (
    content.dynamic_content_method !== Constants.DynamicContentMethods.STATIC &&
    content.query_obj &&
    content.query_obj.data_source &&
    content.query_obj.data_source.name
  ) {
    return content.query_obj.data_source.name;
  }

  return '--';
};

export {
  ListShareCellFactory,
  RoleCell,
  GroupCellFactory,
  NameCell,
  CheckboxCellFactory,
  DynamicContentTypeCell,
  ContentNameCell,
  ContentShortTypeCell,
  DataSourceTypeCell,
  InputTypeCell,
  DataSourceCell,
  getDataSourceName,
};
