import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { closeFullScreenModal } from 'redux/ui/action';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import utils from 'lib/utils';
import { Heading } from 'react-bulma-components';
import InsightsPanel from 'components/shared/flows/InsightsPanel';
import RecipientsPanel from 'components/shared/flows/RecipientsPanel';
function RunAnalyticsModal({ run, templateType, progressBar, statusDetail, fetchCsvData }) {
  const ui = useSelector((state) => mapUiStateToProps(state).ui);
  const dispatch = useDispatch();

  const headerCenter = (
    <div className="full-screen-modal-title-box">
      <Heading className="full-screen-modal-title">
        {`${utils.capitalizeString(templateType)} Run Performance Insights`}
      </Heading>
      <span className="full-screen-modal-subline">{run.name}</span>
    </div>
  );

  return (
    <FullScreenModal
      show={ui.fullScreenModal?.name === 'runAnalyticsModal'}
      onClose={() => dispatch(closeFullScreenModal())}
      headerCenter={headerCenter}
      backgroundColor={'white'}
    >
      <div className="analytics-modal-body">
        <div className="analytics-modal-panel-container">
          <InsightsPanel run={run} statusDetail={statusDetail} progressBar={progressBar} />
          <RecipientsPanel run={run} fetchCsvData={fetchCsvData} />
        </div>
      </div>
    </FullScreenModal>
  );
}

RunAnalyticsModal.propTypes = {
  run: PropTypes.object,
  templateType: PropTypes.string,
  statusDetail: PropTypes.array,
  progressBar: PropTypes.element,
  fetchCsvData: PropTypes.func,
};

export default RunAnalyticsModal;
