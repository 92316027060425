import React from 'react';
import PropTypes from 'prop-types';
import TemplateCard from './TemplateCard';
import NoAccessTemplateCard from './NoAccessTemplateCard';
import Pagination from './Pagination';
import LoadingOverlay from './LoadingOverlay';
import TemplateLibraryCard from './TemplateLibraryCard';
import Constants from '../Constants';
import { filter, map } from 'lodash';
import TemplateBuilderCard from './TemplateBuilderCard';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import useAccesses from 'lib/hooks/useAccess';

function SortableEntityGrid(props) {
  const handlePageChange = (pageIndex) => {
    props.fetchItems(pageIndex, props.pagination.sort);
  };

  return (
    <div>
      <LoadingOverlay loading={props.loading} />
      {props.pagination.numberOfPages > 1 && !props.hideTopPagination && (
        <div className="is-clearfix pbm">
          <div className="is-pulled-right phl">
            <Pagination
              page={props.pagination.currentPage}
              pages={props.pagination.numberOfPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      )}
      <div className="sortable-entity-grid">
        {props.entityType === 'template' &&
          (props.isTemplateLibrary ? <TemplateLibraryCards {...props} /> : <TemplateCards {...props} />)}
        {props.entityType === 'template' && props.noAccessEntityCount > 0 && (
          <NoAccessTemplateCard numTemplates={props.noAccessEntityCount} />
        )}
      </div>
    </div>
  );
}
SortableEntityGrid.propTypes = {
  entityType: PropTypes.string,
  entitiesToRender: PropTypes.array,
  fetchItems: PropTypes.func,
  flags: PropTypes.object,
  hideTopPagination: PropTypes.bool,
  isSearching: PropTypes.bool,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  showLibrary: PropTypes.bool,
  templateBaseUrl: PropTypes.string,
  noAccessEntityCount: PropTypes.number,
  showFavorites: PropTypes.bool,
  isTemplateLibrary: PropTypes.bool,
  templateOnClick: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  narrativeType: PropTypes.string,
  isAdminView: PropTypes.bool,
};

export default withLDConsumer()(SortableEntityGrid);

const moveArchived = (templates, isAdminView = false) => {
  for (let i = templates.length - 1; i >= 0; i--) {
    if (templates[i].deleted) {
      isAdminView && templates.push(templates[i]);
      templates.splice(i, 1);
    }
  }
  return templates;
};
function TemplateCards(props) {
  const { data: accessesByTemplateId } = useAccesses('template');

  let elements = [];
  if (props.entitiesToRender) {
    const templates = moveArchived(props.entitiesToRender, props.isAdminView);
    elements = templates.map((elem) => {
      return (
        <TemplateCard
          key={elem.id}
          template={elem}
          accessesByTemplateId={accessesByTemplateId}
          showLibrary={props.showLibrary}
          templateBaseUrl={props.templateBaseUrl}
          showFavorites={props.showFavorites}
          templateOnClick={props.templateOnClick}
          isSearching={props.isSearching}
          isAdminView={props.isAdminView}
        />
      );
    });
  }
  return elements;
}
TemplateCards.propTypes = {
  entitiesToRender: PropTypes.array,
  isAdminView: PropTypes.bool,
  showLibrary: PropTypes.bool,
  templateBaseUrl: PropTypes.string,
  showFavorites: PropTypes.bool,
  templateOnClick: PropTypes.func,
  isSearching: PropTypes.bool,
};

function TemplateLibraryCards(props) {
  let templateLibraryCards = [];
  if (props.narrativeType) {
    templateLibraryCards = map(
      filter(Constants.TemplateLibraryInfo, { narrative_type: props.narrativeType }),
      (template) => {
        return (
          <TemplateLibraryCard key={template.template_id} templateData={template} onTemplateAdd={props.onTemplateAdd} />
        );
      },
    );
  } else {
    templateLibraryCards = map(Constants.TemplateLibraryInfo, (template) => {
      return (
        <TemplateLibraryCard key={template.template_id} templateData={template} onTemplateAdd={props.onTemplateAdd} />
      );
    });
  }

  if (props.flags.templateBuilder) {
    templateLibraryCards.unshift(<TemplateBuilderCard key="ai-template-builder" />);
  }

  return templateLibraryCards;
}
TemplateLibraryCards.propTypes = {
  narrativeType: PropTypes.string,
  onTemplateAdd: PropTypes.func,
  flags: PropTypes.object,
};
