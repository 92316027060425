import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TemplatePreview from '../TemplatePreview';
import InputCard from 'components/producer/templates/InputCard';
import ContentCard from 'components/producer/templates/ContentCard';
import Constants from 'components/Constants';
import AccessManager from 'lib/AccessManager';
import { UserContext } from 'components/UserContext';
import API from 'lib/api';
import { openModal } from 'redux/ui/action';
import AccessModal from '../AccessModal';
import Pluralize from 'pluralize';
import useAccesses from 'lib/hooks/useAccess';

function ConnectedItems({ connectedItems, connectedItemType }) {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const { data: accessesByItemId } = useAccesses('template');

  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailsByTemplateId, setThumbnailsByTemplateId] = useState(null);

  useEffect(() => {
    if (connectedItemType === Constants.ItemTypes.TEMPLATE) {
      const templateIds = connectedItems[connectedItemType].items.map((template) => template.id);
      const thumbnailCount = 4;
      setIsLoading(true);
      API.post(
        '/templates/bulk_thumbnails/',
        { template_ids: templateIds, thumbnail_count: thumbnailCount },
        (response) => {
          setThumbnailsByTemplateId(response.data);
          setIsLoading(false);
        },
      );
    }
  }, [connectedItemType]);

  const showAccessModal = (e, template) => {
    e.preventDefault();
    dispatch(openModal('accessModal', template));
  };

  const renderConnectedInputs = () =>
    connectedItems[connectedItemType].items.map((input) => (
      <InputCard key={input.id} input={input} className="input-card" />
    ));

  const renderConnectedTemplates = () => {
    return (
      <>
        {connectedItems[connectedItemType].items.map((template) => {
          return (
            <div key={template.id}>
              <TemplatePreview
                accesses={new AccessManager(template.id, accessesByItemId, user)}
                isLoading={isLoading}
                key={`t${template.id}`}
                showAccessModal={(e) => showAccessModal(e, template)}
                template={template}
                thumbnails={thumbnailsByTemplateId && thumbnailsByTemplateId[template.id]}
              />
            </div>
          );
        })}
        {connectedItems[connectedItemType].restricted_count && (
          <span>
            And {Pluralize('template', connectedItems[connectedItemType].restricted_count, true)} you don&apos;t have
            access to.
          </span>
        )}
      </>
    );
  };

  const renderConnectedContent = () =>
    connectedItems[connectedItemType].items.map((content) => {
      content.type = content.dynamic_content_type;
      return (
        <div key={content.id}>
          <ContentCard hovering={false} content={content} link={`/dynamic_content/${content.id}`} inPopup={true} />
        </div>
      );
    });

  return (
    <>
      {connectedItemType === Constants.ItemTypes.INPUT && renderConnectedInputs()}
      {connectedItemType === Constants.ItemTypes.TEMPLATE && renderConnectedTemplates()}
      {connectedItemType === Constants.ItemTypes.DYNAMIC_CONTENT && renderConnectedContent()}
      <AccessModal
        accesses={new AccessManager(ui.modal?.content?.id, accessesByItemId, user)}
        item={ui.modal?.content}
        show={ui.modal?.name === 'accessModal'}
      />
    </>
  );
}

ConnectedItems.propTypes = {
  connectedItems: PropTypes.object,
  connectedItemType: PropTypes.string,
};

export default ConnectedItems;
