import { UPDATE_UI_STATE, UPDATE_OVERFLOW } from './actionTypes';
import { MConfirm } from 'components/shared/Alerts';

export function openModal(name, content) {
  return (dispatch) => {
    dispatch(updateUiState('modal', name, true, content));
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch(updateUiState('modal', false));
  };
}

export function openFullScreenModal(name, content) {
  return (dispatch) => {
    dispatch(updateUiState('fullScreenModal', name, true, content));
  };
}

export function closeFullScreenModal() {
  return (dispatch) => {
    dispatch(updateUiState('fullScreenModal', false));
  };
}

export function setOverflowAsHiddenOrDefault(flag) {
  return (dispatch) => {
    dispatch(updateOverflow(flag));
  };
}

export function openSidepane(content, name, defaultContentType = null) {
  return (dispatch) => {
    dispatch(updateUiState('sidepane', name, true, content, defaultContentType));
  };
}

export function closeSidepane() {
  return (dispatch) => {
    dispatch(updateUiState('sidepane', false));
  };
}

export function openGoogleSheetDrawer() {
  return (dispatch) => {
    dispatch(updateUiState('googleSheetDrawer', 'googleSheetDrawer', true));
  };
}

export function closeGoogleSheetDrawer() {
  return (dispatch) => {
    dispatch(updateUiState('googleSheetDrawer', false));
  };
}

export function openEditableField(content) {
  return (dispatch) => {
    dispatch(updateUiState('editableField', 'editableField', true, content));
  };
}

export function closeEditableField() {
  return (dispatch) => {
    dispatch(updateUiState('editableField', false));
  };
}

export function toggleIsChanged(bool = true) {
  return (dispatch) => {
    dispatch(updateUiState('isChanged', bool));
  };
}

export function onEscPress(e, ui) {
  return (dispatch) => {
    if (e.keyCode === 27) {
      if (ui.fullScreenModal) {
        dispatch(closeFullScreenModal());
      } else if (ui.modal) {
        dispatch(closeModal());
      } else if (ui.editableField) {
        dispatch(closeEditableField());
      } else if (ui.googleSheetDrawer) {
        dispatch(closeGoogleSheetDrawer());
      } else if (ui.sidepane) {
        if (ui.isChanged) {
          dispatch(
            openUnsavedChangesModal({
              title: 'Close Sidepane?',
              onConfirm: 'closeSidepane',
            }),
          );
        } else {
          dispatch(closeSidepane());
        }
      }
    }
  };
}

export function openUnsavedChangesModal(info) {
  return (dispatch) => {
    MConfirm(
      info.title,
      'You have unsaved changes',
      'warning',
      (confirmed) => {
        if (confirmed) {
          switch (info.onConfirm) {
            case 'closeSidepane':
              dispatch(closeSidepane());
              break;
            case 'openSidepane':
              dispatch(openSidepane(info.content, info.name));
              break;
            default:
              break;
          }
        }
      },
      info.primaryButton ? info.primaryButton : 'Close',
      'Cancel',
    );
  };
}

function updateUiState(uiType, name, isActive, content, defaultContentType) {
  return {
    type: UPDATE_UI_STATE,
    uiType,
    isActive,
    name,
    content,
    defaultContentType,
  };
}

function updateOverflow(hiddenFlag) {
  return {
    type: UPDATE_OVERFLOW,
    hiddenFlag,
  };
}
