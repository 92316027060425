import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import utils from 'lib/utils';
import API from 'lib/api';
import { MInsufficientPerms } from 'components/shared/Alerts';
import { UserContext } from 'components/UserContext';

const buildPicker = (accessToken, views, title, onPicked) => {
  const pickerBuilder = new window.google.picker.PickerBuilder()
    .setOAuthToken(accessToken)
    .setAppId(768845740735)
    .setDeveloperKey(process.env.REACT_APP_GOOGLE_BROWSER_KEY) // eslint-disable-line no-undef
    .setCallback(onPicked)
    .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES);

  if (!views) {
    views = ['presentations'];
  } else if (!Array.isArray(views)) {
    views = [views];
  }
  views.forEach((view) => {
    if (view === 'presentations') {
      pickerBuilder.addView(window.google.picker.ViewId.PRESENTATIONS);
    } else if (view === 'sheets') {
      pickerBuilder.addView(window.google.picker.ViewId.SPREADSHEETS);
    } else if (view === 'documents') {
      pickerBuilder.addView(window.google.picker.ViewId.DOCUMENTS);
    } else if (view === 'folders') {
      var docsView = new window.google.picker.DocsView()
        .setIncludeFolders(true)
        .setMimeTypes('application/vnd.google-apps.folder')
        .setSelectFolderEnabled(true);
      pickerBuilder.addView(docsView);
    }
  });

  if (title) {
    pickerBuilder.setTitle(title);
  }

  return pickerBuilder.build();
};

const GoogleDrivePicker = ({
  className,
  children,
  onPicked,
  view,
  title,
  permissionsMessage = 'In order to import your template, you will need to select allow in the authentication window.',
  buttonRef = null,
}) => {
  const userContext = useContext(UserContext);

  const [isPickerLoaded, setPickerLoaded] = useState(window.google?.picker);
  const [shouldShowPicker, setShowPicker] = useState(false);

  useEffect(() => {
    if (!window.google?.picker) {
      window.gapi.load('picker', () => setPickerLoaded(true));
    } else {
      setPickerLoaded(true);
    }
  }, []);

  let googleAuth = null;
  const googleIntegration = utils.googleIntegration(userContext.user);
  if (googleIntegration) {
    googleAuth = {
      access_token: googleIntegration.access_token,
      expires_on: googleIntegration.expires_on,
      hasNecessaryScopes: googleIntegration.has_necessary_scopes,
    };
  }

  // Add 'Z' to the end of the expires_on date string to ensure that it is parsed as UTC
  const isAuthTokenValid = googleAuth?.expires_on && moment(`${googleAuth.expires_on}Z`) > moment();

  // If the access_token is expired, try to create a new one
  useEffect(() => {
    // Only try to refresh the access token if the user has one already and it's expired.
    if (!isAuthTokenValid && googleAuth?.access_token) {
      API.get('/users/me/oauth_token/', (result) => {
        userContext.updateUser(result.data);
      });
    }
  }, [isAuthTokenValid, googleAuth?.access_token]);

  // Ensure pre-requisites are met and open the picker when requested.
  useEffect(() => {
    if (shouldShowPicker && isAuthTokenValid && isPickerLoaded) {
      const picker = buildPicker(googleAuth.access_token, view, title, (e) => {
        if (e.docs?.length > 0) {
          onPicked(e.docs);
        }
      });
      picker.setVisible(true);
      setShowPicker(false);
    }
  }, [shouldShowPicker, isAuthTokenValid, isPickerLoaded]);

  /** If access tokens are missing, prompt the user for access and populate them in user context */
  const requestAccessIfNecessary = () => {
    const showPermsAlert = () => {
      const title = 'Grant Google Drive permissions to Matik';
      MInsufficientPerms(title, permissionsMessage, reconnectGoogle);
    };
    const reconnectGoogle = () => {
      utils.reconnectGoogle(userContext.user, userContext.updateUser, () => {}, showPermsAlert);
    };
    utils.connectGoogle(userContext.user, userContext.updateUser, () => {}, showPermsAlert);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();

    requestAccessIfNecessary();
    setShowPicker(true);
  };

  return (
    <button role="button" className={className} onClick={handleButtonClick} ref={buttonRef}>
      {children}
    </button>
  );
};

const viewEnumType = PropTypes.oneOf(['presentations', 'sheets', 'documents', 'folders']);
GoogleDrivePicker.propTypes = {
  className: PropTypes.string,
  view: PropTypes.oneOfType([PropTypes.arrayOf(viewEnumType), viewEnumType]).isRequired,
  title: PropTypes.string,
  permissionsMessage: PropTypes.string,
  onPicked: PropTypes.func.isRequired,
  children: PropTypes.any,
  buttonRef: PropTypes.any,
};

export default GoogleDrivePicker;
