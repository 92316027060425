import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { AssistantAPI, API } from 'lib/api';
import LongRequest from 'lib/longRequest';
import teams from 'lib/teams';
import utils from 'lib/utils';

import Icon from 'components/lib/Icon';
import Constants from 'components/Constants';
import TBConstants from './TBConstants';
import { UserContext } from 'components/UserContext';
import { MAlert, MInsufficientPerms } from 'components/shared/Alerts';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import PageLoader from 'components/shared/PageLoader';
import CompanyInfoForm from 'components/shared/templateBuilder/CompanyInfoForm';
import { HeaderCenter } from 'components/shared/templateBuilder/HeaderCenter';
import SelectBranding from 'components/shared/templateBuilder/SelectBranding';
import SelectNarrativeScreenV2 from 'components/shared/templateBuilder/SelectNarrativeScreenV2';

const BuilderState = {
  CHOOSE_TEMPLATE: 'choose_template',
  SET_COMPANY_INFO: 'set_company_info',
  CONFIRM_BRANDING: 'confirm_branding',
  LOADING: 'loading',
};

const TemplateTypes = {
  QBROnePagerPresentation: 'QBROnePagerPresentation',
  EndOfYearEmail: 'EndOfYearEmail',
  QBRMultiPagePresentation: 'QBRMultiPagePresentation',
  RenewalMultiPagePresentation: 'RenewalMultiPagePresentation',
  ExpansionMultiPagePresentation: 'ExpansionMultiPagePresentation',
};

// If this component is in the DOM, it can be opened by setting a query param: ?start_flow=<slug>
// query params company_url and company_name can be set to pre-fill the company info form and skip to branding.
const TemplateTypesBySlug = {
  eoy_email: TemplateTypes.EndOfYearEmail,
  qbr_one_pager: TemplateTypes.QBROnePagerPresentation,
  qbr_multi: TemplateTypes.QBRMultiPagePresentation,
  renewal_multi: TemplateTypes.RenewalMultiPagePresentation,
  expansion_multi: TemplateTypes.ExpansionMultiPagePresentation,
};

function TemplateBuilderModal({ onClose, showModal, openModal }) {
  const [builderState, setBuilderState] = useState(BuilderState.CHOOSE_TEMPLATE);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [progressText, setProgressText] = useState('Processing...');
  const [website, setWebsite] = useState('https://');
  const [websiteError, setWebsiteError] = useState('');
  const [templateType, setTemplateType] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [brandingInfo, setBrandingInfo] = useState({});
  const [brandingIsLoading, setBrandingIsLoading] = useState(false);
  const [forceBrandingUpdate, setForceBrandingUpdate] = useState(0);
  const [sourceTypeSelectedOption, setSourceTypeSelectedOption] = useState(
    Constants.TemplateLibrarySourceLabels.googleSlides.dropdown,
  );

  const history = useHistory();
  const userContext = useContext(UserContext);

  const canAccessAIAndEmail =
    (userContext?.user?.enterprise?.enterprise_settings?.ai_enabled &&
      userContext?.user?.enterprise.enterprise_settings.matik_mail_enabled) ||
    (teams.isTeamsUser(userContext?.user?.enterprise.plan_id) &&
      userContext?.user?.enterprise.subscription_status === Constants.SUBSCRIPTION_STATUSES.trialing);

  const onSourceDropdownChange = (e) => {
    setSourceTypeSelectedOption(e);
  };

  const AllTemplateTypeMetaData = {
    [TemplateTypes.QBROnePagerPresentation]: {
      name: 'Quarterly Business Review - One Pager',
      description: 'An at-a-glance resource that summarizes key insights from a quarterly business review.',
      templateType: TemplateTypes.QBROnePagerPresentation,
      narrativeType: TBConstants.narrativeTypes.PRESENTATION,
      assistantTaskUrl: '/template_builder/one_pager/',
      getTemplateGenerationURL: (source_type) => `/templates/build/${source_type}/one_pager`,
    },
    [TemplateTypes.EndOfYearEmail]: {
      name: 'End of Year Email',
      description: 'A wrap-up email that summarizes key metrics to wow your customers.',
      templateType: TemplateTypes.EndOfYearEmail,
      narrativeType: TBConstants.narrativeTypes.EMAIL,
      assistantTaskUrl: '/template_builder/eoy_email/',
      getTemplateGenerationURL: () => '/templates/build/eoy_email',
    },
    [TemplateTypes.QBRMultiPagePresentation]: {
      name: 'Quarterly Business Review',
      description:
        'A presentation that reviews the value a customer is getting from your product and how its ' +
        'helping them progress towards their goals.',
      templateType: TemplateTypes.QBRMultiPagePresentation,
      narrativeType: TBConstants.narrativeTypes.PRESENTATION,
      assistantTaskUrl: '/template_builder/multi-pager/QBR/',
      getTemplateGenerationURL: (source_type) => `/templates/build/${source_type}/multi-pager/QBR`,
    },
    [TemplateTypes.RenewalMultiPagePresentation]: {
      name: 'Renewal Deck',
      description:
        'Provides a comprehensive overview of how the customer has benefited from your product and ' +
        'demonstrates the value of renewing.',
      templateType: TemplateTypes.RenewalMultiPagePresentation,
      narrativeType: TBConstants.narrativeTypes.PRESENTATION,
      assistantTaskUrl: '/template_builder/multi-pager/RENEWAL/',
      getTemplateGenerationURL: (source_type) => `/templates/build/${source_type}/multi-pager/RENEWAL`,
    },
    [TemplateTypes.ExpansionMultiPagePresentation]: {
      name: 'Expansion Deck',
      description:
        'Provides a comprehensive overview of how the customer has benefited from your product and ' +
        'highlights the additional value they could gain from expanding.',
      templateType: TemplateTypes.ExpansionMultiPagePresentation,
      narrativeType: TBConstants.narrativeTypes.PRESENTATION,
      assistantTaskUrl: '/template_builder/multi-pager/EXPANSION/',
      getTemplateGenerationURL: (source_type) => `/templates/build/${source_type}/multi-pager/EXPANSION`,
    },
  };

  useEffect(() => {
    const enterpriseName = userContext?.user?.enterprise?.name;
    if (enterpriseName) {
      setName(enterpriseName);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('start_flow');
    const selectedTemplateType = TemplateTypesBySlug[flow];
    if (selectedTemplateType) {
      setTemplateType(selectedTemplateType);
      openModal();
      const companyName = urlParams.get('company_name');
      const companyUrl = urlParams.get('company_url') || null;
      if (companyName) {
        setName(companyName);
      }
      if (companyUrl) {
        setWebsite(companyUrl);
      }
      if (!!companyName && !!companyUrl && validateName(companyName) && validateWebsite(companyUrl)) {
        getTaskId(AllTemplateTypeMetaData[selectedTemplateType].assistantTaskUrl, companyName, companyUrl);
        setBuilderState(BuilderState.CONFIRM_BRANDING);
      } else {
        setBuilderState(BuilderState.SET_COMPANY_INFO);
      }
    }
  }, []);

  const onWebsiteChange = (e) => {
    e.preventDefault();
    setWebsiteError('');
    setWebsite(e.target.value);
  };

  const onNameChange = (e) => {
    e.preventDefault();
    setNameError('');
    setName(e.target.value);
  };

  const onTemplateTypeSelect = (templateType) => {
    setTemplateType(templateType);
    setBuilderState(BuilderState.SET_COMPANY_INFO);
  };

  const onModalClose = (e) => {
    setBuilderState(BuilderState.CHOOSE_TEMPLATE);
    onClose(e);
  };

  const validateWebsite = (submittedUrl) => {
    const isValid = utils.isURLValid(submittedUrl);
    if (!isValid) {
      setWebsiteError('Invalid URL, ensure it begins with http:// or https://');
    }
    return isValid;
  };

  const validateName = (submittedName) => {
    const isValid = !!submittedName;
    if (!isValid) {
      setNameError('Please enter a name');
    }
    return isValid;
  };

  const onSubmitCompanyInfo = (e) => {
    e.preventDefault();
    const validName = validateName(name);
    const validWebsite = validateWebsite(website);
    if (validName && validWebsite) {
      getTaskId(AllTemplateTypeMetaData[templateType].assistantTaskUrl, name, website);
      setBuilderState(BuilderState.CONFIRM_BRANDING);
    }
  };

  const onCreate = (e) => {
    e.preventDefault();
    setBuilderState(BuilderState.LOADING);
    const templateMetaData = AllTemplateTypeMetaData[templateType];
    let sourceType;
    if (templateMetaData.narrativeType === TBConstants.narrativeTypes.EMAIL) {
      generateTemplate(templateMetaData.getTemplateGenerationURL());
      sourceType = 'email';
    } else {
      sourceType =
        sourceTypeSelectedOption.label === Constants.TemplateLibrarySourceLabels.googleSlides.dropdown.label
          ? 'google_slides'
          : 'powerpoint';
      const generateFunc = () => generateTemplate(templateMetaData.getTemplateGenerationURL(sourceType));
      if (sourceType === 'google_slides') {
        connectGoogleAndGeneratePresentation(generateFunc);
      } else {
        generateFunc();
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('start_flow');
    if (flow) {
      const companyName = urlParams.get('company_name');
      const companyUrl = urlParams.get('company_url');
      API.track('template_builder_generate_click', { templateType: flow, companyName, companyUrl, sourceType });
    }
  };

  const connectGoogleAndGeneratePresentation = (generateFunc) => {
    utils.connectGoogle(userContext.user, userContext.updateUser, generateFunc, showPermsAlert(generateFunc));
  };

  const reconnectGoogle = (generateFunc) => () => {
    utils.reconnectGoogle(userContext.user, userContext.updateUser, generateFunc, showPermsAlert(generateFunc));
  };

  const showPermsAlert = (generateFunc) => () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to import your template, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, reconnectGoogle(generateFunc));
  };

  function generateTemplate(url) {
    const longRequest = new LongRequest(url);
    longRequest.post(
      { company_name: name, company_url: website, branding_info: brandingInfo, template_data_task_id: taskId },
      (response, onComplete) => {
        if (response.data.status === 'done') {
          onClose();
          onComplete();
          const urlParams = new URLSearchParams(window.location.search);
          const flow = urlParams.get('start_flow');
          if (flow) {
            API.track('New_template_navigation', { templateType: flow });
          }
          history.push(`/templates/${response.data.template_id}`);
        } else if (response.data.status === 'error') {
          setBuilderState(BuilderState.CONFIRM_BRANDING);
          onComplete();
          MAlert(response.data.status, 'Error', 'error');
        } else {
          setProgressText(response.data.status);
        }
      },
    );
  }

  const getTaskId = (url, companyName, companyUrl) => {
    const requestData = {
      business_name: companyName,
      url: companyUrl,
      user_name: userContext?.user?.name,
      user_email: userContext?.user?.email,
    };
    AssistantAPI.post(
      url,
      requestData,
      (response) => {
        setTaskId(response.data.task_id);
      },
      (err) => {
        MAlert('An unexpected error occurred. ' + err, 'Error', 'error');
      },
    );
  };

  let modalBody = '';
  let onBackClick = onModalClose;
  let showDefaultFooter = false;
  let primaryButtonText = undefined;
  let primaryButtonOnClick = undefined;
  let primaryButtonIsDisabled = false;
  let secondaryButtonText = undefined;
  let secondaryButtonOnClick = undefined;
  let secondaryButtonLoading = false;

  if (builderState === BuilderState.CHOOSE_TEMPLATE) {
    const options = [
      AllTemplateTypeMetaData[TemplateTypes.QBROnePagerPresentation],
      AllTemplateTypeMetaData[TemplateTypes.QBRMultiPagePresentation],
      AllTemplateTypeMetaData[TemplateTypes.RenewalMultiPagePresentation],
      AllTemplateTypeMetaData[TemplateTypes.ExpansionMultiPagePresentation],
      AllTemplateTypeMetaData[TemplateTypes.EndOfYearEmail],
    ];
    modalBody = (
      <SelectNarrativeScreenV2 onClick={onTemplateTypeSelect} emailEnabled={canAccessAIAndEmail} options={options} />
    );
  } else if (builderState === BuilderState.SET_COMPANY_INFO) {
    onBackClick = () => setBuilderState(BuilderState.CHOOSE_TEMPLATE);
    modalBody = (
      <CompanyInfoForm
        nameError={nameError}
        onNameChange={onNameChange}
        name={name}
        websiteError={websiteError}
        onWebsiteChange={onWebsiteChange}
        website={website}
        onSubmit={onSubmitCompanyInfo}
        buttonText="Next"
      />
    );
  } else if (builderState === BuilderState.CONFIRM_BRANDING || builderState === BuilderState.LOADING) {
    onBackClick = () => setBuilderState(BuilderState.SET_COMPANY_INFO);
    showDefaultFooter = true;
    primaryButtonText = 'Generate';
    primaryButtonOnClick = onCreate;
    primaryButtonIsDisabled = brandingIsLoading;
    secondaryButtonLoading = brandingIsLoading;
    secondaryButtonText = (
      <span className="flex">
        <Icon name={'arrow_counterclockwise'} size={20} theme={'regular'} />
        <span className="ml-2">Re-fetch Branding</span>
      </span>
    );
    secondaryButtonOnClick = () => setForceBrandingUpdate((prevValue) => prevValue + 1);
    let sourceTypeDropdownOptions = [];
    if (templateType) {
      if (AllTemplateTypeMetaData[templateType].narrativeType === TBConstants.narrativeTypes.PRESENTATION) {
        sourceTypeDropdownOptions = Object.values(Constants.TemplateLibrarySourceLabels).map(
          (sourceType) => sourceType.dropdown,
        );
      } else if (templateType === TemplateTypes.EndOfYearEmail) {
        sourceTypeDropdownOptions = null;
      }
    }
    modalBody = (
      <SelectBranding
        brandingInfo={brandingInfo}
        onBrandingInfoChange={setBrandingInfo}
        domainName={website}
        companyName={name}
        isLoading={brandingIsLoading}
        setIsLoading={setBrandingIsLoading}
        forceUpdate={forceBrandingUpdate}
        onSourceDropdownChange={onSourceDropdownChange}
        sourceTypeSelectedOption={sourceTypeSelectedOption}
        sourceTypeDropdownOptions={sourceTypeDropdownOptions}
      />
    );
  }
  return (
    <>
      <PageLoader isActive={builderState === BuilderState.LOADING} showLottie={false} title={progressText} />
      <FullScreenModal
        show={showModal}
        headerCenter={<HeaderCenter />}
        onClose={onModalClose}
        backgroundColor="white"
        onBackClick={onBackClick}
        showDefaultFooter={showDefaultFooter}
        primaryButtonText={primaryButtonText}
        primaryButtonOnClick={primaryButtonOnClick}
        primaryButtonDisabled={primaryButtonIsDisabled}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonOnClick}
        secondaryButtonLoading={secondaryButtonLoading}
      >
        <div className="one-column-modal-body template-builder-modal">{modalBody}</div>
      </FullScreenModal>
    </>
  );
}

TemplateBuilderModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  openModal: PropTypes.func,
};

export default TemplateBuilderModal;
