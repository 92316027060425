import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import API from '../../../lib/api';
import DynamicContentContent from './DynamicContentContent';
import WithNotifications from '../../shared/WithNotifications';
import Constants from '../../Constants';
import { useHistory, withRouter } from 'react-router-dom';
import dynamic_content_empty_icon from '../../../images/dynamic_content_empty.png';
import { Heading } from 'react-bulma-components';
import DynamicContentForm from './DynamicContentForm';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import { useDynamicContent } from '../../../lib/hooks/useDynamicContent';
import { closeFullScreenModal, openFullScreenModal } from '../../../redux/ui/action';

function DynamicContentTab({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ui = useSelector((state) => state.ui);
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: Constants.DYNAMIC_CONTENT_PAGE_SIZE,
    sort: ['updated_on', 'desc'],
  });

  const { offset, limit, sort } = paginationParams;
  const { dynamicContent, isLoading, isPreviousData, pagination } = useDynamicContent(offset, limit, sort);

  useEffect(() => {
    API.track('dynamic_content_page_navigation');
  }, []);

  useEffect(() => {
    if (ui.fullScreenModal && dynamicContent && dynamicContent.length > 0) {
      dispatch(closeFullScreenModal());
    }
  }, [ui, dynamicContent]);

  const onContentAdd = (contentId) => {
    history.push(`/dynamic_content/${contentId}`);
  };

  const paginateContent = (offset = 0, limit = Constants.PAGE_SIZE, sort = null) => {
    setPaginationParams({ offset, sort, limit });
  };

  const onBackToList = () => {
    history.push('/dynamic_content');
  };

  const headerCenter = <Heading className="full-screen-modal-title">Create Dynamic Content</Heading>;
  const onClose = () => {
    dispatch(closeFullScreenModal());
  };

  const emptyInfo = {
    emptyImageUrl: dynamic_content_empty_icon,
    emptyPrompt: "You haven't created any dynamic content yet.",
    buttonText: 'Create Your First',
    emptyOnClick: () => dispatch(openFullScreenModal()),
  };

  return (
    <div className="tab-content">
      <FullScreenModal
        show={ui.fullScreenModal}
        showDefaultFooter={false}
        backgroundColor="white"
        onClose={onClose}
        headerCenter={headerCenter}
      >
        <div
          className={
            ui.fullScreenModal ? 'fullscreen-modal-dynamic-content-with-sheet' : 'fullscreen-modal-dynamic-content'
          }
        >
          <DynamicContentForm
            entityId="new"
            entityType="dynamic_content"
            headerLinkDisabled={true}
            saveButtonText="Add"
            buttonWidth="100%"
            onContentAdd={onContentAdd}
            isFullscreen={true}
          />
        </div>
      </FullScreenModal>
      <DynamicContentContent
        isLoading={isLoading}
        dynamicContent={dynamicContent}
        elements={dynamicContent}
        emptyInfo={emptyInfo}
        entityType="dynamic_content"
        fetchItems={paginateContent}
        match={match}
        isPaginating={isPreviousData}
        pagination={pagination}
        onBackToList={onBackToList}
        onContentAdd={onContentAdd}
      />
    </div>
  );
}

DynamicContentTab.propTypes = {
  match: PropTypes.any,
};

export default withRouter(WithNotifications(DynamicContentTab));
