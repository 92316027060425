import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connectHits } from 'react-instantsearch-dom';
import { reduce, concat } from 'lodash';

import loading_icon from 'images/loading.gif';

import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import HybridSearchBoxWithFilter from 'components/shared/search/HybridSearchBoxWithFilter';
import AssetItemDynamicContent from 'components/producer/dynamicContent/AssetItemDynamicContent';
import { ContentContext } from 'components/producer/templates/ContentContext';

function TemplateContentList({
  currentTemplate,
  highlightSlides,
  dynamicContentTags,
  isContentFetching,
  handleClick,
  searchState,
  isSearching,
  searchAttributes,
  onSearchStateChange,
  emptyContent,
}) {
  const [filterVal, setFilterVal] = useState('');
  const context = useContext(ContentContext);

  const onHighlight = (slidenums, contentName) => {
    context.highlightContentName(contentName);
    if (highlightSlides) {
      highlightSlides(slidenums);
    }
  };

  const onUnhighlight = () => {
    context.highlightContentName(null);
    if (highlightSlides) {
      highlightSlides();
    }
  };

  const onFilterChange = (newFilterVal) => {
    setFilterVal(newFilterVal);
  };

  if (isContentFetching) {
    return (
      <div className="component-params">
        <div className="has-text-centered">
          <img src={loading_icon} width="100rem" alt="loading" />
        </div>
      </div>
    );
  }

  const tagContent = dynamicContentTags ? dynamicContentTags : [];
  if (tagContent.length === 0) {
    return <div className="component-params">{emptyContent}</div>;
  }

  let body;
  if (isSearching && currentTemplate.source_type !== 'email') {
    body = (
      <ContentListWithHits
        matchedTags={tagContent}
        ListComponent={ContentList}
        onUnhighlight={onUnhighlight}
        onHighlight={onHighlight}
        handleClick={handleClick}
      />
    );
  } else {
    let filteredContent = tagContent;
    if (filterVal) {
      filteredContent = tagContent.filter((content) => {
        return (
          content.name.toLowerCase().indexOf(filterVal.toLowerCase()) >= 0 ||
          (content.subContent && content.subContent.name.toLowerCase().indexOf(filterVal.toLowerCase()) >= 0)
        );
      });
    }
    body = (
      <ContentList
        componentsToRender={filteredContent}
        onUnhighlight={onUnhighlight}
        onHighlight={onHighlight}
        handleClick={handleClick}
      />
    );
  }

  return (
    <div className="space-y-2">
      <div className="filters-container mb-4">
        <HybridSearchBoxWithFilter
          filterPlaceholder="Search"
          searchAttributes={searchAttributes}
          filterVal={filterVal}
          onFilterChange={onFilterChange}
          searchState={searchState}
          isSearching={isSearching}
          onSearchStateChange={onSearchStateChange}
        />
      </div>
      {body}
    </div>
  );
}

TemplateContentList.propTypes = {
  dynamicContentTags: PropTypes.array,
  isContentFetching: PropTypes.bool,
  highlightSlides: PropTypes.func,
  unhighlightSlides: PropTypes.func,
  currentTemplate: PropTypes.object,
  handleClick: PropTypes.func,
  emptyContent: PropTypes.node,

  // injected by WithInstantSearch
  searchState: PropTypes.object,
  isSearching: PropTypes.bool,
  searchAttributes: PropTypes.object,
  onSearchStateChange: PropTypes.func,
};

function ContentList({ componentsToRender, handleClick, onHighlight, onUnhighlight }) {
  return (
    <React.Fragment>
      {componentsToRender.map((content, idx) => (
        <AssetItemDynamicContent
          key={`${idx}-${content.name}`}
          content={content}
          handleClick={handleClick}
          onHighlight={onHighlight}
          onUnhighlight={onUnhighlight}
        />
      ))}
    </React.Fragment>
  );
}

ContentList.propTypes = {
  componentsToRender: PropTypes.array,
  onHighlight: PropTypes.func,
  renderContent: PropTypes.func,
  onUnhighlight: PropTypes.func,
  handleClick: PropTypes.func,
};

const ContentListWithHits = connectHits((props) => {
  const { hits, matchedTags, ListComponent, renderContent, ...rest } = props;

  const matchedTagsByName = {};
  matchedTags.forEach((tag) => {
    if (matchedTagsByName[tag.name]) {
      matchedTagsByName[tag.name].push(tag);
    } else {
      matchedTagsByName[tag.name] = [tag];
    }
  });
  // Add slide num to hits
  // prettier-ignore
  const tagsToReturn = reduce(
    hits,
    (tags, hit) => {
      const hitTags = matchedTagsByName[hit.name];
      if (hitTags) {
        return concat(tags, hitTags);
      }

      return tags;
    },
    []
  );

  return <ListComponent {...rest} componentsToRender={tagsToReturn} renderContent={renderContent} />;
});

export default WithInstantSearch(TemplateContentList);
