import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import slides from '../../lib/slides';
import WYSIWYGEditor from '../shared/WYSIWYGEditor';
import Modal from '../shared/modal/Modal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';

class SubTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      isLoading: false,
      name: `${this.props.template.name} Sub Template`,
    };
  }

  render() {
    return (
      <Modal
        show={this.props.ui.modal?.name === 'subTemplateModal'}
        title="Save as Sub Template"
        onClose={this.onClose}
        showDefaultFooter={true}
        footerIsSticky={false}
        primaryButtonText="Save"
        primaryButtonOnClick={this.onSubTemplateSubmit}
        primaryButtonLoading={this.state.isLoading}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={this.onClose}
      >
        <div className="sub-template-modal-body">
          <Form.Field>
            <Form.Label>Name</Form.Label>
            <Form.Control>
              <Form.Input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
                placeholder="Enter Sub Template Name"
                className="long-input"
                aria-label="sub template name"
              />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label htmlFor="template-desc">Description</Form.Label>
            <Form.Control>
              <WYSIWYGEditor
                name="description"
                className="long-input"
                placeholder="Enter sub template description"
                id="template-desc"
                ariaLabel="sub template description"
                updateEditor={this.updateEditor}
                onChange={this.onChange}
                value={this.state.description}
              />
            </Form.Control>
          </Form.Field>
        </div>
      </Modal>
    );
  }

  onSubTemplateSubmit = (e) => {
    e.preventDefault();
    const data = {
      added_library_slides: slides.parseAddedSlides(this.props.addedSlides),
      slide_idxs: this.props.slideIdxs,
      sub_template_name: this.state.name,
      sub_template_description: this.state.description,
    };

    this.setState({ isLoading: true });
    this.props.onSubTemplateCreate(data).finally(() => this.setState({ isLoading: false }));
  };

  onChange = (e) => {
    this.setState({ name: e.target.value });
  };

  updateEditor = (editorData) => {
    this.setState({ description: editorData });
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.closeModal();
  };
}

SubTemplateModal.propTypes = {
  addedSlides: PropTypes.array,
  onSubTemplateCreate: PropTypes.func,
  slideIdxs: PropTypes.array,
  template: PropTypes.object,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(SubTemplateModal);
