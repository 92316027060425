import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AsyncLoadImage from '../../shared/AsyncLoadImage';
import { ReactComponent as Close } from '../../../svg/close.svg';
import { MConfirm } from '../../shared/Alerts';
import InsertSlide from '../../shared/templates/InsertSlide';
import SlideGridSlide from '../../../lib/slideGridSlide';
import InsertLibrarySlidePopUp from './InsertLibrarySlidePopUp';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getLoopedSlideInfo } from 'lib/looping.js';
import { find, findIndex, isEmpty } from 'lodash';
import SmallLoader from 'components/shared/SmallLoader';
import ConditionIcon from './ConditionIcon';
import InputDisplayName from 'components/shared/templates/InputDisplayName';
import Tippy from '@tippyjs/react/headless';
import IconPill from '../../lib/IconPill';
import { useTemplateContent } from 'lib/hooks/useTemplate';
import ConditionSlideButton from './ConditionSlideButton';

const LoopButton = ({ slide, loops, inputs, onClick, size = 'm' }) => {
  const loop = getLoopedSlideInfo(slide, loops);
  if (!loop) {
    return null;
  }
  const loopedInput = Object.values(inputs).find((i) => loop.loop.parameter === i.id);
  return (
    <div
      data-tooltip-content={`A customized version of this slide will be generated for each provided value of ${
        loopedInput ? `"${InputDisplayName.text(loopedInput)}"` : 'the input'
      }.`}
      data-tooltip-id="matik-tooltip"
      role="button"
      className="pan loop-icon"
      onClick={onClick}
    >
      <IconPill iconName="loop" color={loop.color} size={size} theme="circle" />
    </div>
  );
};
LoopButton.propTypes = {
  slide: PropTypes.object,
  loops: PropTypes.array,
  inputs: PropTypes.object,
  onClick: PropTypes.func,
  size: IconPill.propTypes.size,
};

function TemplateSlide(props) {
  const [fetchState, setFetchState] = useState('loading');
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);

  const {
    canEdit,
    currentTemplate,
    flags,
    highlightedSlideNums,
    history,
    inSlider,
    isHidden,
    isLast,
    popupPlacement,
    setScrollToSlideIdx,
    ignoreClicks,
    selectedSlides,
    setViewingSlide,
    showConditions,
    showLoops,
    showSingleSlide,
    showingSingleSlide,
    slide,
    slideFetchStatus,
    slideNum,
    status,
    toggleSlide,
    matchingInputs,
    onInsertLibrarySlide,
    onRemoveLibrarySlide,
    onCopyConditions,
    copiedConditions,
    onSelectSlidesMatchingConditions,
  } = props;

  const ssvIsAvailable = !isEmpty(currentTemplate.pdf);
  const thumbnailHeight = showingSingleSlide ? '90px' : '152px';
  const thumbnailWidth = showingSingleSlide ? '160px' : '270px';
  const tippyOffset = [15, showingSingleSlide ? 90 : -152];
  const tippyStyle = {
    width: thumbnailWidth,
    height: thumbnailHeight,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,. 5)',
  };

  const { data: templateContent, isPending: isContentFetching } = useTemplateContent(
    currentTemplate?.id,
    currentTemplate?.deleted,
  );

  let onLibraryClick = null;
  let libraryTooltip = null;
  if (slide.is_library && slide.library) {
    onLibraryClick = () => {
      if (!ignoreClicks) {
        history.push(`/templates/${slide.library.id}`);
      }
    };
    libraryTooltip = slide.library.name;
  }
  const slideGridSlide = new SlideGridSlide(slide, currentTemplate, templateContent, { status: 'success' }, flags);

  let fetchClass = '';
  if (fetchState === 'loading' || fetchState === 'fetching') {
    fetchClass = 'fetching';
  }

  let dataTip = '';
  if (currentTemplate.is_sub_template) {
    dataTip = 'Switch to the End User Panel to insert slides in a Sub Template';
  } else if (!canEdit) {
    dataTip = 'You need edit access to add library slides';
  }

  const handleClick = (e) => {
    if (!ignoreClicks) {
      onSlideClick(e, slide);
    }
  };

  const handleDoubleClick = () => {
    if (!ssvIsAvailable || ignoreClicks) {
      return;
    }
    showSingleSlide?.(null, slide);
    const slideIdx = findIndex(currentTemplate.slides, { id: slide.id });
    const isGridView = !inSlider;
    if (isGridView) {
      setScrollToSlideIdx?.(slideIdx);
    }
  };

  const onSlideClick = (e, slide) => {
    if (!e.metaKey && !e.ctrlKey && !e.shiftKey) {
      const selected = find(selectedSlides, { id: slide.id });
      if (!selected) {
        const slideIdx = findIndex(currentTemplate.slides, { id: slide.id });
        setScrollToSlideIdx?.(slideIdx);
        setViewingSlide?.(slide);
      }
      toggleSlide(e, slide, true);
    } else if (e.metaKey || e.ctrlKey) {
      toggleSlide(e, slide);
    } else if (e.shiftKey) {
      // select from previous to current
      let selectedSlideIdx = findIndex(currentTemplate.slides, { id: slide.id });
      let firstSlideIdx = selectedSlideIdx;
      if (selectedSlides?.length > 0) {
        firstSlideIdx = findIndex(currentTemplate.slides, { id: selectedSlides[0].id });
      }
      if (selectedSlideIdx < firstSlideIdx) {
        // destructure & swap variables
        [firstSlideIdx, selectedSlideIdx] = [selectedSlideIdx, firstSlideIdx];
      }
      const updatedSelectedSlides = currentTemplate.slides.slice(firstSlideIdx, selectedSlideIdx + 1);
      toggleSlide(e, updatedSelectedSlides, true);
    }
  };

  const displayConditions = (e) => {
    if (!ignoreClicks) {
      e.preventDefault();
      e.stopPropagation();
      toggleSlide(e, [slide], true);
      showConditions(e);
    }
  };

  const displayLoops = (e) => {
    if (!ignoreClicks) {
      e.preventDefault();
      e.stopPropagation();
      showLoops(e, slide);
    }
  };

  const removeLibrarySlide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    MConfirm(
      'Delete Slide?',
      'Are you sure you want to remove this library slide from the template',
      'warning',
      (confirmed) => {
        if (confirmed) {
          setIsBeingDeleted(true);
          onRemoveLibrarySlide(slide.id, currentTemplate.id);
        }
      },
    );
  };

  const handleConditionMenuOpen = () => {
    // If the current slide is not selected, clear selection and select this one.
    // If the current slide is part of the selected set, do nothing.
    const isSelected = selectedSlides.some((s) => s.id === slide.id);
    if (!isSelected) {
      toggleSlide(null, selectedSlides, false);
      toggleSlide(null, slide, true);
    }
  };

  return (
    <Tippy
      offset={tippyOffset}
      render={() =>
        !ssvIsAvailable && (
          <div style={tippyStyle}>
            <p>Single slide view and </p>
            <p>in-app tagging</p>
            <p>is not available for</p> <p>this template</p>
          </div>
        )
      }
      zIndex={9999}
    >
      <div
        className={`slide-wrapper ${slideGridSlide.selectedClass(selectedSlides)} ${slideGridSlide.opaqueClass(
          highlightedSlideNums,
          slideNum,
        )} ${isHidden ? '!hidden' : ''}`}
        key={`thumbnail-${slide.id}-${slideNum}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        {!inSlider && (
          <InsertSlide
            dataTip={dataTip}
            currentTemplate={currentTemplate}
            insertLibrarySlidesIntoTemplate={onInsertLibrarySlide}
            PopUpComponent={InsertLibrarySlidePopUp}
            slideNum={slideNum}
            isDisabled={currentTemplate.is_sub_template || !canEdit}
            placement={popupPlacement}
            inSlider={inSlider}
          />
        )}
        <div className={`slide-view ${fetchClass} group/slide`}>
          {slide.is_library && !currentTemplate.is_library && !inSlider && (
            <button className="slide-close-icon" onClick={removeLibrarySlide}>
              <Close />
            </button>
          )}

          <div className={`flex absolute items-center ${showingSingleSlide ? 'gap-0.5' : 'gap-2'} m-2 right-0`}>
            {flags.enableMultiConditions ? (
              <ConditionSlideButton
                template={currentTemplate}
                selectedSlides={selectedSlides}
                slide={slide}
                onCopy={onCopyConditions}
                copiedConditions={copiedConditions}
                onSelectMatchingSlides={onSelectSlidesMatchingConditions}
                onMenuOpen={handleConditionMenuOpen}
              />
            ) : (
              slideGridSlide.conditionsExist() && (
                <a role="button" onClick={displayConditions}>
                  <ConditionIcon
                    className="conditions-icon"
                    currentTemplate={currentTemplate}
                    condition={slide.conditions[0]}
                    size={showingSingleSlide ? 's' : 'm'}
                  />
                </a>
              )
            )}

            {currentTemplate && (
              <LoopButton
                slide={slide}
                loops={currentTemplate.slide_loops}
                inputs={matchingInputs || {}}
                onClick={displayLoops}
                size={showingSingleSlide ? 's' : 'm'}
              />
            )}

            {slide.is_library && (
              <button
                className="libraries-icon"
                onClick={onLibraryClick}
                data-tooltip-content={libraryTooltip}
                data-tooltip-id="matik-tooltip"
              >
                <IconPill iconName="library" color="purple" theme="circle" size={showingSingleSlide ? 's' : 'm'} />
              </button>
            )}
            {slideGridSlide.hasUnmatchedContent(isContentFetching) && status?.status != 'error' && (
              <span
                className={`unmatched-icon ${isLast ? 'is-last' : ''}`}
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Slide has unmatched content"
              >
                <IconPill iconName="error_circle" color="red" theme="circle" size={showingSingleSlide ? 's' : 'm'} />
              </span>
            )}
          </div>
          {!isBeingDeleted ? (
            <AsyncLoadImage
              fetchUrl={'unused'}
              fetchStatus={slideFetchStatus}
              isBulk={true}
              imageClass={`async-rounded slide-thumbnail ${inSlider ? 'slider-thumbnail' : ''}`}
              imageWrapperClass={inSlider ? 'slider-thumbnail-wrapper' : ''}
              height={thumbnailHeight}
              width={thumbnailWidth}
              onFetchStateChange={() => setFetchState}
              imgAlt="slide-thumbnail"
              status={status}
            />
          ) : (
            <SmallLoader />
          )}
          <span className="slide-number-wrapper">
            <span className="slide-thumbnail-number">{slideNum}</span>
          </span>
        </div>
        {isLast && !inSlider && (
          <InsertSlide
            currentTemplate={currentTemplate}
            dataTip={dataTip}
            isLast={isLast}
            insertLibrarySlidesIntoTemplate={onInsertLibrarySlide}
            PopUpComponent={InsertLibrarySlidePopUp}
            slideNum={slideNum + 1}
            isDisabled={currentTemplate.is_sub_template || !canEdit}
            placement={popupPlacement}
            inSlider={inSlider}
          />
        )}
      </div>
    </Tippy>
  );
}

TemplateSlide.propTypes = {
  isHidden: PropTypes.bool,
  currentTemplate: PropTypes.object,
  highlightedSlideNums: PropTypes.array,
  history: PropTypes.object,
  isLast: PropTypes.bool,
  selectedSlides: PropTypes.array,
  showConditions: PropTypes.func,
  showLoops: PropTypes.func,
  showSingleSlide: PropTypes.func,
  slide: PropTypes.object,
  slideNum: PropTypes.number,
  slideFetchStatus: PropTypes.object,
  status: PropTypes.object,
  toggleSlide: PropTypes.func,
  flags: PropTypes.object,
  showingSingleSlide: PropTypes.bool,
  popupPlacement: PropTypes.string,
  inSlider: PropTypes.bool,
  setViewingSlide: PropTypes.func,
  ignoreClicks: PropTypes.bool,
  setScrollToSlideIdx: PropTypes.func,
  canEdit: PropTypes.bool,
  matchingInputs: PropTypes.object,
  onInsertLibrarySlide: PropTypes.func,
  onRemoveLibrarySlide: PropTypes.func,

  onCopyConditions: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectSlidesMatchingConditions: PropTypes.func,
};

export default withRouter(withLDConsumer()(TemplateSlide));
