import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { isEqual } from 'lodash';
import Constants from '../Constants';
import FormFromInputs from './FormFromInputs';
import QueryProcessor from '../../lib/queryProcessor';
import inputs from '../../lib/inputs';
import { mapInputsStateToProps } from '../../redux/inputs/stateMappers';
import { mapInputDispatchToProps } from '../../redux/inputs/dispatchers';
import { ReactComponent as Run } from '../../svg/run.svg';
import utils from '../../lib/utils';
import QueryDisplay from './QueryDisplay';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';
import Banner from './Banner';

class TestInputsForm extends Component {
  componentDidMount() {
    this.props.initInputValuesFromInputs(this.props.entityType, this.props.entityId, Object.values(this.props.inputs));
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.inputs, this.props.inputs)) {
      this.props.initInputValuesFromInputs(
        this.props.entityType,
        this.props.entityId,
        Object.values(this.props.inputs),
      );
    }
  }

  render() {
    if (!this.props.inputValues || Object.keys(this.props.inputValues).length === 0) {
      return null;
    }
    const buttonActive = inputs.areInputsFilledOut(this.props.inputValues, this.props.inputs);
    const queryProcessor = new QueryProcessor(
      this.props.queryString,
      this.props.inputs,
      this.props.inputValues,
      this.props.dataSource,
    );
    let parameterizedQuery = queryProcessor.parameterizeQueryString();

    if (
      this.props.dataSource.type === 'salesforce' &&
      utils.isValidJSON(parameterizedQuery) &&
      JSON.parse(parameterizedQuery).soql_string
    ) {
      parameterizedQuery = JSON.parse(parameterizedQuery).soql_string;
    }

    let matik_user_banner_text = '';

    const hasMatikUser = utils.hasMatikUserInputs(Object.values(this.props.inputs));

    if (hasMatikUser) {
      const entityTypeToBannerTextMap = {
        template: 'Template',
        input: 'Input',
        dynamic_content: 'Dynamic Content',
      };

      matik_user_banner_text = entityTypeToBannerTextMap[this.props.entityType] || '';
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        {hasMatikUser && (
          <div className="pb-4">
            <Banner
              bannerType="info"
              text={`This ${matik_user_banner_text} uses an input that populates based on the 
              current user. To test, select the user you would like to impersonate.`}
            />
          </div>
        )}
        <FormFromInputs
          inputs={Object.values(this.props.inputs)}
          inputValues={this.props.inputValues}
          updateInputValues={this.updateInputValues}
          test={true}
          isProducer={true}
          entityId={this.props.entityId}
          entityType={this.props.entityType}
        />
        <Form.Field className="mtm">
          <Form.Label className="phm">Generated Query</Form.Label>
          <QueryDisplay queryString={parameterizedQuery} />
        </Form.Field>
        <ButtonGroup align="right">
          <Button type="button" category="secondary" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" status={!buttonActive ? 'disabled' : 'default'}>
            <Run />
            &nbsp;{this.props.submitButtonText ? this.props.submitButtonText : 'Test Query'}
          </Button>
        </ButtonGroup>
      </form>
    );
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.validateFormData()) {
      const queryProcessor = new QueryProcessor(
        this.props.queryString,
        this.props.inputs,
        this.props.inputValues,
        this.props.dataSource,
      );
      this.props.onFormSubmit(queryProcessor.parameterizeQueryString(), this.props.inputValues);
    }
  };

  validateFormData = () => {
    let isValidated = true;
    let updatedInputValues = this.props.inputValues;
    for (let inputName in this.props.inputValues) {
      const inputValue = this.props.inputValues[inputName];
      const input = this.props.inputs[inputName];
      if (
        (input.type !== Constants.InputTypes.BOOLEAN && !inputValue.value) ||
        (input.type === Constants.InputTypes.LIST && inputValue.value.length === 0)
      ) {
        inputValue.error = 'This parameter is required';
        isValidated = false;
      }
    }

    if (!isValidated) {
      this.props.updateInputValues(this.props.entityType, this.props.entityId, updatedInputValues);
    }

    return isValidated;
  };

  updateInputValues = (inputValues) => {
    this.props.updateInputValues(this.props.entityType, this.props.entityId, inputValues);
  };
}

TestInputsForm.propTypes = {
  queryString: PropTypes.string,
  inputs: PropTypes.object,
  onFormSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string,
  dataSource: PropTypes.object,
  updateInputValues: PropTypes.func,
  initInputValuesFromInputs: PropTypes.func,
  inputValues: PropTypes.object,
  entityType: PropTypes.string,
  entityId: PropTypes.any,
};

export default connect(mapInputsStateToProps, mapInputDispatchToProps)(TestInputsForm);
