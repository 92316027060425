import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { addPopoverListener } from 'aceeditor/ace-matik.js';

import { ReactComponent as OpenExternalLogo } from 'svg/open_external.svg';
import { ReactComponent as AppsListLogo } from 'svg/apps_list.svg';
import { ReactComponent as TextInputLogo } from 'svg/text_input.svg';
import { ReactComponent as ApiLogo } from 'svg/api.svg';
import { ReactComponent as QueryLogo } from 'svg/query.svg';
import { openSidepane, openModal } from 'redux/ui/action';
import Constants from 'components/Constants';
import { Link } from 'react-router-dom';

/** Used in the QueryEditor and InputWithOptionalInputs to quickly access referenced inputs
 */
const InputPopoverWrapper = ({
  ace,
  inputs,
  isInputPopoverDisabled,
  inputPopoverTrigger,
  adjustPopoverTop,
  children,
}) => {
  const [popoverDetails, setPopoverDetails] = useState(null);
  const [mouseInTheHouse, setMouseInTheHouse] = useState(false);

  useEffect(() => {
    if (ace) {
      if (isInputPopoverDisabled || (inputPopoverTrigger === 'mouse' && !mouseInTheHouse)) {
        setPopoverDetails(null);
      } else {
        return addPopoverListener(ace, inputs, setPopoverDetails, inputPopoverTrigger);
      }
    }
  }, [ace, isInputPopoverDisabled, JSON.stringify(inputs), inputPopoverTrigger, mouseInTheHouse]);

  const handleContainerBlur = (e) => {
    // hide the popover when this control loses focus, BUT not when it loses focus to the popover
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      setPopoverDetails(null);
    }
  };

  const popoverTop = popoverDetails
    ? adjustPopoverTop
      ? adjustPopoverTop(popoverDetails.top)
      : popoverDetails.top
    : undefined;

  return (
    <div
      onBlur={handleContainerBlur}
      onMouseOver={() => setMouseInTheHouse(true)}
      onMouseEnter={() => setMouseInTheHouse(true)}
      onMouseLeave={() => setMouseInTheHouse(false)}
      className="is-relative"
    >
      {children}
      {popoverDetails && (
        <div
          style={{
            position: 'absolute',
            top: popoverTop + 'px',
            left: popoverDetails.left + 'px',
            zIndex: 2,
          }}
        >
          <InputPopover input={popoverDetails.input} />
        </div>
      )}
    </div>
  );
};
InputPopoverWrapper.propTypes = {
  ace: PropTypes.object,
  inputs: PropTypes.object,
  /** Used to adjust the vertical position of the popover
   */
  adjustPopoverTop: PropTypes.func,
  children: PropTypes.node,
  /** True to suppress the input navigation popover. False will show the popover when the input name
   * is associated with a known input.
   */
  isInputPopoverDisabled: PropTypes.bool,
  /** temporary feature flag
   */
  inputPopoverTrigger: PropTypes.oneOf(['cursor', 'mouse']),
};

export default InputPopoverWrapper;

const InputPopover = ({ input }) => {
  const ui = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const editInput = (e) => {
    e.preventDefault();

    if (ui?.sidepane?.content?.entityType === Constants.UI_TYPES.dynamic_content) {
      // The sidepane is open with a DC form and we don't want to replace
      // that with the input form, so use a modal instead.
      dispatch(openModal('inputModal', input));
    } else {
      dispatch(openSidepane({ ...input, entityType: Constants.UI_TYPES.input }, 'input-form'));
    }
  };

  let Logo;
  switch (input.source_type) {
    case Constants.InputSources.QUERY:
      Logo = QueryLogo;
      break;
    case Constants.InputSources.LIST:
      Logo = AppsListLogo;
      break;
    case Constants.InputSources.API:
      Logo = ApiLogo;
      break;
    default:
      Logo = TextInputLogo;
  }

  return (
    <div className="popup-box phm pvs">
      <div className="is-flex gap-large has-text-dark is-family-primary is-align-items-center">
        <button className="button is-tertiary prs is-flex is-align-items-center" onClick={editInput}>
          <span className="icon-frame mrs">
            <Logo />
          </span>
          <span className="is-underlined">{input.name}</span>
        </button>
        {input.id && (
          <Link className="button small is-tertiary phs ml-auto" to={`/inputs/${input.id}`} target="_blank">
            <OpenExternalLogo />
          </Link>
        )}
      </div>
    </div>
  );
};
InputPopover.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    source_type: PropTypes.string,
  }).isRequired,
};
