import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import Constants from '../Constants';
import Banner from './Banner';

class TestResult extends Component {
  render() {
    const onClick = this.props.testResult?.link ? () => window.open(this.props.testResult.link.url) : null;
    const secondaryButtonLabel = this.props.testResult?.link ? this.props.testResult.link.message : '';
    const secondaryText = this.props.testResult?.secondary_message ? this.props.testResult?.secondary_message : '';
    let testResult = null;
    if (this.props.testResult) {
      let resultMessage = 'Success';
      if (
        this.props.testResult.result &&
        this.props.dynamicContentType !== 'image' &&
        this.props.testResult.result instanceof Array
      ) {
        const resultCount =
          this.props.dynamicContentType === Constants.DynamicContentTypes.TABLE
            ? this.props.testResult.result.length
            : this.props.testResult.result.length - 1;
        resultMessage += '! ';
        if (resultCount > 1) {
          resultMessage += 'First ';
        }
        resultMessage += `${Pluralize('result', resultCount, true)} returned`;
        if (
          this.props.dataSource === Constants.DATA_SOURCE_TYPES.salesforce ||
          this.props.dataSource === Constants.DATA_SOURCE_TYPES.gainsight
        ) {
          if (resultCount >= 20) {
            resultMessage += '. (Note that we limit results to the first 20 when testing the API).';
          }
        } else if (
          this.props.dataSource === Constants.DATA_SOURCE_TYPES.hubspot ||
          this.props.dataSource === Constants.DATA_SOURCE_TYPES.productboard ||
          this.props.dataSource === Constants.DATA_SOURCE_TYPES.tableau
        ) {
          if (resultCount >= 100) {
            resultMessage += '. (Note that we limit results to the first 100 when testing the API).';
          }
        }
      }
      if (this.props.testResult.status && this.props.testResult.status === 'error') {
        resultMessage = this.props.testResult.message;
      }

      testResult = (
        <div className={this.props.noPadding ? 'mtn' : 'mvm'} id="test-result-msg">
          <Banner
            bannerType={this.props.testResult.status}
            text={resultMessage}
            sublineText={secondaryText}
            secondaryButtonLabel={secondaryButtonLabel}
            onSecondaryButtonClick={onClick}
          ></Banner>
        </div>
      );
    }
    return testResult;
  }
}

TestResult.propTypes = {
  testResult: PropTypes.any,
  dynamicContentType: PropTypes.string,
  noPadding: PropTypes.bool,
  dataSource: PropTypes.string,
};

export default TestResult;
