import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ReactComponent as GreenCheckmark } from '../../svg/check_bullet.svg';
import FeatureComparisonHeader from './FeatureComparisonHeader';
import PropTypes from 'prop-types';
import Constants from '../Constants';

class FeatureComparison extends Component {
  render() {
    const columns = [
      { id: 'label', accessor: 'label' },
      { id: 'teamValue', accessor: 'teamValue' },
      { id: 'enterpriseValue', accessor: 'enterpriseValue' },
    ];

    const keyFeatureTableData = [
      {
        label: 'Number of data source connections',
        teamValue: 'Unlimited',
        enterpriseValue: 'Unlimited',
      },
      {
        label: 'Connect to apps',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Connect to data warehouses',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Connect through REST APIs',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Number of dynamic content',
        teamValue: 'Unlimited',
        enterpriseValue: 'Unlimited',
      },
      {
        label: 'Number of templates',
        teamValue: 'Unlimited',
        enterpriseValue: 'Unlimited',
      },
      {
        label: 'Number of presentation generations',
        teamValue: '5 / account / month',
        enterpriseValue: 'Custom',
      },
      {
        label: 'Content search',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
    ];

    const presentationAutomationTableData = [
      {
        label: 'Conditional slides',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Conditional content',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Slide looping',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Slide library',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
    ];

    const workflowAutomationTableData = [
      {
        label: 'Bulk generation',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Scheduling',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Subtemplates',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
    ];

    const administrationTableData = [
      {
        label: 'Number of seats',
        teamValue: Constants.MATIK_TIERS.matik_team.userLimit,
        enterpriseValue: 'Custom',
      },
      {
        label: 'Template sharing',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Permissioning',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Usage analytics',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Bulk changes',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'SSO',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
    ];

    const supportTableData = [
      {
        label: 'Email support',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Chat support',
        teamValue: <GreenCheckmark />,
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Dedicated customer success manager',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
      {
        label: 'Implementation support',
        teamValue: '',
        enterpriseValue: <GreenCheckmark />,
      },
    ];

    return (
      <div className="feature-comparison">
        <FeatureComparisonHeader
          userTier={this.props.userTier}
          onTierSelection={this.props.onTierSelection}
          isTrialing={this.props.isTrialing}
        />
        <hr className="header-hr" />
        <div className="feature-comparison-section-header">Key Features</div>
        <ReactTable
          data={keyFeatureTableData}
          columns={columns}
          resizable={false}
          showPagination={false}
          minRows={0}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
        />
        <div className="feature-comparison-section-header">Presentation Automation</div>
        <ReactTable
          data={presentationAutomationTableData}
          columns={columns}
          resizable={false}
          showPagination={false}
          minRows={0}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
        />
        <div className="feature-comparison-section-header">Workflow Automation</div>
        <ReactTable
          data={workflowAutomationTableData}
          columns={columns}
          resizable={false}
          showPagination={false}
          minRows={0}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
        />
        <div className="feature-comparison-section-header">Administration & Control</div>
        <ReactTable
          data={administrationTableData}
          columns={columns}
          resizable={false}
          showPagination={false}
          minRows={0}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
        />
        <div className="feature-comparison-section-header">Support</div>
        <ReactTable
          data={supportTableData}
          columns={columns}
          resizable={false}
          showPagination={false}
          minRows={0}
          getTdProps={this.getTdProps}
          getTrProps={this.getTrProps}
          getProps={this.getProps}
          getTheadThProps={this.getThProps}
          getTbodyProps={this.getTbodyProps}
        />
        <div className="pbl" />
      </div>
    );
  }

  getTdProps = () => {
    return { className: 'full-page-list-cell is-size-5' };
  };

  getTrProps = (_state, rowInfo) => {
    let classNameString = 'full-page-list-row is-borderless feature-label ptm pbm';
    if (rowInfo.index % 2 === 1) {
      classNameString += ' white-row';
    }

    return { className: classNameString };
  };

  getProps = () => {
    return { className: 'full-page-list-table is-borderless' };
  };

  getThProps = () => {
    return { className: 'full-page-list-header' };
  };

  getTbodyProps = () => {
    return { className: 'tbody-scrollable-no-pagination' };
  };
}

FeatureComparison.propTypes = {
  onTierSelection: PropTypes.func,
  userTier: PropTypes.string,
  isTrialing: PropTypes.bool,
};

export default FeatureComparison;
