import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Tile, Box, Message } from 'react-bulma-components';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { MAlert } from 'components/shared/Alerts';

function SampleData({ dynamicContentType, testResult, ui }) {
  useEffect(() => {
    if (testResult?.is_truncated) {
      MAlert("The data input exceeds the model's limit, which may affect accuracy.", 'Model Limit Exceeded', 'warning');
    }
  }, [testResult]);
  const { sidepane } = ui;
  if (testResult && testResult.status === 'success' && testResult.result?.length > 0 && !testResult.is_raw) {
    if (dynamicContentType === 'image') {
      if (Array.isArray(testResult.result)) {
        if (
          Array.isArray(testResult.result) &&
          testResult.result.length === 2 &&
          testResult.result.every(Array.isArray)
        ) {
          return (
            <Tile kind="child" className="is-paddingless ">
              <Box className="is-paddingless is-shadowless has-light-gray-border">
                <div className={`${sidepane ? 'sidepane-query-result' : 'query-result'} pal`}>
                  <Table size="fullwidth" striped>
                    <thead>
                      <tr>
                        {testResult.result[0].map((column, index) => (
                          <th key={index}>{column.trim()}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {testResult.result[1].map((item, index) => {
                          return (
                            <td key={index}>
                              <img src={`data:image/png;base64,${item}`} alt={`Returned image ${index}`} />
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Box>
            </Tile>
          );
        } else if (
          Array.isArray(testResult.result) &&
          testResult.result.length === 1 &&
          testResult.result.every(Array.isArray)
        ) {
          return (
            <Tile kind="child" className="is-paddingless ">
              <Box className="is-paddingless is-shadowless has-light-gray-border">
                <div className={`${sidepane ? 'sidepane-query-result' : 'query-result'} pal`}>
                  <Table size="fullwidth" striped>
                    <tbody>
                      <tr>
                        {testResult.result[0].map((item, index) => {
                          return (
                            <td key={index}>
                              <img src={`data:image/png;base64,${item}`} alt={`Returned image ${index}`} />
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Box>
            </Tile>
          );
        } else {
          return (
            <Tile kind="child" className="is-paddingless ">
              <Box className="is-paddingless is-shadowless has-light-gray-border">
                <div className={`${sidepane ? 'sidepane-query-result' : 'query-result'} pal`}>
                  {testResult.result.map((item, index) => (
                    <img key={index} src={`data:image/png;base64,${item}`} alt={`Returned image ${index}`} />
                  ))}
                </div>
              </Box>
            </Tile>
          );
        }
      } else {
        return (
          <Tile kind="child" className="is-paddingless ">
            <Box className="is-paddingless is-shadowless has-light-gray-border">
              <div className={`${sidepane ? 'sidepane-query-result' : 'query-result'} pal`}>
                <img src={`data:image/png;base64,${testResult.result}`} alt="Returned matik logo" />
              </div>
            </Box>
          </Tile>
        );
      }
    } else if (testResult.result instanceof Array) {
      return (
        <Tile kind="child" className="is-paddingless ">
          <Box className="is-paddingless is-shadowless has-light-gray-border">
            <div className={`${sidepane ? 'sidepane-query-result' : 'query-result'} pal`}>
              <Table size="fullwidth" striped>
                {!testResult.removeHeader && testResult.result[0] instanceof Array && (
                  <thead>
                    <tr>
                      {testResult.result[0].map((header) => {
                        return (
                          <th key={header} className="plm">
                            {header}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {testResult.result.slice(1).map((row, idx) => {
                    return (
                      <tr key={'res-' + idx}>
                        {row.map((elem) => {
                          if (elem !== null && elem !== undefined) {
                            elem = elem.toString();
                          }
                          return (
                            <td key={Math.random()} className="plm">
                              {elem}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Box>
        </Tile>
      );
    }
  } else if (testResult && testResult.status === 'success') {
    return (
      <Tile kind="child" className="is-paddingless ">
        <Box className="is-paddingless is-shadowless has-light-gray-border">
          <div className="query-result">
            <Message color="success" className="mam">
              <Message.Body>
                NOTE: Matik will be unable to insert this into a document. Please format the result using the form above
              </Message.Body>
            </Message>
            <pre>{JSON.stringify(testResult.result, null, 2)}</pre>
          </div>
        </Box>
      </Tile>
    );
  }

  return '';
}

SampleData.propTypes = {
  dynamicContentType: PropTypes.string,
  testResult: PropTypes.object,
  width: PropTypes.number,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps)(SampleData);
