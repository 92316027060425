import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Container, Form } from 'react-bulma-components';
import Api from '../../lib/api';
import { Prompt } from 'react-router-dom';
import CheckboxWithLabel from '../shared/CheckboxWithLabel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Heading } from 'react-bulma-components';
import Constants from '../Constants';
import SortDropdownSelector from '../shared/SortDropdownSelector';
import { UserContext } from '../UserContext';
import utils from '../../lib/utils';
import teams from '../../lib/teams';
import RadioInput from '../shared/RadioInput';
import EnterpriseUpsellTag from '../shared/EnterpriseUpsellTag';
import HighspotIntegrationSettings from './HighspotIntegrationSettings';
import { find } from 'lodash';
import MatikMailSettingsCard from './MatikMailSettingsCard';
import CustomFolderSettings from './CustomFolderSettings';
import FileUploadButton from '../shared/FileUploadButton';
import { MAlert } from '../shared/Alerts';
import xml_logo from '../../../src/images/XML.svg';
import ManageBillingButton from '../producer/account/ManageBillingButton';
import Button from '../lib/Button';

class EnterpriseSettingsSettings extends Component {
  constructor(props) {
    super(props);
    // App was originally built to only support custom folder for google drive.
    // Variable names reflect this. TODO?: Refactor variable names without introducing breaking changes.
    const custom_presentation_folder = this.props.enterprise.settings
      ? this.props.enterprise.settings.custom_presentation_folder
      : {};
    const highspot_spot_location = this.props.enterprise.settings?.highspot_spot_location;

    this.state = {
      bulk_generation_enabled: this.props.enterprise.settings
        ? this.props.enterprise.settings.bulk_generation_enabled
        : false,
      custom_presentation_folder: custom_presentation_folder,
      custom_office_365_presentation_folder: this.props.enterprise?.settings?.custom_office_365_presentation_folder,
      highspot_spot_location: highspot_spot_location,
      enable_highspot_integration: !!highspot_spot_location,
      integrations: this.props.enterprise.integrations,
      enable_presentation_folder_select: !!custom_presentation_folder,
      enable_office_365_presentation_folder_select:
        !!this.props.enterprise?.settings?.custom_office_365_presentation_folder,
      domain: this.props.enterprise ? this.props.enterprise.domain : '',
      isChanged: false,
      isLoading: false,
      name: this.props.enterprise ? this.props.enterprise.name : '',
      saml_metadata_url: this.props.enterprise ? this.props.enterprise.saml_metadata_url : '',
      sub_templates_enabled: this.props.enterprise.settings
        ? this.props.enterprise.settings.sub_templates_enabled
        : false,
      uses_azure: this.props.enterprise.settings ? this.props.enterprise.settings.uses_azure : false,
      uses_okta: this.props.enterprise.settings ? this.props.enterprise.settings.uses_okta : false,
      uses_generic_sso: this.props.enterprise.settings ? this.props.enterprise.settings.uses_generic_sso : false,
      uses_one_login: this.props.enterprise.settings ? this.props.enterprise.settings.uses_one_login : false,
      uses_google_sso: this.props.enterprise.settings ? this.props.enterprise.settings.uses_google_sso : false,
      template_default_sort_order: this.props.enterprise.settings
        ? this.props.enterprise.settings.template_default_sort_order
        : '',
      use_sso:
        this.props.enterprise.settings &&
        (this.props.enterprise.settings.uses_azure ||
          this.props.enterprise.settings.uses_okta ||
          this.props.enterprise.settings.uses_one_login ||
          this.props.enterprise.settings.uses_generic_sso ||
          this.props.enterprise.settings.uses_google_sso)
          ? true
          : false,
      sso_type:
        (this.props.enterprise.settings.uses_azure && Constants.SSO_TYPE.azure) ||
        (this.props.enterprise.settings.uses_okta && Constants.SSO_TYPE.okta) ||
        (this.props.enterprise.settings.uses_one_login && Constants.SSO_TYPE.onelogin) ||
        (this.props.enterprise.settings.uses_generic_sso && Constants.SSO_TYPE.generic) ||
        (this.props.enterprise.settings.uses_google_sso && Constants.SSO_TYPE.google),
      metadataFileInfo: this.props.enterprise.settings.uses_generic_sso
        ? this.props.enterprise.settings.sso_info['saml_metadata_url']
        : null,
      metadataFile: null,
      fileError: '',
      csv_bulk_enabled: this.props.enterprise.settings?.csv_bulk_enabled,
      disable_all_ai: this.props.enterprise.settings?.disable_all_ai,
    };
  }

  componentDidUpdate() {
    if (this.state.isChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.setState({ isChanged: false });
  }

  isCustomPresentationFolderEnabled() {
    return this.props.flags.customPresentationFolder;
  }

  isHighspotIntegrationEnabled() {
    return this.props.flags.highspotIntegration;
  }

  isGenericSSOEnabled() {
    return this.props.flags.genericSso;
  }

  isSSOEnabled() {
    let isEnabled = true;
    if (this.props.flags.ssoTierCheck) {
      isEnabled = this.props.enterprise.billing.plan_id === Constants.MATIK_TIERS.matik_enterprise.tier_id;
    }
    return isEnabled;
  }

  render() {
    if (this.state.isChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    let plan = this.props.enterprise.billing.plan_id
      ? Constants.MATIK_TIERS[this.props.enterprise.billing.plan_id].display_name
      : '';
    let status = this.props.enterprise.billing.subscription_status
      ? this.props.enterprise.billing.subscription_status
      : '';
    let googleAuth = null;
    const googleIntegration = utils.googleIntegration(this.context.user);
    if (googleIntegration) {
      googleAuth = {
        access_token: googleIntegration.access_token,
        expires_on: googleIntegration.expires_on,
      };
    }

    let useSSOElement = '';
    if (this.isSSOEnabled()) {
      useSSOElement = (
        <CheckboxWithLabel
          name="use_sso"
          checked={this.state.use_sso}
          id="use-sso"
          label=""
          onChange={this.onSSOChange}
        />
      );
    } else {
      useSSOElement = (
        <span className="team-text">
          <Heading size={5}>
            &nbsp;with&nbsp;
            <EnterpriseUpsellTag feature="sso" />
          </Heading>
        </span>
      );
    }

    return (
      <Container>
        <Box className="is-shadowless enterprise-settings-card">
          <form onSubmit={this.onEnterpriseUpdate}>
            <Form.Field>
              <Form.Label>Name</Form.Label>
              <Form.Control>
                <Form.Input value={this.state.name} onChange={this.onChange} name="name" />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <CheckboxWithLabel
                name="bulk_generation_enabled"
                checked={this.state.bulk_generation_enabled}
                id="bulk-generation-enabled"
                label={
                  <span>
                    Enable bulk generation in this enterprise&nbsp;
                    {teams.isTeamsUser(this.props.enterprise.billing.plan_id) ? (
                      <EnterpriseUpsellTag feature="bulk_generation" />
                    ) : (
                      ''
                    )}
                  </span>
                }
                onChange={this.onChange}
                disabled={teams.isTeamsUser(this.props.enterprise.billing.plan_id)}
              />
            </Form.Field>
            {this.isHighspotIntegrationEnabled() && (
              <Form.Field>
                <CheckboxWithLabel
                  name="enable_highspot_integration"
                  checked={!!this.state.enable_highspot_integration}
                  id="custom-spot-enabled"
                  label="Upload generated presentations to a Highspot Spot"
                  onChange={this.onChange}
                />
              </Form.Field>
            )}
            {this.state.enable_highspot_integration && (
              <HighspotIntegrationSettings
                highspotSpotLocation={this.state.highspot_spot_location}
                highspotIntegration={find(this.state.integrations, (i) => i.name === 'highspot')}
                onUpdateHighspotSpot={this.onUpdateHighspotSpot}
                onUpdateIntegration={this.onEnterpriseIntegrationUpdate}
                onDeleteEnterpriseIntegration={this.onDeleteEnterpriseIntegration}
              />
            )}
            <Form.Field>
              <Form.Label>Default Template Sort Order</Form.Label>
              <SortDropdownSelector
                entityType="template"
                name="sort-selector"
                initialValue={this.state.template_default_sort_order}
                onSortedChange={this.onSortedChange}
                width="100%"
              />
            </Form.Field>
            <Form.Field>
              <CheckboxWithLabel
                name="sub_templates_enabled"
                checked={this.state.sub_templates_enabled}
                id="sub-templates-enabled"
                label={
                  <span>
                    Enable sub templates in this enterprise&nbsp;
                    {teams.isTeamsUser(this.props.enterprise.billing.plan_id) ? (
                      <EnterpriseUpsellTag feature="subtemplates" />
                    ) : (
                      ''
                    )}
                  </span>
                }
                onChange={this.onChange}
                disabled={teams.isTeamsUser(this.props.enterprise.billing.plan_id)}
              />
            </Form.Field>
            <Form.Field>
              <CheckboxWithLabel
                name="csv_bulk_enabled"
                checked={this.state.csv_bulk_enabled}
                id="csv-bulk-enabled"
                label={
                  <span>
                    Enable CSV-powered bulk generation&nbsp;
                    {teams.isTeamsUser(this.props.enterprise.billing.plan_id) ? (
                      <EnterpriseUpsellTag feature="csv-bulk-enabled" />
                    ) : (
                      ''
                    )}
                  </span>
                }
                onChange={this.onChange}
                disabled={teams.isTeamsUser(this.props.enterprise.billing.plan_id)}
              />
            </Form.Field>
            <Form.Field>
              <CheckboxWithLabel
                name="disable_all_ai"
                checked={this.state.disable_all_ai}
                id="disable-all-ai"
                label={<span>Disable all AI in this account</span>}
                onChange={this.onChange}
                disabled={this.props.enterprise.settings?.ai_enabled}
                tooltip={
                  this.props.enterprise.settings?.ai_enabled
                    ? "You can't disable AI when you have enabled AI features"
                    : 'Disabling AI will disable all AI features in this account'
                }
              />
            </Form.Field>
          </form>
        </Box>
        {this.isCustomPresentationFolderEnabled() && (
          <CustomFolderSettings
            folder={this.state.custom_presentation_folder}
            enabled={this.state.enable_presentation_folder_select}
            o365FolderEnabled={this.state.enable_office_365_presentation_folder_select}
            oauthFunction={this.oauthFunction}
            handleSelectGoogleSheet={this.handleSelectGoogleSheet}
            auth={googleAuth}
            title="Select a Folder"
            onFolderSettingsChange={this.onFolderSettingsChange}
            customOffice365Folder={this.state.custom_office_365_presentation_folder}
          />
        )}
        {this.props.enterprise?.settings?.matik_mail_enabled && (
          <MatikMailSettingsCard enterprise={this.props.enterprise} />
        )}
        <Card className="is-shadowless mbl enterprise-settings-card">
          <Card.Header className="pal is-shadowless">
            <Card.Header.Title className="p-0">
              <Heading size={5}>SSO</Heading>
            </Card.Header.Title>
            <Card.Header.Icon className="p-0">
              <Heading size={5}>Use Single Sign On {useSSOElement}</Heading>
            </Card.Header.Icon>
          </Card.Header>
          <Card.Content>
            <Form.Field className="mbm">
              <RadioInput
                checked={this.state.uses_okta}
                disabled={!this.state.use_sso || !this.isSSOEnabled()}
                id="uses_okta"
                label="Use Okta as an identity provider"
                name="uses_okta"
                value="Okta"
                onChange={this.onSSOChange}
                className="mbs"
              />
              <RadioInput
                checked={this.state.uses_one_login}
                disabled={!this.state.use_sso || !this.isSSOEnabled()}
                id="uses_one_login"
                label="Use Onelogin as an identity provider"
                name="uses_one_login"
                value="OneLogin"
                onChange={this.onSSOChange}
                className="mbs"
              />
              <RadioInput
                checked={this.state.uses_azure}
                disabled={!this.state.use_sso || !this.isSSOEnabled()}
                id="uses_azure"
                label="Use Azure as an identity provider"
                name="uses_azure"
                value="Azure"
                onChange={this.onSSOChange}
                className="mbs"
              />
              <RadioInput
                checked={this.state.uses_google_sso}
                disabled={!this.state.use_sso || !this.isSSOEnabled()}
                id="uses_google_sso"
                label="Use Google as an identity provider"
                name="uses_google_sso"
                value="Google"
                onChange={this.onSSOChange}
                className="mbs"
              />
              {this.isGenericSSOEnabled() && (
                <RadioInput
                  checked={this.state.uses_generic_sso}
                  disabled={!this.state.use_sso || !this.isSSOEnabled()}
                  id="uses_generic_sso"
                  label="Use Generic SSO as an identity provider"
                  name="uses_generic_sso"
                  value="Generic"
                  onChange={this.onSSOChange}
                  className="mbs"
                />
              )}
            </Form.Field>
            {this.renderSSOFields(this.state.sso_type)}
          </Card.Content>
        </Card>
        {this.props.flags.enableEnterprisePaymentsSettings && (
          <Card className="is-shadowless mbl enterprise-settings-card">
            <Card.Header className="pal is-shadowless">
              <Heading size={5}>Plan and billing</Heading>
            </Card.Header>
            <Card.Content>
              <Heading size={6}>Plan: {plan}</Heading>
              <Heading size={6}>Subscription Status: {status}</Heading>
              {this.props.enterprise.is_self_serve && <ManageBillingButton />}
            </Card.Content>
          </Card>
        )}
        <Prompt
          when={this.state.isChanged}
          message="Are you sure you want to navigate away? There are unsaved changes."
        />
        <div className="fixed-update-button">
          <div className="buttons pointer-events-auto">
            <Button
              onClick={this.onEnterpriseUpdate}
              status={!this.state.isChanged || this.state.isLoading ? 'disabled' : 'default'}
              id="input-save"
            >
              Update
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  oauthFunction = (onOAuth) => {
    const onUpdate = (auth) => {
      if (onOAuth) {
        onOAuth();
      }
      this.context.updateUser(auth);
    };
    utils.openGoogleOauthPopup(this.context.user.nonce, onUpdate, 'prompt=consent');
  };

  handleSelectGoogleSheet = (data) => {
    if (data.docs) {
      this.setState({ custom_presentation_folder: data.docs[0], isChanged: true });
    }
  };

  renderSSOFields(sso_type) {
    if (
      sso_type === Constants.SSO_TYPE.azure ||
      sso_type === Constants.SSO_TYPE.okta ||
      sso_type === Constants.SSO_TYPE.onelogin
    ) {
      return (
        <React.Fragment>
          <Form.Field>
            <Form.Control>
              <Form.Label>SAML Metadata URL</Form.Label>
              <Form.Input value={this.state.saml_metadata_url} name="saml_metadata_url" onChange={this.onChange} />
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Form.Label>Domain</Form.Label>
              <Form.Input value={this.state.domain} name="domain" onChange={this.onChange} />
            </Form.Control>
          </Form.Field>
        </React.Fragment>
      );
    } else if (sso_type === Constants.SSO_TYPE.generic) {
      const fileData = {
        fileType: 'Metadata',
        mimeType: '.xml',
        file: this.state.metadataFile ? this.state.metadataFile : this.state.metadataFileInfo,
        logo: xml_logo,
        logo_alt: 'XML',
      };

      return (
        this.state.use_sso && (
          <FileUploadButton
            fileData={fileData}
            onFileSelected={this.onFileSelected}
            removeFile={this.removeFile}
            fileError={this.state.fileError}
          />
        )
      );
    } else {
      return '';
    }
  }

  onFileSelected = (e) => {
    if (e.target.files[0]) {
      this.setState({
        metadataFile: e.target.files[0],
        fileError: '',
        isChanged: true,
      });
    }
  };

  removeFile = () => {
    let ele = document.getElementById('upload-file');
    if (ele) {
      ele.value = null;
    }
    this.setState({
      metadataFile: null,
      metadataFileInfo: null,
      isChanged: true,
    });
  };

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({ [name]: value, isChanged: true });
  };

  onFolderSettingsChange = ({
    enable_presentation_folder_select,
    enable_office_365_presentation_folder_select,
    custom_office_365_presentation_folder,
  }) => {
    this.setState({
      isChanged: true,
      enable_presentation_folder_select,
      enable_office_365_presentation_folder_select,
      custom_office_365_presentation_folder,
    });
  };

  onSSOChange = (e) => {
    let usesAzure = false;
    let usesOkta = false;
    let usesOneLogin = false;
    let usesGoogleSso = false;
    let usesGenericSSO = false;
    if (e.target.name === 'uses_azure') {
      usesAzure = true;
      this.setState({ sso_type: Constants.SSO_TYPE.azure });
    } else if (e.target.name === 'uses_okta') {
      usesOkta = true;
      this.setState({ sso_type: Constants.SSO_TYPE.okta });
    } else if (e.target.name === 'uses_one_login') {
      usesOneLogin = true;
      this.setState({ sso_type: Constants.SSO_TYPE.onelogin });
    } else if (e.target.name === 'uses_google_sso') {
      usesGoogleSso = true;
      this.setState({ sso_type: Constants.SSO_TYPE.google });
    } else if (e.target.name === 'uses_generic_sso') {
      usesGenericSSO = true;
      this.setState({ sso_type: Constants.SSO_TYPE.generic });
    } else {
      this.setState({ use_sso: e.target.checked });
    }
    this.setState({
      uses_azure: usesAzure,
      uses_okta: usesOkta,
      uses_one_login: usesOneLogin,
      uses_google_sso: usesGoogleSso,
      uses_generic_sso: usesGenericSSO,
      isChanged: true,
    });
  };

  onSortedChange = (e) => {
    let value = e.join(' ');
    this.setState({ template_default_sort_order: value });
  };

  onEnterpriseIntegrationUpdate = (enterpriseIntegration) => {
    const updatedEnterprise = Object.assign({}, this.props.enterprise);
    let integrationInserted = false;
    if (updatedEnterprise.integrations) {
      for (let i = 0; i < updatedEnterprise.integrations.length; i++) {
        if (updatedEnterprise.integrations[i].name === enterpriseIntegration.name) {
          updatedEnterprise.integrations[i] = enterpriseIntegration;
          integrationInserted = true;
        }
      }
    }

    if (!integrationInserted) {
      updatedEnterprise.integrations.push(enterpriseIntegration);
    }
    this.props.onEnterpriseUpdate(updatedEnterprise);
    this.setState({ integrations: updatedEnterprise.integrations });
  };

  onUpdateHighspotSpot = (newSpot) => {
    this.setState({ highspot_spot_location: newSpot, isChanged: true });
  };

  onDeleteEnterpriseIntegration = (integrationName) => {
    const updatedEnterprise = Object.assign({}, this.props.enterprise);
    let newIntegrations = [];
    if (updatedEnterprise.integrations) {
      for (let integration of updatedEnterprise.integrations) {
        if (integration.name !== integrationName) {
          newIntegrations.push(integration);
        }
      }
    }

    updatedEnterprise.integrations = newIntegrations;
    this.props.onEnterpriseUpdate(updatedEnterprise);
    this.setState({ integrations: newIntegrations });
  };

  validateMetaDataFile = () => {
    if (!this.state.metadataFile && (!this.state.metadataFileInfo || !this.state.metadataFileInfo.name)) {
      this.setState({ fileError: 'File not found' });
      return false;
    }

    const filename = this.state.metadataFile ? this.state.metadataFile.name : this.state.metadataFileInfo.name;
    const fileExtension = filename.split('.').pop();
    //validate if file is .xml type
    if (fileExtension !== 'xml') {
      MAlert('Please select an valid metadata file.');
      return false;
    }
    return true;
  };

  onEnterpriseUpdate = (e) => {
    e.preventDefault();
    const custom_presentation_folder = this.state.enable_presentation_folder_select
      ? this.state.custom_presentation_folder
      : null;
    const custom_office_365_presentation_folder = this.state.enable_office_365_presentation_folder_select
      ? this.state.custom_office_365_presentation_folder
      : null;
    const updateData = {
      bulk_generation_enabled: this.state.bulk_generation_enabled,
      csv_bulk_enabled: this.state.csv_bulk_enabled,
      custom_presentation_folder: custom_presentation_folder,
      custom_office_365_presentation_folder: custom_office_365_presentation_folder,
      disable_all_ai: this.state.disable_all_ai,
      domain: this.state.domain ? this.state.domain : '',
      highspot_spot_location: this.state.enable_highspot_integration ? this.state.highspot_spot_location : '',
      name: this.state.name,
      saml_metadata_url:
        this.state.uses_okta || this.state.uses_one_login || this.state.uses_azure ? this.state.saml_metadata_url : '',
      sub_templates_enabled: this.state.sub_templates_enabled,
      uses_okta: this.state.uses_okta,
      uses_one_login: this.state.uses_one_login,
      uses_azure: this.state.uses_azure,
      uses_google_sso: this.state.uses_google_sso,
      uses_generic_sso: this.state.uses_generic_sso,
      template_default_sort_order: this.state.template_default_sort_order,
    };
    if (this.state.uses_generic_sso) {
      if (!this.validateMetaDataFile()) {
        return false;
      }
    }
    this.setState({ isLoading: true });
    Api.put(
      '/enterprises/current/',
      updateData,
      (response) => {
        this.setState({ isLoading: false, isChanged: false });
        this.props.onEnterpriseUpdate(response.data);

        const user = this.context.user;
        user.enterprise.enterprise_settings = response.data.settings;
        this.context.updateUser(user);
      },
      (err) => {
        this.setState({ isLoading: false });
        Api.defaultError(err);
      },
      this.state.metadataFile ?? undefined,
    );
  };
}

EnterpriseSettingsSettings.contextType = UserContext;

EnterpriseSettingsSettings.propTypes = {
  enterprise: PropTypes.object,
  onEnterpriseUpdate: PropTypes.func,
  flags: PropTypes.object,
};

export default withLDConsumer()(EnterpriseSettingsSettings);
