import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { UserContext } from '../UserContext';
import duplicate_icon from '../../images/duplicate.svg';
import select_all_icon from '../../images/select_all.svg';
import deselect_all_icon from '../../images/deselect_all.svg';
import AccessAvatarsWithShareButton from '../shared/AccessAvatarsWithShareButton';
import EditableTemplateName from '../shared/templates/EditableTemplateName';
import Pluralize from 'pluralize';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTemplateFavorites } from 'lib/hooks/useTemplate';
import { openModal } from 'redux/ui/action';
import Icon from '../lib/Icon';

function EndUserTemplateHeader(props) {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const { data: favorites, update: updateFavorite } = useTemplateFavorites();
  const isFavorite = favorites?.indexOf(props.template.id) > -1;

  const subTemplatesEnabled = user && user.enterprise.enterprise_settings.sub_templates_enabled;
  const showSubTemplateButton = !props.template.is_sub_template && subTemplatesEnabled;
  const showDeleteSubTemplateButton = props.template.is_sub_template;

  const showSubTemplateModal = (e) => {
    e.preventDefault();
    dispatch(openModal('subTemplateModal'));
  };

  return (
    <Level className="end-user-template-nav">
      <Level.Side align="left">
        <Level.Item>
          <Link to="/create/templates" className="button is-secondary is-round-button" id="end-user-template-back">
            <Icon name="chevron_left" size={16} theme="regular" />
          </Link>
        </Level.Item>
        <Level.Item>
          <EditableTemplateName
            template={props.template}
            orderedInputs={props.orderedInputs}
            onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
            canEdit={props.canEdit}
          />
        </Level.Item>
        <Level.Item className="has-text-grey is-flex-shrink-0">
          {Pluralize('slide', props.selectedCount, true)}
          {!props.isAllSelected ? ' selected' : ''}
        </Level.Item>
      </Level.Side>
      <Level.Side align="right">
        <Level.Item>
          <AccessAvatarsWithShareButton
            template={props.template}
            accessesByTemplateId={props.accessesByTemplateId}
            showShareButton={true}
          />
        </Level.Item>
        <Level.Item>
          <button
            className="button is-secondary"
            disabled={!favorites}
            onClick={() => updateFavorite(props.template, !isFavorite)}
          >
            {isFavorite ? (
              <span>
                <Icon name="star" size={16} theme="filled" />
                <span className="ml-2">Remove from Favorites</span>
              </span>
            ) : (
              <span>
                <Icon name="star" size={16} theme="regular" />
                <span className="ml-2">Add to Favorites</span>
              </span>
            )}
          </button>
        </Level.Item>
        {showSubTemplateButton && (
          <Level.Item>
            <button
              className="button is-secondary"
              onClick={showSubTemplateModal}
              id="end-user-template-add-sub-template"
            >
              <span className="icon is-small">
                <img src={duplicate_icon} alt="save as sub template" />
              </span>
              <span>Save as sub template</span>
            </button>
          </Level.Item>
        )}
        {showDeleteSubTemplateButton && (
          <Level.Item>
            <button
              className="button is-secondary"
              onClick={props.deleteSubTemplate}
              id="end-user-template-delete-sub-template"
            >
              <span>Delete Sub Template</span>
            </button>
          </Level.Item>
        )}
        <Level.Item>
          <div className="field has-addons">
            <div className="control">
              <button
                id="end-user-template-select-all"
                className="button is-secondary"
                onClick={() => props.toggleAllSlides(true)}
                disabled={props.isAllSelected ? true : undefined}
              >
                <span className="icon is-small">
                  <img src={select_all_icon} alt="select all" />
                </span>
                <span>Select All</span>
              </button>
            </div>
            <div className="control">
              <button
                id="end-user-template-deselect-all"
                className="button is-secondary"
                onClick={() => props.toggleAllSlides(false)}
                disabled={props.isAllDeselected ? true : undefined}
              >
                <span className="icon is-small">
                  <img src={deselect_all_icon} alt="deselect all" />
                </span>
                <span>Deselect All</span>
              </button>
            </div>
          </div>
        </Level.Item>
      </Level.Side>
    </Level>
  );
}

EndUserTemplateHeader.propTypes = {
  template: PropTypes.object,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
  canEdit: PropTypes.bool,
  isAllSelected: PropTypes.bool,
  isAllDeselected: PropTypes.bool,
  selectedCount: PropTypes.number,
  accessesByTemplateId: PropTypes.object,
  deleteSubTemplate: PropTypes.func,
  toggleAllSlides: PropTypes.func,
};

export default EndUserTemplateHeader;
