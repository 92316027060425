import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import FullPageList from '../../shared/FullPageList';
import utils from '../../../lib/utils';
import { ContentNameCell, DataSourceTypeCell } from '../../shared/FullPageListCells';
import moment from 'moment';
import { Box } from 'react-bulma-components';
import { withRouter } from 'react-router-dom';
import Constants from '../../Constants';

const ListWithFilterHeader = WithFilterListHeader(FullPageList);

class DataSourcesList extends Component {
  render() {
    const columns = [
      {
        id: 'type',
        Header: utils.tableHeader('Type'),
        accessor: 'type',
        Cell: DataSourceTypeCell,
        className: 'pvm is-size-6',
        maxWidth: 70,
      },
      {
        id: 'name',
        Header: utils.tableHeader('Name'),
        Cell: ContentNameCell,
        accessor: 'name',
        className: 'min-width-0 has-text-dark is-size-6',
      },
      {
        id: 'updated_on',
        Header: utils.tableHeader('Last Updated'),
        accessor: (d) => moment(d.updated_on),
        maxWidth: 140,
        className: 'is-size-6',
      },
    ];
    return (
      <Box className="is-shadowless is-paddingless is-fullheight">
        <ListWithFilterHeader
          entities={this.props.dataSources}
          newEntityUrl={this.props.newEntityUrl}
          columns={columns}
          title="Your Data Sources"
          buttonClass="is-secondary"
          selectedRow={this.props.selectedDataSource}
          type="data_source"
          scrollInElement={true}
          loading={this.props.isPaginating}
          newEntityButton="Add Data Source"
          filterPlaceholder="Search Data Sources"
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          hideTopPagination={true}
          onRowClick={this.onRowClick}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
        />
      </Box>
    );
  }
  onRowClick = (dataSourceId) => {
    this.props.history.push(`/data_sources/${dataSourceId}`);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };
}

DataSourcesList.propTypes = {
  dataSources: PropTypes.array,
  fetchItems: PropTypes.func,
  history: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  onDataSourceSelect: PropTypes.func,
  newEntityUrl: PropTypes.string,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDataSource: PropTypes.object,
};

export default withRouter(DataSourcesList);
