export function mapInputsStateToProps(state, ownProps) {
  const entityId = ownProps.entityId;
  const entityType = ownProps.entityType;
  const entity = `${entityType}_${entityId}`;
  const { inputValuesByEntity } = state;
  const inputValues = inputValuesByEntity && inputValuesByEntity[entity] ? inputValuesByEntity[entity] : {};
  return {
    inputValues,
  };
}
