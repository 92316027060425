import { UPDATE_UI_STATE, UPDATE_OVERFLOW } from './actionTypes';

export function ui(
  state = {
    fullScreenModal: null,
    modal: null,
    sidepane: null,
    editableField: null,
    googleSheetDrawer: null,
    isChanged: false,
    overflowIsHidden: false,
  },
  action,
) {
  switch (action.type) {
    case UPDATE_UI_STATE:
      switch (action.uiType) {
        case 'sidepane': {
          if (action.isActive && action.content) {
            const content = { ...action.content };
            if (!content.entityType) {
              if (content.matchingContent !== undefined) {
                content.entityType = 'dynamic_content';
              } else {
                content.entityType = 'input';
              }
            }
            return {
              ...state,
              [action.uiType]: {
                name: action.name,
                uiType: action.uiType,
                content: content,
              },
            };
          } else if (action.isActive && !action.content) {
            const content = {};
            content.entityType = 'dynamic_content';
            content.name = '';
            return Object.assign({}, state, {
              [action.uiType]: {
                name: action.name,
                uiType: action.uiType,
                content: content,
                defaultContentType: action.defaultContentType,
              },
            });
          } else if (!action.isActive) {
            return {
              ...state,
              [action.uiType]: null,
              isChanged: false,
            };
          }
          break;
        }
        case 'modal': {
          const stateWithModal = toggleComponent(action, state);
          return stateWithModal;
        }
        case 'googleSheetDrawer': {
          const stateWithDrawer = toggleComponent(action, state);
          return stateWithDrawer;
        }
        case 'editableField': {
          const stateWithField = toggleComponent(action, state);
          return stateWithField;
        }
        case 'isChanged': {
          const updatedState = {
            ...state,
            isChanged: action.name,
          };
          return updatedState;
        }
        case 'fullScreenModal': {
          const stateWithFullScreenModal = toggleComponent(action, state);
          return stateWithFullScreenModal;
        }
      }
      break;
    case UPDATE_OVERFLOW:
      return {
        ...state,
        overflowIsHidden: action.hiddenFlag,
      };
    default:
      return state;
  }
}

function toggleComponent(action, state) {
  if (action.isActive) {
    return {
      ...state,
      [action.uiType]: {
        name: action.name,
        isActive: action.isActive,
        uiType: action.uiType,
        content: action.content,
      },
    };
  } else if (!action.isActive) {
    return {
      ...state,
      [action.uiType]: null,
    };
  }
}
