import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FullPageList from './FullPageList';
import SmallLoader from './SmallLoader';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import edit_icon from '../../svg/edit.svg';
import Icon from '../lib/Icon';

const WithSelectedElement = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      loading: PropTypes.bool,
      isReadOnly: PropTypes.bool,
      startExpanded: PropTypes.bool,
      currentEntity: PropTypes.object,
      columns: PropTypes.array,
      onRowClick: PropTypes.func,
      onExpandClick: PropTypes.func,
      ancestorWantsToExpand: PropTypes.bool,
      closeSidepane: PropTypes.func,
      ui: PropTypes.object,
      openSidepane: PropTypes.func,
      toggleIsExpanded: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.state = {
        isExpanded: this.props.startExpanded,
      };
      this.ref = React.createRef();
    }

    componentDidUpdate() {
      if (!this.props.ancestorWantsToExpand && this.state.isExpanded) {
        this.setState({ isExpanded: false });
      }
    }

    componentWillUnmount() {
      if (!location.pathname.startsWith('/templates/')) {
        this.props.closeSidepane();
      }
    }

    render() {
      if (this.props.loading) {
        return <SmallLoader />;
      }

      // At the moment, Expanded view doesn't make much sense in read-only mode
      if (this.state.isExpanded && !this.props.isReadOnly) {
        return <WrappedComponent {...this.props} onRowClick={!this.props.isReadOnly && this.onRowClick} />;
      }

      // Collapsed view is just a contrived FullPageList with a single element, no headers, and other minor tweaks
      let downChevronCell;
      if (this.props.isReadOnly) {
        downChevronCell = () => <span />;
      } else {
        downChevronCell = () => (
          <>
            <button className="is-flex is-vertical-centered icon-button">
              <img
                src={edit_icon}
                width="16px"
                alt="Parameter Type"
                data-tooltip-id="matik-tooltip"
                data-tooltip-content="Edit"
                className="edit-condition mrm"
                ref={this.ref}
              />
            </button>
            <button
              className="is-flex is-vertical-centered icon-button"
              data-tooltip-content="Select"
              data-tooltip-id="matik-tooltip"
            >
              <Icon name="chevron_down" />
            </button>
          </>
        );
      }

      const collapsedColumns = this.props.columns
        .filter((column) => column.id !== 'updated_on')
        .map((column) => {
          delete column.Header;
          return column;
        });

      collapsedColumns.push({ id: 'down_chevron', Cell: downChevronCell, className: 'right-aligned' });

      return (
        <FullPageList
          entitiesToRender={[this.props.currentEntity]}
          columns={collapsedColumns}
          onHeaderClick={!this.props.isReadOnly && this.onExpandClick}
          buttonClass="is-primary"
          clickRef={this.ref}
        />
      );
    }

    onRowClick = (id) => {
      this.setState({ isExpanded: false });
      if (this.props.onRowClick) {
        return this.props.onRowClick(id);
      }
    };

    onExpandClick = (e) => {
      e.preventDefault();
      if (e.target === this.ref.current) {
        if (this.props.ui.sidepane) {
          window.open(`/dynamic_content/${this.props.currentEntity.id}`, '_blank').focus();
        } else {
          this.props.openSidepane({ ...this.props.currentEntity, entityType: 'dynamic_content' }, 'template-sidepane');
        }
      } else {
        this.props.toggleIsExpanded(e);
        this.setState({ isExpanded: true });
        if (this.props.onExpandClick) {
          return this.props.onExpandClick();
        }
      }
    };
  };
};

export default compose(connect(mapUiStateToProps, mapDispatchToProps), WithSelectedElement);
