import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import utils from '../../../lib/utils';
import { ContentNameCell, InputTypeCell } from '../../shared/FullPageListCells';
import moment from 'moment';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import FullPageList from '../../shared/FullPageList';
import Constants from '../../Constants';
import { Box } from 'react-bulma-components';

const PageWithFilterHeader = WithFilterListHeader(FullPageList);

class InputsList extends Component {
  render() {
    const columns = [
      {
        id: 'source_type',
        Header: utils.tableHeader('Type'),
        accessor: 'source_type',
        Cell: InputTypeCell,
        className: 'pvm is-size-6',
        maxWidth: 70,
      },
      {
        id: 'name',
        Header: utils.tableHeader('Name'),
        Cell: ContentNameCell,
        accessor: 'name',
        className: 'min-width-0 has-text-dark is-size-6',
      },
      {
        id: 'updated_on',
        Header: utils.tableHeader('Last Updated'),
        accessor: (d) => moment(d.updated_on),
        maxWidth: 140,
        className: 'is-size-6',
      },
    ];
    return (
      <Box className="is-shadowless is-paddingless is-fullheight">
        <PageWithFilterHeader
          entities={this.props.inputs}
          newEntityUrl="/inputs/new"
          type="input"
          title="Your Inputs"
          buttonClass="is-secondary"
          selectedRow={this.props.selectedInput}
          newEntityButton="Add Input"
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          filterPlaceholder="Search Inputs"
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          hideTopPagination={true}
          onRowClick={this.onRowClick}
          scrollInElement={true}
          loading={this.props.isPaginating}
          columns={columns}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
        />
      </Box>
    );
  }

  onRowClick = (inputId) => {
    this.props.history.push(`/inputs/${inputId}`);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };
}

InputsList.propTypes = {
  fetchItems: PropTypes.func,
  history: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  inputs: PropTypes.array,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedInput: PropTypes.object,
};

export default withRouter(InputsList);
