import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { find } from 'lodash';
import ApiFilters from './ApiFilters';
import ApiSorts from './ApiSorts';
import ApiLimit from './ApiLimit';
import ApiReturnFields from './ApiReturnFields';
import { Select } from '../../../shared/FormSelect';
import { MAlert } from '../../../shared/Alerts';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import ToggleSwitch from '../../../lib/ToggleSwitch';
import UniqueValuesFilter from '../UniqueValuesFilter';
function TableauForm({
  apiInfo,
  entityType,
  input,
  inputMapping,
  inputsInQueryString,
  onInputMappingUpdate,
  queryObj,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);

  const [filterDuplicates, setFilterDuplicates] = useState(
    queryObj.filterDuplicates ? queryObj.filterDuplicates : false,
  );

  const renderWorkbookSelect = (workbooks) => (
    <Form.Field className="mbl">
      <Form.Label>Workbook</Form.Label>
      <Form.Help>Select Tableau Workbook</Form.Help>
      <Form.Control>
        <Select
          aria-label="Select Tableau View"
          disabled={dynamicContentContext.isReadOnly}
          classNamePrefix="matik-select"
          value={queryObj.selectedWorkbook}
          name="selectedWorkbook"
          onChange={selectWorkbook}
          options={workbooks}
        />
      </Form.Control>
    </Form.Field>
  );

  const renderSheetSelect = () => {
    const selectedWorkbook = getFullSelectedWorkbook();
    let sheets = [];
    if (selectedWorkbook !== undefined) {
      sheets = selectedWorkbook['sheets'].map((sheet) => ({
        value: sheet['luid'],
        label: sheet['name'],
        isDisabled: !sheet['luid'],
      }));
    }
    return (
      <React.Fragment>
        <Form.Field className="mbl">
          <Form.Label>Worksheet</Form.Label>
          <Form.Help>
            Select Tableau Worksheet. Worksheet must be published in Tableau and only Tableau Worksheets are compatible
            with Matik.
          </Form.Help>
          <Form.Control>
            <Select
              aria-label="Select Tableau View"
              disabled={dynamicContentContext.isReadOnly}
              classNamePrefix="matik-select"
              value={queryObj.selectedSheet}
              name="selectedSubView"
              onChange={selectSheet}
              options={sheets}
            />
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );
  };

  const toggleFilterDuplicates = (bool = !filterDuplicates) => {
    setFilterDuplicates(bool);
    const updatedQueryObj = { ...queryObj, filterDuplicates: bool };
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const renderQueryFields = () => {
    const selectedWorkbook = getFullSelectedWorkbook();
    let selectedSheet = null;
    let fields = [];
    if (selectedWorkbook !== undefined) {
      selectedSheet = find(selectedWorkbook['sheets'], (sheet) => sheet['luid'] === queryObj.selectedSheet['value']);
      fields =
        selectedSheet && selectedSheet['sheetFieldInstances']
          ? selectedSheet['sheetFieldInstances'].map((field) => field['name'])
          : [];
    }
    const limit = !queryObj.query || !queryObj.query.limit ? '' : queryObj.query.limit.toString();
    return (
      <React.Fragment>
        <ApiReturnFields
          queryObj={queryObj}
          fields={fields}
          filterTitle="Filter Returned Fields"
          noOptionsMessage="No options returned, is the selected view a worksheet?"
          placeholder="Returning All Fields"
          entityType={entityType}
          input={input}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          canCreateReturnField={true}
        />
        <ApiFilters
          updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
          queryObj={queryObj}
          fields={fields}
          inputs={dynamicContentContext.existingInputs}
          isReadOnly={dynamicContentContext.isReadOnly}
          inputsInQueryString={inputsInQueryString}
          noOptionsMessage="No options returned, is the selected view a worksheet?"
          isInputPopoverDisabled={entityType === 'input'}
        />
        <ApiSorts
          fields={fields}
          queryObj={queryObj}
          updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
          isReadOnly={dynamicContentContext.isReadOnly}
        />
        <ApiLimit
          updateQueryObj={dynamicContentContext.onQueryObjectUpdate}
          queryObj={queryObj}
          limit={limit}
          isReadOnly={dynamicContentContext.isReadOnly}
        />
        <UniqueValuesFilter filterDuplicates={filterDuplicates} toggleFilterDuplicates={toggleFilterDuplicates} />
        <Form.Field>
          <Form.Label>Set maximum data age</Form.Label>
          <Form.Help>Avoid stale data by setting a maximum age in minutes</Form.Help>
          <ToggleSwitch className="my-1.5" switchState={queryObj.maxAgeEnabled} onClick={(e) => toggleMaxAge(e)} />
          {queryObj.maxAgeEnabled && (
            <Form.Input className="!w-16" type="number" value={queryObj.maxAge || 1} onChange={updateMaxAge} min="1" />
          )}
        </Form.Field>
      </React.Fragment>
    );
  };

  const getFullSelectedWorkbook = () => {
    if (apiInfo && queryObj && queryObj.selectedWorkbook) {
      const selectedWorkbook = find(
        apiInfo['workbooks'],
        (workbook) => workbook['luid'] === queryObj.selectedWorkbook['value'],
      );
      if (selectedWorkbook === undefined) {
        MAlert('You may need to ensure it still exists before creating content', 'Missing Tableau Workbook', 'error');
      } else {
        return selectedWorkbook;
      }
    }
  };

  const selectWorkbook = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj.selectedWorkbook = obj;
      updatedQueryObj.selectedSheet = null;
      updatedQueryObj.returnFieldsByName = {};
      updatedQueryObj.query = {};
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const selectSheet = (obj, action) => {
    if (action.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj);
      updatedQueryObj.selectedSheet = obj;
      updatedQueryObj.returnFieldsByName = {};
      updatedQueryObj.query = {};
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const toggleMaxAge = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    updatedQueryObj.maxAgeEnabled = !queryObj.maxAgeEnabled;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const updateMaxAge = (e) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    updatedQueryObj.maxAge = e.target.value;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  let workbooks = [];
  if (apiInfo && apiInfo['workbooks']) {
    workbooks = apiInfo['workbooks']
      .filter((workbook) => !!workbook['name'])
      .map((workbook) => ({ label: workbook.name, value: workbook.luid }));
  }

  return (
    <>
      {workbooks && renderWorkbookSelect(workbooks)}
      {queryObj.selectedWorkbook && renderSheetSelect()}
      {queryObj.selectedSheet && renderQueryFields()}
    </>
  );
}

TableauForm.propTypes = {
  apiInfo: PropTypes.object,
  dataSourceId: PropTypes.number,
  fetchApiInfoIfNeeded: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  queryObj: PropTypes.object,
  updateQueryObj: PropTypes.func,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
};

export default TableauForm;
