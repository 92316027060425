import React, { useContext, useEffect, useState } from 'react';
import AnalyticsRechart from './AnalyticsRechart';
import { Box, Heading, Level } from 'react-bulma-components';
import AnalyticsUserSelector from './AnalyticsUserSelector';
import AnalyticsGroupSelector from './AnalyticsGroupSelector';
import AnalyticsTemplateSelector from './AnalyticsTemplateSelector';
import AnalyticsPeriodSelector from './AnalyticsPeriodSelector';
import AnalyticsDateRangePicker from './AnalyticsDateRangePicker';
import API from '../../../lib/api';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AnalyticsColumnChartUpsell from './AnalyticsColumnChartUpsell';
import teams from '../../../lib/teams';
import { UserContext } from '../../UserContext';
import { toast } from 'react-toastify';
import utils from '../../../lib/utils';
import Button from '../../lib/Button';

function AnalyticsColumnChart() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(2, 'weeks').startOf('day'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days').startOf('day'));
  const [period, setPeriod] = useState('day');
  const [selectedPeriod, setSelectedPeriod] = useState({ label: 'Daily', value: 'day' });
  const [presentationChartData, setPresentationChartData] = useState([]);

  useEffect(() => {
    fetchChartData();
  }, [startDate, endDate, users, groups, templates, period]);

  const userContext = useContext(UserContext);

  const { enableAnalyticsGroupSelect } = useFlags();

  const constructUrlParams = () => {
    const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
    const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

    return new URLSearchParams({
      userIds: JSON.stringify(users.map((user) => user.value)),
      groupIds: JSON.stringify(groups.map((group) => group.value)),
      templateIds: JSON.stringify(templates.map((template) => template.value)),
      startDate: startDateStr,
      endDate: endDateStr,
      period: period,
      selectedPeriod: selectedPeriod,
    });
  };

  const fetchChartData = () => {
    setIsLoading(true);
    API.get('/analytics/generated_presentations/?' + constructUrlParams(), (response) => {
      setPresentationChartData(response.data);
    }).finally(() => setIsLoading(false));
  };

  const fetchCsvData = () => {
    setIsLoading(true);
    API.get('/analytics/generated_presentations_csv/?' + constructUrlParams(), (response) => {
      if (response.data.status === 'success') {
        utils.notify('Your analytics CSV is processing! We’ll email you the file once it’s ready.', toast.TYPE.SUCCESS);
      }
    }).finally(() => setIsLoading(false));
  };

  const updateUsers = (users) => {
    setUsers(users);
  };

  const updateGroups = (groups) => {
    setGroups(groups);
  };

  const updateTemplates = (templates) => {
    setTemplates(templates);
  };

  const updateDates = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const updatePeriod = (selectedPeriod, period) => {
    setSelectedPeriod(selectedPeriod);
    setPeriod(period);
  };

  let daysDiff = 0;
  let daysAllowedForDownload = 365;
  if (startDate && endDate) {
    daysDiff = endDate.diff(startDate, 'days');
  }
  const csvDisabled = daysDiff > daysAllowedForDownload;
  let buttonStatus = 'default';
  if (csvDisabled) {
    buttonStatus = 'disabled';
  } else if (isLoading) {
    buttonStatus = 'loading';
  }

  return (
    <Box className="analytics-column-chart is-shadowless has-light-gray-border">
      {userContext?.user && !teams.isTeamsUser(userContext?.user?.enterprise?.plan_id) ? (
        <React.Fragment>
          <Level>
            <Level.Side align="left" className="prm pll">
              <Level.Item>
                <Heading size={5}>Generated Presentations</Heading>
              </Level.Item>
            </Level.Side>
            <Level.Side align="right">
              <Level.Item>
                <AnalyticsUserSelector
                  updateQueryParams={(queryParams) => updateUsers(queryParams.users)}
                  selectedUsers={users}
                />
              </Level.Item>
              {enableAnalyticsGroupSelect && (
                <Level.Item>
                  <AnalyticsGroupSelector
                    updateQueryParams={(queryParams) => updateGroups(queryParams.groups)}
                    selectedGroups={groups}
                  />
                </Level.Item>
              )}
              <Level.Item>
                <AnalyticsTemplateSelector
                  updateQueryParams={(queryParams) => updateTemplates(queryParams.templates)}
                  selectedTemplates={templates}
                />
              </Level.Item>
              <Level.Item>
                <AnalyticsDateRangePicker
                  updateQueryParams={(queryParams) => updateDates(queryParams.startDate, queryParams.endDate)}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Level.Item>
              <Level.Item>
                <AnalyticsPeriodSelector
                  updateQueryParams={(queryParams) => updatePeriod(queryParams.selectedPeriod, queryParams.period)}
                  selectedPeriod={selectedPeriod}
                />
              </Level.Item>
            </Level.Side>
          </Level>
          <AnalyticsRechart presentationData={presentationChartData} isLoading={isLoading} />
          <div className="flex justify-end m-2">
            <Button
              category="secondary"
              onClick={fetchCsvData}
              status={buttonStatus}
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={csvDisabled ? 'Please limit date range to one year for CSV download' : null}
            >
              Download CSV
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <AnalyticsColumnChartUpsell />
      )}
    </Box>
  );
}

export default AnalyticsColumnChart;
