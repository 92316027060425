import React, { Component } from 'react';
import { PresentationContext } from './PresentationContext';

function WithPresentationContextProvider(WrappedComponent) {
  return class Inner extends Component {
    constructor(props) {
      super(props);
      this.state = {
        regenerate: false,
        presentationToRegenerate: {},
        regenerateTemplateChanged: false,
        overrideConditions: false,
      };
    }

    render() {
      const presentationContext = {
        regenerate: this.state.regenerate,
        presentationToRegenerate: this.state.presentationToRegenerate,
        regenerateTemplateChanged: this.state.regenerateTemplateChanged,
        overrideConditions: this.state.overrideConditions,
        setRegenerate: this.setRegenerate,
        setPresentationToRegenerate: this.setPresentationToRegenerate,
        setRegenerateTemplateChanged: this.setRegenerateTemplateChanged,
        getInputValues: this.getInputValues,
        getSelectedSlides: this.getSelectedSlides,
        clearPresentationContext: this.clearPresentationContext,
        setOverrideConditions: this.setOverrideConditions,
      };

      return (
        <PresentationContext.Provider value={presentationContext}>
          <WrappedComponent {...this.props} />
        </PresentationContext.Provider>
      );
    }

    setOverrideConditions = (overrideConditions) => {
      this.setState({ overrideConditions });
    };

    setRegenerate = (regenerate) => {
      this.setState({ regenerate });
    };

    setPresentationToRegenerate = (presentation) => {
      this.setState({ presentationToRegenerate: presentation });
    };

    setRegenerateTemplateChanged = (templateChanged) => {
      this.setState({ regenerateTemplateChanged: templateChanged });
    };

    getInputValues = () => {
      let inputValues = {};
      if (this.state.presentationToRegenerate) {
        for (let param in this.state.presentationToRegenerate.params) {
          inputValues[param] = { value: this.state.presentationToRegenerate.params[param] };
        }
      }
      return inputValues;
    };

    getSelectedSlides = (presentationSlides) => {
      let selectedSlideNums = {};
      if (presentationSlides) {
        // TODO: Presentation slide_ids is not currently a reliable way to determine
        // which slides were selected when the presentation was generated. Better to
        // select all than the wrong set of slides.
        // https://matik.atlassian.net/browse/MPD-4075

        const slideIds = null; // this.state.presentationToRegenerate?.slide_ids;
        if (slideIds) {
          for (let num = 1; num <= presentationSlides.length; num++) {
            const slideId = presentationSlides[num - 1].id;
            selectedSlideNums[num] = slideIds.indexOf(slideId) > -1;
          }
        } else {
          for (let num = 1; num <= presentationSlides.length; num++) {
            selectedSlideNums[num] = true;
          }
        }
      }
      return selectedSlideNums;
    };

    clearPresentationContext = () => {
      this.setState({
        regenerate: false,
        presentationToRegenerate: {},
        regenerateTemplateChanged: false,
      });
    };
  };
}

export default WithPresentationContextProvider;
