import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pluralize from 'pluralize';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserContext } from 'components/UserContext';
import AccessManager from 'lib/AccessManager';
import Modal from 'components/shared/modal/Modal';
import useAccesses, { useAccessMutator } from 'lib/hooks/useAccess';
import { closeModal } from 'redux/ui/action';
import AddAccessField from 'components/shared/AddAccessField';
import AccessElement from 'components/shared/AccessElement';

function BulkAccessModal(props) {
  const { user } = useContext(UserContext);

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const { data: accessesByItemId } = useAccesses(props.entityType);
  const { del: deleteAccesses, update: updateAccesses } = useAccessMutator();
  const flags = useFlags();

  const accessManagers = props.itemIds?.map((itemId) => {
    return new AccessManager(itemId, accessesByItemId, user);
  });
  const canEdit = accessManagers?.length > 0 && accessManagers.every((access) => access.can('edit'));
  if (!user || !canEdit) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeModal());
  };
  const handleAdd = (accessors, permission) => {
    updateAccesses(props.entityType, props.itemIds, permission, accessors);
  };

  // The old way: all dynamic content includes "all end user: read" permissions, so
  // no need grant access to add individual end users.
  // The new way: DC can be read-restricted to end users, so we'll want the include
  // the ability to grant individual end users access.
  // Note: once we've enabled data-source permissions for everyone, we can remove this logic.
  const onlyAddProducers = props.entityType === 'dynamic_content' && !flags.dataSourcePermissions;

  const accessesByAccessor = AccessManager.accessesByAccessorKey(accessManagers);

  const title = `Manage access for ${Pluralize('item', props.itemIds.length, true)}`;
  return (
    <Modal title={title} onClose={handleClose} show={ui.modal?.name === 'bulkAccessModal'}>
      <div className="pb-4">
        <AddAccessField producersOnly={onlyAddProducers} onAdd={handleAdd} />
      </div>

      <div>
        <div className="access-container">
          <div className="grid grid-cols-[1fr_auto_auto] items-center gap-y-2 pt-2">
            {Object.values(accessesByAccessor).map((accesses) => {
              const accessor = accesses[0].accessor;
              return (
                <AccessElement
                  key={accessor.type + accessor.id}
                  itemIds={props.itemIds}
                  itemType={props.entityType}
                  accessor={accessor}
                  accesses={accesses}
                  canEdit={true}
                  onDelete={deleteAccesses}
                  onUpdate={updateAccesses}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}
BulkAccessModal.propTypes = {
  itemIds: PropTypes.array.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default BulkAccessModal;
