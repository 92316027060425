import Constants from '../components/Constants';
import utils from './utils';
import Pluralize from 'pluralize';
import team_icon from '../images/team.svg';

class AccessManager {
  constructor(itemId, accessesByItemId, currentUser) {
    this.itemId = itemId;
    this.accessesByItemId = accessesByItemId ? accessesByItemId : {};
    this.currentUser = currentUser;
  }

  accesses() {
    return this.accessesByItemId && this._sortedAccesses();
  }

  _sortedAccesses() {
    const compare = (a, b) => {
      if (a.permission === Constants.PERMISSIONS.owner.value && b.permission !== Constants.PERMISSIONS.owner.value) {
        return -1;
      } else if (
        b.permission === Constants.PERMISSIONS.owner.value &&
        a.permission !== Constants.PERMISSIONS.owner.value
      ) {
        return 1;
      }
      if (a.accessor.type === 'group' && b.accessor.type !== 'group') {
        return -1;
      } else if (b.accessor.type === 'group' && a.accessor.type !== 'group') {
        return 1;
      }
      return a.accessor.id - b.accessor.id;
    };
    return this.accessesByItemId[this.itemId] ? this.accessesByItemId[this.itemId].sort(compare) : [];
  }

  isShared() {
    const accesses = this.accessesByItemId[this.itemId];
    return accesses && accesses.length > 0;
  }

  /** True if current user is the owner and no one else has read-access. */
  isPrivateToMe() {
    if (this.currentUserIsOwner()) {
      const accesses = this.accessesByItemId[this.itemId];
      return !accesses?.some((access) => access.permission !== Constants.PERMISSIONS.owner.value);
    } else {
      return false;
    }
  }

  bigListSharedColumnAvatars() {
    let avatarSrc = '';
    let avatarName = '';
    const avatars = [];
    if (!this.isShared()) {
      if (this.currentUser) {
        avatarName = this.currentUser.name ? this.currentUser.name : this.currentUser.email;
        avatarSrc = this.currentUser.photo_url ? this.currentUser.photo_url : '';
      }
      avatars.push({ accessId: 1, src: avatarSrc, name: avatarName });
      return avatars;
    }

    const accesses = this.accessesByItemId[this.itemId];

    for (let i = 0; i < accesses.length; i++) {
      const access = accesses[i];
      let avatarSrc = '';
      let avatarName = '';
      if (access.accessor.type === 'group') {
        avatarSrc = team_icon;
        avatarName = access.accessor.name;
      } else {
        avatarSrc = access.accessor.photo_url ? access.accessor.photo_url : '';
        avatarName = access.accessor.name ? access.accessor.name : access.accessor.email;
      }
      avatars.push({ accessId: access.id, src: avatarSrc, name: avatarName });
    }

    return avatars;
  }

  /**
   * Returns the element for the shared column for the big list component. For now just a span with a string
   */
  bigListSharedColumnText() {
    if (!this.isShared()) {
      return 'Only visible to you';
    }

    const accesses = this.accessesByItemId[this.itemId];
    let userCount = 0;
    let producerGroup = false;
    let consumerGroup = false;
    for (let access of accesses) {
      if (access.accessor.type === 'user') {
        userCount++;
      } else if (access.accessor.name === 'All Admins') {
        producerGroup = true;
      } else if (access.accessor.name === 'All End Users') {
        consumerGroup = true;
      }
    }

    let sharedWithArray = [];
    if (producerGroup) {
      sharedWithArray.push('all admins');
    }
    if (consumerGroup) {
      sharedWithArray.push('all end users');
    }
    if (userCount > 0) {
      sharedWithArray.push(Pluralize('users', userCount, true));
    }

    return `Shared with ${utils.convertArrayToEnglish(sharedWithArray)}`;
  }

  can(permissionName) {
    if (this.currentUserIsOwner()) {
      return true;
    }

    return this.currentUserAccesses().indexOf(permissionName) >= 0;
  }

  currentUserIsOwner() {
    return this.currentUserAccesses().indexOf(Constants.PERMISSIONS.owner.value) >= 0;
  }

  currentUserAccesses() {
    let accesses = {};
    const accessesByAccessor = this.accessesByAccessor();
    accesses = this._parseRoleAccesses(accessesByAccessor, accesses, 'consumer');
    accesses = this._parseRoleAccesses(accessesByAccessor, accesses, 'producer');
    accesses = this._parseGroupAccesses(accessesByAccessor, accesses);
    if (this.currentUser) {
      const currentUserAccessId = AccessManager.getAccessorKey('user', this.currentUser.id);
      if (accessesByAccessor[currentUserAccessId]) {
        for (let access in accessesByAccessor[currentUserAccessId]) {
          accesses[accessesByAccessor[currentUserAccessId][access].permission] = true;
        }
      }
    }
    return Object.keys(accesses);
  }

  _parseRoleAccesses(accessesByAccessor, accesses, role) {
    const roleId = Constants.ROLE_ID_MAPPING[role];
    const accessKey = AccessManager.getAccessorKey('group', roleId);
    if (accessesByAccessor[accessKey] && this.currentUser && this.currentUser.roles.indexOf(role) >= 0) {
      for (let access in accessesByAccessor[accessKey]) {
        accesses[accessesByAccessor[accessKey][access].permission] = true;
      }
    }

    return accesses;
  }

  _parseGroupAccesses(accessesByAccessor, accesses) {
    if (this.currentUser && this.currentUser.group_ids) {
      for (let groupId of this.currentUser.group_ids) {
        const accessKey = AccessManager.getAccessorKey('group', groupId);
        if (accessesByAccessor[accessKey]) {
          for (let access in accessesByAccessor[accessKey]) {
            accesses[accessesByAccessor[accessKey][access].permission] = true;
          }
        }
      }
    }

    return accesses;
  }

  accessesByAccessor() {
    const accesses = this.accessesByItemId[this.itemId] ? this.accessesByItemId[this.itemId] : [];
    const accessesByAccessor = {};
    for (let access of accesses) {
      const accessKey = AccessManager.getAccessorKey(access.accessor.type, access.accessor.id);
      if (!accessesByAccessor[accessKey]) {
        accessesByAccessor[accessKey] = [access];
      } else {
        accessesByAccessor[accessKey].push(access);
      }
    }
    return accessesByAccessor;
  }

  static getAccessorKey(accessorType, accessorId) {
    return `${accessorType}_${accessorId}`;
  }

  static parseAccessorKey(accessorKey) {
    const accessorArray = accessorKey.split('_');
    return { type: accessorArray[0], id: parseInt(accessorArray[1], 10) };
  }

  static accessesByAccessorKey(accessManagers) {
    if (!accessManagers.length) {
      return {};
    }
    const allAccesses = accessManagers.reduce((accumulatedAccesses, access) => {
      return accumulatedAccesses.concat(access.accesses());
    }, []);
    let accessesByAccessor = {};
    for (let access of allAccesses) {
      const accessorKey = AccessManager.getAccessorKey(access.accessor_type, access.accessor_id);
      if (accessesByAccessor[accessorKey]) {
        accessesByAccessor[accessorKey].push(access);
      } else {
        accessesByAccessor[accessorKey] = [access];
      }
    }
    return accessesByAccessor;
  }
}

export default AccessManager;
