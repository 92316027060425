import React from 'react';
import PropTypes from 'prop-types';
import { Level } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import Constants from '../../Constants';
import unknown_icon from '../../../images/unknown.svg';
import replace_icon from '../../../images/replace.svg';
import TextOverflowWithTooltip from 'components/shared/TextOverflowWithTooltip';

function ContentCard(props) {
  const { content, handleClick, hovering, link, onChangeContent, onClose, showEditIcon } = props;

  const imgSrc = Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.type.toLowerCase()]
    ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[content.type.toLowerCase()].icon
    : unknown_icon;
  let titleClassName;
  let formatsClassName;
  if (content.isChild) {
    titleClassName = 'dc-content-title-child';
    formatsClassName = 'dc-content-formats-child';
  } else {
    titleClassName = `dc-content-title ${showEditIcon ? 'with-edit' : ''}`;
    formatsClassName = 'dc-content-formats';
  }

  return (
    <>
      {link ? (
        <div
          className={`param-card has-cursor-pointer text-overflow-ellipsis ${hovering ? 'hover' : ''}`}
          id={`param-card-${content.id}`}
          onClick={onClose}
        >
          <Link to={link}>
            <Level className={`has-text-black ${content.formats ? 'pts' : 'mts'}`}>
              <Level.Side align="left">
                <Level.Item>
                  <img src={imgSrc} alt="Content Type" />
                </Level.Item>
                <div className={`content-card-container ${link && 'is-connected-content'}`}>
                  <TextOverflowWithTooltip
                    className={`text-overflow-ellipsis min-width-0 ${titleClassName}`}
                    text={content.name}
                  />
                  <span>
                    <TextOverflowWithTooltip
                      className={`text-overflow-ellipsis min-width-0 ${formatsClassName} has-text-grey is-size-7`}
                      text={content.formats ? content.formats.join(', ') : ''}
                    />
                  </span>
                </div>
              </Level.Side>
              <Level.Side align="right">
                {onChangeContent && showEditIcon && (
                  <Level.Item onClick={onChangeContent}>
                    <img
                      src={replace_icon}
                      alt="Replace Content"
                      data-tooltip-id="matik-tooltip"
                      data-tooltip-content="Change Content"
                    />
                  </Level.Item>
                )}
              </Level.Side>
            </Level>
          </Link>
        </div>
      ) : (
        <div
          className={`param-card has-cursor-pointer text-overflow-ellipsis ${hovering ? 'hover' : ''}`}
          id={`param-card-${content.id}`}
          onClick={() => handleClick(content)}
        >
          <Level className={`has-cursor-pointer has-text-black ${content.formats ? 'pts' : 'mts'}`}>
            <Level.Side align="left">
              <Level.Item>
                <img src={imgSrc} alt="Content Type" />
              </Level.Item>
              <div className="content-card-container">
                <TextOverflowWithTooltip
                  className={`text-overflow-ellipsis min-width-0 ${titleClassName}`}
                  text={content.name}
                />
                <span>
                  <TextOverflowWithTooltip
                    className={`text-overflow-ellipsis min-width-0 ${formatsClassName} has-text-grey is-size-7`}
                    text={content.formats ? content.formats.join(', ') : ''}
                  />
                </span>
              </div>
            </Level.Side>
            <Level.Side align="right">
              {onChangeContent && showEditIcon && (
                <Level.Item onClick={onChangeContent}>
                  <img
                    src={replace_icon}
                    alt="Replace Content"
                    data-tooltip-id="matik-tooltip"
                    data-tooltip-content="Change Content"
                  />
                </Level.Item>
              )}
            </Level.Side>
          </Level>
        </div>
      )}
    </>
  );
}

ContentCard.propTypes = {
  content: PropTypes.object,
  hovering: PropTypes.bool,
  link: PropTypes.string,
  onChangeContent: PropTypes.func,
  showEditIcon: PropTypes.bool,
  handleClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default ContentCard;
