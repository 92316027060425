import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ConditionIconPill, { getConditionCount } from 'components/producer/conditions/ConditionIconPill';
import useAllConditions, {
  useConditionTargetMutator,
  useConditionsInTemplate,
  useSlideConditionTargets,
} from 'lib/hooks/useCondition';
import PopupMenu from 'components/shared/PopupMenu';
import ConditionGroups from 'components/producer/conditions/ConditionGroups';
import Button from 'components/lib/Button';
import ButtonGroup from 'components/lib/ButtonGroup';
import Icon from 'components/lib/Icon';
import ConditionContextPopupMenu from '../conditions/ConditionContextPopupMenu';
import { useAllDynamicContentById } from 'lib/hooks/useDynamicContent';

const ConditionSlideButton = ({
  template,
  slide,
  selectedSlides,
  onCopy,
  copiedConditions,
  onSelectMatchingSlides,
  onMenuOpen,
}) => {
  const { elements: conditions } = useAllConditions(0, 999);
  const { upsertSlides: upsertSlideConditions } = useConditionTargetMutator();

  const conditionsInTemplate = useConditionsInTemplate(template);
  const { data: slideConditionTarget } = useSlideConditionTargets([slide.id])[slide.id];
  const conditionClauses = slideConditionTarget?.condition_clauses;

  const conditionsById = {};
  if (conditions) {
    conditions.forEach((condition) => {
      conditionsById[condition.id] = condition;
    });
  }
  const menuRef = useRef();
  const manageFormRef = useRef();
  const pillRef = useRef();

  const toggleManageCondition = () => {
    menuRef.current.close();

    if (manageFormRef.current.isOpen) {
      manageFormRef.current.close();
    } else {
      manageFormRef.current.open();
    }
  };
  const toggleContextMenu = () => {
    manageFormRef.current.close();

    if (menuRef.current.isOpen) {
      menuRef.current.close();
    } else {
      menuRef.current.open();
      onMenuOpen();
    }
  };
  const handleConditionFormClose = () => {
    manageFormRef.current.close();
  };

  const handleUpdate = (updatedConditions) => {
    upsertSlideConditions({ [slide.id]: updatedConditions });
  };
  const handleRemoveAll = () => {
    upsertSlideConditions({ [slide.id]: null });
  };

  const count = getConditionCount(conditionClauses);

  return (
    <div className={`${count === 0 ? 'hidden group-hover/slide:block' : ''}`}>
      <ConditionIconPill
        ref={pillRef}
        conditionClauses={conditionClauses}
        conditionsById={conditionsById}
        onMenuClick={toggleContextMenu}
        onIndicatorClick={toggleManageCondition}
      />
      <ConditionContextPopupMenu
        ref={menuRef}
        anchorRef={pillRef}
        selectedSlides={selectedSlides}
        template={template}
        onCopyConditions={onCopy}
        onSelectSlidesMatchingConditions={onSelectMatchingSlides}
        copiedConditions={copiedConditions}
      />
      <PopupMenu ref={manageFormRef} anchorRef={pillRef}>
        <ManageConditionForm
          conditionsInTemplate={conditionsInTemplate}
          conditionClauses={conditionClauses}
          conditionsById={conditionsById}
          onClose={handleConditionFormClose}
          onSave={handleUpdate}
          onRemoveAll={handleRemoveAll}
        />
      </PopupMenu>
    </div>
  );
};
ConditionSlideButton.propTypes = {
  template: PropTypes.shape({
    slides: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  }),
  selectedSlides: PropTypes.array,
  slide: PropTypes.shape({
    id: PropTypes.number,
    condition_clauses: PropTypes.object,
  }),
  onCopy: PropTypes.func,
  copiedConditions: PropTypes.object,
  onSelectMatchingSlides: PropTypes.func,
  onMenuOpen: PropTypes.func,
};

export default ConditionSlideButton;

const ManageConditionForm = ({
  conditionClauses,
  conditionsInTemplate,
  conditionsById,
  onClose,
  onRemoveAll,
  onSave,
}) => {
  const [conditionClausesWip, setConditionClausesWip] = useState(conditionClauses);
  useEffect(() => {
    setConditionClausesWip(conditionClauses);
  }, [JSON.stringify(conditionClauses)]);

  const { dynamicContentById: allDynamicContentNamesById } = useAllDynamicContentById();

  const handleSave = () => {
    onSave(conditionClausesWip);
    onClose();
  };

  return (
    <div className="bg-matik-white shadow-m rounded-lg w-[640px] cursor-auto flex flex-col max-h-screen">
      {/*
        note: bulma also defines these padding classes, but not with the same size as tailwind.
        "!" is used to use tailwind definitions instead.
      */}
      <div className="!p-6 overflow-x-auto">
        <ConditionGroups
          conditionsInTemplate={conditionsInTemplate}
          conditionClauses={conditionClausesWip}
          conditionsById={conditionsById}
          dynamicContentById={allDynamicContentNamesById}
          onChange={setConditionClausesWip}
        />
      </div>
      <div className="!py-5 !px-6 border-t border-grey-300 flex items-center">
        <ButtonGroup width="hug" gap={3}>
          <Button
            category="secondary"
            onClick={() => {
              onRemoveAll();
              onClose();
            }}
          >
            <div className="flex gap-2 items-center">
              <Icon name="trash_can" size={16} theme="regular" />
              <span>Remove All</span>
            </div>
          </Button>
          <Button category="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
        <div className="ml-auto">
          <Button onClick={handleSave}>Save Condition</Button>
        </div>
      </div>
    </div>
  );
};
ManageConditionForm.propTypes = {
  conditionClauses: PropTypes.object,
  conditionsInTemplate: PropTypes.array,
  conditionsById: PropTypes.object,
  onClose: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onSave: PropTypes.func,
};
