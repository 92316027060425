import Constants from '../../components/Constants';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../api';
import utils from '../utils';
import { addNotificationSuccess } from '../../redux/notifications/action';
import LongRequest from '../longRequest';

export const useDynamicContent = (
  offset = 0,
  limit = Constants.PAGE_SIZE,
  sort = ['updated_on', 'desc'],
  keepPreviousData = true, // e.g. when paginating
) => {
  let fullUrl = API.generate_paginated_url('/dynamic_content/', offset, limit, sort);

  const { isLoading, isError, data, error, isPreviousData, refetch } = useQuery({
    queryKey: ['dynamic_content', offset, limit, sort],
    queryFn: () => {
      return API.get(
        `${fullUrl}&lite-params=true`,
        (response) => {
          return {
            count: API.getCountFromResponse(response),
            dynamicContent: response.data,
          };
        },
        (err) => {
          throw err;
        },
      );
    },
    cacheTime: 0,
    keepPreviousData,
  });

  const pagination = data ? utils.getPaginationFromRequest(data?.count, limit, offset, sort) : undefined;

  return {
    isLoading,
    isPreviousData,
    isError,
    count: data?.count,
    dynamicContent: data?.dynamicContent,
    error,
    pagination,
    refetch,
  };
};

/** Used to fetch a limited view of all dynamic for use in selection dropdowns */
export const useAllDynamicContentById = () => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['all_dynamic_content_by_id'],
    queryFn: () => {
      return new Promise((resolve, reject) =>
        API.get(
          '/dynamic_content/names/',
          (response) => {
            const dynamicContentById = {};
            response.data.forEach((content) => (dynamicContentById[content.id] = content));
            resolve({
              dynamicContentById: dynamicContentById,
            });
          },
          (err) => {
            // eslint-disable-next-line no-console
            console.error('Could not load all dynamic content by name, got error', err);
            reject(err);
          },
        ),
      );
    },
    cacheTime: 0,
  });

  return {
    isLoading,
    isError,
    error,
    dynamicContentById: data?.dynamicContentById,
  };
};

export const useOneDynamicContent = (dynamicContentId) => {
  const parsedId = parseInt(dynamicContentId);
  const {
    isInitialLoading: isLoading,
    isError,
    data,
    error,
  } = useQuery({
    queryKey: ['one_dynamic_content', parsedId],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        if (dynamicContentId === Constants.NEW_CONTENT_ID || !dynamicContentId) {
          resolve({ dynamicContent: null });
        }

        API.get(
          `/dynamic_content/${parsedId}/`,
          (response) => {
            resolve({
              dynamicContent: response.data,
            });
          },
          (err) => {
            // eslint-disable-next-line no-console
            console.error('Could not load one dynamic content, got error', err);
            reject(err);
          },
        );
      });
    },
    enabled: !!dynamicContentId && !isNaN(parsedId),
    cacheTime: 0,
  });

  return {
    isLoading,
    isError,
    error,
    dynamicContent: data?.dynamicContent,
  };
};

export const useDynamicContentTemplates = (dynamicContentId) => {
  const parsedId = parseInt(dynamicContentId);
  const {
    isInitialLoading: isLoading,
    isError,
    data,
    error,
  } = useQuery({
    queryKey: ['one_dynamic_content', parsedId, 'templates'], // So it will be invalidated when parent dc is
    queryFn: () => {
      return new Promise((resolve, reject) =>
        API.get(
          `/dynamic_content/${parsedId}/templates/`,
          (response) =>
            resolve({ templates: response.data.templates, allTemplatesCount: response.data.all_templates_count }),
          (err) => {
            // Set everything to 0 and pass along an error to be rendered
            reject({
              parsedId,
              response: { data: { templates: [], allTemplatesCount: 0 } },
              error: err,
            });
          },
        ),
      );
    },
    enabled: !!dynamicContentId && !isNaN(parsedId),
    cacheTime: 0,
  });

  return {
    isLoading,
    isError,
    error,
    templates: data?.templates,
    allTemplatesCount: data?.allTemplatesCount,
  };
};

export const useDynamicContentMutator = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const create = (dynamicContentData) => {
    return new Promise((resolve, reject) =>
      API.post(
        '/dynamic_content/',
        dynamicContentData,
        (response) => {
          dispatch(addNotificationSuccess('Content added'));

          queryClient.setQueryData(['one_dynamic_content', parseInt(response.data.new_entity.id)], {
            dynamicContent: response.data.new_entity,
          });
          queryClient.invalidateQueries({ queryKey: ['all_dynamic_content_by_id'] });
          queryClient.invalidateQueries({ queryKey: ['dynamic_content'] });
          resolve(response);
        },
        (err) => reject(err),
      ),
    );
  };

  const update = (dynamicContentId, dynamicContentData) => {
    return new Promise((resolve, reject) =>
      API.put(
        `/dynamic_content/${dynamicContentId}/`,
        dynamicContentData,
        (response) => {
          const updatedContent = response.data.updated_entity;
          dispatch(addNotificationSuccess('Content updated'));
          queryClient.setQueryData(['one_dynamic_content', parseInt(response.data.updated_entity.id)], {
            dynamicContent: response.data.updated_entity,
          });
          queryClient.invalidateQueries({ queryKey: ['all_dynamic_content_by_id'] });
          queryClient.invalidateQueries({ queryKey: ['dynamic_content'] });
          resolve(updatedContent);
        },
        (err) => reject(err),
      ),
    );
  };

  const updateWithTemplates = (dynamicContentId, dynamicContentData, onProcessing) => {
    const getStatusCallback = (response, onComplete, onDone) => {
      let statusMessage = '';
      if (response.data.status === 'done') {
        onComplete();
        onDone();
      } else if (response.data.status === 'unknown') {
        // This may happen if there is a race condition: another user loads the templates page in between status checks
        onComplete();
        window.location.reload();
      } else if (response.data.status === 'processing') {
        if (statusMessage !== response.data.message) {
          onProcessing(response.data.message);
        }
      }
    };

    return new Promise((resolve, reject) =>
      API.put(
        `/dynamic_content/${dynamicContentId}/`,
        dynamicContentData,
        (response) => {
          const updatedContent = response.data.updated_entity;
          const longRequest = new LongRequest('/dynamic_content');
          longRequest.getStatus(
            response['data']['dynamic_content_status_id'],
            (response, onComplete) => {
              getStatusCallback(response, onComplete, () => {
                dispatch(addNotificationSuccess(response.data.message));

                queryClient.setQueryData(['one_dynamic_content', parseInt(updatedContent.id)], {
                  dynamicContent: updatedContent,
                });
                queryClient.invalidateQueries({ queryKey: ['all_dynamic_content_by_id'] });
                queryClient.invalidateQueries({ queryKey: ['dynamic_content'] });
                resolve(updatedContent);
              });
            },
            (err) => reject(err),
          );
        },
        (err) => reject(err),
      ),
    );
  };

  const deleteContent = (dynamicContentId) => {
    return new Promise((resolve, reject) =>
      API.delete(
        `/dynamic_content/${dynamicContentId}/`,
        () => {
          dispatch(addNotificationSuccess('Content deleted'));
          queryClient.invalidateQueries({
            queryKey: ['one_dynamic_content', parseInt(dynamicContentId)],
            refetchType: 'none',
          });
          queryClient.invalidateQueries({ queryKey: ['all_dynamic_content_by_id'] });
          queryClient.invalidateQueries({ queryKey: ['dynamic_content'] });
          resolve();
        },
        (err) => reject(err),
      ),
    );
  };

  return {
    create,
    update,
    updateWithTemplates,
    deleteContent,
  };
};

export const useBulkDynamicContentMutator = () => {
  const queryClient = useQueryClient();
  const bulkInvalidate = (dynamicContentIds) => {
    queryClient.invalidateQueries({
      queryKey: ['one_dynamic_content'],
      predicate: (q) => {
        if (q.queryKey.length === 2) {
          return dynamicContentIds.indexOf(q.queryKey[1]) >= 0;
        }
        return false;
      },
    });
    queryClient.invalidateQueries({ queryKey: ['all_dynamic_content_by_id'] });
    queryClient.invalidateQueries({ queryKey: ['dynamic_content'] });
    queryClient.invalidateQueries({ queryKey: ['access', 'dynamic_content'] });
  };

  const bulkUpdateDataSource = (dynamicContentIds, dataSourceId) => {
    return new Promise((resolve, reject) =>
      API.post(
        '/dynamic_content/bulk/data_source/',
        { data_source_id: dataSourceId, dynamic_content_ids: dynamicContentIds },
        (response) => {
          bulkInvalidate(dynamicContentIds);
          resolve(response);
        },
        (err) => reject(err),
      ),
    );
  };

  const bulkDelete = (dynamicContentIds) => {
    return new Promise((resolve, reject) =>
      API.post(
        '/dynamic_content/bulk_delete/',
        { dynamic_content_ids: dynamicContentIds },
        (response) => {
          bulkInvalidate(dynamicContentIds);
          resolve(response);
        },
        (err) => reject(err),
      ),
    );
  };

  return {
    bulkDelete,
    bulkUpdateDataSource,
  };
};
