import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Section, Level } from 'react-bulma-components';

import TagTree from 'lib/tagTree';
import utils from 'lib/utils';
import API from 'lib/api';
import inputs from 'lib/inputs';

import DocumentTemplateSidebar from 'components/producer/templates/DocumentTemplateSidebar';
import Banner from 'components/shared/Banner';
import DocumentView from 'components/shared/DocumentView';
import { useTemplateContent, useTemplateLinkedObjectStatus, useTemplateResyncStatus } from 'lib/hooks/useTemplate';

function DocumentTemplateComponents({
  allDynamicContent,
  currentTemplate,
  onSyncTemplate,
  onReviewPermissions,
  onContentClick,
  onInputClick,
}) {
  const ui = useSelector((state) => state.ui);

  const { data: templateContent, isPending: isContentFetching } = useTemplateContent(
    currentTemplate?.id,
    currentTemplate?.deleted,
  );

  useEffect(() => {
    API.track('input_form_in_template_sidepane');
  }, []);

  const { dynamicContentTags, templateInputs, attachmentInputs } = findTemplateContentTagsAndInputs(
    isContentFetching,
    currentTemplate,
    templateContent,
    allDynamicContent,
  );

  const wrapperClass = ui.googleSheetDrawer ? 'template-wrapper-with-sheet' : 'template-wrapper';

  return (
    <div className={wrapperClass}>
      <div className="template-components">
        <DocumentTemplateSidebar
          dynamicContentTags={dynamicContentTags}
          inputsInMatchingContent={templateInputs}
          attachmentInputsInMatchingContent={attachmentInputs}
          isContentFetching={isContentFetching}
          currentTemplate={currentTemplate}
          onContentClick={onContentClick}
          onInputClick={onInputClick}
        />
      </div>
      <Section className="is-paddingless template-slides document">
        <Banners
          currentTemplate={currentTemplate}
          onSyncTemplate={onSyncTemplate}
          onReviewPermissions={onReviewPermissions}
        />
        <DocumentView className="mvxl mhl" template={currentTemplate} />
      </Section>
    </div>
  );
}
DocumentTemplateComponents.propTypes = {
  allDynamicContent: PropTypes.object,
  currentTemplate: PropTypes.object,
  onSyncTemplate: PropTypes.func,
  onReviewPermissions: PropTypes.func,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
};

export default DocumentTemplateComponents;

const flattenTags = (tagTree) => {
  return tagTree?.flattenTree((a) => {
    if (!a.matchingContent) {
      return -1;
    } else {
      return 1;
    }
  });
};
const findTemplateContentTagsAndInputs = (isContentFetching, currentTemplate, templateContent, allDynamicContent) => {
  let allTagTree = new TagTree();
  let flattenedContentTags = [];
  if (!isContentFetching) {
    allTagTree = utils.getDynamicContentTags(currentTemplate, templateContent, allDynamicContent);
    flattenedContentTags = flattenTags(allTagTree);
  }

  const allTags = allTagTree.getTagNodes();
  const allInputsOnTemplate = inputs.getAllInputs(allTags, allDynamicContent, currentTemplate, templateContent, null);

  return {
    dynamicContentTags: flattenedContentTags,
    templateInputs: allInputsOnTemplate,
  };
};

const Banners = ({ currentTemplate, onSyncTemplate, onReviewPermissions }) => {
  const {
    data: needsResync,
    isWarningShown: isResyncWarningShown,
    hideWarning: hideResyncWarning,
  } = useTemplateResyncStatus(currentTemplate);

  const {
    data: linkedObjectStatus,
    isWarningShown: isLinkedObjectWarningShown,
    hideWarning: hideLinkedObjectWarning,
  } = useTemplateLinkedObjectStatus(currentTemplate);

  const showNeedsUpdate = needsResync && isResyncWarningShown;
  const showPermissionsMissing = linkedObjectStatus?.should_update && isLinkedObjectWarningShown;

  if (!showNeedsUpdate && !showPermissionsMissing) {
    return null;
  }

  const handleOpenGoogleDocsTab = () => {
    window.open(currentTemplate.source.url, '_blank');
  };

  const handleRequestPermissions = (e) => {
    e.preventDefault();
    linkedObjectStatus?.linked_objects?.forEach((element) => {
      if (element.status_code !== 200) {
        let url = `https://docs.google.com/${element.type}/d/${element.id}/edit`;
        window.open(url, `${element.type} - ${element.id}`);
      }
    });
  };

  const handleSyncTemplate = () => {
    hideResyncWarning();
    onSyncTemplate();
  };

  return (
    <div className="ptl prl pll template-banners">
      {showNeedsUpdate && (
        <Level className="grid-header">
          <Banner
            bannerType="warning"
            text="Connected Google Docs Template is outdated"
            sublineText="This document might not be generated as expected"
            primaryButtonLabel="Sync Template"
            onPrimaryButtonClick={handleSyncTemplate}
            secondaryButtonLabel="Open in Google Docs"
            onSecondaryButtonClick={handleOpenGoogleDocsTab}
            onDismiss={() => hideResyncWarning()}
          />
        </Level>
      )}
      {showPermissionsMissing && (
        <Level className="grid-header">
          <Banner
            bannerType="error"
            text="Permissions for linked objects are missing"
            sublineText="Missing permission can lead to generation errors"
            primaryButtonLabel="Review"
            onPrimaryButtonClick={onReviewPermissions}
            secondaryButtonLabel="Request permission"
            onSecondaryButtonClick={handleRequestPermissions}
            onDismiss={() => hideLinkedObjectWarning()}
          />
        </Level>
      )}
    </div>
  );
};
Banners.propTypes = {
  currentTemplate: PropTypes.object,
  onSyncTemplate: PropTypes.func,
  onReviewPermissions: PropTypes.func,
};
