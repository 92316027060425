import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { find, findIndex, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  Colors,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import PaddingInput from 'components/shared/PaddingInput';
import FormInput from 'components/shared/FormInput';
import Indicator from './Indicator';
import { ReactComponent as TextAlignLeftIcon } from 'svg/text_align_left.svg';
import { ReactComponent as TextAlignCenterIcon } from 'svg/text_align_center.svg';
import { ReactComponent as TextAlignRightIcon } from 'svg/text_align_right.svg';
import utils from 'lib/utils';
import { useDispatch } from 'react-redux';
import { openSidepane } from 'redux/ui/action';
import Constants from 'components/Constants';
import HtmlBuilderChartTypeDropdown from '../HtmlBuilderChartTypeDropdown';
import { PickColor } from '../../PickColor';
import ChartSubTypeSelect from '../../charts/ChartSubTypeSelect';
import { CreatableSelect, Select } from '../../FormSelect';
import Icon from '../../../lib/Icon';
import htmlUtils from '../../../../lib/html';
import ToggleSwitchWithLabel from '../../../lib/ToggleSwitchWithLabel';
import { HtmlBuilderContext } from '../../../producer/email/EmailBodyHtml';
import { MAlert } from '../../../shared/Alerts';
import HtmlBuilderVisualizeEmptyState from './HtmlBuilderVisualizeEmptyState';
import InputDynamicContent from '../../../producer/dynamicContent/InputDynamicContent';
import { useAllDynamicContentById } from '../../../../lib/hooks/useDynamicContent';

ChartJS.register(
  ChartDataLabels,
  ChartjsPluginStacked100,
  Colors,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  SubTitle,
  Title,
  Tooltip,
);

const chartComponentMap = {
  column: Bar,
  bar: Bar,
  donut: Doughnut,
  area: Line,
  line: Line,
  pie: Pie,
};

const chartTypeOptions = [
  { label: 'Column Chart', value: 'column', icon: <Icon name="data" size={20} theme="regular" /> },
  { label: 'Bar Chart', value: 'bar', icon: <Icon name="bar_chart" size={20} theme="regular" /> },
  { label: 'Line Chart', value: 'line', icon: <Icon name="line_chart" size={20} theme="regular" /> },
  { label: 'Area Chart', value: 'area', icon: <Icon name="line_chart" size={20} theme="regular" /> },
  { label: 'Pie Chart', value: 'pie', icon: <Icon name="pie_chart" size={20} theme="regular" /> },
  { label: 'Donut Chart', value: 'donut', icon: <Icon name="donut_chart" size={20} theme="regular" /> },
];

const axisOptions = [
  { label: 'Vertical Axis', value: 'y' },
  { label: 'Horizontal Axis', value: 'x' },
];

const chartDefaultColors = [
  '#3279EF',
  '#ACD1F4',
  '#004585',
  '#7B61FF',
  '#C8BDFF',
  '#3D2D8F',
  '#2ACCAF',
  '#B4E8C8',
  '#11836E',
  '#F69D00',
  '#FFDC9E',
  '#7B4F00',
  '#DF3C71',
  '#F2B1C6',
  '#8F2347',
];

const stopPropagationIfNeeded = (e) => {
  if (e.stopPropagation instanceof Function) {
    e.stopPropagation();
  }
};

function Chart({
  backgroundColor,
  chartAlignment,
  chartDc,
  previewDc,
  chartOptions,
  chartData,
  chartType,
  style,
  width,
  height,
  isNewNode,
}) {
  const chartRef = useRef();
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(Object.keys(previewDc).length > 0 ? true : false);
  const { testResult, setAllDynamicContent, allDynamicContent, setTestResult, setIsFetchingDc, onPreviewDcModal } =
    useContext(HtmlBuilderContext);

  const {
    id,
    actions: { setProp },
  } = useNode((node) => ({
    id: node.id,
  }));

  useEffect(() => {
    chartRef?.current?.resize(width, height);
  }, [width, height]);

  const {
    actions: { selectNode },
    isActive,
    isHover,
  } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
    isHover: query.getEvent('hovered').contains(id),
  }));

  const {
    connectors: { connect, drag },
  } = useNode((state) => ({
    hasDraggedNode: state.events.dragged,
  }));

  useEffect(() => {
    if (isNewNode) {
      selectNode(id);
      setProp((props) => {
        props.isNewNode = false;
      });
    }
  }, []);

  // on preview dynamic content
  function onPreviewDc() {
    if (isReceivingData) {
      setIsReceivingData(false);
      setIsFetchingDc(false);
    } else {
      setIsReceivingData(true);
      const previewChartDc = {
        [chartDc.name]: chartDc,
      };
      setAllDynamicContent(previewChartDc);
    }
  }

  // when allDynamicContent is set open inputs modal
  useEffect(() => {
    if (isReceivingData && Object.keys(allDynamicContent).length > 0) {
      onPreviewDcModal();
    }
  }, [allDynamicContent]);

  // on test result return
  useEffect(() => {
    if (isReceivingData && Object.keys(testResult).length > 0) {
      if (testResult?.status === 'error') {
        MAlert(`${testResult.message}`, 'Error', 'error');
        setIsFetchingDc(false);
      } else {
        setProp((props) => (props.previewDc = testResult[chartDc.name]));
        setIsPreviewed(true);
        setIsFetchingDc(false);
      }
      setIsReceivingData(false);
      setTestResult({});
    }
  }, [testResult]);

  useEffect(() => {
    if (isPreviewed && Object.keys(previewDc).length > 0) {
      const newChartData = buildChartData(previewDc);
      setProp((props) => (props.chartData = newChartData));
    }
  }, [previewDc]);

  function buildChartData(dynamicContent) {
    const datasets = [];
    const labels = dynamicContent.slice(1).map((row) => row[0]);

    dynamicContent[0].forEach((label, idx) => {
      if (idx > 0) {
        datasets.push({
          label,
          data: [],
          fill: chartData.datasets[idx - 1]?.fill || chartDefaultColors[idx - 1],
          backgroundColor: chartData.datasets[idx - 1]?.backgroundColor || chartDefaultColors[idx - 1],
          borderWidth: chartData.datasets[idx - 1]?.borderWidth || 2,
          borderColor: chartData.datasets[idx - 1]?.borderColor || chartDefaultColors[idx - 1],
          pointRadius: chartData.datasets[idx - 1]?.pointRadius || 2,
          pointBackgroundColor: chartData.datasets[idx - 1]?.pointBackgroundColor || chartDefaultColors[idx - 1],
          pointBorderColor: chartData.datasets[idx - 1]?.pointBorderColor || chartDefaultColors[idx - 1],
        });
      }
    });

    dynamicContent.forEach((row, idx) => {
      if (idx > 0) {
        row.forEach((datapoint, _idx) => {
          if (_idx > 0) {
            datasets[_idx - 1].data.push(datapoint);
          }
        });
      }
    });

    const updatedChartData = {
      labels: labels,
      datasets: datasets,
    };

    return updatedChartData;
  }

  const ChartComponent = chartComponentMap[chartType.value];

  return (
    <div
      className="is-relative is-transparent"
      ref={(dom) => connect(dom)}
      style={{ display: 'flex', justifyContent: chartAlignment }}
    >
      {(isActive || isHover) && (
        <Indicator
          borderWidth="0px"
          borderRadius="4px"
          tabText="Chart"
          isActive={isActive}
          nodeId={id}
          drag={drag}
          onPreviewDc={Object.keys(chartDc).length > 0 ? onPreviewDc : null}
          isPreviewed={isPreviewed}
          placeholder={Object.keys(chartDc).length > 0 && !isPreviewed}
        />
      )}
      {Object.keys(chartDc).length > 0 ? (
        <div style={style} data-chartDc={`{{${chartDc.name}}}`}>
          <ChartComponent
            ref={chartRef}
            type={chartType.value}
            data={chartData}
            options={chartOptions}
            redraw={true}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
          />
        </div>
      ) : (
        <HtmlBuilderVisualizeEmptyState dataType="chart" />
      )}
    </div>
  );
}

Chart.craft = {
  props: {
    backgroundColor: '#FFFFFF',
    chartAlignment: 'center',
    chartDc: {},
    previewDc: {},
    isNewNode: true,
    chartOptions: {
      font: {
        family: 'Arial',
      },
      indexAxis: 'x',
      layout: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      plugins: {
        datalabels: {
          display: false,
          color: '#000000',
          font: {
            size: 10,
          },
        },
        customCanvasBackgroundColor: {
          color: '#FFFFFF',
        },
        events: [],
        legend: {
          display: true,
          position: 'top',
          align: 'center',
          labels: {
            font: {
              weight: 'normal',
              size: 10,
            },
          },
          onClick: (e) => stopPropagationIfNeeded(e),
        },
        stacked100: {
          enable: false,
          replaceTooltipLabel: false,
        },
        subtitle: {
          display: true,
          text: 'Chart Subtitle',
          padding: 10,
          align: 'center',
          position: 'top',
          color: '#000000',
          font: {
            weight: 'normal',
            size: 10,
          },
        },
        title: {
          display: true,
          text: 'Chart Module',
          padding: 10,
          align: 'center',
          position: 'top',
          color: '#000000',
          font: {
            weight: 'normal',
            size: 12,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
          },
          ticks: {
            display: true,
            font: {
              size: 10,
              family: 'Arial',
            },
          },
          title: {
            display: true,
            text: 'x axis title',
            color: '#000000',
            font: {
              size: 10,
              family: 'Arial',
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: true,
          },
          ticks: {
            display: true,
            font: {
              size: 10,
              family: 'Arial',
            },
          },
          title: {
            display: true,
            text: 'y axis title',
            color: '#000000',
            font: {
              size: 10,
              family: 'Arial',
            },
          },
        },
      },
    },
    chartData: {
      labels: ['Red', 'Blue', 'Green'],
      datasets: [
        {
          label: 'Trace 1',
          data: [12, 19, 3],
          fill: chartDefaultColors[0],
          backgroundColor: chartDefaultColors[0],
          borderColor: chartDefaultColors[0],
          borderWidth: 2,
          pointRadius: 2,
          pointBackgroundColor: chartDefaultColors[0],
          pointBorderColor: chartDefaultColors[0],
        },
      ],
    },
    chartType: chartTypeOptions[0],
    chartRef: {},
    type: 'bar',
    width: 600,
    height: 400,
  },
  related: {
    settings: ChartSettings,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
    canMoveIn: () => false,
    canMoveOut: () => false,
  },
};

Chart.propTypes = {
  backgroundColor: PropTypes.string,
  chartAlignment: PropTypes.string,
  chartDc: PropTypes.object,
  previewDc: PropTypes.object,
  chartOptions: PropTypes.object,
  chartData: PropTypes.array,
  chartType: PropTypes.object,
  style: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  isNewNode: PropTypes.bool,
};

function ChartSettings() {
  const {
    actions: { setProp },
    chartAlignment,
    chartDc,
    chartOptions,
    chartData,
    chartType,
  } = useNode((node) => ({
    chartAlignment: node.data.props.chartAlignment,
    chartDc: node.data.props.chartDc,
    chartOptions: node.data.props.chartOptions,
    chartData: node.data.props.chartData,
    chartType: node.data.props.chartType,
  }));

  const [selectedChartSeriesIndex, setSelectedChartSeriesIndex] = useState(0);
  const [selectedAxis, setSelectedAxis] = useState('y');
  const [currentInput, setCurrentInput] = useState('');
  const [title, setTitle] = useState(chartOptions.plugins.title.text);
  const [subtitle, setSubtitle] = useState(chartOptions.plugins.subtitle.text);
  const [xTitle, setXTitle] = useState(chartOptions.scales.x.title.text);
  const [yTitle, setYTitle] = useState(chartOptions.scales.y.title.text);
  const [widthInput, setWidthInput] = useState(600);
  const [heightInput, setHeightInput] = useState(400);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    clearTimeout(timer);

    setTimer(
      setTimeout(async () => {
        if (currentInput === 'title') {
          setProp((props) => {
            props.chartOptions.plugins.title.text = title;
          });
        }
        if (currentInput === 'subtitle') {
          setProp((props) => {
            props.chartOptions.plugins.subtitle.text = subtitle;
          });
        }
        if (currentInput === 'xTitle') {
          setProp((props) => {
            props.chartOptions.scales[selectedAxis].title.text = xTitle;
          });
        }
        if (currentInput === 'yTitle') {
          setProp((props) => {
            props.chartOptions.scales[selectedAxis].title.text = yTitle;
          });
        }
        if (currentInput === 'width') {
          setProp((props) => {
            props.width = widthInput;
          });
        }
        if (currentInput === 'height') {
          setProp((props) => {
            props.height = heightInput;
          });
        }
      }, 1000),
    );
  }, [title, subtitle, xTitle, yTitle, widthInput, heightInput]);

  const dispatch = useDispatch();

  const { dynamicContentById } = useAllDynamicContentById();

  const fontFamilyOptions = htmlUtils.constants.fontFamilyOptions;

  const onUpdateNumberValue = (e, propName) => {
    setProp((props) => {
      // eslint-disable-next-line react/prop-types
      if (propName === 'width') {
        setCurrentInput('width');
        setWidthInput(parseInt(e.target.value));
      } else if (propName === 'height') {
        setCurrentInput('height');
        setHeightInput(parseInt(e.target.value));
      } else {
        props.style[propName] = e.target.value;
      }
    });
  };

  const onChartAlignmentChange = (e, direction, element) => {
    e.preventDefault();
    setProp((props) => {
      if (element === 'title') {
        props.chartOptions['plugins']['title']['align'] = direction;
      } else if (element === 'subtitle') {
        props.chartOptions['plugins']['subtitle']['align'] = direction;
      } else if (element === 'legend') {
        props.chartOptions['plugins']['legend']['align'] = direction;
      } else {
        props.chartAlignment = direction;
      }
    });
  };

  const onPositionChange = (e, position, element) => {
    e.preventDefault();
    setProp((props) => {
      if (element === 'title') {
        props.chartOptions['plugins']['title']['position'] = position;
      } else if (element === 'subtitle') {
        props.chartOptions['plugins']['subtitle']['position'] = position;
      } else if (element === 'legend') {
        props.chartOptions['plugins']['legend']['position'] = position;
      }
    });
  };

  // const onChartResponsivenessChange = (e, toggleOn) => {
  //   e.preventDefault();
  //   setProp((props) => {
  //     props.chartOptions.responsive = !toggleOn;
  //   });
  // };

  const onPaddingChange = (num, paddingProp) => {
    setProp((props) => {
      if (!props.chartOptions.layout) {
        props.chartOptions['layout'] = {
          padding: {},
        };
      }
      if (!props.chartOptions.layout.padding) {
        props.chartOptions.layout['padding'] = {};
      }
      // eslint-disable-next-line react/prop-types
      if (paddingProp === 'titlePadding') {
        props.chartOptions['plugins']['title']['padding'] = num;
      }
      if (paddingProp === 'subtitlePadding') {
        props.chartOptions['plugins']['subtitle']['padding'] = num;
      }
      if (paddingProp === 'legendPadding') {
        props.chartOptions['plugins']['legend']['labels']['padding'] = num;
      }
      if (paddingProp === 'paddingTop') {
        props.chartOptions['layout']['padding']['top'] = num;
      }
      if (paddingProp === 'paddingRight') {
        props.chartOptions['layout']['padding']['right'] = num;
      }
      if (paddingProp === 'paddingBottom') {
        props.chartOptions['layout']['padding']['bottom'] = num;
      }
      if (paddingProp === 'paddingLeft') {
        props.chartOptions['layout']['padding']['left'] = num;
      }
    });
  };

  const onDynamicContentChange = async (dynamicContent) => {
    if (dynamicContent && dynamicContent.dynamic_content_type === Constants.DynamicContentTypes.CHART) {
      setProp((props) => {
        props.chartDc = dynamicContent;
      });
    }
  };

  const onChartTypeChange = (chartType) => {
    let type = chartType.value;
    let indexAxis = 'x';
    if (chartType.value === 'bar') {
      indexAxis = 'y';
    }
    if (chartType.value === 'column') {
      type = 'bar';
    }
    if (chartType.value === 'area') {
      type = 'line';
    }
    if (chartType.value === 'donut') {
      type = 'pie';
    }
    setProp((props) => {
      props.chartOptions.indexAxis = indexAxis;
      props.chartType = chartType;
      props.type = type;
      if (chartType.value === 'area') {
        if (props.chartData.datasets) {
          props.chartData.datasets.forEach((dataset) => {
            dataset['fill'] = 'origin';
            if (!dataset['backgroundColor']) {
              dataset['backgroundColor'] = dataset['borderColor'];
            }
          });
        }
      }
      if (chartType.value === 'line') {
        if (props.chartData.datasets) {
          props.chartData.datasets.forEach((dataset) => {
            dataset['fill'] = 'none';
          });
        }
      }
    });
  };

  const onColorChange = (propName) => (color) => {
    setProp((props) => {
      if (propName === 'backgroundColor') {
        props.chartOptions.plugins.customCanvasBackgroundColor.color = color.hex;
      }
      if (propName === 'titleColor') {
        props.chartOptions.plugins.title.color = color.hex;
      }
      if (propName === 'subtitleColor') {
        props.chartOptions.plugins.subtitle.color = color.hex;
      }
      if (propName === 'legendColor') {
        props.chartOptions.plugins.legend.labels.color = color.hex;
      }
      if (propName === 'xScaleLabel') {
        props.chartOptions.scales.x.title.color = color.hex;
      }
      if (propName === 'yScaleLabel') {
        props.chartOptions.scales.y.title.color = color.hex;
      }
      if (propName === 'xScaleTicks') {
        props.chartOptions.scales.x.ticks.color = color.hex;
      }
      if (propName === 'yScaleTicks') {
        props.chartOptions.scales.y.ticks.color = color.hex;
      }
      if (propName === 'dataLabels') {
        props.chartOptions.plugins.datalabels.color = color.hex;
      }
    });
  };

  const onSeriesColorChange = (color) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.backgroundColor = color.hex;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onSeriesBorderThicknessChange = (e) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.borderWidth = e.target.value;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onSeriesBorderColorChange = (color) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.borderColor = color.hex;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onSeriesPointRadiusChange = (e) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.pointRadius = e.target.value;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onSeriesPointColorChange = (color) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.pointBackgroundColor = color.hex;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onSeriesPointBorderColorChange = (color) => {
    setProp((props) => {
      const seriesToUpdate = Object.assign({}, chartData.datasets[selectedChartSeriesIndex]);
      if (seriesToUpdate) {
        seriesToUpdate.pointBorderColor = color.hex;
        props.chartData.datasets[selectedChartSeriesIndex] = seriesToUpdate;
      }
    });
  };

  const onUpdateFontFamily = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      setProp((props) => {
        props.chartOptions.plugins.title.font.family = value;
        props.chartOptions.plugins.subtitle.font.family = value;
        props.chartOptions.plugins.legend.labels.font.family = value;
        props.chartOptions.scales.x.ticks.font.family = value;
        props.chartOptions.scales.y.ticks.font.family = value;
        props.chartOptions.scales.x.title.font.family = value;
        props.chartOptions.scales.y.title.font.family = value;
      });
    }
  };

  const onFontWeightChange = (value, element) => {
    setProp((props) => {
      if (element === 'title') {
        props.chartOptions.plugins.title.font.weight = value.value;
      }
      if (element === 'subtitle') {
        props.chartOptions.plugins.subtitle.font.weight = value.value;
      }
      if (element === 'legend') {
        props.chartOptions.plugins.legend.labels.font.weight = value.value;
      }
    });
  };

  const onFontSizeChange = (value, element) => {
    setProp((props) => {
      if (element === 'title') {
        props.chartOptions.plugins.title.font.size = value.value;
      }
      if (element === 'subtitle') {
        props.chartOptions.plugins.subtitle.font.size = value.value;
      }
      if (element === 'legend') {
        props.chartOptions.plugins.legend.labels.font.size = value.value;
      }
      if (element === 'xScaleLabel') {
        props.chartOptions.scales.x.title.font.size = value.value;
      }
      if (element === 'xScaleTicks') {
        props.chartOptions.scales.x.ticks.font.size = value.value;
      }
      if (element === 'yScaleLabel') {
        props.chartOptions.scales.y.title.font.size = value.value;
      }
      if (element === 'yScaleTicks') {
        props.chartOptions.scales.y.ticks.font.size = value.value;
      }
      if (element === 'dataLabels') {
        props.chartOptions.plugins.datalabels.font.size = value.value;
      }
    });
  };

  const onChartSubTypeSelect = (subType) => {
    setProp((props) => {
      if (subType.value === 'stacked' || subType.value === 'stacked100') {
        props.chartOptions.scales.x.stacked = true;
        props.chartOptions.scales.y.stacked = true;

        if (subType.value === 'stacked100') {
          props.chartOptions.plugins.stacked100.enable = true;
        } else {
          props.chartOptions.plugins.stacked100.enable = false;
        }
      } else {
        props.chartOptions.scales.x.stacked = false;
        props.chartOptions.scales.y.stacked = false;
        props.chartOptions.plugins.stacked100.enable = false;
      }
    });
  };

  const onShowTitleChange = (e, showTitle) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.plugins.title.display = !showTitle;
    });
  };

  const onShowSubtitleChange = (e, showSubtitle) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.plugins.subtitle.display = !showSubtitle;
    });
  };

  const onShowLegendChange = (e, showLegend) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.plugins.legend.display = !showLegend;
    });
  };

  // const onShowDataLabelsChange = (e, showDataLabels) => {
  //   e.preventDefault();
  //   setProp((props) => {
  //     props.chartOptions.plugins.datalabels.display = !showDataLabels;
  //   });
  // };

  const onShowAxisLabelChange = (e, showAxisLabel) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.scales[selectedAxis].title.display = !showAxisLabel;
    });
  };

  const onChartAxisChange = (value) => {
    setSelectedAxis(value.value);
  };

  const onAxisLabelTitleChange = (e) => {
    if (selectedAxis === 'x') {
      setCurrentInput('xTitle');
      setXTitle(e.target.value);
    } else {
      setCurrentInput('yTitle');
      setYTitle(e.target.value);
    }
  };

  const onTitleChange = (e) => {
    setCurrentInput('title');
    setTitle(e.target.value);
  };

  const onSubtitleChange = (e) => {
    setCurrentInput('subtitle');
    setSubtitle(e.target.value);
  };

  const onShowTicksChange = (e, showTicks) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.scales[selectedAxis].ticks.display = !showTicks;
    });
  };

  const onShowGridlinesChange = (e, showGridlines) => {
    e.preventDefault();
    setProp((props) => {
      props.chartOptions.scales[selectedAxis].grid.display = !showGridlines;
    });
  };

  const onChartSeriesChange = (value) => {
    setSelectedChartSeriesIndex(findIndex(chartData.datasets, { label: value.value }));
  };

  const editOnClick = () => {
    dispatch(openSidepane(utils.getTagFromSingleDynamicContent(chartDc), 'template-sidepane'));
  };

  const chartDataOptions = chartData.datasets.map((trace, idx) => {
    setProp((props) => {
      if (isUndefined(trace.borderWidth)) {
        props.chartData.datasets[idx].borderWidth = 0;
      }
    });

    return {
      value: trace.label,
      label: trace.label,
    };
  });

  return (
    // TOP MENU
    <div className="module-settings-container">
      <label>Select Data</label>
      <div>
        <div className="pts">
          <InputDynamicContent
            onEditClick={editOnClick}
            allDynamicContentNamesById={dynamicContentById}
            onChange={onDynamicContentChange}
            content={chartDc}
            acceptedContentTypes={[Constants.DynamicContentTypes.CHART]}
            showSubtitle={true}
          />
        </div>
        <div className="module-setting">
          <p className="sidebar-description help">Select the Dynamic Content that will populate your chart.</p>
        </div>
      </div>

      <div className="module-settings-divider" />

      {Object.keys(chartDc).length > 0 && (
        <>
          <label>Select Chart Type</label>
          <div className="pts">
            <HtmlBuilderChartTypeDropdown
              chartTypeOptions={chartTypeOptions}
              onChartTypeChange={onChartTypeChange}
              selectedChartType={chartType}
            />
          </div>

          <ChartSubTypeSelect
            hartOptions={chartOptions}
            chartType={chartType.value}
            onTypeSelect={onChartSubTypeSelect}
          />

          {/*<p className="is-secondary-description mt-4 mb-2">DataRange</p>*/}

          {/*<label>X-Axis</label>*/}
          {/*<div>*/}
          {/*  <div className="pts">*/}
          {/*    <Select options={[]} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<label>Series</label>*/}
          {/*<div>*/}
          {/*  <div className="pts">*/}
          {/*    <Select options={[]} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="module-setting pts pbxs">*/}
          {/*  <label>Use row 1 as headers</label>*/}
          {/*  <Level.Item className="has-text-centered"></Level.Item>*/}
          {/*</div>*/}

          <div className="module-settings-divider" />

          <div className="module-setting">
            <label>Padding</label>
            <div>
              <PaddingInput
                onPaddingChange={onPaddingChange}
                paddingBottom={chartOptions.layout.padding.bottom}
                paddingLeft={chartOptions.layout.padding.left}
                paddingRight={chartOptions.layout.padding.right}
                paddingTop={chartOptions.layout.padding.top}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Alignment</label>
            <div className="html-builder-settings-icons">
              <TextAlignLeftIcon
                className={`html-builder-settings-button ${chartAlignment === 'left' ? 'selected' : ''}`}
                onClick={(e) => onChartAlignmentChange(e, 'left', 'main')}
              />
              <TextAlignCenterIcon
                className={`html-builder-settings-button ${chartAlignment === 'center' ? 'selected' : ''}`}
                onClick={(e) => onChartAlignmentChange(e, 'center', 'main')}
              />
              <TextAlignRightIcon
                className={`html-builder-settings-button ${chartAlignment === 'right' ? 'selected' : ''}`}
                onClick={(e) => onChartAlignmentChange(e, 'right', 'main')}
              />
            </div>
          </div>

          {/*<div className="pt-2">*/}
          {/*  <ToggleSwitchWithLabel*/}
          {/*    label="Responsiveness"*/}
          {/*    onClick={(e) => onChartResponsivenessChange(e, chartOptions.responsive)}*/}
          {/*    switchState={chartOptions.responsive}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="module-setting">
            <label>Width</label>
            <FormInput
              inputClass="module-settings-input pixel-input"
              onChange={(e) => onUpdateNumberValue(e, 'width')}
              suffix={<span>px</span>}
              type="number"
              value={widthInput}
            />
          </div>

          <div className="module-setting">
            <label>Height</label>
            <FormInput
              inputClass="module-settings-input pixel-input"
              onChange={(e) => onUpdateNumberValue(e, 'height')}
              suffix={<span>px</span>}
              type="number"
              value={heightInput}
            />
          </div>

          <div className="module-setting">
            <label>Background Color</label>
            <PickColor
              onChange={onColorChange('backgroundColor')}
              hexColor={chartOptions.plugins?.customCanvasBackgroundColor?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="module-setting">
            <label>Border Color</label>
            <PickColor
              onChange={onColorChange('borderColor')}
              hexColor={null}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="module-setting">
            <label>Font</label>
            <div>
              <Select
                aria-label="Select Font"
                classNamePrefix="custom-select"
                id="font-family-select-dropdown"
                name="table-font-family"
                onChange={onUpdateFontFamily}
                options={fontFamilyOptions}
                value={find(fontFamilyOptions, { value: chartOptions?.scales?.x?.title?.font?.family })}
              />
            </div>
          </div>

          <div className="module-settings-divider" />

          <p className="is-secondary-description mt-4 mb-2">Series</p>

          <div className="pts">
            <Select
              options={chartDataOptions}
              value={chartDataOptions.filter(
                (trace) => trace.value === chartData.datasets[selectedChartSeriesIndex].label,
              )}
              onChange={onChartSeriesChange}
            />
          </div>

          {chartType.value === 'line' ? (
            <>
              <div className="module-setting">
                <label>Line Thickness</label>
                <FormInput
                  inputClass="module-settings-input pixel-input"
                  onChange={onSeriesBorderThicknessChange}
                  suffix={<span>px</span>}
                  type="number"
                  value={chartData.datasets[selectedChartSeriesIndex].borderWidth || 0}
                />
              </div>

              <div className="module-setting">
                <label>Line Color</label>
                <PickColor
                  onChange={onSeriesBorderColorChange}
                  hexColor={chartData.datasets[selectedChartSeriesIndex].borderColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>
            </>
          ) : (
            <>
              <div className="module-setting">
                <label>Fill Color</label>
                <PickColor
                  onChange={onSeriesColorChange}
                  hexColor={chartData.datasets[selectedChartSeriesIndex].backgroundColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>

              <div className="module-setting">
                <label>Border Thickness</label>
                <FormInput
                  inputClass="module-settings-input pixel-input"
                  onChange={onSeriesBorderThicknessChange}
                  suffix={<span>px</span>}
                  type="number"
                  value={chartData.datasets[selectedChartSeriesIndex].borderWidth || 0}
                />
              </div>

              <div className="module-setting">
                <label>Border Color</label>
                <PickColor
                  onChange={onSeriesBorderColorChange}
                  hexColor={chartData.datasets[selectedChartSeriesIndex].borderColor}
                  ancestorEl={document.querySelector('.template-components')}
                />
              </div>
            </>
          )}

          {chartType.value === 'line' ||
            (chartType.value === 'area' && (
              <>
                <div className="module-setting">
                  <label>Point Radius</label>
                  <FormInput
                    inputClass="module-settings-input pixel-input"
                    onChange={onSeriesPointRadiusChange}
                    suffix={<span>px</span>}
                    type="number"
                    value={chartData.datasets[selectedChartSeriesIndex].pointRadius || 0}
                  />
                </div>

                <div className="module-setting">
                  <label>Point Color</label>
                  <PickColor
                    onChange={onSeriesPointColorChange}
                    hexColor={chartData.datasets[selectedChartSeriesIndex].pointBackgroundColor}
                    ancestorEl={document.querySelector('.template-components')}
                  />
                </div>

                <div className="module-setting">
                  <label>Point Border Color</label>
                  <PickColor
                    onChange={onSeriesPointBorderColorChange}
                    hexColor={chartData.datasets[selectedChartSeriesIndex].pointBorderColor}
                    ancestorEl={document.querySelector('.template-components')}
                  />
                </div>
              </>
            ))}

          <div className="module-settings-divider" />

          <p className="is-secondary-description mt-4 mb-2">Title</p>

          <div className="pt-2">
            <ToggleSwitchWithLabel
              label="Show Title"
              onClick={(e) => onShowTitleChange(e, chartOptions.plugins.title.display)}
              switchState={chartOptions.plugins.title.display}
            />
          </div>

          <div className="module-setting w-full mts">
            <FormInput
              inputClass="module-settings-input html-builder-text-input w-full"
              onChange={onTitleChange}
              type="text"
              value={title}
              placeholder="Enter Chart Title"
            />
          </div>

          <div className="module-setting">
            <label>Font size</label>
            <div className="module-settings-select">
              <CreatableSelect
                aria-label="Select Font Size"
                classNamePrefix="custom-select"
                id="font-size-select-dropdown"
                name="chart-font-size"
                onChange={(value) => onFontSizeChange(value, 'title')}
                options={htmlUtils.constants.fontSizeOptions}
                value={find(htmlUtils.constants.fontSizeOptions, { value: chartOptions.plugins.title.font.size })}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Font Color</label>
            <PickColor
              onChange={onColorChange('titleColor')}
              hexColor={chartOptions.plugins?.title?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="module-setting">
            <label>Font weight</label>
            <div>
              <CreatableSelect
                aria-label="Select Font Weight"
                classNamePrefix="custom-select"
                id="font-weight-select-dropdown"
                name="font-weight"
                onChange={(value) => onFontWeightChange(value, 'title')}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                ]}
                value={find(
                  [
                    { label: 'Normal', value: 'normal' },
                    { label: 'Bold', value: 'bold' },
                  ],
                  { value: chartOptions.plugins.title.font.weight },
                )}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Padding</label>
            <FormInput
              inputClass="module-settings-input pixel-input"
              onChange={(e) => {
                e.preventDefault();
                onPaddingChange(e.target.value, 'titlePadding');
              }}
              suffix={<span>px</span>}
              type="number"
              value={chartOptions.plugins.title.padding}
            />
          </div>

          <div className="module-setting ptxs pbxs">
            <label>Alignment</label>
            <div className="html-builder-settings-icons">
              <TextAlignLeftIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.title.align === 'start' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'start', 'title')}
              />
              <TextAlignCenterIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.title.align === 'center' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'center', 'title')}
              />
              <TextAlignRightIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.title.align === 'end' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'end', 'title')}
              />
            </div>
          </div>

          <div className="module-setting ptxs pbxs">
            <label>Position</label>
            <div className="html-builder-settings-icons">
              <div
                className={`html-builder-settings-button ${
                  chartOptions.plugins.title.position === 'top' ? 'selected' : ''
                }`}
                onClick={(e) => onPositionChange(e, 'top', 'title')}
              >
                <Icon name="textbox_align_top" size={16} theme="regular" />
              </div>
            </div>
          </div>

          <div className="module-settings-divider" />

          <p className="is-secondary-description mt-4 mb-2">Subtitle</p>

          <div className="pt-2">
            <ToggleSwitchWithLabel
              label="Show Subtitle"
              onClick={(e) => onShowSubtitleChange(e, chartOptions.plugins.subtitle.display)}
              switchState={chartOptions.plugins.subtitle.display}
            />
          </div>

          <div className="module-setting w-full mts">
            <FormInput
              inputClass="module-settings-input html-builder-text-input w-full"
              onChange={onSubtitleChange}
              type="text"
              value={subtitle}
              placeholder="Enter Chart Subtitle"
            />
          </div>

          <div className="module-setting">
            <label>Font size</label>
            <div className="module-settings-select">
              <CreatableSelect
                aria-label="Select Font Size"
                classNamePrefix="custom-select"
                id="font-size-select-dropdown"
                name="chart-font-weight"
                onChange={(value) => onFontSizeChange(value, 'subtitle')}
                options={htmlUtils.constants.fontSizeOptions}
                value={find(htmlUtils.constants.fontSizeOptions, { value: chartOptions.plugins.subtitle.font.size })}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Font Color</label>
            <PickColor
              onChange={onColorChange('subtitleColor')}
              hexColor={chartOptions.plugins?.subtitle?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="module-setting">
            <label>Font Weight</label>
            <div>
              <CreatableSelect
                aria-label="Select Font Weight"
                classNamePrefix="custom-select"
                id="font-weight-select-dropdown"
                name="font-weight"
                onChange={(value) => onFontWeightChange(value, 'subtitle')}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                ]}
                value={find(
                  [
                    { label: 'Normal', value: 'normal' },
                    { label: 'Bold', value: 'bold' },
                  ],
                  { value: chartOptions.plugins.subtitle.font.weight },
                )}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Padding</label>
            <FormInput
              inputClass="module-settings-input pixel-input"
              onChange={(e) => {
                e.preventDefault();
                onPaddingChange(e.target.value, 'subtitlePadding');
              }}
              suffix={<span>px</span>}
              type="number"
              value={chartOptions.plugins.subtitle.padding}
            />
          </div>

          <div className="module-setting ptxs pbxs">
            <label>Alignment</label>
            <div className="html-builder-settings-icons">
              <TextAlignLeftIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.subtitle.align === 'start' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'start', 'subtitle')}
              />
              <TextAlignCenterIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.subtitle.align === 'center' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'center', 'subtitle')}
              />
              <TextAlignRightIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.subtitle.align === 'end' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'end', 'subtitle')}
              />
            </div>
          </div>

          <div className="module-setting ptxs pbxs">
            <label>Position</label>
            <div className="html-builder-settings-icons">
              <div
                className={`html-builder-settings-button ${
                  chartOptions.plugins.subtitle.position === 'top' ? 'selected' : ''
                }`}
                onClick={(e) => onPositionChange(e, 'top', 'subtitle')}
              >
                <Icon name="textbox_align_top" size={16} theme="regular" />
              </div>
              <div
                className={`html-builder-settings-button ${
                  chartOptions.plugins.subtitle.position === 'bottom' ? 'selected' : ''
                }`}
                onClick={(e) => onPositionChange(e, 'bottom', 'subtitle')}
              >
                <Icon name="textbox_align_bottom" size={16} theme="regular" />
              </div>
            </div>
          </div>

          <div className="module-settings-divider" />

          <p className="is-secondary-description mt-4 mb-2">Legend</p>

          <div className="pt-2">
            <ToggleSwitchWithLabel
              label="Show Legend"
              onClick={(e) => onShowLegendChange(e, chartOptions.plugins.legend.display)}
              switchState={chartOptions.plugins.legend.display}
            />
          </div>

          <div className="module-setting">
            <label>Font size</label>
            <div className="module-settings-select">
              <CreatableSelect
                aria-label="Select Font Size"
                classNamePrefix="custom-select"
                id="font-size-select-dropdown"
                name="chart-font-weight"
                onChange={(value) => onFontSizeChange(value, 'legend')}
                options={htmlUtils.constants.fontSizeOptions}
                value={find(htmlUtils.constants.fontSizeOptions, {
                  value: chartOptions.plugins.legend.labels.font.size,
                })}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Font Color</label>
            <PickColor
              onChange={onColorChange('legendColor')}
              hexColor={chartOptions.plugins?.legend?.labels?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="module-setting">
            <label>Font Weight</label>
            <div>
              <CreatableSelect
                aria-label="Select Font Weight"
                classNamePrefix="custom-select"
                id="font-weight-select-dropdown"
                name="font-weight"
                onChange={(value) => onFontWeightChange(value, 'legend')}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                ]}
                value={find(
                  [
                    { label: 'Normal', value: 'normal' },
                    { label: 'Bold', value: 'bold' },
                  ],
                  { value: chartOptions.plugins.legend.labels.font.weight },
                )}
              />
            </div>
          </div>

          {/*PADDING NOT WORKING FOR LEGEND*/}
          {/*<div className="module-setting">*/}
          {/*  <label>Padding</label>*/}
          {/*  <FormInput*/}
          {/*    inputClass="module-settings-input pixel-input"*/}
          {/*    onChange={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      onPaddingChange(e.target.value, 'legendPadding')*/}
          {/*    }}*/}
          {/*    suffix={<span>px</span>}*/}
          {/*    type="number"*/}
          {/*    value={chartOptions.plugins.legend.labels.padding}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="module-setting ptxs pbxs">
            <label>Alignment</label>
            <div className="html-builder-settings-icons">
              <TextAlignLeftIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.legend.align === 'start' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'start', 'legend')}
              />
              <TextAlignCenterIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.legend.align === 'center' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'center', 'legend')}
              />
              <TextAlignRightIcon
                className={`html-builder-settings-button ${
                  chartOptions.plugins.legend.align === 'end' ? 'selected' : ''
                }`}
                onClick={(e) => onChartAlignmentChange(e, 'end', 'legend')}
              />
            </div>
          </div>

          <div className="module-setting ptxs pbxs">
            <label>Position</label>
            <div className="html-builder-settings-icons">
              <div
                className={`html-builder-settings-button ${
                  chartOptions.plugins.legend.position === 'top' ? 'selected' : ''
                }`}
                onClick={(e) => onPositionChange(e, 'top', 'legend')}
              >
                <Icon name="textbox_align_top" size={16} theme="regular" />
              </div>
              <div
                className={`html-builder-settings-button ${
                  chartOptions.plugins.legend.position === 'bottom' ? 'selected' : ''
                }`}
                onClick={(e) => onPositionChange(e, 'bottom', 'legend')}
              >
                <Icon name="textbox_align_bottom" size={16} theme="regular" />
              </div>
            </div>
          </div>

          <div className="module-settings-divider" />

          <p className="is-secondary-description mt-4 mb-2">Axis Labels</p>

          <div className="pts">
            <Select
              options={axisOptions}
              value={axisOptions.filter((axis) => axis.value === selectedAxis)}
              onChange={onChartAxisChange}
            />
          </div>

          <div className="pt-2">
            <ToggleSwitchWithLabel
              label="Show Axis Title"
              onClick={(e) => onShowAxisLabelChange(e, chartOptions.scales[selectedAxis]?.title?.display)}
              switchState={chartOptions.scales[selectedAxis]?.title?.display}
            />
          </div>

          <div className="module-setting w-full mts">
            <FormInput
              inputClass="module-settings-input html-builder-text-input w-full"
              onChange={(e) => onAxisLabelTitleChange(e)}
              type="text"
              value={selectedAxis === 'x' ? xTitle : yTitle}
              placeholder="Enter Axis Title"
            />
          </div>

          <div className="module-setting">
            <label>Title Font size</label>
            <div className="module-settings-select">
              <CreatableSelect
                aria-label="Select Font Size"
                classNamePrefix="custom-select"
                id="font-size-select-dropdown"
                name="chart-font-weight"
                onChange={(value) => onFontSizeChange(value, `${selectedAxis}ScaleLabel`)}
                options={htmlUtils.constants.fontSizeOptions}
                value={find(htmlUtils.constants.fontSizeOptions, {
                  value: chartOptions.scales[selectedAxis].title.font.size,
                })}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Title Font Color</label>
            <PickColor
              onChange={onColorChange(`${selectedAxis}ScaleLabel`)}
              hexColor={chartOptions.scales[selectedAxis]?.title?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          <div className="pt-2 pb-2">
            <ToggleSwitchWithLabel
              label="Show Grid Lines"
              onClick={(e) => onShowGridlinesChange(e, chartOptions.scales[selectedAxis]?.grid?.display)}
              switchState={chartOptions.scales[selectedAxis]?.grid?.display}
            />
          </div>

          <div className="pt-2 pb-2">
            <ToggleSwitchWithLabel
              label="Show Axis Ticks"
              onClick={(e) => onShowTicksChange(e, chartOptions.scales[selectedAxis]?.ticks?.display)}
              switchState={chartOptions.scales[selectedAxis]?.ticks?.display}
            />
          </div>

          <div className="module-setting">
            <label>Ticks Font size</label>
            <div className="module-settings-select">
              <CreatableSelect
                aria-label="Select Font Size"
                classNamePrefix="custom-select"
                id="font-size-select-dropdown"
                name="chart-font-weight"
                onChange={(value) => onFontSizeChange(value, `${selectedAxis}ScaleTicks`)}
                options={htmlUtils.constants.fontSizeOptions}
                value={find(htmlUtils.constants.fontSizeOptions, {
                  value: chartOptions.scales[selectedAxis].ticks.font.size,
                })}
              />
            </div>
          </div>

          <div className="module-setting">
            <label>Ticks Font Color</label>
            <PickColor
              onChange={onColorChange(`${selectedAxis}ScaleTicks`)}
              hexColor={chartOptions.scales[selectedAxis]?.ticks?.color}
              ancestorEl={document.querySelector('.template-components')}
            />
          </div>

          {/*// DATALABELS NOT WORKING WITH CHARTJS-NODE-CANVAS*/}
          {/*//*/}
          {/*// <div className="module-settings-divider" />*/}
          {/*//*/}
          {/*// <p className="is-secondary-description mt-4 mb-2">Data Labels</p>*/}
          {/*//*/}
          {/*// <div className="pt-2">*/}
          {/*//   <ToggleSwitchWithLabel*/}
          {/*//     label="Show Data Labels"*/}
          {/*//     onClick={(e) => onShowDataLabelsChange(e, chartOptions.plugins.datalabels.display)}*/}
          {/*//     switchState={chartOptions.plugins.datalabels.display}*/}
          {/*//   />*/}
          {/*// </div>*/}
          {/*//*/}
          {/*// <div className="module-setting">*/}
          {/*//   <label>Font size</label>*/}
          {/*//   <div className="module-settings-select">*/}
          {/*//     <CreatableSelect*/}
          {/*//       aria-label="Select Font Size"*/}
          {/*//       classNamePrefix="custom-select"*/}
          {/*//       id="font-size-select-dropdown"*/}
          {/*//       name="chart-font-weight"*/}
          {/*//       onChange={(value) => onFontSizeChange(value, 'dataLabels')}*/}
          {/*//       options={htmlUtils.constants.fontSizeOptions}*/}
          {/*//       value={find(htmlUtils.constants.fontSizeOptions, { value: chartOptions.plugins.datalabels.font.size })}*/}
          {/*//     />*/}
          {/*//   </div>*/}
          {/*// </div>*/}
          {/*//*/}
          {/*// <div className="module-setting">*/}
          {/*//   <label>Font Color</label>*/}
          {/*//   <PickColor*/}
          {/*//     onChange={onColorChange('dataLabels')}*/}
          {/*//     hexColor={chartOptions.plugins.datalabels.color}*/}
          {/*//     ancestorEl={document.querySelector('.template-components')}*/}
          {/*//   />*/}
          {/*// </div>*/}
        </>
      )}
    </div>
  );
}

ChartSettings.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.array,
  style: PropTypes.object,
};

export default Chart;
