import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FullScreenModal from 'components/shared/modal/FullScreenModal';
import matik_logo_black from 'images/matik_logo_black.svg';
import EmailNarrativeCard from 'components/producer/templates/EmailNarrativeCard';
import PresentationNarrativeCard from 'components/producer/templates/PresentationNarrativeCard';
import moment from 'moment';
import teams from 'lib/teams';
import ModalTemplateLibrary from 'components/producer/templates/ModalTemplateLibrary';
import { UserContext } from 'components/UserContext';
import DocumentNarrativeCard from 'components/producer/templates/DocumentNarrativeCard';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import API from 'lib/api';

const AddNarrativeModal = ({
  onEmailNarrativeClick,
  onTemplateAdd,
  templates,
  allTemplatesCount,
  onPresentationNarrativeClick,
  show,
  onClose,
}) => {
  const userContext = useContext(UserContext);
  const { enableEmailBuilder, addDocsTemplate } = useFlags();
  const emailEnabled = userContext.user?.enterprise?.enterprise_settings?.matik_mail_enabled;

  const { createFast: createTemplate } = useTemplateMutator();

  const handleEmailNarrativeClick = () => {
    if (enableEmailBuilder) {
      onEmailNarrativeClick();
    } else {
      if (userContext.user && !teams.isTeamsUser(userContext.user.enterprise.plan_id)) {
        const templateData = {
          name: 'Email Template ' + moment(),
          source_type: 'email',
          source: {
            subject: '',
            recipients: { cc: [], dynamicRecipients: '' },
            from: { name: '', fromEmail: 'no-reply@matik.io', replyEmail: '' },
            editor_type: 'code',
          },
        };
        // "null" file triggers a multipart/form-data post, so we need it.
        createTemplate(templateData, null)
          .then((newTemplate) => onTemplateAdd(newTemplate))
          .catch(API.defaultError);
      } else {
        const demoUrl = teams.buildRequestDemoUrl(
          userContext.user,
          'email_narrative',
          userContext.user.enterprise.trial_days_remaining,
        );
        window.open(demoUrl, '_blank');
      }
    }
  };

  const headerImage = <img src={matik_logo_black} alt="matik logo" style={{ maxWidth: '100px' }} />;
  let noAccessTemplateCount = templates ? allTemplatesCount - templates.length : 0;
  return (
    <FullScreenModal show={show} onClose={onClose} showDefaultFooter={false} headerCenter={headerImage}>
      <div className="add-template-body">
        <h3>Select your template format</h3>
        <div className="add-template-cards">
          {emailEnabled && (
            <EmailNarrativeCard onEmailNarrativeClick={handleEmailNarrativeClick} user={userContext.user} />
          )}
          <PresentationNarrativeCard onPresentationNarrativeClick={onPresentationNarrativeClick} />
          {addDocsTemplate && <DocumentNarrativeCard onTemplateAdd={onTemplateAdd} />}
        </div>
        <ModalTemplateLibrary noAccessTemplateCount={noAccessTemplateCount} />
      </div>
    </FullScreenModal>
  );
};

AddNarrativeModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onPresentationNarrativeClick: PropTypes.func,
  onEmailNarrativeClick: PropTypes.func,
  templates: PropTypes.array,
  allTemplatesCount: PropTypes.number,
};

export default AddNarrativeModal;
